import { useQuery } from "@tanstack/react-query"

import { getSertifikasiDiscount } from "../functions"
import { CmsQueryKey, GetDiscountSertifikasiParams } from "../../types"

const useGetSertifikasiDiscount = (params?: GetDiscountSertifikasiParams) => {
    return useQuery({
        queryKey: [CmsQueryKey.DiscountCertification, params?.qParams ?? ""],
        queryFn: () => getSertifikasiDiscount(params?.qParams),
        ...params?.options,
    })
}

export default useGetSertifikasiDiscount
