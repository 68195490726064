import { useQuery } from "@tanstack/react-query"

import { getTransactionHistories } from "../functions"
import { getTransactionHistoriesParams, UserQueryKey } from "../../types"

const useGetTransactionHistories = (params?: getTransactionHistoriesParams) => {
  return useQuery({
    queryKey: [UserQueryKey.TransactionHistories, params?.qParams ?? {}],
    queryFn: () => getTransactionHistories(params?.qParams),
    ...params?.options,
  })
}

export default useGetTransactionHistories
