import { useMutation } from "@tanstack/react-query"

import { GeneralCareerResponse, UpdateCareerRequest } from "../../types"
import { updateCareer } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationUpdateCareer = (
  options?: GeneralMutationParams<GeneralCareerResponse, UpdateCareerRequest>
) => {
  return useMutation({
    mutationFn: ({ id, payload }) => updateCareer(id, payload),
    ...options,
  })
}

export default useMutationUpdateCareer
