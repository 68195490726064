import { requestCMS } from "utils/axiosInstance"
import type { GetVideoMaxSequenceResponse } from "../../types"

const getVideoMaxSequence = async (sectionID: string) => {
  const res = await requestCMS.get<GetVideoMaxSequenceResponse>(
    `employee/course/section/video/max/${sectionID}`
  )
  return res
}

export default getVideoMaxSequence
