import { useQuery } from "@tanstack/react-query"
import { getUsers } from "../functions"
import { CmsQueryKey, GetUsersParams } from "../../types"

const useGetUsers = (params?: GetUsersParams) => {
  return useQuery({
    queryKey: [CmsQueryKey.Users, params?.qParams ?? ""],
    queryFn: () => getUsers(params?.qParams),
    ...params?.options,
  })
}

export default useGetUsers
