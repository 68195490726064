import { requestCMS } from "utils/axiosInstance"
import type { Course, GeneralApiResponse, QueryParams } from "utils/types"

const getCourses = async (qParams?: QueryParams) => {
  const res = await requestCMS.get<GeneralApiResponse<Course[]>>(
    "/employee/course",
    qParams
  )
  return res
}

export default getCourses
