import { useMutation } from "@tanstack/react-query"

import type { CreateVideoResponse, UpdateVideoRequest } from "../../types"
import { updateVideo } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useUpdateVideo = (
  options?: GeneralMutationParams<CreateVideoResponse, UpdateVideoRequest>
) => {
  return useMutation({
    mutationFn: ({ videoID, payload }) => updateVideo(videoID, payload),
    ...options,
  })
}

export default useUpdateVideo
