import { useMutation } from "@tanstack/react-query"

import { GeneralApiResponse, GeneralMutationParams } from "utils/types"
import { logout } from "../functions"

const useMutationLogout = (
    options?: GeneralMutationParams<GeneralApiResponse<null>, {}>
) => {
    return useMutation({
        mutationFn: logout,
        ...options,
    })
}

export default useMutationLogout
