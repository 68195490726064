import { requestPartnership } from "utils/axiosInstance"
import { DashboardResponse } from "../type"

const dashboard = async (startDate: string, endDate: string) => {
    const res = await requestPartnership.get<DashboardResponse>(
        `/partnership/dashboard?tgl_awal=${startDate}&tgl_akhir=${endDate}`
    )
    return res
}

export default dashboard
