import clsxm from "utils/clsxm"
import SectionTitle from "./SectionTitle"

type Space = "top-bottom" | "top" | "bottom" | "right-left" | "right" | "left"

interface SectionProps {
  space?: Space
  className?: string
  withContainer?: boolean
  withBgColor?: boolean
  children: React.ReactNode
}

const Section = ({
  space,
  withContainer,
  withBgColor,
  className,
  children,
}: SectionProps) => {
  return (
    <section
      className={clsxm(
        "relative z-1",
        [withContainer && "container "],
        [withBgColor && "lg:px-32 py-12 px-4"],
        [
          space === "top-bottom" && "my-20",
          space === "top" && "mt-20",
          space === "bottom" && "mb-20",
        ],
        className
      )}
    >
      {children}
    </section>
  )
}

Section.Title = SectionTitle

export default Section
