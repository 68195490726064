import Card from "components/ui/card/vertical"
import clsx from "clsx"
import Typography from "components/ui/typography"

interface AboutCardProps {
  title: string
  desc: string
  icon: string
}

const AboutCard = ({ title, desc, icon }: AboutCardProps) => {
  return (
    <Card className="shadow-2xl">
      <div className="flex justify-center gap-6">
        <img
          className="w-10 h-10"
          src={icon}
          alt={title}
        />
        <div className="flex flex-col">
          <Card.Title
            as="p"
            className="text-primary text-xl"
          >
            {title}
          </Card.Title>
          <Typography
            variant="text2"
            className="font-medium text-justify"
          >
            {desc}
          </Typography>
        </div>
      </div>
    </Card>
  )
}

export default AboutCard
