import { useMemo } from "react"
import { KeyIcon, UserPlusIcon } from "@heroicons/react/24/solid"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"

import Card from "components/ui/card/horizontal"
import { getCMSSegments } from "utils/helper"
import { useEmployeeInfoStore } from "utils/store/employee-info"

const Settings = () => {
  const segments = getCMSSegments()
  const employeeInfo = useEmployeeInfoStore((state) => state.employeeInfo)

  const isSuperAdmin = useMemo(
    () => employeeInfo.roles.some((role) => role.name === "SUPERADMIN"),
    [employeeInfo]
  )

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix="cms"
      />
      <div className="flex gap-6">
        <Card
          to="change-password"
          className="flex gap-2 bg-white"
          scale
        >
          <KeyIcon
            width={24}
            height={24}
          />
          Ubah Password
        </Card>
        {isSuperAdmin && (
          <Card
            to="create-employee"
            className="flex gap-2 bg-white"
            scale
          >
            <UserPlusIcon
              width={24}
              height={24}
            />
            Buat Admin Baru
          </Card>
        )}
      </div>
    </EmployeeDashboardWrapper>
  )
}

export default Settings
