import { lazy, Suspense, useState } from "react"
import { CustomFlowbiteTheme, Tabs } from "flowbite-react"
import { DocumentPlusIcon, PencilSquareIcon } from "@heroicons/react/24/solid"
import { Link } from "react-router-dom"

import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import Typography from "components/ui/typography"
import { getCMSSegments } from "utils/helper"
import Button from "components/ui/button/Button"
import useGetCareerCategories from "utils/api/cms/queries/hooks/useGetCareerCategories"
import { createColumnHelper, PaginationState } from "@tanstack/react-table"
import { CareerCategory } from "utils/api/cms/types"
import ReactTable from "components/ui/react-table/ReactTable"

const CareerCategoryManagement = () => {
  const segments = getCMSSegments()
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 1,
  })
  const [searchFilter, setSearchFilter] = useState("")
  const [perPageLimit, setPerPageLimit] = useState(10)

  const { data } = useGetCareerCategories({
    options: {
      staleTime: 0,
    },
    qParams: {
      limit: perPageLimit,
      page: pagination.pageIndex + 1,
      keyword: searchFilter,
    },
  })

  const careerCatColHelper = createColumnHelper<CareerCategory>()
  const careerCatColumns = [
    careerCatColHelper.accessor("name", {
      header: () => "Nama Kategori",
    }),
    careerCatColHelper.accessor("created_at", {
      header: () => "Dibuat Pada",
      cell: (props) => props.getValue().replace(/(<([^>]+)>)/gi, ""),
    }),
    careerCatColHelper.display({
      id: "actions",
      header: "Aksi",
      cell: (props) => (
        <div className="flex flex-row w-fit space-x-3">
          <Button
            as={Link}
            to={`edit/${props.row.original.id}`}
          >
            <PencilSquareIcon
              width={20}
              height={20}
            />
          </Button>
        </div>
      ),
    }),
  ]

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix="cms"
      />
      <Typography
        variant="subheading4"
        as="h1"
        className="text-primary my-2"
      >
        Pengaturan Kategori Karir
      </Typography>
      <div className="flex flex-wrap justify-between gap-4 mb-4">
        <Button
          as={Link}
          to="create"
          className="inline-flex items-center gap-2 py-[10px] px-5 lg:ml-auto"
        >
          <DocumentPlusIcon className="h-6 w-6 text-white" />
          <span>Buat Kategori Karir</span>
        </Button>
      </div>

      <ReactTable
        columns={careerCatColumns}
        data={data?.data ?? []}
        pagination={pagination}
        onPaginationChange={setPagination}
        pageCount={data?.meta?.last_page ?? 1}
      />
    </EmployeeDashboardWrapper>
  )
}

const theme: CustomFlowbiteTheme["tab"] = {
  tablist: {
    tabitem: {
      base: "flex items-center justify-center p-4 text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 focus:outline-none",
      styles: {
        underline: {
          active: {
            on: "text-primary border-b-2 border-primary active dark:text-primary dark:border-primary",
            off: "border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300",
          },
        },
      },
    },
  },
}

export default CareerCategoryManagement
