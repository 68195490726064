import Typography from "components/ui/typography"

interface CardDescriptionProps {
  className?: string
  children: React.ReactNode
}

const CardDescription = ({ children, className }: CardDescriptionProps) => {
  return <Typography className={className}>{children}</Typography>
}

export default CardDescription
