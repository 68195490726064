import { useQuery } from "@tanstack/react-query"

import { getWebinarPeserta } from "../functions"
import { CmsQueryKey, GetWebinarPesertaParams } from "../../types"

const useGetPesertaWebinar = (params?: GetWebinarPesertaParams) => {
    return useQuery({
        queryKey: [CmsQueryKey.WebinarPeserta, params?.qParams ?? ""],
        queryFn: () => getWebinarPeserta(params?.qParams),
        ...params?.options,
    })
}

export default useGetPesertaWebinar
