import { PowerIcon, Cog6ToothIcon } from "@heroicons/react/24/solid"
import { Avatar, Dropdown } from "flowbite-react"
import Cookies from "js-cookie"
import { Link, NavLink, useNavigate } from "react-router-dom"

import { useMutationLogout } from "utils/api/employee/mutations/hooks"
import { useEmployeeInfoStore } from "utils/store/employee-info"

const EmployeeNavigation: React.FC = () => {
  const navigate = useNavigate()
  const resetEmployeeInfo = useEmployeeInfoStore(
    (state) => state.resetEmployeeInfo
  )

  const { mutate } = useMutationLogout({
    onSuccess() {
      Cookies.remove("cms_auth_token")
      resetEmployeeInfo()
      navigate("/cms/login")
    },
  })

  const handleLogout = () => {
    mutate({})
  }

  return (
    <>
      <nav className="border-gray-800 bg-gray-950 sticky top-0 z-50">
        <div className="max-w-screen-xl flex flex-wrap justify-between items-center mx-auto p-4">
          <Dropdown
            arrowIcon={false}
            inline
            label={
              <svg
                className="w-6 h-6 text-gray-500 md:hidden"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                  clipRule="evenodd"
                ></path>
              </svg>
            }
          >
            <Link to="course">
              <Dropdown.Item>
                Course
              </Dropdown.Item>
            </Link>
            <Link to="slide">
              <Dropdown.Item>
                Slide
              </Dropdown.Item>
            </Link>
            <Link to="webinar">
              <Dropdown.Item>
                Webinar
              </Dropdown.Item>
            </Link>
            <Link to="career">
              <Dropdown.Item>
                Karir
              </Dropdown.Item>
            </Link>
            <Link to="partnership">
              <Dropdown.Item>
                Partnership
              </Dropdown.Item>
            </Link>
            <Link to="user">
              <Dropdown.Item>
                User
              </Dropdown.Item>
            </Link>
            <Link to="sertifikat">
              <Dropdown.Item>
                Sertifikasi
              </Dropdown.Item>
            </Link>
            <Dropdown className="bg-primary" label="Master" placement="right-start" >
              <Link to="master/discount">
                <Dropdown.Item>
                  Discount
                </Dropdown.Item>
              </Link>
              <Link to="master/referral">
                <Dropdown.Item>
                  Kode Referral
                </Dropdown.Item>
              </Link>
              <Link to="master/course-category">
                <Dropdown.Item>
                  Kategori Pembelajaran
                </Dropdown.Item>
              </Link>
              <Link to="master/webinar-category">
                <Dropdown.Item>
                  Kategori Webinar
                </Dropdown.Item>
              </Link>
              <Link to="master/career-category">
                <Dropdown.Item>
                  Kategori Karir
                </Dropdown.Item>
              </Link>
            </Dropdown>
          </Dropdown>
          <Link
            to={"/cms/dashboard"}
            className="flex items-center"
          >
            <span className="self-center text-2xl font-bold whitespace-nowrap text-primary">
              BPI CMS
            </span>
          </Link>
          <div className="flex order-2">
            <Dropdown
              arrowIcon={false}
              inline
              label={
                <Avatar
                  placeholderInitials="EM"
                  rounded
                />
              }
            >
              <Dropdown.Item
                as={NavLink}
                to="/cms/settings"
                className="flex flex-row items-center space-x-2"
              >
                <Cog6ToothIcon className="h-4 w-4 text-gray-600" />
                <span>Pengaturan</span>
              </Dropdown.Item>
              <Dropdown.Item
                onClick={handleLogout}
                className="flex flex-row items-center space-x-2"
              >
                <PowerIcon className="h-4 w-4 text-gray-600" />
                <span>Keluar</span>
              </Dropdown.Item>
            </Dropdown>
          </div>
          <div
            className="hidden w-full mx-auto md:block md:w-auto"
            id="navbar-solid-bg"
          >
            <ul className="flex flex-col font-medium mt-4 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-transparent">
              <li>
                <NavLink
                  to="course"
                  className="block py-2 pl-3 pr-4  text-white rounded md:bg-transparent md:p-0 hover:text-primary"
                >
                  Course
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="slide"
                  className="block py-2 pl-3 pr-4  text-white rounded md:bg-transparent md:p-0 hover:text-primary"
                >
                  Slide
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="webinar"
                  className="block py-2 pl-3 pr-4  text-white rounded md:bg-transparent md:p-0 hover:text-primary"
                >
                  Webinar
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="career"
                  className="block py-2 pl-3 pr-4  text-white rounded md:bg-transparent md:p-0 hover:text-primary"
                >
                  Karir
                </NavLink>
              </li>
              <li>
                <Dropdown
                  dismissOnClick={true}
                  arrowIcon={false}
                  inline
                  label={
                    <div className="flex items-center justify-between w-full py-2 pl-3 pr-4 text-white rounded md:bg-transparent md:text-white md:p-0 hover:text-primary">
                      Master
                      <svg
                        className="w-5 h-5 ml-1"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                          clipRule="evenodd"
                        ></path>
                      </svg>
                    </div>
                  }
                >
                  <Dropdown.Item
                    as={NavLink}
                    to="master/discount"
                  >
                    Discount
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={NavLink}
                    to="master/referral"
                  >
                    Kode Referral
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={NavLink}
                    to="master/course-category"
                  >
                    Kategori Pembelajaran
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={NavLink}
                    to="master/webinar-category"
                  >
                    Kategori Webinar
                  </Dropdown.Item>
                  <Dropdown.Item
                    as={NavLink}
                    to="master/career-category"
                  >
                    Kategori Karir
                  </Dropdown.Item>
                </Dropdown>
              </li>
              <li>
                <NavLink
                  to="partnership"
                  className="block py-2 pl-3 pr-4  text-white rounded md:bg-transparent md:p-0 hover:text-primary"
                >
                  Partnership
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="user"
                  className="block py-2 pl-3 pr-4  text-white rounded md:bg-transparent md:p-0 hover:text-primary"
                >
                  User
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="sertifikat"
                  className="block py-2 pl-3 pr-4  text-white rounded md:bg-transparent md:p-0 hover:text-primary"
                >
                  Sertifikasi
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  )
}

export default EmployeeNavigation
