import { requestCMS } from "utils/axiosInstance"
import type { UploadIcon, UploadIconResponse } from "../../types"

const uploadThumbnailCompanyIcon = async (
  careerId: string,
  payload: UploadIcon
) => {
  const res = await requestCMS.upload<UploadIconResponse>(
    `/employee/career/upload-comp-icon/${careerId}`,
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  )
  return res
}

export default uploadThumbnailCompanyIcon
