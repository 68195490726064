import { useMutation } from "@tanstack/react-query"

import {
  GeneralPartnershipResponse,
  UpdatePartnershipRequest,
} from "../../types"
import { updatePartnership } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationUpdatePartnership = (
  options?: GeneralMutationParams<
    GeneralPartnershipResponse,
    UpdatePartnershipRequest
  >
) => {
  return useMutation({
    mutationFn: ({ id, payload }) => updatePartnership(id, payload),
    ...options,
  })
}

export default useMutationUpdatePartnership
