import { SubmitHandler, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import TextInput from "components/ui/form/TextInput"
import { useMutationCreateCareerCategory } from "utils/api/cms/mutations/hooks"
import { GeneralCareerCategoryRequest } from "utils/api/cms/types"
import {
  BookmarkIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"
import useMutationUpdateCareerCategory from "utils/api/cms/mutations/hooks/useMutationUpdateCareerCategory"
import useGetCareerCategoryDetail from "utils/api/cms/queries/hooks/useGetCareerCategoryDetail"
import { useEffect } from "react"

interface CareerCategoryForm {
  name: string
}

const CareerCategoryForm = () => {
  const navigate = useNavigate()
  const params = useParams()

  const isUpdate = !!params.id

  const {
    handleSubmit,
    register,
    control,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<CareerCategoryForm>({})

  const { data: dataDetail } = useGetCareerCategoryDetail({
    id: params.id ?? "",
  })

  const { mutate: mutateCreateCareerCategory } =
    useMutationCreateCareerCategory({
      onSuccess(data) {
        if (!data.success) {
          return
        }
        toast.success("Kategori Karir berhasil dibuat")
        setTimeout(() => {
          navigate("/cms/master/career-category")
        }, 1000)
      },
      onError(error) {
        toast.error(error.message)
      },
    })

  const { mutate: mutateUpdateCareerCategory } =
    useMutationUpdateCareerCategory({
      onSuccess(data) {
        if (!data.success) {
          return
        }
        toast.success("Kategori Karir berhasil diubah")
        setTimeout(() => {
          navigate("/cms/master/career-category")
        }, 1000)
      },
      onError(error) {
        toast.error(error.message)
      },
    })

  useEffect(() => {
    if (!dataDetail) return

    const careerCategory = dataDetail.data

    reset({
      name: careerCategory.name,
    })
  }, [dataDetail])

  const onSubmit: SubmitHandler<CareerCategoryForm> = (data) => {
    const payload: GeneralCareerCategoryRequest = {
      name: data.name,
    }

    if (!isUpdate) {
      return mutateCreateCareerCategory(payload)
    }

    mutateUpdateCareerCategory({
      id: params.id ?? "",
      payload: payload,
    })
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-4 pb-4 min-w-[42rem]"
    >
      <TextInput
        type="text"
        className="cms-base-input"
        labelClass="cms-base-label"
        isRequired
        label="Titel Karir"
        errorMessage={errors.name?.message}
        errorIcon={
          <ExclamationTriangleIcon
            height={24}
            width={24}
            className="text-red-800"
          />
        }
        {...register("name", {
          required: {
            value: true,
            message: "Nama kategori karir wajib diisi",
          },
        })}
      />
      <ButtonWithIcon
        action={undefined}
        type="submit"
        class="self-start mt-12 border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
        disabled={isSubmitting}
      >
        <BookmarkIcon className="h-6 w-6 text-white" />
        <span>
          {!isSubmitting ? (
            <>{isUpdate ? "Simpan" : "Tambahkan"}</>
          ) : (
            "Menyimpan..."
          )}
        </span>
      </ButtonWithIcon>
    </form>
  )
}

export default CareerCategoryForm
