import Card from "./Card"
import Content from "./Content"
import { ProductCategory } from "utils/types"

interface ContentCareerProps {
  id: string
  title: string
  slug: string
  description: string
  page: string
  location: string
  working_type: string
  company: string
  company_icon: string
  categories?: ProductCategory[]
}

const ContentCareer = ({
  id,
  title,
  slug,
  description,
  page,
  location,
  working_type,
  company,
  company_icon,
  categories,
}: ContentCareerProps) => {
  return (
    <div className="flex flex-col md:flex-row h-full w-full gap-6 items-start">
      <div className="flex w-full md:w-2/3 h-full flex-col gap-6 items-center md:items-start">
        <Content
          title={title}
          location={location}
          description={description}
          company={company}
        />
      </div>

      <div className="items-center justify-center w-full md:w-2/5 flex h-[28rem]">
        <div className="flex justify-center w-full h-full gap-6 items-center">
          <Card
            title={title}
            company={company}
            location={location}
            working_type={working_type}
            page={`/careers/users/${slug}`}
            company_icon={company_icon}
          />
        </div>
      </div>
    </div>
  )
}

export default ContentCareer
