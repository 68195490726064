import { AxiosRequestConfig } from "axios"

import {
  CMSCourseCategoryListResponse,
  CMSCourseListResponse,
  CMSCreateCourseCategoryResponse,
  CMSCreateCourseResponse,
  CMSCreateCourseSectionResponse,
  CMSCreateReferralResponse,
  CMSCreateWebinarCategoryResponse,
  CMSCreateWebinarResponse,
  CMSGetCourseResponse,
  CMSGetCourseSectionResponse,
  CMSGetCourseSectionsResponse,
  CMSGetReferralsResponse,
  CMSGetWebinarCategoryResponse,
  CMSGetWebinarResponse,
  CMSWebinarCategoryListResponse,
  CMSWebinarListResponse,
  Course,
  CourseSection,
  GeneralApiResponse,
  ProductCategory,
  QueryParams,
  Referral,
  Transaction,
  CMSGetUserTransactionsReferral,
  Webinar,
  CMSPartnershipListResponse,
  Partnership,
} from "../../types"
import { requestCMS } from "../../axiosInstance"
import { CourseCategoryForm } from "components/cms/CourseCategoryManagementForm"
import { WebinarCategoryForm } from "components/cms/WebinarCategoryManagementForm"
import { CourseForm } from "components/cms/CourseManagementForm"
import { CreateWebinarRequest } from "components/cms/WebinarManagementForm"
import { ReferralForm } from "components/cms/ReferralManagementForm"
import { CourseSectionForm } from "components/cms/CourseSectionManagementForm"
import type { User } from "utils/api/cms/types"

const cms = {
  getCourses: (qParams?: QueryParams) =>
    requestCMS.get<CMSCourseListResponse<Course>>("/employee/course", qParams),
  getCourse: (id: number) =>
    requestCMS.get<CMSGetCourseResponse<Course>>(
      `/employee/course/detail/${id}`
    ),
  getWebinars: (qParams?: QueryParams) =>
    requestCMS.get<CMSWebinarListResponse<Webinar>>(
      "/employee/webinar",
      qParams
    ),
  getWebinar: (id: number) =>
    requestCMS.get<CMSGetWebinarResponse<Webinar>>(
      `/employee/webinar/detail/${id}`
    ),
  getCourseCategories: () =>
    requestCMS.get<CMSCourseCategoryListResponse<ProductCategory>>(
      "/course-categories"
    ),
  getPartnership: () =>
    requestCMS.get<CMSPartnershipListResponse<Partnership>>(
      "/employee/partnership"
    ),
  getWebinarCategories: () =>
    requestCMS.get<CMSWebinarCategoryListResponse<ProductCategory>>(
      "/webinar-categories"
    ),
  getWebinarCategory: (id: number) =>
    requestCMS.get<CMSGetWebinarCategoryResponse<ProductCategory>>(
      `/employee/webinar-categories/view/${id}`
    ),
  createCourseCategory: (data: CourseCategoryForm) =>
    requestCMS.post<CMSCreateCourseCategoryResponse<ProductCategory>>(
      "/employee/course-categories/create",
      data
    ),
  createWebinarCategory: (data: WebinarCategoryForm) =>
    requestCMS.post<CMSCreateWebinarCategoryResponse<ProductCategory>>(
      "/employee/webinar-categories/create",
      data
    ),
  updateWebinarCategory: (id: number, data: WebinarCategoryForm) =>
    requestCMS.put<CMSCreateWebinarCategoryResponse<ProductCategory>>(
      `/employee/webinar-categories/update/${id}`,
      data
    ),
  createCourse: (data: CourseForm) =>
    requestCMS.post<CMSCreateCourseResponse<Course>>(
      "/employee/course/create",
      data
    ),
  updateCourse: (id: number, data: CourseForm) =>
    requestCMS.put<CMSCreateCourseResponse<Course>>(
      `/employee/course/update/${id}`,
      data
    ),
  createWebinar: (data: CreateWebinarRequest) =>
    requestCMS.post<CMSCreateWebinarResponse<Webinar>>(
      `/employee/webinar/create`,
      data
    ),
  updateWebinar: (id: number, data: CreateWebinarRequest) =>
    requestCMS.put<CMSCreateWebinarResponse<Webinar>>(
      `/employee/webinar/update/${id}`,
      data
    ),
  getCourseSections: (courseID: number, qParams?: QueryParams) =>
    requestCMS.get<CMSGetCourseSectionsResponse<CourseSection>>(
      `/employee/course/section?course_id=${courseID}`,
      qParams
    ),
  getReferralList: (qParams?: QueryParams) =>
    requestCMS.get<CMSGetReferralsResponse<Referral>>(
      `/employee/referral`,
      qParams
    ),
  getTransactionsReferralList: (refferalID: number, qParams?: QueryParams) =>
    requestCMS.get<CMSGetUserTransactionsReferral<Transaction>>(
      `/employee/referral/transaction/${refferalID}`,
      qParams
    ),
  getAllActiveCourses: () =>
    requestCMS.get<CMSCourseListResponse<Course>>(
      "/employee/course/active-all"
    ),
  createReferral: (data: ReferralForm) =>
    requestCMS.post<CMSCreateReferralResponse>(
      "/employee/referral/create",
      data
    ),
  getCourseSectionMaxSeq: (courseID: number) =>
    requestCMS.get<GeneralApiResponse<number>>("/employee/course/section/max", {
      course_id: courseID,
    }),
  createCourseSection: (courseID: number, data: CourseSectionForm) =>
    requestCMS.post<CMSCreateCourseSectionResponse>(
      "/employee/course/section/create",
      {
        title: data.title,
        sequence: data.sequence,
        course_id: courseID,
      }
    ),
  updateCourseSection: (
    courseID: number,
    sectionID: number,
    data: CourseSectionForm
  ) =>
    requestCMS.put<CMSCreateCourseSectionResponse>(
      `/employee/course/section/update/${sectionID}`,
      {
        title: data.title,
        sequence: data.sequence,
        course_id: courseID,
      }
    ),
  getCourseSection: (sectionID: number) =>
    requestCMS.get<CMSGetCourseSectionResponse>(
      `/employee/course/section/detail/${sectionID}`
    ),
  temporaryUploadVideo: (data: FormData, config: AxiosRequestConfig) =>
    requestCMS.upload<GeneralApiResponse<{ id: string }>>(
      "/employee/course/section/video/put",
      data,
      config
    ),
  getTemporaryUploadedVideo: (id: string) =>
    requestCMS.get(`/employee/course/section/video/get/${id}`),
}

export default cms
