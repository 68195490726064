import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import TextInput from "components/ui/form/TextInput"
import { useMutationStatusSertifikat, useMutationUpdateSertifikat } from "utils/api/cms/mutations/hooks"
import { GeneralSertifikatRequest, GeneralSertifikatUserRequest, SertifikatUser } from "utils/api/cms/types"
import {
    BookmarkIcon,
    ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"
import { useEffect, useState } from "react"
import { useGetSertifikatDetail } from "utils/api/cms/queries/hooks"
import SelectInput from "components/ui/form/SelectInput"
import InputFile from "components/ui/form/InputFile"

type Option = {
    label: string
    value: string
}

interface SertifikatUserForm {
    id: number
    status: Option
    image?: File
}

const STATUS_OPTIONS = [
    { label: "LOLOS", value: "LOLOS" },
    { label: "TIDAK LOLOS", value: "TIDAK LOLOS" },
]

const SertifikatPesertaForm = () => {
    const navigate = useNavigate()
    const params = useParams()
    const [file, setFile] = useState<File | null>(null)

    const {
        handleSubmit,
        register,
        control,
        watch,
        getValues,
        setValue,
        reset,
        formState: { errors, isSubmitting },
    } = useForm<SertifikatUserForm>({})

    const { mutate: mutateUpdateSertifikatStatus } =
        useMutationStatusSertifikat({
            onSuccess(data) {
                if (!data.success) return
                toast.success("Sertifikat berhasil diupdate statusnya")
                setTimeout(() => {
                    navigate(`/cms/sertifikat/peserta/${params.id}`)
                }, 1000)
            },
            onError(error) {
                toast.error(error.message)
            },
        })

    const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0]

        if (selectedFile) {
            setFile(selectedFile)
        }
    }

    const onSubmit: SubmitHandler<SertifikatUserForm> = (data) => {

        const payload: GeneralSertifikatUserRequest = {
            status: data.status.value,
        }
        mutateUpdateSertifikatStatus({
            id: params.id ?? "",
            payload: payload,
        })

    }

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-4 pb-4 min-w-[42rem]"
        >
            <Controller
                control={control}
                name="status"
                rules={{
                    required: { value: true, message: "Status wajib dipilih" },
                }}
                render={({ field }) => (
                    <SelectInput
                        {...field}
                        label="Pilih Status"
                        labelClass="cms-base-label"
                        errorMessage={errors.status?.message}
                        errorIcon={
                            <ExclamationTriangleIcon
                                height={24}
                                width={24}
                                className="text-red-800"
                            />
                        }
                        classNames={{
                            container: () => "max-w-2xl",
                        }}
                        options={STATUS_OPTIONS}
                        isRequired
                    />
                )}
            />
            {/* <InputFile
                label="Bukti"
                className="cms-base-input"
                labelClass="cms-base-label"
                accept=".pdf,.png,.jpg"
                onChange={handleChangeFile}
                register={register("image", {
                    required: {
                        value: false,
                        message: "",
                    },
                })}
            /> */}
            <ButtonWithIcon
                action={undefined}
                type="submit"
                class="self-start mt-12 border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
                disabled={isSubmitting}
            >
                <BookmarkIcon className="h-6 w-6 text-white" />
                <span>
                    {!isSubmitting ? (
                        <>{"Simpan"}</>
                    ) : (
                        "Menyimpan..."
                    )}
                </span>
            </ButtonWithIcon>
        </form>
    )
}

export default SertifikatPesertaForm
