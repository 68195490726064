import { useMutation } from "@tanstack/react-query"

import {
  GeneralCareerCategoryResponse,
  UpdateCareerCategoryRequest,
} from "../../types"
import { updateCareerCategory } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationUpdateCareerCategory = (
  options?: GeneralMutationParams<
    GeneralCareerCategoryResponse,
    UpdateCareerCategoryRequest
  >
) => {
  return useMutation({
    mutationFn: ({ id, payload }) => updateCareerCategory(id, payload),
    ...options,
  })
}

export default useMutationUpdateCareerCategory
