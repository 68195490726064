import { useMutation } from "@tanstack/react-query"

import { validateResetPassword } from "../functions"
import { GeneralMutationParams } from "utils/types"
import {
  PublicValidateResetPasswordResponse,
  ValidateResetPasswordRequest,
} from "../../types"

const useMutationValidateResetPassword = (
  options?: GeneralMutationParams<
    PublicValidateResetPasswordResponse,
    ValidateResetPasswordRequest
  >
) => {
  return useMutation({
    mutationFn: (payload) => validateResetPassword(payload),
    ...options,
  })
}

export default useMutationValidateResetPassword
