import { requestCMS } from "utils/axiosInstance"
import type { QueryParams } from "utils/types"
import type { UsersResponse } from "../../types"

const getUsers = async (qParams?: QueryParams) => {
  const res = await requestCMS.get<UsersResponse>(
    "/employee/user/list",
    qParams
  )
  return res
}

export default getUsers
