import { requestUser } from "utils/axiosInstance"
import { QueryParams } from "utils/types"
import { PublicGetCareerResponse } from "../../types"

const getCareers = async (qParams?: QueryParams) => {
  const res = await requestUser.get<PublicGetCareerResponse>(
    "/careers",
    qParams
  )
  return res
}

export default getCareers
