import { useQuery } from "@tanstack/react-query"
import { CmsQueryKey, GetPartnershipsParams } from "../../types"
import { getPartnershipDetail } from "../functions"

const useGetPartnershipDetail = (params?: GetPartnershipsParams) => {
  return useQuery({
    queryKey: [CmsQueryKey.Partnership, params?.id ?? ""],
    queryFn: () => getPartnershipDetail(params?.id),
  })
}

export default useGetPartnershipDetail
