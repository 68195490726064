import clsx from "clsx"
import { useNavigate, useParams, useLocation } from "react-router-dom"
import { SubmitHandler, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import TextInput from "components/ui/form/TextInput"
import Section from "components/ui/section/Section"
import Typography from "components/ui/typography"
import Button from "components/ui/button/Button"
import type { GeneralResetPasswordPayload } from "utils/api/user/types"
import { useMutationResetPassword } from "utils/api/user/mutations/hooks"
import { useMutationValidateResetPassword } from "utils/api/user/mutations/hooks"
import { Spinner } from "flowbite-react"
import { useEffect } from "react"

const ResetPassword = () => {
  const navigate = useNavigate()
  const token = useParams().token ?? ""
  const email = useLocation().search.split("=")[1]

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<GeneralResetPasswordPayload>({
    defaultValues: {
      email: email,
    },
  })

  const { mutate: mutateValidateResetPassword, isLoading } =
    useMutationValidateResetPassword({
      onSuccess(data) {
        if (!data.success) {
          setTimeout(() => {
            navigate("/reset-password/invalid")
          }, 1000)
          return
        }
        return
      },
      onError(error) {
        navigate("/reset-password/invalid")
      },
    })

  const onValidate = () => {
    mutateValidateResetPassword({
      email,
      token,
    })
  }

  useEffect(() => {
    onValidate()
  }, [])

  const { mutate: mutateResetPassword } = useMutationResetPassword({
    onSuccess(data) {
      if (!data.success) {
        toast.error("Reset password tidak berhasil")
        return
      }

      toast.success("Berhasil reset password")
      setTimeout(() => {
        navigate("/login")
      }, 1000)
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  const onSubmit: SubmitHandler<GeneralResetPasswordPayload> = (data) => {
    mutateResetPassword({
      token: token,
      payload: data,
    })
  }

  if (isLoading) return <Spinner />

  return (
    <Section space="top-bottom">
      <div
        className={clsx(
          "flex flex-col items-center justify-center",
          "mx-8 md:mx-auto",
          "max-w-5xl",
          "rounded",
          "space-y-4",
          "md:py-16 md:px-[200px] md:shadow"
        )}
      >
        <Typography
          as="h1"
          variant="heading3"
          className="text-secondary mb-10"
        >
          Reset<span className="text-primary"> Password</span>
        </Typography>

        <Typography
          variant="subheading4"
          className="font-medium text-center w-2/3"
        >
          Pastikan akun Anda terdaftar manual bukan melalui Google Account
        </Typography>

        <form
          className="w-full space-y-5"
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextInput
            type="password"
            isRequired
            label="Password"
            errorMessage={errors.password?.message}
            {...register("password", {
              required: {
                value: true,
                message: "Password wajib diisi",
              },
            })}
          />
          <TextInput
            type="password"
            isRequired
            label="Password Confirmation"
            errorMessage={errors.password?.message}
            {...register("password_confirmation", {
              required: {
                value: true,
                message: "Konfirmasi password wajib diisi",
              },
            })}
          />
          <Button
            className="!mt-8"
            type="submit"
            size="lg"
            fullWidth
          >
            Reset Password
          </Button>
          <hr className="h-1 mx-auto my-4 bg-gray-200 border-0 rounded" />
        </form>
      </div>
    </Section>
  )
}

export default ResetPassword
