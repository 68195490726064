import { useMutation } from "@tanstack/react-query"

import { GeneralDiscountResponse, GeneralDiscountRequest } from "../../types"
import { createDiscount } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationCreateDiscount = (
  options?: GeneralMutationParams<
    GeneralDiscountResponse,
    GeneralDiscountRequest
  >
) => {
  return useMutation({
    mutationFn: (payload) => createDiscount(payload),
    ...options,
  })
}

export default useMutationCreateDiscount
