import { requestCMS } from "utils/axiosInstance"
import type { DiscountWebinarResponse } from "../../types"
import { QueryParams } from "utils/types"

const getWebinarsDiscount = async (qParams?: QueryParams) => {
  const res = await requestCMS.get<DiscountWebinarResponse>(
    "/employee/discount/webinars",
    qParams
  )
  return res
}

export default getWebinarsDiscount
