import { requestUser } from "utils/axiosInstance"
import { PublicGetWorkshopDetailResponse } from "../../types"

const getWorkshopDetail = async (slug: string) => {
  const res = await requestUser.get<PublicGetWorkshopDetailResponse>(
    `/webinars/${slug}`
  )
  return res
}

export default getWorkshopDetail
