import { requestCMS } from "utils/axiosInstance"
import type { CreateEmployeeRequest, CreateEmployeeResponse } from "../../types"

const createEmployee = async (payload: CreateEmployeeRequest) => {
  const res = await requestCMS.post<CreateEmployeeResponse>(
    "/employee/register",
    payload
  )
  return res
}

export default createEmployee
