import { useMutation } from "@tanstack/react-query"

import { careerUser } from "../functions"
import { GeneralMutationParams } from "utils/types"
import { PublicCareerUserResponse, KarirUserRequest } from "../../types"

const useMutationCareerUser = (
  options?: GeneralMutationParams<PublicCareerUserResponse, FormData>
) => {
  return useMutation({
    mutationFn: (payload) => careerUser(payload),
    ...options,
  })
}

export default useMutationCareerUser
