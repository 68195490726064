import { useInfiniteQuery } from "@tanstack/react-query"

import { getCourses } from "../functions"
import { PublicQueryKey, PublicGetInfiniteCoursesParams } from "../../types"

const useInfiniteGetCourses = (params?: PublicGetInfiniteCoursesParams) => {
  return useInfiniteQuery({
    queryKey: [PublicQueryKey.Courses, params?.qParams ?? {}],
    queryFn: ({ pageParam }) =>
      getCourses({ ...params?.qParams, page: pageParam }),
    getNextPageParam: (lastPage) => {
      if (!lastPage.meta) return

      const isLastPage = lastPage.meta.current_page === lastPage.meta?.last_page
      if (isLastPage) return

      return lastPage.meta.current_page + 1
    },
    ...params?.options,
  })
}

export default useInfiniteGetCourses
