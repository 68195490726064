import { CircularProgressbar } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"

import clsxm from "utils/clsxm"

interface EncodingStatusProps {
  status: "failed" | "completed" | "in_progress"
  percentage: number
}

const EncodingStatus = ({ status, percentage }: EncodingStatusProps) => {
  const displayedStatus =
    status === "in_progress" ? status.split("_").join(" ") : status

  if (status === "in_progress") {
    return (
      <CircularProgressbar
        className="w-10 h-10"
        value={percentage}
        text={`${percentage}%`}
      />
    )
  }

  return (
    <p
      className={clsxm("capitalize rounded w-fit px-2 py-0.5", [
        status === "failed" && "bg-red-800 text-red-100",
        status === "completed" && "bg-green-900 text-green-300",
      ])}
    >
      {displayedStatus}
    </p>
  )
}

export default EncodingStatus
