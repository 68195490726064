import { requestPartnership } from "utils/axiosInstance"
import { DetailPartnership } from "../type"

const detailPartnership = async () => {
    const res = await requestPartnership.get<DetailPartnership>(
        `/partnership/getUser`
    )
    return res
}

export default detailPartnership
