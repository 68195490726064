import { requestCMS } from "utils/axiosInstance"
import { GeneralSertifikatRequest, GeneralSertifikatResponse } from "utils/api/cms/types"

const updateSertifikat = async (
    id: string,
    payload: GeneralSertifikatRequest
) => {
    const res = await requestCMS.put<GeneralSertifikatResponse>(
        `/employee/sertifikat/${id}`,
        payload
    )
    return res
}

export default updateSertifikat
