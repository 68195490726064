import { requestCMS } from "utils/axiosInstance"
import type { ReferralDetailResponse } from "../../types"

const getReferralDetail = async (id: number) => {
  const res = await requestCMS.get<ReferralDetailResponse>(
    `/employee/referral/detail/${id}`
  )
  return res
}

export default getReferralDetail
