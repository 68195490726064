import { useMutation } from "@tanstack/react-query"

import { GeneralSertifikasiSkkniResponse } from "../../types"
import { GeneralMutationParams } from "utils/types"
import { deleteSertifikasiSkkni } from "../functions"

const useMutationDeleteSkkni = (
    options?: GeneralMutationParams<GeneralSertifikasiSkkniResponse, { sertifikasiID: string }>
) => {
    return useMutation({
        mutationFn: ({ sertifikasiID }) => deleteSertifikasiSkkni(sertifikasiID),
        ...options,
    })
}

export default useMutationDeleteSkkni
