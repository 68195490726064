import React from 'react';

interface ImageComponentProps {
  src: string;
  alt?: string;
  className?: string;
}

const IconImageNav: React.FC<ImageComponentProps> = ({ src, alt, className }) => {
  return <img src={src} alt={alt} className={className} />;
};

export default IconImageNav;
