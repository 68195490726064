import React from "react"
import { SubmitHandler } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import CourseCategoryManagementForm, {
  CourseCategoryForm,
} from "../../../components/cms/CourseCategoryManagementForm"
import EmployeeDashboardWrapper from "../../../components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "../../../components/ui/breadcrumb/DefBreadcrumb"
import api from "../../../utils/axiosInstance"
import { getCMSSegments } from "../../../utils/helper"

const CourseCategoryManagementCreate: React.FC = () => {
  const segments = getCMSSegments()
  const navigate = useNavigate()
  const [serverValidationError, setServerValidation] = React.useState<{
    name: string | undefined
    description: string | undefined
  }>({
    name: undefined,
    description: undefined,
  })

  const [courseCategorySubmitting, setCourseCategorySubmit] =
    React.useState(false)

  const courseCategoryCreateSubmitHandler: SubmitHandler<
    CourseCategoryForm
  > = async (data) => {
    setCourseCategorySubmit(true)

    try {
      await api.cms.createCourseCategory(data)

      navigate("/cms/master/course-category", {
        state: { courseCategoryCreateSuccess: true },
      })
    } catch (error: any) {
      const validationResponse: {
        name: string | undefined
        description: string | undefined
      } = { ...serverValidationError }

      Object.keys(validationResponse).forEach((field) => {
        const errMessages: string[] | undefined = error.errors[field]

        if (errMessages === undefined) return

        const message = (errMessages as string[]).join(". ")

        validationResponse[field] = message
      })

      setServerValidation(validationResponse)

      setCourseCategorySubmit(false)

      return
    }

    navigate("/cms/master/course-category", {
      state: { courseCategoryUpsertSuccess: true },
    })
  }

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        prefix="cms"
        segments={segments}
      />
      <h2 className="text-xl text-primary font-semibold leading-loose tracking-wide">
        Tambah Kategori Pembelajaran
      </h2>
      <CourseCategoryManagementForm
        method="POST"
        onSubmitHandler={courseCategoryCreateSubmitHandler}
        serverValidationResponse={serverValidationError}
        submitting={courseCategorySubmitting}
      />
    </EmployeeDashboardWrapper>
  )
}

export default CourseCategoryManagementCreate
