import { requestUser } from "utils/axiosInstance"
import type { GetCourseSectionResponse } from "../../types"

const getCourseSections = async (id: string) => {
  const res = await requestUser.get<GetCourseSectionResponse>(
    `/user/course-sections/${id}`
  )
  return res
}

export default getCourseSections
