import { Modal as FlowbiteModal, Flowbite } from "flowbite-react"

const Modal: React.FC<{
  show: boolean
  position?: string
  header: string
  size?: string
  onclose: () => void
  body: React.ReactNode
  footer?: React.ReactNode
}> = (props) => {
  return (
    <Flowbite
      theme={{
        theme: {
          modal: {
            root: {
              base: "fixed top-0 right-0 left-0 z-50 h-modal h-screen overflow-y-auto overflow-x-hidden md:inset-0 md:h-full",
              show: {
                off: "hidden",
                on: "flex bg-gray-900 bg-opacity-50 dark:bg-opacity-80",
              },
            },
            content: {
              base: "relative h-full w-2/3 md:h-auto",
              inner:
                "relative rounded-lg bg-white shadow dark:bg-gray-700 flex flex-col h-screen",
            },
            body: {
              base: "p-6 flex-1 overflow-auto",
              popup: "pt-0",
            },
            header: {
              base: "flex items-start justify-between rounded-t dark:border-gray-600 border-b p-5",
              popup: "p-2 border-b-0",
              title: "text-xl font-medium text-gray-900 dark:text-white",
              close: {
                base: "ml-auto inline-flex items-center rounded-lg bg-transparent p-1.5 text-sm text-gray-400 hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-white",
                icon: "h-5 w-5",
              },
            },
            footer: {
              base: "flex items-center space-x-2 rounded-b border-gray-200 p-6 dark:border-gray-600",
              popup: "border-t",
            },
          },
        },
      }}
    >
      <FlowbiteModal
        size={props.size}
        dismissible
        position={props.position}
        show={props.show}
        onClose={props.onclose}
      >
        <FlowbiteModal.Header>{props.header}</FlowbiteModal.Header>
        <FlowbiteModal.Body>{props.body}</FlowbiteModal.Body>
        {props.footer && (
          <FlowbiteModal.Footer>{props.footer}</FlowbiteModal.Footer>
        )}
      </FlowbiteModal>
    </Flowbite>
  )
}

export default Modal
