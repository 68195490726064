import { useQuery } from "@tanstack/react-query"
import { CmsQueryKey } from "../../types"
import { getTransactionDetail } from "../functions"

const useGetTransactionDetail = (trx_id: String) => {
  return useQuery({
    queryKey: [CmsQueryKey.Users, trx_id],
    queryFn: () => getTransactionDetail(trx_id),
  })
}

export default useGetTransactionDetail
