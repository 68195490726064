import PesertaList from "components/cms/Webinar/PesertaList"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import Typography from "components/ui/typography"
import Button from "components/ui/button/Button"
import { FaFileExcel } from "react-icons/fa"
import { getCMSSegments } from "utils/helper"
import * as XLSX from 'xlsx';
import { useGetPesertaWebinar } from "utils/api/cms/queries/hooks"
import { useParams } from "react-router-dom"

const WebinarManagementPeserta = () => {
    const segments = getCMSSegments()
    const params = useParams()

    const { data } = useGetPesertaWebinar({
        options: {
            staleTime: 0,
        },
        qParams: {
            webinar_id: Number(params.id)
        },
    })

    const webinarData = data?.data?.map(item => ({
        name: item.no_invoice,
        nama_webinar: item.webinars.title,
        peserta: item.transaction.user.email
    })) || [];


    const handleExport = () => {

        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(webinarData);

        const colWidths = webinarData.reduce<number[]>((widths, row) => {
            Object.keys(row).forEach((key, i) => {
                const contentLength = row[key] ? row[key].toString().length : 0;
                widths[i] = Math.max(widths[i] || 0, contentLength);
            });
            return widths;
        }, []).map(w => ({ wch: w + 2 }));

        worksheet['!cols'] = colWidths;

        XLSX.utils.book_append_sheet(workbook, worksheet, 'Peserta Webinar');
        XLSX.writeFile(workbook, 'peserta-webinar.xlsx');
    };

    return (
        <EmployeeDashboardWrapper>
            <DefBreadcrumb
                segments={segments}
                prefix="cms"
            />
            <div className="inline-flex items-center">
                <Typography
                    variant="subheading4"
                    as="h1"
                    className="text-primary mt-5"
                >
                    Detail Peserta Webinar
                </Typography>
                <Button
                    onClick={handleExport}
                    className="inline-flex items-center gap-2 py-[10px] px-5 ml-auto"
                >
                    <FaFileExcel className="h-6 w-6 text-white" />
                    <span>Export Excel</span>
                </Button>
            </div>
            <PesertaList />
        </EmployeeDashboardWrapper>
    )
}

export default WebinarManagementPeserta
