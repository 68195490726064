import { useQuery } from "@tanstack/react-query"

import { PublicQueryKey, PublicGetProductCategoriesParams } from "../../types"
import { getCareerCategories } from "../functions"

const useGetCareerCategories = (params?: PublicGetProductCategoriesParams) => {
  return useQuery({
    queryKey: [PublicQueryKey.WorkshopCategories],
    queryFn: getCareerCategories,
    ...params?.options,
  })
}

export default useGetCareerCategories
