import { useState } from "react"
import {
  DocumentPlusIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/solid"
import { LoaderFunction, useLoaderData, useNavigate } from "react-router-dom"

import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import { getCMSSegments } from "utils/helper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import { CMSCourseCategoryListResponse, ProductCategory } from "utils/types"
import api from "utils/axiosInstance"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import useFlashMessage from "hooks/useFlashMessage"
import { createColumnHelper } from "@tanstack/react-table"
import moment from "moment"
import ReactTable from "components/ui/react-table/ReactTable"
import { toast } from "react-toastify"
import Modal from "components/ui/modal/Modal"
import Button from "components/ui/button/Button"
import Typography from "components/ui/typography"
import useDeleteCourseCategory from "utils/api/cms/mutations/hooks/useDeleteCourseCategory"

const CourseCategoryManagement: React.FC = () => {
  const segments = getCMSSegments()
  const response =
    useLoaderData() as CMSCourseCategoryListResponse<ProductCategory>
  const navigate = useNavigate()
  useFlashMessage(
    "success",
    "courseCategoryUpsertSuccess",
    "Berhasil menyimpan kategori modul Pembejalaran"
  )
  const [modalDelete, setModalDelete] = useState({
    open: false,
    title: "",
    courseCategoryID: "",
  })

  const handleModalDelete = (courseCategoryID?: string, title?: string) => {
    setModalDelete((prev) => ({
      open: !prev.open,
      courseCategoryID: courseCategoryID ?? "",
      title: title ?? "",
    }))
  }

  const { mutate: mutateDeleteCourseCategory } = useDeleteCourseCategory({
    onSuccess() {
      toast.success("Kategori Pembelajaran berhasil dihapus")
      setModalDelete({
        open: false,
        title: "",
        courseCategoryID: "",
      })

      navigate("")
    },
    onError(err) {
      toast.error(err.message)
    },
  })

  const colHelper = createColumnHelper<ProductCategory>()
  const categoryCols = [
    colHelper.accessor("name", {
      header: () => "Nama Kategori Pembelajaran",
    }),
    colHelper.accessor("description", {
      header: () => "Deskripsi Kategori Pembelajaran",
    }),
    colHelper.accessor("created_at", {
      header: () => "Tanggal Dibuat",
      cell: (props) =>
        moment(props.getValue()).locale("id").format("dddd, D MMMM YYYY H:mm"),
    }),
    colHelper.accessor("updated_at", {
      header: () => "Tanggal Terakhir Diupdate",
      cell: (props) =>
        moment(props.getValue()).locale("id").format("dddd, D MMMM YYYY H:mm"),
    }),
    colHelper.display({
      id: "actions",
      header: () => "AKSI",
      cell: (props) => (
        <div className="flex flex-row w-fit space-x-3">
          <ButtonWithIcon
            class="mx-auto border-2 border-transparent bg-yellow-400 text-white hover:bg-yellow-300 hover:border-yellow-300"
            type="button"
            action={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault()
              return navigate(`edit/${props.row.original.id}`)
            }}
            sizeClass="p-2"
          >
            <PencilSquareIcon className="h-5 w-5" />
          </ButtonWithIcon>
          <Button
            onClick={() =>
              handleModalDelete(
                props.row.original.id.toString(),
                props.row.original.name
              )
            }
            className="bg-red-600 border-red-600"
          >
            <TrashIcon
              width={20}
              height={20}
            />
          </Button>
        </div>
      ),
    }),
  ]

  return (
    <>
      <EmployeeDashboardWrapper>
        <DefBreadcrumb
          prefix="cms"
          segments={segments}
        />
        <h2 className="text-xl text-primary font-semibold leading-loose tracking-wide my-2">
          Pengaturan Konten Kategori Belajar
        </h2>
        <div className="flex flex-wrap justify-between gap-4 mb-4">
          <ButtonWithIcon
            action={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault()
              return navigate("create")
            }}
            type="submit"
            class="lg:ml-auto border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
          >
            <DocumentPlusIcon className="h-6 w-6 text-white" />
            <span>Tambah Kategori</span>
          </ButtonWithIcon>
        </div>
        <ReactTable
          columns={categoryCols}
          data={response.data}
        />

        <Modal
          header="Hapus Kategori Pembelajaran"
          onclose={handleModalDelete}
          show={modalDelete.open}
          body={
            <Typography className="text-white">
              Apakah Anda yakin ingin menghapus kategori pembelajaran
              <b> {modalDelete.title}</b>?
            </Typography>
          }
          footer={
            <div className="space-x-2 ml-auto">
              <Button
                onClick={() => handleModalDelete()}
                variant="outlined"
                className="text-gray-300 border-gray-300"
              >
                Batal
              </Button>
              <Button
                onClick={() =>
                  mutateDeleteCourseCategory(modalDelete.courseCategoryID)
                }
              >
                Hapus
              </Button>
            </div>
          }
        />
      </EmployeeDashboardWrapper >
    </>
  )
}

export default CourseCategoryManagement

export const loadCourseCategories: LoaderFunction = async (): Promise<
  CMSCourseCategoryListResponse<ProductCategory>
> => {
  const response = await api.cms.getCourseCategories()

  return response
}
