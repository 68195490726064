import { requestCMS } from "utils/axiosInstance"
import type { DiscountSertifikasiResponse } from "../../types"
import { QueryParams } from "utils/types"

const getSertifikasiDiscount = async (qParams?: QueryParams) => {
    const res = await requestCMS.get<DiscountSertifikasiResponse>(
        "/employee/discount/certifications",
        qParams
    )
    return res
}

export default getSertifikasiDiscount
