import moment from "moment"

import Section from "components/ui/section/Section"
import ShowAllLink from "../../components/ShowAllLink"
import WorkshopCard from "../../components/WorkshopCard"
import { useGetWorkshops } from "utils/api/public/queries/hooks"
import CardHorizontalSkeleton from "components/public/components/CardHorizontalSkeleton"

const LatestWorkshop = () => {
  const { data: dataWorkshops, isLoading: isLoadingWorkshops } =
    useGetWorkshops({
      qParams: { limit: 6 },
    })

  return (
    <>
      {dataWorkshops?.data && dataWorkshops.data.length > 0 && (
        <Section
          space="top-bottom"
          withContainer
        >
          <Section.Title position="center">
            Work<span className="text-primary">Shop</span>
          </Section.Title>
          <div className="flex flex-wrap justify-center gap-x-4 gap-y-5 mb-10">
            {isLoadingWorkshops
              ? [...Array(6)].map((_, index) => (
                  <CardHorizontalSkeleton key={index} />
                ))
              : dataWorkshops?.data.map((workshop) => {
                  const formatedDate = moment(workshop.start_date).format(
                    "DD-MMMM"
                  )
                  const [date, month] = formatedDate.split("-")

                  return (
                    <WorkshopCard
                      key={workshop.id}
                      title={workshop.title}
                      quota={workshop.quota}
                      date={date}
                      month={month}
                      slug={workshop.slug}
                    />
                  )
                })}
          </div>
          <ShowAllLink to="/workshops">
            <span className="text-secondary">Lihat Semua</span> Workshop
          </ShowAllLink>
        </Section>
      )}
    </>
  )
}

export default LatestWorkshop
