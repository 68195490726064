import { requestCMS } from "utils/axiosInstance"
import { PublishCourseResponse } from "../../types"

const publishCourse = async (courseID: string) => {
  const res = await requestCMS.post<PublishCourseResponse>(
    `/employee/course/publish/${courseID}`,
    {}
  )
  return res
}

export default publishCourse
