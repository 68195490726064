import { useMutation } from "@tanstack/react-query"

import { GeneralDiscountResponse, UpdateDiscountRequest } from "../../types"
import { updateDiscount } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationUpdateDiscount = (
  options?: GeneralMutationParams<
    GeneralDiscountResponse,
    UpdateDiscountRequest
  >
) => {
  return useMutation({
    mutationFn: ({ id, payload }) => updateDiscount(id, payload),
    ...options,
  })
}

export default useMutationUpdateDiscount
