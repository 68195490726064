import { SubmitHandler, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import TextInput from "components/ui/form/TextInput"
import {
    BookmarkIcon,
    ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"
import InputFile from "components/ui/form/InputFile"
import { useState } from "react"
import { useMutationCreateSkkni } from "utils/api/cms/mutations/hooks"

interface SertifikatForm {
    name: string
    sertifikat_id: number
    file: File
}

const SertifikatFormSkkni = () => {
    const navigate = useNavigate()
    const params = useParams()


    const [file, setFile] = useState<File | null>(null)

    const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedFile = e.target.files?.[0]

        if (selectedFile) {
            setFile(selectedFile)
        }
    }

    const {
        handleSubmit,
        register,
        formState: { errors, isSubmitting },
    } = useForm<SertifikatForm>({})

    const { mutate: mutateCreate } =
        useMutationCreateSkkni({
            onSuccess(data) {
                if (!data.success) {
                    return
                }
                toast.success("Sertifikasi skkni berhasil dibuat")
                setTimeout(() => {
                    navigate(`/cms/sertifikat/document/${params.id}`)
                }, 1000)
            },
            onError(error) {
                toast.error(error.message)
            },
        })

    const onSubmit: SubmitHandler<SertifikatForm> = (data) => {
        const formPayload = new FormData();
        formPayload.append("name", data.name);

        if (params.id) {
            formPayload.append("sertifikat_id", params.id);
        }
        if (file) {
            formPayload.append("file", file);
        }

        return mutateCreate(formPayload);
    }



    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-4 pb-4 min-w-[42rem]"
        >
            <TextInput
                type="text"
                className="cms-base-input"
                labelClass="cms-base-label"
                isRequired
                label="Nama"
                errorMessage={errors.name?.message}
                errorIcon={
                    <ExclamationTriangleIcon
                        height={24}
                        width={24}
                        className="text-red-800"
                    />
                }
                {...register("name", {
                    required: {
                        value: true,
                        message: "Nama wajib diisi",
                    },
                })}
            />
            <InputFile
                label="Dokumen"
                className="cms-base-input"
                isRequired
                labelClass="cms-base-label"
                accept=".pdf"
                onChange={handleChangeFile}
                errorMessage={errors.file?.message}
                register={register("file", {
                    required: {
                        value: true,
                        message: "Dokumen wajib diisi",
                    },
                })}
            />
            <ButtonWithIcon
                action={undefined}
                type="submit"
                class="self-start mt-12 border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
                disabled={isSubmitting}
            >
                <BookmarkIcon className="h-6 w-6 text-white" />
                <span>
                    {!isSubmitting ? (
                        <>{"Tambahkan"}</>
                    ) : (
                        "Menyimpan..."
                    )}
                </span>
            </ButtonWithIcon>
        </form>
    )
}

export default SertifikatFormSkkni
