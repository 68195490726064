import { useMutation } from "@tanstack/react-query"

import { GeneralPartnershipResponse, UpdatePartnershipPasswordRequest } from "../../types"
import { GeneralMutationParams } from "utils/types"
import { updatePasswordPartnership } from "../functions"

const useUpdatePasswordPartnership = (
    options?: GeneralMutationParams<GeneralPartnershipResponse, UpdatePartnershipPasswordRequest>
) => {
    return useMutation({
        mutationFn: (requestData: UpdatePartnershipPasswordRequest) => updatePasswordPartnership(requestData),
        ...options,
    });
}


export default useUpdatePasswordPartnership
