import { Navigate, Outlet } from "react-router-dom"

import { useUserInfoStore } from "utils/store/user-info"

const UserPrivateRouter = () => {
  const isLoggedIn = useUserInfoStore((state) => state.isLoggedIn)

  return isLoggedIn ? <Outlet /> : <Navigate to="/login" />
}

export default UserPrivateRouter
