import Typography from "components/ui/typography"
import clsxm from "utils/clsxm"

interface CardTitleProps {
  as?: React.ElementType
  className?: string
  children: React.ReactNode
}

const CardTitle = ({ as, children, className }: CardTitleProps) => {
  return (
    <Typography
      as={as ?? "h3"}
      variant="text2"
      className={clsxm("font-bold", className)}
    >
      {children}
    </Typography>
  )
}

export default CardTitle
