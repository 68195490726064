import { useQuery } from "@tanstack/react-query"

import { getVideos } from "../functions"
import { CmsQueryKey, GetCourseVideoSectionParams } from "../../types"

const useGetVideos = (params?: GetCourseVideoSectionParams) => {
  return useQuery({
    queryKey: [CmsQueryKey.Videos, params?.sectionID ?? ""],
    queryFn: () => getVideos(params?.sectionID),
    ...params?.options,
  })
}

export default useGetVideos
