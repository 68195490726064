import React from "react"
import Typography from "components/ui/typography"
import DOMPurify from "dompurify"

interface CardProps {
  location: string
  company: string
  description: string
  title: string
}

const Content = ({ location, company, description, title }: CardProps) => {
  return (
    <div className="w-full">
      <Typography
        variant="heading3"
        as="h1"
        className="mb-2 text-black"
      >
        {title}
      </Typography>
      <Typography
        variant="subheading3"
        as="h1"
        className="mb-5 flex font-bold text-primary"
      >
        {company}
      </Typography>
      <div className="h-1 flex w-full bg-gray-200"></div>
      <div className="flex flex-col my-10">
        <Typography
          variant="heading5"
          className="flex font-bold mb-3 text-black"
        >
          Deskripsi Pekerjaan
        </Typography>
        <Typography
          variant="text2"
          className="font-normal description-container"
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(description ?? ``, {
              USE_PROFILES: { svg: false, html: true },
            }),
          }}
        />
      </div>
      <div className="flex justify-center items-center gap-3"></div>
    </div>
  )
}

export default Content
