import { useQuery } from "@tanstack/react-query"

import { getSertifikatUser } from "../functions"
import { CmsQueryKey, GetSertifikatUserParams } from "../../types"

const useGetSertifikatUser = (params?: GetSertifikatUserParams) => {
    return useQuery({
        queryKey: [CmsQueryKey.SertifikatUser, params?.qParams ?? ""],
        queryFn: () => getSertifikatUser(params?.qParams),
        ...params?.options,
    })
}

export default useGetSertifikatUser
