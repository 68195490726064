import { useMutation } from "@tanstack/react-query"

import { DeleteVideoResponse } from "../../types"
import { deleteVideo } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useDeleteVideo = (
  options?: GeneralMutationParams<DeleteVideoResponse, string>
) => {
  return useMutation({
    mutationFn: (videoID) => deleteVideo(videoID),
    ...options,
  })
}

export default useDeleteVideo
