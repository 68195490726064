import clsx from "clsx"

interface PaymentHeaderProps {
  icon: React.ReactNode
  status: "success" | "failed"
  children: React.ReactNode
}

const PaymentHeader = ({ icon, status, children }: PaymentHeaderProps) => {
  return (
    <div className="block space-y-2 text-center">
      <div className="relative w-fit p-2 mx-auto">
        <div
          className={clsx(
            "absolute top-0 left-0 w-full h-full p-2 mx-auto rounded-full animate-pulse",
            [
              status === "success" && "bg-green-100",
              status === "failed" && "bg-red-100",
            ]
          )}
        />
        {icon}
      </div>
      <div>{children}</div>
    </div>
  )
}

export default PaymentHeader
