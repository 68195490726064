import { useQuery } from "@tanstack/react-query"
import { PublicGetSertifikasiParams, PublicQueryKey } from "utils/api/public/types"
import { getSertifikasi } from "../functions"

const useGetSertifikasi = (
    params?: PublicGetSertifikasiParams
) => {
    return useQuery({
        queryKey: [PublicQueryKey.Sertifikasi],
        queryFn: getSertifikasi,
        ...params?.options,
    })
}

export default useGetSertifikasi