import clsxm from "utils/clsxm"
import Typography from "../../typography"

interface CardCaptionProps {
  text: string
  icon?: React.ReactNode
  className?: string
}

const CardCaption = ({ text, icon, className }: CardCaptionProps) => {
  return (
    <div className="flex items-center gap-1 text-typo-gray">
      {icon}
      <Typography
        variant="text4"
        className={clsxm("font-semibold", className)}
      >
        {text}
      </Typography>
    </div>
  )
}

export default CardCaption
