import { requestUser } from "utils/axiosInstance"
import { PublicGetProductCategoriesResponse } from "../../types"

const getCareerCategories = async () => {
  const res =
    await requestUser.get<PublicGetProductCategoriesResponse>(
      "/career-categories"
    )

  return res
}

export default getCareerCategories
