import { requestCMS } from "utils/axiosInstance"
import type { GeneralSertifikatResponse } from "../../types"

const detailSertifikat = async (id?: string) => {
    const res = await requestCMS.get<GeneralSertifikatResponse>(
        `/employee/sertifikat/${id}`
    )
    return res
}

export default detailSertifikat
