import { useEffect, useState } from "react"
import clsx from "clsx"
import { Accordion } from "flowbite-react"
import { useParams } from "react-router-dom"
import { Helmet } from "react-helmet"
import DOMPurify from "dompurify"

import VideoButton from "components/public/course/detail-course/VideoButton"
import Typography from "components/ui/typography"
import { useGetCourseSections } from "utils/api/user/queries/hooks"
import { useGetCourseDetail } from "utils/api/public/queries/hooks"
import VideoPlayer from "components/ui/video-player"
import { getUserToken } from "utils/auth"
import Section from "components/ui/section/Section"
import { useMediaQuery } from "usehooks-ts"

const MyCourseDetail = () => {
  const desktop = useMediaQuery("(min-width: 1024px)")
  const mobile = !desktop

  const params = useParams<{ slug: string }>()

  const [selectedCourse, setSelectedCourse] = useState<{
    videoID: number
    mediaID: string
    title: string
    notes?: string
  }>()

  const { data: dataDetailCourse, isLoading: isLoadingDetailCourse } =
    useGetCourseDetail({
      slug: params.slug ?? "",
      options: {
        staleTime: 0,
      },
    })

  const { data: dataSections, isLoading: isLoadingSections } =
    useGetCourseSections({
      id: dataDetailCourse?.data.id.toString() ?? "",
      options: {
        enabled: !!dataDetailCourse,
        staleTime: 0,
      },
    })

  //set initial selected video
  useEffect(() => {
    if (!dataDetailCourse || !dataSections) return

    const lastWatchedVideo = localStorage.getItem(
      `course_${dataDetailCourse.data.id.toString()}`
    )
    const selected = JSON.parse(lastWatchedVideo ?? "{}")
    if (!!selected?.mediaID) {
      const isMediaExist = dataSections.data.some((section) =>
        section.videos.some((video) => video.media_id === selected.mediaID)
      )
      if (isMediaExist) {
        setSelectedCourse({
          videoID: selected.videoID,
          mediaID: selected.mediaID,
          title: selected.title,
          notes: selected.notes,
        })
        return
      }
    }

    if (dataSections.data[0].videos.length === 0) return

    const video = dataSections.data[0].videos[0]
    setSelectedCourse({
      videoID: video.id,
      mediaID: video.media_id,
      title: video.title,
      notes: video.notes,
    })
  }, [dataDetailCourse, dataSections])

  const handleButtonCourse = async (
    videoID: number,
    mediaID: string,
    title: string,
    notes: string
  ) => {
    if (!dataDetailCourse) return

    const selected = {
      videoID,
      mediaID,
      title,
      notes,
    }
    setSelectedCourse(selected)
    localStorage.setItem(
      `course_${dataDetailCourse.data.id}`,
      JSON.stringify(selected)
    )
  }

  const isLoading = isLoadingDetailCourse || isLoadingSections

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const notes = selectedCourse?.notes ?? "";
  const truncatedNotes = showMore ? notes : notes.slice(0, 150);
  const isLongDescription = notes.length < 150;
  return (
    <>
      <Helmet>
        <title>Kelas: {dataDetailCourse?.data.title ?? ""}</title>
      </Helmet>
      <Section
        withContainer
      >
        <div className="flex flex-col md:flex-row md:h-screen mt-6 gap-4">
          <div className="md:w-[70%] space-y-4">
            <div className="relative h-[35vh] md:h-[65%] bg-black rounded-lg">
              {!!selectedCourse && (
                <VideoPlayer
                  url={`${process.env.REACT_APP_API_URL}/watch/video/${selectedCourse.videoID
                    }/${selectedCourse.mediaID}?token=${getUserToken()}`}
                />
              )}
            </div>
            <div className="space-y-2 px-4 py-2">
              <Typography
                variant="subheading4"
                className="mb-3 text-lg  lg:text-xl"
              >
                {selectedCourse?.title}
              </Typography>
              <Typography
                variant="text3"
                className="mb-3"
              >
                <p
                  className="text-justify hidden lg:block"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      selectedCourse?.notes ?? ``,
                      {
                        USE_PROFILES: { svg: false, html: true },
                      }
                    ),
                  }}
                ></p>
                <p
                  className="text-justify block lg:hidden"
                  dangerouslySetInnerHTML={{ __html: truncatedNotes }}
                  style={{ maxHeight: showMore ? "none" : "200px", overflow: "hidden" }}
                />
                {!isLongDescription && (
                  <button
                    className="block lg:hidden text-primary font-semibold mt-2"
                    onClick={toggleShowMore}
                  >
                    {showMore ? "Sembunyikan" : "Tampilkan lebih banyak"}
                  </button>
                )}
              </Typography>
            </div>
          </div>
          <div
            className={clsx(
              "sticky top-16",
              "border border-slate-200",
              "h-[90%] md:w-[30%]",
              "py-2 rounded-lg"
            )}
          >
            <div className="border-b border-slate-200 px-4">
              <Typography
                variant="text1"
                className="font-semibold py-2 pb-3"
              >
                Materi kursus
              </Typography>
            </div>
            <div
              className={clsx("flex flex-col gap-1", "h-[95%]", "overflow-auto", [
                isLoading && "p-1",
              ])}
            >
              {!isLoading ? (
                <Accordion alwaysOpen className="rounded-none " >
                  {(dataSections?.data ?? []).map((section, index) => (
                    <Accordion.Panel key={section.id}>
                      <Accordion.Title className="text-sm capitalize">
                        Bagian {index + 1}: {section.title}
                      </Accordion.Title>
                      <Accordion.Content className="p-0 ">
                        {section.videos.map((video, index) => (
                          <VideoButton
                            key={index}
                            className="md:w-full"
                            title={video.title}
                            selected={selectedCourse?.videoID === video.id}
                            onClick={() =>
                              handleButtonCourse(
                                video.id,
                                video.media_id,
                                video.title,
                                video.notes
                              )
                            }
                          />
                        ))}
                      </Accordion.Content>
                    </Accordion.Panel>
                  ))}
                </Accordion>
              ) : (
                [...Array(10)].map((_, index) => (
                  <div
                    key={index}
                    className="bg-slate-200 w-full h-16 space-y-1 animate-pulse"
                  />
                ))
              )}
            </div>
          </div>
        </div>
      </Section>
    </>
  )
}

export default MyCourseDetail
