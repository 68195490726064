import { requestPartnership } from "utils/axiosInstance"
import { GeneralApiResponse } from "utils/types"

const logout = async () => {
    const res = await requestPartnership.post<GeneralApiResponse<null>>(
        "/partnership/logout",
        {}
    )
    return res
}

export default logout
