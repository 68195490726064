import { requestCMS } from "utils/axiosInstance"
import { GeneralSertifikatUserRequest, GeneralSertifikatUserResponse } from "utils/api/cms/types"

const updateSertifikat = async (
    id: string,
    payload: GeneralSertifikatUserRequest
) => {
    const res = await requestCMS.put<GeneralSertifikatUserResponse>(
        `/employee/sertifikat/users/status/${id}`,
        payload
    )
    return res
}

export default updateSertifikat
