const LinkedInIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      d="M4.75 3.5C4.75 4.74264 3.74264 5.75 2.5 5.75C1.25736 5.75 0.25 4.74264 0.25 3.5C0.25 2.25736 1.25736 1.25 2.5 1.25C3.74264 1.25 4.75 2.25736 4.75 3.5Z"
      fill="#575378"
    />
    <path
      d="M5.75 22H0.25V7.25H5.75V22Z"
      fill="#575378"
    />
    <path
      d="M8.25 22H13.75V14.5C13.75 12.75 14 11.5 15.75 11.5C17.5 11.5 17.75 12.75 17.75 14.5V22H23.25V13.25C23.25 9.25 21 7 17.75 7C16.25 7 15.25 8 14.75 8.75H14.5V7.25H8.25C8.25 7.25 8.5 9 8.25 9.75V22Z"
      fill="#575378"
    />
  </svg>
)

export default LinkedInIcon
