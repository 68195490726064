import { requestCMS } from "utils/axiosInstance"
import { DeactivateCourseResponse } from "../../types"

const deactivateCourse = async (courseID: string) => {
  const res = await requestCMS.post<DeactivateCourseResponse>(
    `/employee/course/deactivate/${courseID}`,
    {}
  )
  return res
}

export default deactivateCourse
