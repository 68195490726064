import React, { useEffect, useRef } from "react"
import videojs from "video.js"
import "video.js/dist/video-js.css"
import "@videojs/themes/dist/city/index.css"

const CourseVideoManagementPlayer: React.FC<{
  options
  onReady?
}> = (props) => {
  const videoRef = useRef<any>(null)
  const playerRef = useRef<any>(null)

  useEffect(() => {
    const player = playerRef.current

    if (!player) {
      const videoElement = document.createElement("video-js")

      videoElement.classList.add(
        "video-js",
        "vjs-theme-city",
        "vjs-big-play-centered",
        "max-w-[42rem]",
        "max-h-[25rem]"
      )
      videoRef.current.appendChild(videoElement)

      playerRef.current = videojs(videoElement, props.options)

      return
    }

    player.autoplay(props.options.autoplay)
    player.src(props.options.sources)
  }, [props.options, videoRef])

  useEffect(() => {
    const player = playerRef.current

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose()
        playerRef.current = null
      }
    }
  }, [playerRef])

  return (
    <div data-vjs-player>
      <div ref={videoRef}></div>
    </div>
  )
}

export default CourseVideoManagementPlayer
