import { requestCMS } from "utils/axiosInstance"
import { GeneralApiResponse, QueryParams } from "utils/types"
import { Slide } from "../../types"

const getSlide = async (qParams?: QueryParams) => {
  const res = await requestCMS.get<GeneralApiResponse<Slide[]>>(
    "/employee/slide",
    qParams
  )
  return res
}

export default getSlide
