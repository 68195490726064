import { GeneralSertifikatProgramRequest, GeneralSertifikatProgramResponse } from "utils/api/cms/types"
import { requestCMS } from "utils/axiosInstance"

const createSertifikatProgram = async (payload: GeneralSertifikatProgramRequest) => {
    const res = await requestCMS.post<GeneralSertifikatProgramResponse>(
        "/employee/program-sertifikat",
        payload
    )
    return res
}

export default createSertifikatProgram
