import { lazy, Suspense } from "react"
import { CustomFlowbiteTheme, Tabs } from "flowbite-react"
import { DocumentPlusIcon } from "@heroicons/react/24/solid"
import { Link } from "react-router-dom"

import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import Typography from "components/ui/typography"
import { getCMSSegments } from "utils/helper"
import Button from "components/ui/button/Button"

const DiscountCourseList = lazy(
  () => import("components/cms/DiscountCourseList")
)
const DiscountWebinarList = lazy(
  () => import("components/cms/DiscountWebinarList")
)

const DiscountSertifikasiList = lazy(
  () => import("components/cms/DiscountSertifikasiList")
)

const DiscountManagement = () => {
  const segments = getCMSSegments()

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix="cms"
      />
      <div className="lg:inline-flex grid items-center">
        <Typography
          variant="subheading4"
          as="h1"
          className="text-primary my-2"
        >
          Pengaturan Discount
        </Typography>
        <Button
          as={Link}
          to="create"
          className="inline-flex items-center gap-2 py-[10px] px-5 lg:ml-auto"
        >
          <DocumentPlusIcon className="h-6 w-6 text-white" />
          <span>Buat Diskon</span>
        </Button>
      </div>

      <Tabs.Group
        aria-label="Discount tabs"
        style="underline"
        theme={theme}
      >
        <Tabs.Item
          active
          title="Kelas"
        >
          <Suspense>
            <DiscountCourseList />
          </Suspense>
        </Tabs.Item>
        <Tabs.Item title="Webinar">
          <Suspense>
            <DiscountWebinarList />
          </Suspense>
        </Tabs.Item>
        <Tabs.Item title="Sertifikasi">
          <Suspense>
            <DiscountSertifikasiList />
          </Suspense>
        </Tabs.Item>
      </Tabs.Group>
    </EmployeeDashboardWrapper>
  )
}

const theme: CustomFlowbiteTheme["tab"] = {
  tablist: {
    tabitem: {
      base: "flex items-center justify-center p-4 text-sm font-medium first:ml-0 disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 focus:outline-none",
      styles: {
        underline: {
          active: {
            on: "text-primary border-b-2 border-primary active dark:text-primary dark:border-primary",
            off: "border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300",
          },
        },
      },
    },
  },
}

export default DiscountManagement
