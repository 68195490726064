import { useMutation } from "@tanstack/react-query"

import {
  GeneralCourseCategoryResponse,
  UpdateCourseCategoryRequest,
} from "../../types"
import { updateCourseCategory } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationUpdateCourseCategory = (
  options?: GeneralMutationParams<
    GeneralCourseCategoryResponse,
    UpdateCourseCategoryRequest
  >
) => {
  return useMutation({
    mutationFn: ({ id, payload }) => updateCourseCategory(id, payload),
    ...options,
  })
}

export default useMutationUpdateCourseCategory
