import { useLocation, useParams } from "react-router-dom"

export const getCMSSegments: () => string[] = (): string[] => {
  const location = useLocation()
  const params = useParams()

  let paths = location.pathname.split("/")

  paths.forEach((segment, i) => {
    const isParam = Object.keys(params).find((key) => params[key] === segment)
    if (isParam) {
      paths[i - 1] = `${paths[i - 1]}/${segment}`
      return
    }

    return
  })

  return paths.filter((segment) => {
    const isParam = Object.keys(params).find((key) => params[key] === segment)
    return segment !== "cms" && segment !== "" && !isParam
  })
}
