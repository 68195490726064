import { Select } from "flowbite-react"
import Typography from "components/ui/typography"
import { Section } from "./types"
import { useState } from "react"

interface SideFilterProps {
  sections: Section[]
}

const SideFilter = ({ sections }: SideFilterProps) => {
  const [showCareerOption, setShowCareerOption] = useState(true)

  const handleSelectChange = () => {
    setShowCareerOption(false)
  }

  const handleSelectBlur = () => {
    setShowCareerOption(true)
  }

  return (
    <div className="w-64">
      {sections.map((section, index) => (
        <div
          key={index}
          className="flex flex-col text-white font-bold gap-4 max-w-md"
          id={`select-${index}`}
        >
          <Select
            id={`select-${index}`}
            className="text-primary cursor-pointer focus:outline-primary"
            {...section.register}
            onClick={handleSelectChange}
            onFocus={handleSelectBlur} // Call the handler when select loses focus
          >
            {section.category == "Career" ? <></> : <></>}

            {section.items.map((item, index) => (
              <option
                className="text-primary font-bold cursor-pointer hover:bg-primary focus:outline-primary"
                key={index}
                value={item.value}
              >
                {item.name}
              </option>
            ))}
          </Select>
        </div>
      ))}
    </div>
  )
}

export default SideFilter
