import { useParams } from "react-router-dom"

import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import { useGetVideoDetail } from "utils/api/cms/queries/hooks"
import { getCMSSegments } from "utils/helper"
import VideoPlayer from "components/ui/video-player"
import { getCmsToken } from "utils/auth"
import CourseVideoManagementForm from "components/cms/CourseVideoManagementForm"

const CourseVideoDetail = () => {
  const segments = getCMSSegments()
  const params = useParams<{ id: string; videoID: string; sectionID: string }>()

  const { data: dataVideoDetail } = useGetVideoDetail({
    options: {
      staleTime: 0,
    },
    videoID: params.videoID ?? "",
  })

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        prefix="cms"
        segments={segments}
      />
      <h2 className="text-xl text-primary font-semibold leading-loose tracking-wide">
        Video Belajar
      </h2>

      {dataVideoDetail && (
        <>
          <span className="cms-base-label">Preview Video</span>
          <div className="bg-black w-full h-80 md:w-3/5">
            <VideoPlayer
              token={getCmsToken() ?? ""}
              url={`${process.env.REACT_APP_API_URL}/employee/course/section/video/stream/${dataVideoDetail.data.id}/${dataVideoDetail.data.media_id}`}
            />
          </div>
          <CourseVideoManagementForm
            maxSeq={1}
            onSubmitHandler={() => {}}
            initialValue={{
              title: dataVideoDetail.data.title,
              notes: dataVideoDetail.data.notes,
              is_preview: dataVideoDetail.data.is_preview,
              sequence: dataVideoDetail.data.sequence,
            }}
            isDetail
          />
        </>
      )}
    </EmployeeDashboardWrapper>
  )
}

export default CourseVideoDetail
