// updatePasswordPartnership.ts
import { GeneralPartnershipResponse, UpdatePartnershipPasswordRequest } from "utils/api/cms/types";
import { requestCMS } from "utils/axiosInstance";

const updatePasswordPartnership = async (
    { id, payload }: UpdatePartnershipPasswordRequest
): Promise<GeneralPartnershipResponse> => {
    const response = await requestCMS.put<GeneralPartnershipResponse>(`/employee/partnership/password/${id}`, payload);
    return response;
};

export default updatePasswordPartnership