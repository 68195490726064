import React, { useState, useEffect } from "react"
import CourseManagementForm, {
  CourseForm,
} from "../../../components/cms/CourseManagementForm"
import EmployeeDashboardWrapper from "../../../components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "../../../components/ui/breadcrumb/DefBreadcrumb"
import { SubmitHandler } from "react-hook-form"
import { getCMSSegments } from "../../../utils/helper"
import { LoaderFunction, useLoaderData, useNavigate } from "react-router-dom"
import {
  CMSCourseCategoryListResponse,
  CMSCreateCourseResponse,
  CMSGetCourseResponse,
  Course,
  GeneralAPIValidationResponse,
  ProductCategory,
} from "../../../utils/types"
import api from "../../../utils/axiosInstance"
import to from "await-to-js"
import useGetPartnership from "utils/api/cms/queries/hooks/useGetPartnership"

const CourseManagementEdit: React.FC = () => {
  const segments = getCMSSegments()
  const [serverValidationError, setServerValidation] = React.useState<{
    title: string | undefined
    category_ids: string | undefined
    price: string | undefined
    description: string | undefined
    partnership_id: string | undefined
  }>({
    title: undefined,
    category_ids: undefined,
    price: undefined,
    description: undefined,
    partnership_id: undefined,
  })
  const loaderResponse = useLoaderData() as {
    categoryOptions: CMSCourseCategoryListResponse<ProductCategory>
    course: CMSGetCourseResponse<Course>
  }
  const [categoryOptions, setCategoryOptions] = useState<
    {
      label: string
      value: number
    }[]
  >([])
  const { data: dataPartnersip } =
  useGetPartnership({
    options: {
      staleTime: 5 * 60 * 1000,
    },
  })
const partnershipOptions = dataPartnersip?.data.map((partnership) => ({
  label: partnership.full_name,
  value: partnership.id,
})) || [];
  const [course, setCourse] = useState<Course | undefined>(undefined)
  const navigate = useNavigate()

  const [courseSubmitting, setCourseSubmitting] = useState(false)

  useEffect(() => {
    if (!loaderResponse) return

    const options: {
      label: string
      value: number
    }[] = []
    loaderResponse.categoryOptions.data.forEach((category) => {
      options.push({
        label: category.name,
        value: category.id,
      })
    })

    setCategoryOptions(options)
    setCourse(loaderResponse.course.data)
  }, [loaderResponse])

  const courseSubmitHandler: SubmitHandler<CourseForm> = async (
    data: CourseForm
  ) => {
    setCourseSubmitting(true)

    const [err, _] = await to<
      CMSCreateCourseResponse<Course>,
      GeneralAPIValidationResponse
    >(api.cms.updateCourse(course!.id, data))

    if (err) {
      const validationResponse = { ...serverValidationError }

      Object.keys(validationResponse).forEach((field) => {
        const errMessages: string[] | undefined = err!.errors![field]

        if (errMessages === undefined) return

        const message = (errMessages as string[]).join(". ")

        validationResponse[field] = message
      })

      setServerValidation(validationResponse)
      setCourseSubmitting(false)

      return
    }

    navigate("/cms/course", {
      state: { courseUpsertSuccess: true },
    })
  }

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix={"cms"}
      />
      <h2 className="text-xl text-primary font-semibold leading-loose tracking-wide my-2">
        Edit Konten Belajar
      </h2>
      {course && categoryOptions && (
        <CourseManagementForm
          method="PUT"
          onSubmitHandler={courseSubmitHandler}
          serverValidationResponse={serverValidationError}
          categoryOptions={categoryOptions}
          partnershipOptions={partnershipOptions}
          submitting={courseSubmitting}
          initialValue={{
            title: course.title,
            lecturer: course.lecturer,
            description: course.description,
            price: course.price.master_price,
            category_ids: course.categories!.map((obj) => obj.id),
            partnership_id: course.partnerships?.id,
          }}
        />
      )}
    </EmployeeDashboardWrapper>
  )
}

export default CourseManagementEdit

export const loadCourse: LoaderFunction = async ({ params }) => {
  const courseID = params.id

  if (courseID === undefined || isNaN(+courseID!))
    throw new Response("Not Found", { status: 404 })

  const loadCategoryOptions = async (): Promise<
    CMSCourseCategoryListResponse<ProductCategory>
  > => {
    const response = await api.cms.getCourseCategories()

    return response
  }

  const loadCourse = async (): Promise<CMSGetCourseResponse<Course>> => {
    const response = await api.cms.getCourse(+courseID)

    return response
  }

  return {
    categoryOptions: await loadCategoryOptions(),
    course: await loadCourse(),
  }
}
