import { KeyIcon } from "@heroicons/react/24/solid"
import { Link } from "react-router-dom"

import Button from "components/ui/button/Button"
import Typography from "components/ui/typography"
import { Helmet } from "react-helmet"

const Settings = () => {
  return (
    <div className="container pt-8">
      <Helmet>
        <title>Pengaturan</title>
      </Helmet>
      <Typography
        as="h1"
        variant="heading6"
        className="mb-8"
      >
        Pengaturan
      </Typography>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-[repeat(auto-fill,350px)]">
        <div className="shadow px-6 py-4">
          <div className="inline-flex gap-4 items-center">
            <KeyIcon
              className="bg-red-100 text-primary p-2.5 rounded-full"
              width={64}
              height={64}
            />
            <div className="flex flex-col items-start gap-1">
              <Typography
                as="h2"
                variant="text1"
                className="font-medium"
              >
                Password
              </Typography>
              <Button
                as={Link}
                to="/settings/change-password"
                variant="outlined"
                size="sm"
              >
                Ubah Password
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings
