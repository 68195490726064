import { useQuery } from "@tanstack/react-query"

import { getCoursesDiscount } from "../functions"
import { CmsQueryKey, GetDiscountCourseParams } from "../../types"

const useGetCoursesDiscount = (params?: GetDiscountCourseParams) => {
  return useQuery({
    queryKey: [CmsQueryKey.DiscountCourse, params?.qParams ?? ""],
    queryFn: () => getCoursesDiscount(params?.qParams),
    ...params?.options,
  })
}

export default useGetCoursesDiscount
