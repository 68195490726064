import { useQuery } from "@tanstack/react-query"

import { getCareerDetail } from "../functions"
import { PublicQueryKey, PublicGetCareerDetailParams } from "../../types"

const useGetCareerDetail = (params?: PublicGetCareerDetailParams) => {
  return useQuery({
    queryKey: [PublicQueryKey.Career, params?.slug ?? ""],
    queryFn: () => getCareerDetail(params?.slug ?? ""),
    ...params?.options,
  })
}

export default useGetCareerDetail
