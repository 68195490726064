import { useEffect, useRef } from "react"
import { AcademicCapIcon } from "@heroicons/react/24/solid"
import DOMPurify from "dompurify"
import noImage from "assets/images/no-image.jpg"
import { useIntersectionObserver, useMediaQuery } from "usehooks-ts"

import Card from "components/ui/card/horizontal"
import { useInfiniteGetPurchasedCourses } from "utils/api/user/queries/hooks"
import Button from "components/ui/button/Button"
import Typography from "components/ui/typography"
import "./styles.css"
const Courses = () => {
  const ref = useRef<HTMLParagraphElement | null>(null)
  const entry = useIntersectionObserver(ref, {})
  const desktop = useMediaQuery("(min-width: 1024px)")
  const {
    data: dataPurchasedCourses,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteGetPurchasedCourses({
    options: {
      staleTime: 0,
    },
    qParams: {
      limit: 10,
    },
  })

  useEffect(() => {
    if (!entry?.isIntersecting) return
    fetchNextPage()
  }, [entry])


  return (
    <div className="space-y-5">
      {
        dataPurchasedCourses?.pages.map((page) =>
          page.data.map((course) => (
            <Card
              key={course.id}
              to={`/my-course/${course.slug}`}
              className="w-full lg:h-[180px]"
              thumbnail={course.thumbnail ?? noImage}
              height={desktop ? 130 : 60}
              maxHeight={desktop ? 0 : 95}
              thumbWidth={desktop ? "" : "w-24 md:w-60"}
              desktop={desktop ? true : false}
            >
              <div className="flex flex-col gap-2">
                <div className="flex flex-col gap-1">
                  {desktop ? (
                    <>
                      <Card.Title className="text-primary capitalize">
                        {course.title}
                      </Card.Title>
                      <p
                        className="text-sm font-medium text-tertiary clamp-3"
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            course.description ?? "",
                            {
                              USE_PROFILES: { svg: false },
                            }
                          ),
                        }}
                      ></p>
                    </>
                  ) : (
                    <div className="flex flex-col gap-1 ">
                      <Typography
                        variant="text3"
                        className="text-primary font-semibold"
                      >
                        {course.title}
                      </Typography>
                      <div className="space-y-1 capitalize">
                        <Card.Caption
                          text={course.lecturer ?? "-"}
                          icon={
                            <AcademicCapIcon
                              className="text-primary"
                              width={16}
                              height={18}
                            />
                          }
                        />
                      </div>
                      {/* <div className="absolute bottom-0 left-0 p-4">
                        <p
                          className="text-[13px]  lg:mt-0 font-medium text-tertiary clamp-3"
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              course.description ?? "",
                              {
                                USE_PROFILES: { svg: false },
                              }
                            ),
                          }}
                        ></p>

                      </div> */}
                    </div>
                  )}
                </div>
                <div className="space-y-1 capitalize hidden lg:block">
                  <Card.Caption
                    text={course.lecturer ?? "-"}
                    icon={
                      <AcademicCapIcon
                        className="text-primary"
                        width={16}
                        height={18}
                      />
                    }
                  />
                </div>
              </div>
            </Card>
          ))
        )
      }
      {
        hasNextPage && (
          <div
            ref={ref}
            className="flex justify-center items-center w-[522px]"
          >
            {isFetchingNextPage ? (
              <Typography className="text-primary">Muat Lebih...</Typography>
            ) : (
              <Button
                variant="outlined"
                onClick={() => fetchNextPage()}
              >
                Muat Lebih
              </Button>
            )}
          </div>
        )
      }
    </div >
  )
}

export default Courses
