import { useMutation } from "@tanstack/react-query"

import { DeactivateDiscountResponse } from "../../types"
import { deactivateDiscount } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationDeactivateDiscount = (
  options?: GeneralMutationParams<
    DeactivateDiscountResponse,
    { discountID: string }
  >
) => {
  return useMutation({
    mutationFn: ({ discountID }) => deactivateDiscount(discountID),
    ...options,
  })
}

export default useMutationDeactivateDiscount
