import { useNavigate, useParams } from "react-router-dom"
import { type SubmitHandler, useForm } from "react-hook-form"
import clsx from "clsx"
import { toast } from "react-toastify"
import { Helmet } from "react-helmet"
import TextInput from "components/ui/form/TextInput"
import Section from "components/ui/section/Section"
import Typography from "components/ui/typography"
import Button from "components/ui/button/Button"
import type { KarirUserPayload } from "utils/api/user/types"
import { useGetCareerDetail } from "utils/api/public/queries/hooks"
import { ReactComponent as Decoration } from "assets/svg/Public_Decoration.svg"
import { ReactComponent as MobileDecoration } from "assets/svg/Public_Decoration_2.svg"
import useMutationCareerUser from "utils/api/user/mutations/hooks/useMutationCareerUser"
import { useState } from "react"
import InputFile from "components/ui/form/InputFile"

const CareerUser = () => {
  const { slug } = useParams()
  const { data } = useGetCareerDetail({ slug: slug ?? "" })
  const [fileCV, setFileCV] = useState<File | null>(null)
  const [filePortfolio, setFilePortfolio] = useState<File | null>(null)
  const dataCareer = data?.data
  const careerId = dataCareer?.id ?? 0

  const navigate = useNavigate()
  const {
    formState: { errors },
    watch,
    register,
    handleSubmit,
  } = useForm<KarirUserPayload>()

  const { mutate, isLoading } = useMutationCareerUser({
    onSuccess(data) {
      if (!data.success) {
        toast.error("Sudah terdaftar")
        return
      }
      toast.success("Pendaftaran berhasil")
      setTimeout(() => {
        navigate("/careers")
      }, 1000)
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  const handleChangeFileCV = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0]

    if (selectedFile) {
      setFileCV(selectedFile)
    }
  }

  const handleChangeFilePortfolio = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const selectedFile = e.target.files?.[0]

    if (selectedFile) {
      setFilePortfolio(selectedFile)
    }
  }

  const onSubmit: SubmitHandler<KarirUserPayload> = (formData) => {
    const formPayload = new FormData()
    formPayload.append("email", formData.email)
    formPayload.append("name", formData.name)
    formPayload.append("address", formData.address)
    formPayload.append("career_id", String(careerId))

    if (fileCV) {
      formPayload.append("cv", fileCV)
    }

    if (filePortfolio) {
      formPayload.append("portfolio", filePortfolio)
    }

    mutate(formPayload)
  }

  return (
    <>
      <Helmet>
        <title>Pendaftaran Karir</title>
      </Helmet>
      <div className="w-full h-40 mb-40">
        <div className="flex flex-col justify-center text-center relative z-10 mt-5 md:mt-16 px-10">
          <Typography
            variant="heading4"
            as="h1"
            className="text-white mb-2 lg:mb-4 md:text-4xl text-lg md:font-bold font-semibold"
          >
            Karir {dataCareer?.title}
          </Typography>
          <Typography
            variant="text2"
            className="text-white font-light md:text-md"
          >
            Ayo Bergabung dengan kami{" "}
            <span className="md:block">
              buktikan kreativitas dan kemampuan anda
            </span>
          </Typography>
        </div>
        <Decoration className="absolute md:top-5 left-0 right-0 w-full z-0 md:h-96 top-0 md:block hidden" />
        <MobileDecoration className="absolute left-0 right-0 w-full z-0 md:h-96 top-20 md:hidden block" />
      </div>
      <Section space="top-bottom">
        <div
          className={clsx(
            "flex flex-col items-center justify-center",
            "mx-8 md:mx-auto",
            "max-w-5xl",
            "rounded",
            "space-y-4",
            "md:py-16 md:px-[200px] md:shadow"
          )}
        >
          <Typography
            as="h1"
            variant="heading3"
            className="text-secondary mb-10"
          >
            Form Pendaftaran
          </Typography>
          <form
            className="w-full space-y-5"
            autoComplete="off"
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextInput
              label="Nama Lengkap"
              isRequired
              errorMessage={errors.name?.message}
              {...register("name", {
                required: {
                  value: true,
                  message: "Nama Lengkap wajib diisi",
                },
              })}
            />
            <TextInput
              label="Email"
              type="email"
              isRequired
              errorMessage={errors.email?.message}
              {...register("email", {
                required: {
                  value: true,
                  message: "Email wajib diisi",
                },
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message:
                    'Pastikan email Anda memiliki format "nama@example.com"',
                },
              })}
            />
            <TextInput
              label="Alamat"
              isRequired
              errorMessage={errors.address?.message}
              {...register("address", {
                required: {
                  value: true,
                  message: "Alamat wajib diisi",
                },
              })}
            />
            <InputFile
              label="Upload CV"
              accept=".pdf,.png,.jpg"
              onChange={handleChangeFileCV}
              errorMessage={errors.cv?.message}
              register={register("cv", {
                required: {
                  value: true,
                  message: "CV wajib diisi",
                },
              })}
            />
            <InputFile
              label="Upload Portfolio"
              accept=".pdf,.png,.jpg"
              onChange={handleChangeFilePortfolio}
              errorMessage={errors.portofolio?.message}
              register={register("portofolio", {
                required: {
                  value: false,
                  message: "",
                },
              })}
            />
            <Button
              className="!mt-8"
              type="submit"
              size="lg"
              fullWidth
              isLoading={isLoading}
            >
              Daftar
            </Button>
          </form>
        </div>
      </Section>
    </>
  )
}

export default CareerUser
