import clsx from "clsx"
import React from "react"
import ReactPaginate from "react-paginate"
import "./styles.css"
const LightPagination: React.FC<{
  breakLabel?: React.ReactNode
  nexLabel?: React.ReactNode
  pageCount: number
  initialPage?: number
  currentPage: number
  previousLabel?: React.ReactNode
  containerClassName?: React.ReactNode
  pageClassName?: React.ReactNode
  activeClassName?: React.ReactNode
  pageRangeDisplayed?: number
  forcePage?: number
  onPageChange?: React.Dispatch<React.SetStateAction<number>>
}> = (props) => {
  const onPageClickHandler = ({ selected }: { selected: number }) => {
    if (!props.onPageChange) return
    props.onPageChange(selected)
  }

  const firstPage = props.currentPage === 0
  const lastPage = props.currentPage === props.pageCount - 1

  return (
    <ReactPaginate
      pageCount={props.pageCount}
      containerClassName={clsx(
        props.containerClassName
          ? props.containerClassName
          : "inline-flex items-center -space-x-px"
      )}
      breakLabel={props.breakLabel}
      nextLabel={!lastPage ? props.nexLabel : null}
      initialPage={props.initialPage}
      previousLabel={!firstPage ? props.previousLabel : null}
      pageClassName={clsx(
        " hover:bg-gray-700 hover:text-white",
        props.pageClassName
          ? props.pageClassName
          : "block px-1 py-3.5 pagination ml-0 leading-tight border"
      )}
      activeClassName={clsx(
        props.activeClassName
          ? props.activeClassName
          : "bg-primary border-primary text-white"
      )}
      pageRangeDisplayed={props.pageRangeDisplayed}
      nextLinkClassName={
        !lastPage
          ? clsx(
            "rounded-r-lg text-gray-400 hover:bg-gray-700 hover:text-white",
            props.pageClassName
              ? props.pageClassName
              : "block px-4 py-3.5 ml-0 leading-tight border"
          )
          : undefined
      }
      previousLinkClassName={
        !firstPage
          ? clsx(
            "rounded-l-lg text-gray-400 hover:bg-gray-700 hover:text-white",
            props.pageClassName
              ? props.pageClassName
              : "block px-4 py-3.5 ml-0 leading-tight border"
          )
          : undefined
      }
      breakClassName={clsx(
        " text-gray-400 hover:bg-gray-700 hover:text-white",
        props.pageClassName
          ? props.pageClassName
          : "block px-4 py-3.5 ml-0 leading-tight border"
      )}
      forcePage={props.forcePage}
      {...{ onPageChange: props.onPageChange && onPageClickHandler }}
    />
  )
}

export default LightPagination
