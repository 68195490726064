import { useEffect, useRef } from "react"
import { AcademicCapIcon } from "@heroicons/react/24/solid"
import DOMPurify from "dompurify"
import noImage from "assets/images/no-image.jpg"
import { useIntersectionObserver } from "usehooks-ts"

import Card from "components/ui/card/horizontal"
import { useInfiniteGetPurchasedWebinars } from "utils/api/user/queries/hooks"
import Button from "components/ui/button/Button"
import Typography from "components/ui/typography"

const Workshops = () => {
  const ref = useRef<HTMLParagraphElement | null>(null)
  const entry = useIntersectionObserver(ref, {})

  const {
    data: dataPurchasedWorkshops,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteGetPurchasedWebinars({
    options: {
      staleTime: 0,
    },
    qParams: {
      limit: 10,
    },
  })

  useEffect(() => {
    if (!entry?.isIntersecting) return
    fetchNextPage()
  }, [entry])

  return (
    <div className="space-y-4">
      {dataPurchasedWorkshops?.pages.map((page) =>
        page.data.map((workshop) => (
          <Card
            key={workshop.id}
            to={`/my-workshop/${workshop.slug}`}
            className="flex justify-between w-full md:w-[522px]"
            thumbnail={workshop.thumbnail ?? noImage}
            height={130}
          >
            <div className="flex flex-col justify-between">
              <Card.Title className="text-primary capitalize">
                {workshop.title}
              </Card.Title>
              <div
                className="line-clamp-2 text-sm"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(workshop?.description ?? "", {
                    USE_PROFILES: { svg: false },
                  }),
                }}
              />
            </div>
          </Card>
        ))
      )}
      {hasNextPage && (
        <div
          ref={ref}
          className="flex justify-center items-center w-[522px]"
        >
          {isFetchingNextPage ? (
            <Typography className="text-primary">Muat Lebih...</Typography>
          ) : (
            <Button
              variant="outlined"
              onClick={() => fetchNextPage()}
            >
              Muat Lebih
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

export default Workshops
