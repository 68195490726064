import { useEffect, useState } from "react"
import { SubmitHandler } from "react-hook-form"
import to from "await-to-js"
import { LoaderFunction, useLoaderData, useNavigate } from "react-router-dom"

import api from "utils/axiosInstance"
import CourseManagementForm, {
  CourseForm,
} from "components/cms/CourseManagementForm"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import { getCMSSegments } from "utils/helper"
import {
  CMSCourseCategoryListResponse,
  CMSCreateCourseResponse,
  Course,
  GeneralAPIValidationResponse,
  ProductCategory,
} from "utils/types"
import useGetPartnership from "utils/api/cms/queries/hooks/useGetPartnership"

const CourseManagementCreate: React.FC = () => {
  const segments = getCMSSegments()
  const [serverValidationError, setServerValidation] = useState<{
    title?: string
    lecturer?: string
    category_ids?: string
    price?: string
    description?: string
    partnership_id?: string
  }>({
    title: undefined,
    lecturer: undefined,
    category_ids: undefined,
    price: undefined,
    description: undefined,
    partnership_id: undefined,
  })
  const loaderResponse =
    useLoaderData() as CMSCourseCategoryListResponse<ProductCategory>
  const [categoryOptions, setCategoryOptions] = useState<
    {
      label: string
      value: number
    }[]
  >([])
  const navigate = useNavigate()

  const { data: dataPartnersip } =
    useGetPartnership({
      options: {
        staleTime: 5 * 60 * 1000,
      },
    })
  const partnershipOptions = dataPartnersip?.data.map((partnership) => ({
    label: partnership.full_name,
    value: partnership.id,
  })) || [];
  const [courseSubmitting, setCourseSubmitting] = useState(false)
  useEffect(() => {
    if (!loaderResponse) return

    const options: {
      label: string
      value: number
    }[] = []
    loaderResponse.data.forEach((category) => {
      options.push({
        label: category.name,
        value: category.id,
      })
    })

    setCategoryOptions(options)
  }, [loaderResponse])

  const courseSubmitHandler: SubmitHandler<CourseForm> = async (
    data: CourseForm
  ) => {
    setCourseSubmitting(true)

    const [err, _] = await to<
      CMSCreateCourseResponse<Course>,
      GeneralAPIValidationResponse
    >(api.cms.createCourse(data))

    if (err) {
      const validationResponse = { ...serverValidationError }

      Object.keys(validationResponse).forEach((field) => {
        const errMessages: string[] | undefined = err!.errors![field]

        if (errMessages === undefined) return

        const message = (errMessages as string[]).join(". ")

        validationResponse[field] = message
      })

      setServerValidation(validationResponse)

      setCourseSubmitting(false)

      return
    }

    navigate("/cms/course", {
      state: { courseUpsertSuccess: true },
    })
  }

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix={"cms"}
      />
      <h2 className="text-xl text-primary font-semibold leading-loose tracking-wide my-2">
        Tambah Konten Belajar Baru
      </h2>
      <CourseManagementForm
        method="POST"
        onSubmitHandler={courseSubmitHandler}
        serverValidationResponse={serverValidationError}
        categoryOptions={categoryOptions}
        partnershipOptions={partnershipOptions}
        submitting={courseSubmitting}
      />
    </EmployeeDashboardWrapper>
  )
}

export default CourseManagementCreate

export const loadCategoryOptions: LoaderFunction = async (): Promise<
  CMSCourseCategoryListResponse<ProductCategory>
> => {
  const response = await api.cms.getCourseCategories()

  return response
}
