import React from 'react';
import Badge from 'components/ui/badge/Badge';
import { useNavigate } from 'react-router-dom';
import Typography from 'components/ui/typography';

interface Order {
    no_invoice: string;
    created_at: string;
    status: 'PENDING' | 'SUCCESS' | 'EXPIRED';
    transaction_detail: {
        transactionable: {
            title: string;
            slug: string;
        };
        transactionable_type: string;
    };
    amount: number;
    snap_token: string;
}

interface OrderCardProps {
    order: Order;
}

const OrderCard: React.FC<OrderCardProps> = ({ order }) => {
    const navigate = useNavigate();

    const onRowClick = ({ snapToken, slug, type }) => {
        navigate(`/payment/${type}/${slug}/show/${snapToken}`);
    };

    const getBadgeType = (status: 'PENDING' | 'SUCCESS' | 'EXPIRED') => {
        const statusToBadgeTypeMap: { [key: string]: string } = {
            'PENDING': 'warning',
            'SUCCESS': 'success',
            'EXPIRED': 'danger',
        };
        return statusToBadgeTypeMap[status] || 'neutral';
    };

    const formatter = new Intl.NumberFormat("id-ID", {
        style: 'currency',
        currency: 'IDR',
    });

    const isOrderExpired = (createdAt) => {
        const oneDayInMilliseconds = 24 * 60 * 60 * 1000; // 24 jam
        const orderDate = new Date(createdAt).getTime();
        const currentDate = new Date().getTime();

        return currentDate - orderDate > oneDayInMilliseconds;
    };

    const expired = isOrderExpired(order.created_at);

    const formatDate = (dateString: string): string => {
        const date = new Date(dateString);
        const options: Intl.DateTimeFormatOptions = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric'
        };
        return date.toLocaleDateString('en-GB', options);
    };

    return (
        <div className="bg-white p-4 mb-4 border-b flex flex-col md:flex-row relative">
            <div className="flex-grow">
                <div className="flex justify-between items-center">
                    <Typography
                        variant='text2'
                        className="text-tertiary mb-1 font-semibold">
                        {order.transaction_detail.transactionable.title}
                    </Typography>
                    <div className="block lg:hidden">
                        {/* <Badge type={getBadgeType(order.status)}>{order.status}</Badge> */}
                        <Badge type={getBadgeType(expired ? 'EXPIRED' : order.status)}>
                            {expired ? 'Expired' : order.status}
                        </Badge>
                        {/* {order.status === 'PENDING' && !expired && (
                            <button
                                className="bg-green-500 text-white py-2 ml-5 px-4 rounded hover:bg-green-600 focus:outline-none focus:bg-green-600 md:w-auto"
                                onClick={() => onRowClick({
                                    snapToken: order.snap_token,
                                    slug: order.transaction_detail.transactionable.slug,
                                    type: order.transaction_detail.transactionable_type.toLowerCase(),
                                })}
                            >
                                Bayar
                            </button>
                        )} */}
                    </div>
                </div>
                <Typography variant='text3' className="mb-2 text-gray-400 font-medium">
                    {formatDate(order.created_at)}
                </Typography>
                <Typography variant='text3' className="mb-2 text-gray-400 font-medium">
                    {order.no_invoice}
                </Typography>
                <div className="flex justify-between items-center">
                    <Typography variant='text3' className='font-semibold'>Total:</Typography>
                    <div className="font-semibold text-gray-600">{formatter.format(order.amount)}</div>
                </div>
            </div>
            <div className="lg:absolute mt-2 lg:flex lg:right-2 lg:top-0 flex-col gap-4 ">
                <div className="hidden lg:block">
                    <Badge type={getBadgeType(expired ? 'EXPIRED' : order.status)}>
                        {expired ? 'Expired' : order.status}
                    </Badge>
                </div>
                {order.status === 'PENDING' && !expired && (
                    <button
                        className="bg-green-500 text-white py-2 px-5 lg:px-2 mr-2 rounded hover:bg-green-600 focus:outline-none focus:bg-green-600 "
                        onClick={() => onRowClick({
                            snapToken: order.snap_token,
                            slug: order.transaction_detail.transactionable.slug,
                            type: order.transaction_detail.transactionable_type.toLowerCase(),
                        })}
                    >
                        Bayar
                    </button>
                )}
            </div>
        </div>
    );
};

export default OrderCard;
