import { requestCMS } from "utils/axiosInstance"
import type { DeleteCourseCategoryResponse } from "../../types"

const deleteCourseCategory = async (courseCategoryID: string) => {
  const res = await requestCMS.delete<DeleteCourseCategoryResponse>(
    `/employee/course-categories/delete/${courseCategoryID}`
  )
  return res
}

export default deleteCourseCategory
