import Typography from "../../typography"

interface CardCaptionProps {
  text: string
  icon?: React.ReactNode
}

const CardCaption = ({ text, icon }: CardCaptionProps) => {
  return (
    <div className="flex items-center gap-1 text-typo-gray">
      {icon}
      <Typography
        variant="text4"
        className="font-semibold"
      >
        {text}
      </Typography>
    </div>
  )
}

export default CardCaption
