import { Link } from "react-router-dom"
import Typography from "components/ui/typography"

import Button from "components/ui/button/Button"
import {
  MapPinIcon,
  BriefcaseIcon,
  CalendarIcon,
} from "@heroicons/react/20/solid"

interface CardProps {
  location: string
  working_type: string
  title: string
  page: string
  company_icon: string
  company: string
}

const Card = ({
  location,
  working_type,
  company,
  page,
  company_icon,
}: CardProps) => {
  return (
    <div className="h-[33rem] w-[24rem]  flex items-center justify-between ">
      <div className=" h-full w-full shadow-2xl  rounded-2xl flex flex-col justify-center  items-center ">
        <div className="h-[86%] w-[80%] flex flex-col justify-between">
          <div className="w-full flex-col h-1/2 flex justify-evenly items-center">
            <img
              className="w-40 h-40"
              src={company_icon}
            ></img>
          </div>
          <div className="mt-4">
            <div className="h-10 flex items-center justify-center mb-8">
              <Typography
                variant="subheading2"
                as="h1"
                className="text-primary"
              >
                {company}
              </Typography>
            </div>
            <div className="h-10 flex items-center">
              <BriefcaseIcon className="w-8 h-8 mr-2 text-quarternary" />
              <Typography
                variant="text1"
                as="h1"
                className="font-semibold text-quarternary"
              >
                {working_type.charAt(0).toUpperCase() +
                  working_type.slice(1).toLowerCase()}
              </Typography>
            </div>
            <div className="h-10 flex items-center">
              <MapPinIcon className="w-8 h-8 mr-2 text-quarternary" />
              <Typography
                variant="text1"
                as="h1"
                className="font-semibold text-quarternary"
              >
                {location}
              </Typography>
            </div>
            {/* Hardcode pada date */}
            <div className="flex justify-center mt-8 items-center gap-3">
              <Button
                as={Link}
                to={page}
                className="py-4 px-10 rounded-lg w-full text-center"
              >
                Lamar Sekarang
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Card
