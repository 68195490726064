import clsx from "clsx"
import { SubmitHandler, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import TextInput from "components/ui/form/TextInput"
import Section from "components/ui/section/Section"
import Typography from "components/ui/typography"
import Button from "components/ui/button/Button"
import type { ForgotPasswordRequest } from "utils/api/user/types"
import { useMutationForgotPassword } from "utils/api/user/mutations/hooks"
import { useEffect, useState } from "react"

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPasswordRequest>()

  const { mutate } = useMutationForgotPassword({
    onSuccess(data) {
      if (!data.success) {
        toast.error("Pengiriman email tidak berhasil")
        return
      }
      handleResetButton()
      toast.success(
        "Link reset password sudah dikirimkan silahkan cek kotak masuk email anda"
      )
    },
    onError(error) {
      if (error.message === "The selected email is invalid.") {
        toast.error("Email yang anda masukkan tidak valid")
      } else {
        toast.error(error.message)
      }
    },
  })

  const [isResendDisabled, setIsResendDisabled] = useState(
    localStorage.getItem("resendPasswordDisabled") === "true" ? true : false
  )
  const [remainingTime, setRemainingTime] = useState(0)

  const handleResetButton = () => {
    setIsResendDisabled(true)
    setRemainingTime(130000)

    setTimeout(() => {
      setIsResendDisabled(false)
      setRemainingTime(0)
      localStorage.removeItem("resendPasswordDisabled")
    }, 130000)

    localStorage.setItem("resendPasswordDisabled", "true")
  }

  function formatTime(ms) {
    const minutes = Math.floor(ms / 60000)
    const seconds = Math.floor((ms % 60000) / 1000)
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`
  }

  useEffect(() => {
    if (isResendDisabled === true) {
      handleResetButton()
    }
  }, [])

  useEffect(() => {
    let intervalId
    if (remainingTime > 0) {
      intervalId = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1000)
      }, 1000)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [remainingTime])

  const onSubmit: SubmitHandler<ForgotPasswordRequest> = (data) => {
    mutate(data)
  }

  return (
    <Section space="top-bottom">
      <div
        className={clsx(
          "flex flex-col items-center justify-center",
          "mx-8 md:mx-auto",
          "max-w-5xl",
          "rounded",
          "space-y-4",
          "md:py-16 md:px-[200px] md:shadow"
        )}
      >
        <Typography
          as="h1"
          variant="heading3"
          className="text-secondary mb-10"
        >
          Lupa<span className="text-primary"> Password</span>
        </Typography>

        <Typography
          variant="subheading4"
          className="font-medium text-center w-2/3"
        >
          Pastikan akun Anda terdaftar manual bukan melalui Google Account
        </Typography>

        <form
          className="w-full space-y-5"
          onSubmit={handleSubmit(onSubmit)}
        >
          <TextInput
            type="email"
            isRequired
            label="Email"
            errorMessage={errors.email?.message}
            {...register("email", {
              required: {
                value: true,
                message: "Email wajib diisi",
              },
              pattern: {
                value: /\S+@\S+\.\S+/,
                message:
                  'Pastikan email Anda memiliki format "nama@example.com"',
              },
            })}
          />
          {remainingTime > 0 ? (
            <Typography
              as="h1"
              variant="text4"
              className="text-tertiary w-full sm:text-left items-center sm:w-1/2"
            >
              Tunggu {formatTime(remainingTime)} sebelum dapat Resend Email
              kembali.
            </Typography>
          ) : (
            <></>
          )}
          <Button
            className="!mt-8"
            type="submit"
            size="lg"
            fullWidth
            disabled={isResendDisabled}
          >
            Send Reset Link
          </Button>
          <hr className="h-1 mx-auto my-4 bg-gray-200 border-0 rounded" />
        </form>
      </div>
    </Section>
  )
}

export default ForgotPassword
