import { requestCMS } from "utils/axiosInstance"
import { DisableReferralResponse } from "../../types"

const disableReferral = async (referralID: string) => {
  const res = await requestCMS.put<DisableReferralResponse>(
    `/employee/referral/disable/${referralID}`,
    {}
  )
  return res
}

export default disableReferral
