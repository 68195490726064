import { useParams } from "react-router-dom"
import clsx from "clsx"
import moment from "moment"

import ContentCareer from "components/public/career/detail-career/CareerContent"
import { useGetCareerDetail } from "utils/api/public/queries/hooks"
import { Helmet } from "react-helmet"

const CareerDetail = () => {
  const { slug } = useParams()

  const { data } = useGetCareerDetail({ slug: slug ?? "" })

  const dataCareer = data?.data

  return (
    <>
      <Helmet>
        <title>Karir: {dataCareer?.title ?? ""}</title>
      </Helmet>
      <div
        className={clsx(
          "container",
          "flex flex-col-reverse my-36 items-start gap-12 lg:justify-between lg:gap-10 lg:flex-row",
          "my-0",
          "min-h-[calc(100vh-350px)]"
        )}
      >
        <ContentCareer
          id={String(dataCareer?.id)}
          slug={dataCareer?.slug ?? ""}
          title={dataCareer?.title ?? ""}
          description={dataCareer?.description ?? ""}
          categories={dataCareer?.categories ?? []}
          page={dataCareer?.page ?? ""}
          location={dataCareer?.location ?? ""}
          working_type={dataCareer?.working_type ?? ""}
          company={dataCareer?.company ?? ""}
          company_icon={dataCareer?.company_icon ?? ""}
        />
      </div>
    </>
  )
}

export default CareerDetail
