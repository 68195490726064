import { requestCMS } from "utils/axiosInstance"
import type { UserDetailResponse } from "../../types"

const getUserDetail = async (id: number) => {
  const res = await requestCMS.get<UserDetailResponse>(
    `/employee/user/detail/${id}`
  )
  return res
}

export default getUserDetail
