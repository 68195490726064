import { useMutation } from "@tanstack/react-query"

import { CreateEmployeeResponse, CreateEmployeeRequest } from "../../types"
import { createEmployee } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationCreateEmployee = (
  options?: GeneralMutationParams<CreateEmployeeResponse, CreateEmployeeRequest>
) => {
  return useMutation({
    mutationFn: (payload) => createEmployee(payload),
    ...options,
  })
}

export default useMutationCreateEmployee
