import clsx from "clsx"
import { Link } from "react-router-dom"

const DefBreadcrumb: React.FC<{
  prefix: string
  segments: string[]
  exclude?: string[]
}> = (props) => {
  let toURL: string = `/${props.prefix}`
  const crumbs = props.segments.map((segment, i) => {
    const isFirst = i === 0
    const isLast = i === props.segments.length - 1

    toURL += `/${segment}`

    if (props.exclude?.includes(segment)) return

    if (isFirst) {
      return (
        <li
          key={i}
          className="inline-flex items-center"
        >
          <Link
            to={toURL}
            className="inline-flex items-center text-sm font-medium text-white hover:text-primary"
          >
            <svg
              aria-hidden="true"
              className="w-4 h-4 mr-2"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
            </svg>
            {segment}
          </Link>
        </li>
      )
    }

    return (
      <li key={i}>
        <div className="flex items-center">
          <svg
            aria-hidden="true"
            className="w-6 h-6 text-gray-400"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            ></path>
          </svg>
          <Link
            to={isLast ? "#" : toURL}
            className={clsx(
              isLast && "text-primary pointer-events-none",
              !isLast && "text-white hover:text-primary",
              "ml-1 text-sm font-medium md:ml-2"
            )}
          >
            {segment}
          </Link>
        </div>
      </li>
    )
  })

  return (
    <nav
      className="flex"
      aria-label="Breadcrumb"
    >
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        {crumbs}
      </ol>
    </nav>
  )
}

export default DefBreadcrumb
