import { useMutation } from "@tanstack/react-query"

import { GeneralSertifikatUserResponse, UpdateSertifikatUserRequest } from "../../types"
import { updateStatus } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationUpdateSertifikat = (
    options?: GeneralMutationParams<
        GeneralSertifikatUserResponse,
        UpdateSertifikatUserRequest
    >
) => {
    return useMutation({
        mutationFn: ({ id, payload }) => updateStatus(id, payload),
        ...options,
    })
}

export default useMutationUpdateSertifikat
