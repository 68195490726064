import { requestCMS } from "utils/axiosInstance"
import type { GeneralCareerResponse } from "../../types"

const getCareerDetail = async (id?: string) => {
  const res = await requestCMS.get<GeneralCareerResponse>(
    `/employee/career/detail/${id}`
  )
  return res
}

export default getCareerDetail
