import { requestCMS } from "utils/axiosInstance"
import { GeneralApiResponse, QueryParams } from "utils/types"
import { SertifikatProgram } from "../../types"

const getSertifikatProgram = async (qParams?: QueryParams) => {
    const res = await requestCMS.get<GeneralApiResponse<SertifikatProgram[]>>(
        "/employee/program-sertifikat",
        qParams
    )
    return res
}

export default getSertifikatProgram
