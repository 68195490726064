import { requestCMS } from "utils/axiosInstance"
import type { UploadThumbnail, UploadThumbnailResponse } from "../../types"

const uploadThumbnailWebinar = async (
  webinarID: string,
  payload: UploadThumbnail
) => {
  const res = await requestCMS.upload<UploadThumbnailResponse>(
    `/employee/webinar/upload-thumbnail/${webinarID}`,
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  )
  return res
}

export default uploadThumbnailWebinar
