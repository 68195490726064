import { requestCMS } from "utils/axiosInstance"
import {
  EmployeeAuthenticationReq,
  EmployeeAuthenticationResponse,
} from "utils/types"

const login = async (payload: EmployeeAuthenticationReq) => {
  const res = await requestCMS.post<EmployeeAuthenticationResponse>(
    "/employee/authenticate",
    payload
  )
  return res
}

export default login
