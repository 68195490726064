import { requestCMS } from "utils/axiosInstance"
import type { QueryParams } from "utils/types"
import type { PartnershipResponse } from "../../types"

const getPartnership = async (qParams?: QueryParams) => {
  const res = await requestCMS.get<PartnershipResponse>(
    "/employee/partnership",
    qParams
  )
  return res
}

export default getPartnership
