import { useMutation } from "@tanstack/react-query"

import { GeneralSertifikatResponse, GeneralSertifikatRequest } from "../../types"
import { GeneralMutationParams } from "utils/types"
import { createSertifikat } from "../functions"

const useMutationCreateSertifikat = (
    options?: GeneralMutationParams<GeneralSertifikatResponse, GeneralSertifikatRequest>
) => {
    return useMutation({
        mutationFn: (payload) => createSertifikat(payload),
        ...options,
    })
}

export default useMutationCreateSertifikat
