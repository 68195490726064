import { requestCMS } from "utils/axiosInstance"
import type { GeneralWebinarResponse } from "../../types"

const getWebinarDetail = async (id?: string) => {
  const res = await requestCMS.get<GeneralWebinarResponse>(
    `/employee/webinar/detail/${id}`
  )
  return res
}

export default getWebinarDetail
