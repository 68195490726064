import {
    useReactTable,
    getCoreRowModel,
    ColumnDef,
    PaginationState,
    OnChangeFn,
} from "@tanstack/react-table"
import LightPagination from "../../ui/pagination/LightPagination"
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/solid"
import OrderCard from "./CardOrder"

interface ReactTableProps {
    columns: ColumnDef<any, any>[]
    data: any[]
    pagination?: PaginationState
    pageCount?: number
    onRowClick?: ({
        snapToken,
        slug,
        type,
    }: {
        snapToken: string
        slug: string
        type: string
    }) => void
    onPaginationChange?: OnChangeFn<PaginationState>
}


const CardTransaction = ({
    columns,
    data,
    pagination,
    pageCount,
    onRowClick,
    onPaginationChange,
}: ReactTableProps) => {
    const table = useReactTable({
        data,
        columns,
        pageCount,
        state: { pagination },
        manualPagination: typeof pagination !== "undefined",
        onPaginationChange,
        getCoreRowModel: getCoreRowModel(),
    })


    return (
        <>
            <div className="max-h-[450px] overflow-x-auto sm:rounded-lg">
                {table.getRowModel().rows.map((row, index) => (
                    <OrderCard key={row.original} order={row.original} />
                ))}
            </div>
            {pagination && (
                <div className="flex lg:justify-start justify-end mt-4">
                    <LightPagination
                        nexLabel={
                            <ChevronRightIcon
                                width={20}
                                height={20}
                                className="text-gray-400"
                            />
                        }
                        previousLabel={
                            <ChevronLeftIcon
                                width={20}
                                height={20}
                                className="text-gray-400"
                            />
                        }
                        pageCount={table.getPageCount()}
                        pageRangeDisplayed={2}
                        currentPage={table.getState().pagination.pageIndex}
                        initialPage={pagination.pageIndex}
                        onPageChange={table.setPageIndex}
                    />
                </div>
            )}
        </>
    )
}

export default CardTransaction
