import { Helmet } from "react-helmet"

import Typography from "components/ui/typography"
import clsxm from "utils/clsxm"
import AboutCard from "components/public/components/AboutCard"
import AboutContents from "assets/static/AboutContents"
import { ReactComponent as Decoration } from "assets/svg/Public_Decoration.svg"
import { ReactComponent as MobileDecoration } from "assets/svg/Public_Decoration_2.svg"
import { ReactComponent as BPILogo } from "assets/svg/BeePlusInstitute_PrimaryLogo.svg"

const About = () => {
  return (
    <>
      <Helmet>
        <title>Tentang Kami</title>
      </Helmet>
      <div className="w-full mb-20">
        <div className="w-full h-40">
          <div className="flex flex-col justify-center text-center relative z-10 mt-5 md:mt-16 px-10">
            <Typography
              variant="heading4"
              as="h1"
              className="text-white mb-2 lg:mb-4 md:text-4xl text-lg md:font-bold font-semibold"
            >
              Build Your Skill <span className="text-white">With Us!</span>
            </Typography>
            <Typography
              variant="text2"
              className="text-white font-light md:text-md"
            >
              Ciptakan peningkatan keterampilan dengan platform belajar digital
              praktis dan{" "}
              <span className="md:block">
                inovatif untuk pekerja baru dan lainnya
              </span>
            </Typography>
          </div>
          <Decoration className="absolute md:top-5 left-0 right-0 w-full z-0 md:h-96 top-0 md:block hidden" />
          <MobileDecoration className="absolute left-0 right-0 w-full z-0 md:h-96 top-20 md:hidden block" />
        </div>
        <div className="container mt-20">
          <div className="flex flex-col md:flex-row">
            <div className="flex flex-col flex-1 mb-10">
              <div className={clsxm("mt-10 mb-10 lg:mt-28 text-left px-4")}>
                <Typography
                  variant="heading4"
                  as="h1"
                  className="text-primary mb-6 md:mb-4"
                >
                  Kurangnya pemanfaatan{" "}
                  <span className="text-secondary block">
                    keterampilan pada new joiner
                  </span>
                </Typography>
                <Typography
                  variant="text3"
                  className="font-medium w-full md:max-w-[85%]"
                >
                  Bee Plus merupakan tim berpengalaman dari startup dan
                  perusahaan ternama, sering menemukan bakat baru yang kesulitan
                  mengoptimalkan keterampilan mereka. Di era saat ini,
                  perusahaan mengharapkan pengalaman dan kemampuan multitasking.
                  Itu sebabnya kami hadir untuk membantu mereka tampil maksimal.
                </Typography>
              </div>
              <div className={clsxm("mb-10 md:mt-10 text-left px-4")}>
                <Typography
                  variant="heading4"
                  as="h1"
                  className="text-primary mb-6 md:mb-4"
                >
                  Visi dan Misi
                </Typography>
                <Typography
                  variant="text2"
                  className="font-medium w-full md:max-w-[85%]"
                >
                  Visi kami adalah Menjadi kekuatan utama dalam mengubah bakat
                  baru menjadi profesional berkualitas yang siap menghadapi
                  tantangan era saat ini. Misi Kami adalah menjadi kekuatan
                  utama dalam mengubah bakat baru menjadi profesional
                  berkualitas yang siap menghadapi tantangan era saat ini.
                </Typography>
              </div>
            </div>
            <BPILogo className="flex-1 md:block hidden" />
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
            {AboutContents.map((data) => (
              <div
                className="flex"
                key={data.title}
              >
                {" "}
                <AboutCard
                  title={data.title}
                  desc={data.desc}
                  icon={data.icon}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export default About
