import About_Access from "assets/svg/About_Access.svg"
import About_Certificate from "assets/svg/About_Certificate.svg"
import About_Project from "assets/svg/About_Project.svg"
import About_Forum from "assets/svg/About_Forum.svg"

const AboutContents = [
  {
    title: "Akses tak terbatas",
    desc: "Nikmati pembelajaran kursus dengan akses tanpa batas!",
    icon: About_Access,
  },
  {
    title: "Sertifikat",
    desc: "Sobatbee akan mendapat sertifikat pelatihan setelah menyelesaikan kursus",
    icon: About_Certificate,
  },
  {
    title: "Membuat Project",
    desc: "Sobatbee akan diarahkan untuk membuat latihan akhir berupa project (Tergantung pada jenis kursus yang dipilih)",
    icon: About_Project,
  },
  {
    title: "Forum Diskusi",
    desc: "Grup diskusi interaktif untuk memecahkan masalah, berbagi pengalaman, dan belajar bersama.",
    icon: About_Forum,
  },
]

export default AboutContents
