import { useMutation } from "@tanstack/react-query"

import { updatePassword } from "../functions"
import { GeneralMutationParams } from "utils/types"
import {
  PublicUpdatePasswordResponse,
  UpdatePasswordRequest,
} from "../../types"

const useMutationUpdatePassword = (
  options?: GeneralMutationParams<
    PublicUpdatePasswordResponse,
    UpdatePasswordRequest
  >
) => {
  return useMutation({
    mutationFn: (payload) => updatePassword(payload),
    ...options,
  })
}

export default useMutationUpdatePassword
