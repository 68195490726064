import { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import {
  PencilSquareIcon,
  EyeIcon,
  PaperAirplaneIcon,
  BookOpenIcon,
  TrashIcon,
} from "@heroicons/react/24/solid"
import { PaginationState, createColumnHelper } from "@tanstack/react-table"
import { Tooltip } from "flowbite-react"
import Modal from "components/ui/modal/Modal"
import ReactTable from "components/ui/react-table/ReactTable"
import Button from "components/ui/button/Button"
import Status from "components/ui/status"
import { useGetCareers } from "utils/api/cms/queries/hooks"
import type { Slide } from "utils/api/cms/types"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import FileInput from "components/ui/form/FileInput"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import ReactSimpleImageViewer from "react-simple-image-viewer"
import Typography from "components/ui/typography"
import { toast } from "react-toastify"
import { CMSSlideListResponse } from "utils/types"
import useGetSlide from "utils/api/cms/queries/hooks/useGetSlide"
import useMutationDeleteSlide from "utils/api/cms/mutations/hooks/useMutationDeleteSlide"

const SlideList = () => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 1,
  })
  const [lastPage, setLastPage] = useState(1)
  const [slideResponse, setSlideResponse] =
    useState<CMSSlideListResponse<Slide>>()
  const [searchFilter, setSearchFilter] = useState("")
  const [perPageLimit, setPerPageLimit] = useState(10)
  const navigate = useNavigate()
  const [dataSlide, setDataSlide] = useState<Slide[]>([])
  const [modalSlide, setModalSlide] = useState<{
    isOpen: boolean
    slideID: string
  }>()

  const { data: resultResponse } = useGetSlide({
    options: {
      staleTime: 0,
    },
    qParams: {
      limit: perPageLimit,
      page: pagination.pageIndex + 1,
      keyword: searchFilter,
    },
  })

  useEffect(() => {
    setSlideResponse(resultResponse)
    setDataSlide(resultResponse?.data || [])
    setLastPage(resultResponse?.meta?.last_page || 1)
  }, [resultResponse])

  const handleModalSlide = (slideId?: string) => {
    setModalSlide((prev) => ({
      isOpen: !prev?.isOpen,
      slideID: slideId ?? ""
    }))
  }

  const { mutate: mutateDeleteSlide, isLoading } = useMutationDeleteSlide({
    onSuccess(data, variables) {
      handleModalSlide()
      toast.success("Berhasil hapus slide")
      setDataSlide((prev) => prev.filter((d) => d.id !== Number(variables.slideID)));
    },
  })

  //   desktop
  const [isViewerOpenDesktop, setIsViewerOpenDesktop] = useState(false);
  const [currentImageDesktop, setCurrentImageDesktop] = useState(0);

  const openImageViewerDesktop = (index: number) => {
    setCurrentImageDesktop(index);
    setIsViewerOpenDesktop(true);
  };

  const closeImageViewerDesktop = () => setIsViewerOpenDesktop(false);


  //   mobile
  const [isViewerOpenMobile, setIsViewerOpenMobile] = useState(false);
  const [currentImageMobile, setCurrentImageMobile] = useState(0);

  const openImageViewerMobile = (index: number) => {
    setCurrentImageMobile(index);
    setIsViewerOpenMobile(true);
  };

  const closeImageViewerMobile = () => setIsViewerOpenMobile(false);

  const slideColumnHelper = createColumnHelper<Slide>()

  const slideColumns = [
    slideColumnHelper.accessor("ordering_number", {
      header: "Nomor",
      cell: (props) => props.getValue(),
    }),
    slideColumnHelper.display({
      header: "Slide Desktop",
      cell: (props) => (
        <div className="flex flex-row w-fit space-x-3">
          <img
            src={props.row.original.image_desktop}
            alt="Image desktop"
            width={100}
            height={100}
            onClick={() => openImageViewerDesktop(props.row.index)}
            style={{ cursor: "pointer" }}
          />
        </div>
      ),
    }),

    slideColumnHelper.display({
      header: "Slide Mobile",
      cell: (props) => (
        <div className="flex flex-row w-fit space-x-3">
          <img
            src={props.row.original.image_mobile}
            alt="Image Mobile"
            width={100}
            height={100}
            onClick={() => openImageViewerMobile(props.row.index)}
            style={{ cursor: "pointer" }}
          />
        </div>
      ),
    }),
    slideColumnHelper.accessor("created_at", {
      header: "Dibuat Pada",
      cell: (props) => props.getValue(),
    }),
    slideColumnHelper.display({
      id: "actions",
      header: "Aksi",
      cell: (props) => (
        <div className="flex flex-row w-fit space-x-3">
          <Tooltip content="Delete">
            <ButtonWithIcon
              class="mx-auto border-2 border-transparent bg-red-600 text-white hover:bg-red-500 hover:border-red-500"
              type="button"
              action={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault()
                handleModalSlide(
                  props.row.original.id.toString(),
                )
              }}
              sizeClass="p-1"
            >
              <TrashIcon className="h-5 w-5" />
            </ButtonWithIcon>
          </Tooltip>
        </div>

      ),
    }),
  ]

  return (
    <EmployeeDashboardWrapper>
      <ReactTable
        columns={slideColumns}
        data={dataSlide ?? []}
        pagination={pagination}
        onPaginationChange={setPagination}
        pageCount={lastPage}
      />

      {isViewerOpenDesktop && (
        <ReactSimpleImageViewer
          src={dataSlide?.map((slide) => slide.image_desktop) || []}
          currentIndex={currentImageDesktop}
          onClose={closeImageViewerDesktop}
        />
      )}

      {isViewerOpenMobile && (
        <ReactSimpleImageViewer
          src={dataSlide?.map((slide) => slide.image_mobile) || []}
          currentIndex={currentImageMobile}
          onClose={closeImageViewerMobile}
        />
      )}

      <Modal
        header="Delete Slide"
        onclose={handleModalSlide}
        show={!!modalSlide?.isOpen}
        body={
          <Typography className="text-white">
            Apakah Anda yakin untuk menghapus slide ini
          </Typography>
        }
        footer={
          <div className="space-x-2 ml-auto">
            <Button
              onClick={() => handleModalSlide()}
              variant="outlined"
              className="text-gray-300 border-gray-300"
            >
              Batal
            </Button>
            <Button
              isLoading={isLoading}
              onClick={() =>
                mutateDeleteSlide({
                  slideID: modalSlide?.slideID ?? "",
                })
              }
            >
              Hapus
            </Button>
          </div>
        }
      />
    </EmployeeDashboardWrapper>
  )
}

export default SlideList
