import { NavLink, useLocation } from "react-router-dom"
import clsx from "clsx"
import {
  UserCircleIcon,
  Bars3Icon,
  AcademicCapIcon,
  ComputerDesktopIcon,
  BriefcaseIcon,
} from "@heroicons/react/24/solid"
import { Dropdown } from "flowbite-react"
import Cookies from "js-cookie"
import { useMediaQuery } from "usehooks-ts"

import { ReactComponent as BpiLogo } from "assets/svg/BeePlusInstitute_PrimaryLogo.svg"
import Button from "components/ui/button/Button"
import clsxm from "utils/clsxm"
import { useUserInfoStore } from "utils/store/user-info"
import Typography from "components/ui/typography"
import Drawer from "components/ui/drawer"
import { useMutationLogout } from "utils/api/user/mutations/hooks"
import {
  AboutUs,
  AboutUsNonActive,
  Sertifikasi,
  SertifikasiNonActive,
  Career,
  CareerNonActive,
  Kelas,
  KelasNonActive,
  Workshop,
  WorkshopNonActive
} from "assets/images/icon-nav"
import IconImageNav from "assets/images/icon-nav/IconImage"

interface Navigation {
  label: string
  to: string
}

const NAVIGATIONS: Navigation[] = [
  {
    label: "Kelas",
    to: "courses",
  },
  {
    label: "Workshop",
    to: "workshops",
  },
  {
    label: "Tentang Kami",
    to: "about",
  },
  {
    label: "Karir",
    to: "careers",
  },
  {
    label: "Sertifikasi",
    to: "sertifikasi",
  },
]

const GeneralPublicNavigation = () => {
  const mdSize = useMediaQuery("(min-width: 768px)")

  const { isLoggedIn, userInfo, resetUserInfo } = useUserInfoStore()

  const { mutate: mutateLogout } = useMutationLogout({
    onSuccess() {
      resetUserInfo()
      Cookies.remove("user_auth_token")
    },
  })

  const handleLogout = () => mutateLogout({})
  const location = useLocation();

  return (
    <header
      className={clsx("sticky z-30 top-0", "h-[70px]", "bg-white shadow-sm")}
    >
      <nav className={clsx("flex", "h-full w-full")}>
        <ul
          className={clsx(
            "flex justify-end items-center gap-6",
            "w-full",
            "my-auto mx-6 md:mx-16 lg:mx-24"
          )}
        >
          {/* Desktop */}
          {mdSize && (
            <>
              <li className="mr-auto">
                <NavLink
                  to="/"
                  aria-label="home"
                >
                  <BpiLogo className="h-[70px]" />
                </NavLink>
              </li>
              {NAVIGATIONS.map((navigation, index) => (
                <li key={`${navigation.label}-${index}`}>
                  <NavLink
                    to={navigation.to}
                    className={({ isActive }) =>
                      clsxm(
                        "flex items-center",
                        "font-medium 2xl:text-[16px]",
                        "leading-snug text-tertiary",
                        [isActive && "text-primary"]
                      )
                    }
                  >
                    {navigation.label}
                  </NavLink>
                </li>
              ))}
              {isLoggedIn ? (
                <>
                  <NavLink
                    to="/my-course"
                    className={({ isActive }) =>
                      clsxm(
                        "flex items-center",
                        "font-medium 2xl:text-[16px]",
                        "leading-snug text-tertiary",
                        [isActive && "text-primary"]
                      )
                    }
                  >
                    Kelas Saya
                  </NavLink>
                  <Dropdown
                    arrowIcon={false}
                    inline
                    label={
                      <div className="inline-flex items-center gap-1.5">
                        <UserCircleIcon
                          width={35}
                          height={35}
                          className="text-primary"
                        />
                        <Typography
                          variant="text3"
                          className="text-typo-gray font-medium capitalize"
                        >
                          Hi, {userInfo.first_name}
                        </Typography>
                      </div>
                    }
                  >
                    <Dropdown.Header>
                      <span className="block w-[150px] truncate capitalize font-semibold text-left">
                        {userInfo.first_name} {userInfo.middle_name}{" "}
                        {userInfo.last_name}
                      </span>
                      <span className="block w-[150px] truncate text-xs text-typo-gray">
                        {userInfo.email}
                      </span>
                    </Dropdown.Header>
                    <Dropdown.Item
                      as={NavLink}
                      to="/my-course"
                    >
                      Profil
                    </Dropdown.Item>
                    <Dropdown.Item onClick={handleLogout}>Logout</Dropdown.Item>
                  </Dropdown>
                </>
              ) : (
                <li className="hidden md:block">
                  <Button
                    className="mr-2"
                    as={NavLink}
                    to="/register"
                  >
                    Daftar
                  </Button>
                  <Button
                    as={NavLink}
                    variant="outlined"
                    to="/login"
                  >
                    Login
                  </Button>
                </li>
              )}
            </>
          )}

          {/* Tab/Mobile */}
          {!mdSize && (
            <>
              <li className="mr-auto">
                <Drawer
                  title="Menu"
                  height="50%"
                  renderButton={({ handleDrawer }) => (
                    <button
                      className="bg-red-50 rounded-lg shadow"
                      onClick={() => handleDrawer(true)}
                    >
                      <Bars3Icon
                        width={32}
                        height={32}
                        className="text-primary"
                      />
                    </button>
                  )}
                >
                  {({ handleDrawer }) => (
                    <div className="drawer-content w-full mt-8">
                      <ul className="font-medium text-base grid grid-cols-3 items-center gap-4">
                        <li className="flex justify-center">
                          <NavLink
                            className={({ isActive }) =>
                              clsxm(
                                "grid items-center justify-items-center text-sm w-24 gap-2 border p-2 py-3 rounded-lg",
                                [isActive && "text-primary"]
                              )
                            }
                            onClick={() => handleDrawer(false)}
                            to="/courses"
                          >
                            <IconImageNav
                              src={location.pathname.startsWith('/courses') ? Kelas : KelasNonActive}
                              className="w-[44px] h-[42px]"
                            />
                            Kelas
                          </NavLink>
                        </li>
                        <li className="flex justify-center">
                          <NavLink
                            className={({ isActive }) =>
                              clsxm(
                                "grid items-center justify-items-center text-sm w-24 gap-2 border p-2 py-3 rounded-lg",
                                [isActive && "text-primary"]
                              )
                            }
                            onClick={() => handleDrawer(false)}
                            to="/workshops"
                          >
                            <IconImageNav
                              src={location.pathname.startsWith('/workshops') ? Workshop : WorkshopNonActive}
                              className="w-[44px] h-[42px]"
                            />
                            Workshop
                          </NavLink>
                        </li>
                        <li className="flex justify-center">
                          <NavLink
                            className={({ isActive }) =>
                              clsxm(
                                "grid items-center justify-items-center text-sm w-24 gap-2 border p-2 py-3 rounded-lg",
                                [isActive && "text-primary"]
                              )
                            }
                            onClick={() => handleDrawer(false)}
                            to="/careers"
                          >
                            <IconImageNav
                              src={location.pathname.startsWith('/careers') ? Career : CareerNonActive}
                              className="w-[44px] h-[42px]"
                            />
                            Karir
                          </NavLink>
                        </li>
                        <li className="flex justify-center">
                          <NavLink
                            className={({ isActive }) =>
                              clsxm(
                                "grid items-center justify-items-center text-sm w-24 gap-2 border p-2 py-3 rounded-lg",
                                [isActive && "text-primary"]
                              )
                            }
                            onClick={() => handleDrawer(false)}
                            to="/sertifikasi"
                          >
                            <IconImageNav
                              src={location.pathname.startsWith('/sertifikasi') ? Sertifikasi : SertifikasiNonActive}
                              className="w-[44px] h-[42px]"
                            />
                            Sertifikasi
                          </NavLink>
                        </li>
                        <li className="flex justify-center">
                          <NavLink
                            className={({ isActive }) =>
                              clsxm(
                                "grid items-center justify-items-center text-sm w-24 gap-2 border p-2 py-3 rounded-lg",
                                [isActive && "text-primary"]
                              )
                            }
                            onClick={() => handleDrawer(false)}
                            to="/about"
                          >
                            <IconImageNav
                              src={location.pathname.startsWith('/about') ? AboutUs : AboutUsNonActive}
                              className="w-[44px] h-[42px]"
                            />
                            About Us
                          </NavLink>
                        </li>
                      </ul>
                    </div>
                  )}
                </Drawer>
              </li>
              <li className="mr-auto">
                <NavLink
                  to="/"
                  aria-label="home"
                >
                  <BpiLogo className="h-[70px]" />
                </NavLink>
              </li>
              {isLoggedIn ? (
                <Drawer
                  height="70%"
                  renderButton={({ handleDrawer }) => (
                    <button onClick={() => handleDrawer(true)}>
                      <UserCircleIcon
                        width={35}
                        height={35}
                        className="text-primary"
                      />
                    </button>
                  )}
                >
                  {({ handleDrawer }) => (
                    <div className="w-full">
                      <div className="flex items-center gap-2 mb-6">
                        <UserCircleIcon
                          className="text-primary"
                          width={76}
                          height={76}
                        />
                        <div>
                          <Typography className="font-medium capitalize">
                            {userInfo.first_name} {userInfo.middle_name}{" "}
                            {userInfo.last_name}
                          </Typography>
                          <Typography
                            variant="text3"
                            className="text-typo-gray"
                          >
                            {userInfo.email}
                          </Typography>
                        </div>
                      </div>
                      <ul className="font-medium text-base space-y-5">
                        <li>
                          <NavLink
                            onClick={() => handleDrawer(false)}
                            className={({ isActive }) =>
                              clsx([isActive && "text-primary"])
                            }
                            to="/my-course"
                          >
                            Kelas Saya
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            onClick={() => handleDrawer(false)}
                            className={({ isActive }) =>
                              clsx([isActive && "text-primary"])
                            }
                            to="/my-workshop"
                          >
                            Workshop Saya
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            onClick={() => handleDrawer(false)}
                            className={({ isActive }) =>
                              clsx("flex items-center gap-3", [
                                isActive && "text-primary",
                              ])
                            }
                            to="/transaction-history"
                          >
                            Riwayat Transaksi
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            onClick={() => handleDrawer(false)}
                            className={({ isActive }) =>
                              clsx([isActive && "text-primary"])
                            }
                            to="/settings"
                          >
                            Pengaturan
                          </NavLink>
                        </li>
                        <li onClick={handleLogout}>Logout</li>
                      </ul>
                    </div>
                  )}
                </Drawer>
              ) : (
                <Button
                  as={NavLink}
                  variant="outlined"
                  to="/login"
                >
                  Login
                </Button>
              )}
            </>
          )}
        </ul>
      </nav>
    </header>
  )
}

export default GeneralPublicNavigation
