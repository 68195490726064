import to from "await-to-js"
import moment from "moment"
import React, { useEffect, useState } from "react"
import { SubmitHandler } from "react-hook-form"
import { LoaderFunction, useLoaderData, useNavigate } from "react-router-dom"
import WebinarManagementForm, {
  CreateWebinarRequest,
  WebinarForm,
} from "../../../components/cms/WebinarManagementForm"
import EmployeeDashboardWrapper from "../../../components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "../../../components/ui/breadcrumb/DefBreadcrumb"
import api from "../../../utils/axiosInstance"
import { getCMSSegments } from "../../../utils/helper"
import {
  CMSCreateWebinarResponse,
  CMSGetWebinarResponse,
  CMSWebinarCategoryListResponse,
  GeneralAPIValidationResponse,
  ProductCategory,
  Webinar,
} from "../../../utils/types"

const WebinarManagementEdit: React.FC = () => {
  const loaderResponse = useLoaderData() as {
    webinar: CMSGetWebinarResponse<Webinar>
    categoryOptions: CMSWebinarCategoryListResponse<ProductCategory>
  }
  const segments = getCMSSegments()
  const [serverValidationError, setServerValidation] = useState<{
    title: string | undefined
    description: string | undefined
    price: string | undefined
    quota: string | undefined
    extras: string | undefined
    start_date: string | undefined
    end_date: string | undefined
    category_ids: string | undefined
  }>({
    title: undefined,
    description: undefined,
    price: undefined,
    quota: undefined,
    extras: undefined,
    start_date: undefined,
    end_date: undefined,
    category_ids: undefined,
  })
  const [webinarSubmitting, setWebinarSubmit] = React.useState(false)
  const [categoryOptions, setCategoryOptions] = useState<
    {
      label: string
      value: number
    }[]
  >([])
  const navigate = useNavigate()
  const [webinarToEdit, setWebinarToEdit] = useState<WebinarForm | undefined>(
    undefined
  )
  const [webinar, setWebinar] = useState<Webinar | undefined>(undefined)

  useEffect(() => {
    if (!loaderResponse) return

    const options: {
      label: string
      value: number
    }[] = []
    loaderResponse.categoryOptions.data.forEach((category) => {
      options.push({
        label: category.name,
        value: category.id,
      })
    })

    setCategoryOptions(options)
    setWebinar(loaderResponse.webinar.data)
  }, [loaderResponse])

  useEffect(() => {
    if (!webinar) return
    const extra: {
      speakers: string[]
      location: string
      online: boolean
      offline: boolean
    } = JSON.parse(webinar.extras)

    const webinarForm: WebinarForm = {
      ...webinar,
      price: webinar.price.master_price,
      action_date: {
        start_date: new Date(webinar.start_date),
        end_date: new Date(webinar.end_date),
      },
      location: extra.location as string,
      speakers: extra.speakers.map((speaker) => ({
        value: speaker,
      })) as { value: string }[],
      execution_type: {
        online: extra.online as boolean,
        offline: extra.offline as boolean,
      },
      category_ids: webinar.categories?.map(
        (category) => category.id
      ) as number[],
    }

    setWebinarToEdit(webinarForm)
  }, [webinar])

  const webinarSubmitHandler: SubmitHandler<WebinarForm> = async (data) => {
    setWebinarSubmit(true)

    const extra: {
      location: string
      online: boolean
      offline: boolean
      speakers: string[]
    } = {
      location: data.location,
      offline: data.execution_type.offline,
      online: data.execution_type.online,
      speakers: data.speakers.map((speaker) => speaker.value),
    }

    const request: CreateWebinarRequest = {
      ...data,
      start_date: moment(data.action_date.start_date).format(
        "YYYY-MM-DD HH:mm"
      ),
      end_date: moment(data.action_date.end_date).format("YYYY-MM-DD HH:mm"),
      extras: JSON.stringify(extra),
    }

    const [err, _] = await to<
      CMSCreateWebinarResponse<Webinar>,
      GeneralAPIValidationResponse
    >(api.cms.updateWebinar(webinar!.id, request))

    if (err) {
      const validationResponse = { ...serverValidationError }

      Object.keys(validationResponse).forEach((field) => {
        const errMessages: string[] | undefined = err!.errors![field]

        if (errMessages === undefined) return

        const message = (errMessages as string[]).join(". ")

        validationResponse[field] = message
      })

      setServerValidation(validationResponse)

      setWebinarSubmit(false)

      return
    }

    navigate("/cms/webinar", {
      state: {
        webinarUpsertSuccess: true,
      },
    })
  }

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix="cms"
      />
      <h2 className="text-xl text-primary font-semibold leading-loose tracking-wide">
        Edit Konten Webinar
      </h2>
      {categoryOptions && webinarToEdit && (
        <WebinarManagementForm
          method="PUT"
          onSubmitHandler={webinarSubmitHandler}
          categoryOptions={categoryOptions}
          submitting={webinarSubmitting}
          serverValidationResponse={serverValidationError}
          initialValue={webinarToEdit}
        />
      )}
    </EmployeeDashboardWrapper>
  )
}

export default WebinarManagementEdit

export const loadWebinarEdit: LoaderFunction = async ({ params }) => {
  const webinarID = params.id

  if (webinarID === undefined || isNaN(+webinarID!))
    throw new Response("Not Found", { status: 404 })

  const loadCategoryOptions = async (): Promise<
    CMSWebinarCategoryListResponse<ProductCategory>
  > => {
    const response = await api.cms.getWebinarCategories()

    return response
  }

  const loadWebinar = async (): Promise<CMSGetWebinarResponse<Webinar>> => {
    const response = await api.cms.getWebinar(+webinarID)

    return response
  }

  return {
    categoryOptions: await loadCategoryOptions(),
    webinar: await loadWebinar(),
  }
}
