import { requestUser } from "utils/axiosInstance"
import { PublicGetCourseDetailResponse } from "../../types"

const getCourseDetail = async (slug: string) => {
  const res = await requestUser.get<PublicGetCourseDetailResponse>(
    `/courses/${slug}`
  )
  return res
}

export default getCourseDetail
