import { useEffect } from "react"
import { Outlet } from "react-router-dom"
import jwt_decode, { JwtPayload } from "jwt-decode"
import moment from "moment"

import EmployeeNavigation from "../navigations/EmployeeNavigation"
import { useEmployeeInfoStore } from "utils/store/employee-info"
import { getCmsToken } from "utils/auth"
import { useMutationLogout } from "utils/api/employee/mutations/hooks"

const EmployeeDashboardLayout: React.FC = () => {
  const resetEmployeeInfo = useEmployeeInfoStore(
    (state) => state.resetEmployeeInfo
  )

  useEffect(() => {
    document.documentElement.classList.add("dark")
    return () => document.documentElement.classList.remove("dark")
  }, [])

  const { mutate: mutateLogout } = useMutationLogout({
    onSuccess() {
      resetEmployeeInfo()
    },
  })

  useEffect(() => {
    const token = getCmsToken()

    if (!token) return resetEmployeeInfo()

    const decodedToken = jwt_decode<JwtPayload>(token)
    const tokenExpiration = new Date((decodedToken.exp ?? 0) * 1000)
    const currentDate = new Date()

    const diff = moment(tokenExpiration).diff(currentDate, "milliseconds")

    setTimeout(() => {
      mutateLogout({})
    }, diff)
  }, [])

  return (
    <>
      <EmployeeNavigation />
      <main>
        <Outlet />
      </main>
    </>
  )
}

export default EmployeeDashboardLayout
