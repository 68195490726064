import { requestCMS } from "utils/axiosInstance"
import type { CourseVideoSectionResponse } from "../../types"

const getVideos = async (sectionID?: string) => {
  const res = await requestCMS.get<CourseVideoSectionResponse>(
    `/employee/course/section/videos/${sectionID}`
  )
  return res
}

export default getVideos
