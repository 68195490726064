import { forwardRef } from "react"
import clsxm from "utils/clsxm"
import Typography from "../typography"

interface TextInputProps extends React.ComponentPropsWithoutRef<"input"> {
  label?: string
  name: string
  className?: string
  labelClass?: string
  value?: string
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isRequired?: boolean
  type?: string
  errorMessage?: string
  errorIcon?: React.ReactNode
  /** @deprecated uses errorMessage instead */
  validationMessage?: React.ReactNode
  /** @deprecated */
  hooks?: any
  /** @deprecated uses className instead */
  inputClass?: string
}

const TextInput = forwardRef(
  (
    {
      label,
      className,
      labelClass,
      type = "text",
      errorMessage,
      isRequired,
      value,
      onChange,
      name,
      errorIcon,
      hooks,
      validationMessage,
      inputClass,
      ...restProps
    }: TextInputProps,
    ref: React.ComponentPropsWithRef<"input">["ref"]
  ) => {
    return (
      <div>
        {label && (
          <label
            htmlFor={`${name}-id`}
            className={clsxm(
              "block mb-1 font-semibold",
              [
                isRequired &&
                  "after:content-['*'] after:ml-1 after:text-red-500",
              ],
              labelClass
            )}
          >
            {label}
          </label>
        )}
        <input
          ref={ref}
          id={`${name}-id`}
          name={name}
          type={type}
          onChange={onChange}
          className={clsxm(
            "w-full",
            "block p-2.5",
            "bg-gray-100",
            "border border-transparent",
            "rounded-md",
            "focus:ring-primary focus:border-primary",
            [!!errorMessage && "border-2 border-red-600"],
            [validationMessage && "mb-2"],
            inputClass,
            className
          )}
          defaultValue={value}
          {...hooks}
          {...restProps}
        />
        {!!errorMessage && (
          <div
            className={clsxm("inline-flex gap-2 items-center", [
              errorIcon && "mt-2",
            ])}
          >
            {errorIcon}
            <Typography
              variant="text3"
              className="text-red-600 first-letter:capitalize mt-1"
            >
              {errorMessage}
            </Typography>
          </div>
        )}
        {validationMessage}
      </div>
    )
  }
)

export default TextInput
