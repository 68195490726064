import { DocumentPlusIcon, PencilSquareIcon } from "@heroicons/react/24/solid"
import { createColumnHelper } from "@tanstack/react-table"
import React from "react"
import {
  LoaderFunction,
  useLoaderData,
  useLocation,
  useNavigate,
} from "react-router-dom"
import Swal from "sweetalert2"
import EmployeeDashboardWrapper from "../../../components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "../../../components/ui/breadcrumb/DefBreadcrumb"
import ButtonWithIcon from "../../../components/ui/button/ButtonWithIcon"
import api from "../../../utils/axiosInstance"
import { getCMSSegments } from "../../../utils/helper"
import {
  CMSWebinarCategoryListResponse,
  ProductCategory,
} from "../../../utils/types"
import moment from "moment"
import "moment/locale/id"
import ReactTable from "../../../components/ui/react-table/ReactTable"
// import Modal from "../../../components/ui/modal/Modal"

const WebinarCategoryManagement: React.FC = () => {
  const segments = getCMSSegments()
  const navigate = useNavigate()
  const location = useLocation()
  const [webinarCategoryCreated, _] = React.useState(
    location.state?.webinarCategoryUpsertSuccess
  )
  const response =
    useLoaderData() as CMSWebinarCategoryListResponse<ProductCategory>
  // const [categoryViewDetail, setCategoryViewDetail] = React.useState(false)

  React.useEffect(() => {
    if (webinarCategoryCreated === null || !webinarCategoryCreated) return

    Swal.mixin({
      toast: true,
      position: "top-right",
      iconColor: "white",
      customClass: {
        popup: "colored-toast",
      },
      showConfirmButton: false,
      timer: 1500,
      timerProgressBar: true,
    }).fire({
      icon: "success",
      title: "Berhasil menyimpan kategori",
    })

    navigate(".", { replace: true })
  }, [webinarCategoryCreated, navigate])

  const colHelper = createColumnHelper<ProductCategory>()
  const categoryCols = [
    colHelper.accessor("name", {
      header: () => "Nama Kategori Webinar",
    }),
    colHelper.accessor("description", {
      header: () => "Deskripsi Kategori Webinar",
    }),
    colHelper.accessor("created_at", {
      header: () => "Tanggal Dibuat",
      cell: (props) =>
        moment(props.getValue()).locale("id").format("dddd, D MMMM YYYY H:mm"),
    }),
    colHelper.accessor("updated_at", {
      header: () => "Tanggal Terakhir Diupdate",
      cell: (props) =>
        moment(props.getValue()).locale("id").format("dddd, D MMMM YYYY H:mm"),
    }),
    colHelper.display({
      id: "actions",
      header: () => "AKSI",
      cell: (props) => (
        <div className="flex flex-row w-fit space-x-3">
          <ButtonWithIcon
            class="mx-auto border-2 border-transparent bg-yellow-400 text-white hover:bg-yellow-300 hover:border-yellow-300"
            type="button"
            action={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault()
              return navigate(`edit/${props.row.original.id}`)
            }}
            sizeClass="p-1"
          >
            <PencilSquareIcon className="h-5 w-5" />
          </ButtonWithIcon>
          {/* <ButtonWithIcon
                        class="mx-auto border-2 border-transparent bg-blue-600 text-white hover:bg-blue-500 hover:border-blue-500"
                        type="button"
                        action={(e: React.MouseEvent<HTMLButtonElement>) => {
                            e.preventDefault()
                            setCategoryViewDetail(true)
                        }}
                        sizeClass="p-1"
                    >
                        <EyeIcon className="h-5 w-5" />
                    </ButtonWithIcon> */}
        </div>
      ),
    }),
  ]

  return (
    <EmployeeDashboardWrapper>
      {/* <Modal
                body={<>Test</>}
                header={"header"}
                show={categoryViewDetail}
                onclose={() => setCategoryViewDetail(false)}
            /> */}
      <DefBreadcrumb
        prefix="cms"
        segments={segments}
      />
      <h2 className="text-xl text-primary font-semibold leading-loose tracking-wide my-2">
        Pengaturan Konten Kategori Webinar
      </h2>
      <div className="flex flex-wrap justify-between gap-4 mb-4">
        <ButtonWithIcon
          action={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            return navigate("create")
          }}
          type="submit"
          class="lg:ml-auto border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
        >
          <DocumentPlusIcon className="h-6 w-6 text-white" />
          <span>Tambah Kategori Webinar</span>
        </ButtonWithIcon>
      </div>
      <ReactTable
        columns={categoryCols}
        data={response.data}
      />
    </EmployeeDashboardWrapper>
  )
}

export default WebinarCategoryManagement

export const loadWebinarCategories: LoaderFunction = async (): Promise<
  CMSWebinarCategoryListResponse<ProductCategory>
> => {
  const response = await api.cms.getWebinarCategories()

  return response
}
