import { Link, Navigate, useNavigate } from "react-router-dom"
import { type SubmitHandler, useForm } from "react-hook-form"
import clsx from "clsx"
import { toast } from "react-toastify"
import { Helmet } from "react-helmet"
import TextInput from "components/ui/form/TextInput"
import Section from "components/ui/section/Section"
import Typography from "components/ui/typography"
import Button from "components/ui/button/Button"
import { useMutationRegisterUser } from "utils/api/user/mutations/hooks"
import type {
  RegisterFormPayload,
  RegisterNamePayload,
  RegisterRequest,
} from "utils/api/user/types"
import { useUserInfoStore } from "utils/store/user-info"

const Register = () => {
  const navigate = useNavigate()
  const {
    formState: { errors },
    watch,
    register,
    handleSubmit,
  } = useForm<RegisterFormPayload>()

  const isLoggedIn = useUserInfoStore((state) => state.isLoggedIn)

  const { mutate, isLoading } = useMutationRegisterUser({
    onSuccess(data) {
      if (!data.success) {
        toast.error("Pedaftaran tidak berhasil")
        return
      }
      localStorage.setItem("email_registration", data.data.email)
      toast.success(
        "Pendaftaran berhasil. Untuk melanjutkan, silakan masuk menggunakan akun yang sudah Anda daftarkan"
      )
      setTimeout(() => {
        navigate("/resend-verify-email")
      }, 1000)
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  const splitName = (fullName: string): RegisterNamePayload => {
    const nameParts = fullName.split(" ")

    if (nameParts.length === 1) {
      return {
        first_name: fullName,
        last_name: fullName,
      }
    }
    if (nameParts.length === 2) {
      const [first_name, last_name] = nameParts
      return {
        first_name,
        last_name,
      }
    }
    if (nameParts.length === 3) {
      const [first_name, middle_name, last_name] = nameParts
      return {
        first_name,
        middle_name,
        last_name,
      }
    }
    if (nameParts.length > 3) {
      const [first_name, middle_name, ...last_nameParts] = nameParts
      const last_name = last_nameParts.join(" ")
      return {
        first_name,
        middle_name,
        last_name,
      }
    }
    return {
      first_name: "",
      middle_name: "",
      last_name: "",
    }
  }

  const onSubmit: SubmitHandler<RegisterFormPayload> = (data) => {
    const nameParts = splitName(data.full_name)
    const mutationData: RegisterRequest = {
      email: data.email,
      first_name: nameParts?.first_name,
      middle_name: nameParts?.middle_name ?? "",
      last_name: nameParts?.last_name,
      phone: data.phone,
      password: data.password,
      password_confirmation: data.password_confirmation,
    }
    mutate(mutationData)
  }

  if (isLoggedIn) {
    return <Navigate to="/" />
  }

  return (
    <>
      <Helmet>
        <title>Daftar</title>
      </Helmet>
      <Section space="top-bottom">
        <div
          className={clsx(
            "flex flex-col items-center justify-center",
            "mx-8 md:mx-auto",
            "max-w-5xl",
            "rounded",
            "space-y-4",
            "md:py-16 md:px-[200px] md:shadow"
          )}
        >
          <Typography
            as="h1"
            variant="heading3"
            className="text-secondary mb-10"
          >
            Re<span className="font-bold text-primary ">gister</span>
          </Typography>
          <form
            className="w-full space-y-5"
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextInput
              label="Email"
              type="email"
              isRequired
              errorMessage={errors.email?.message}
              {...register("email", {
                required: {
                  value: true,
                  message: "Email wajib diisi",
                },
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message:
                    'Pastikan email Anda memiliki format "nama@example.com"',
                },
              })}
            />
            <TextInput
              label="Nama Lengkap"
              isRequired
              errorMessage={errors.full_name?.message}
              {...register("full_name", {
                required: {
                  value: true,
                  message: "Nama depan wajib diisi",
                },
              })}
            />
            <TextInput
              label="Nomor Telepon / WA"
              isRequired
              maxLength={15}
              placeholder="081xxxxxxx"
              errorMessage={errors.phone?.message}
              {...register("phone", {
                required: {
                  value: true,
                  message: "Nomor telepon wajib diisi",
                },
                pattern: {
                  value: /^\d+$/,
                  message: "Pastikan nomor telepon hanya berisi angka",
                },
              })}
            />

            <TextInput
              type="password"
              label="Password"
              isRequired
              errorMessage={errors.password?.message}
              {...register("password", {
                required: {
                  value: true,
                  message: "Password wajib diisi",
                },
                pattern: {
                  value: /^(?=.*[A-Za-z])(?=.*\d).{8,}$/,
                  message: `Pastikan password yand Anda masukkan terdiri dari minimal 8 karakter dan setidaknya mengandung 1 huruf dan 1 angka`,
                },
              })}
            />
            <TextInput
              type="password"
              label="Konfirmasi Password"
              isRequired
              errorMessage={errors.password_confirmation?.message}
              {...register("password_confirmation", {
                required: {
                  value: true,
                  message: "Konfirmasi password wajib diisi",
                },
                validate: (value) => {
                  if (watch("password") !== value) {
                    return "Konfirmasi password tidak sesuai. Pastikan kedua password yang Anda masukkan sama"
                  }
                  return
                },
              })}
            />
            <Button
              className="!mt-8"
              type="submit"
              size="lg"
              fullWidth
              isLoading={isLoading}
            >
              Daftar
            </Button>
            <hr className="h-1 mx-auto my-4 bg-gray-200 border-0 rounded" />
            <div className="flex justify-center gap-1">
              <Typography
                variant="text3"
                className="text-gray-500 tracking-wider"
              >
                Sudah punya akun?
              </Typography>
              <Link
                to="/login"
                className="text-sm font-semibold tracking-wider text-secondary hover:underline"
              >
                Login
              </Link>
            </div>
          </form>
        </div>
      </Section>
    </>
  )
}

export default Register
