import { requestCMS } from "utils/axiosInstance"
import type { GeneralApiResponse, QueryParams, Webinar } from "utils/types"

const getWebinars = async (qParams?: QueryParams) => {
  const res = await requestCMS.get<GeneralApiResponse<Webinar[]>>(
    "/employee/webinar",
    qParams
  )
  return res
}

export default getWebinars
