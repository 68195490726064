import { useMutation } from "@tanstack/react-query"

import { activateEmail } from "../functions"
import { GeneralMutationParams } from "utils/types"
import { PublicActivationEmailResponse, ActivationRequest } from "../../types"

const useMutationActivationEmail = (
  options?: GeneralMutationParams<
    PublicActivationEmailResponse,
    ActivationRequest
  >
) => {
  return useMutation({
    mutationFn: (payload) => activateEmail(payload),
    ...options,
  })
}

export default useMutationActivationEmail
