import { useEffect } from "react"
import {
  BookmarkIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"
import { SubmitHandler, useForm } from "react-hook-form"

import ButtonWithIcon from "../ui/button/ButtonWithIcon"
import TextInput from "../ui/form/TextInput"

export type CourseSectionForm = {
  title: string
  sequence: number
}

const CourseSectionManagementForm: React.FC<{
  method: "POST" | "PUT"
  onSubmitHandler: SubmitHandler<CourseSectionForm>
  maxSeq: number
  serverValidationResponse?: {
    title: string | undefined
    sequence: string | undefined
  }
  submitting?: boolean
  initialValue?: CourseSectionForm
}> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<CourseSectionForm>({
    mode: "all",
    defaultValues: props.initialValue,
  })

  useEffect(() => {
    if (props.serverValidationResponse !== undefined) {
      Object.keys(props.serverValidationResponse as object).forEach((field) => {
        const messageVal: string | undefined =
          props.serverValidationResponse![field]

        if (messageVal === undefined) {
          return
        }

        setError(field as keyof CourseSectionForm, {
          type: "custom",
          message: messageVal,
        })
      })
    }
  }, [props.serverValidationResponse])

  return (
    <form
      method={props.method}
      onSubmit={handleSubmit(props.onSubmitHandler)}
    >
      <div className="grid gap-4 sm:grid-cols-2 sm:gap-6">
        <div className="sm:col-span-2">
          <TextInput
            className="cms-base-input min-w-[42rem]"
            labelClass="cms-base-label"
            isRequired
            label="Judul Bagian Belajar"
            errorMessage={errors.title?.message}
            errorIcon={
              <ExclamationTriangleIcon className="h-6 w-6 text-red-800" />
            }
            {...register("title", {
              required: "Judul modul belajar tidak boleh kosong!",
              minLength: {
                message: "Judul module belajar minimal 3 karakter!",
                value: 3,
              },
              maxLength: {
                message:
                  "Judul module belajar tidak boleh melebihi 500 karakter!",
                value: 500,
              },
            })}
          />
        </div>
        <div className="sm:col-span-2">
          <TextInput
            label="Urutan Bagian"
            className="cms-base-input min-w-[42rem]"
            labelClass="cms-base-label"
            type="number"
            isRequired
            min={1}
            max={props.maxSeq}
            value={String(props.maxSeq)}
            errorMessage={errors.sequence?.message}
            errorIcon={
              <ExclamationTriangleIcon className="h-6 w-6 text-red-800" />
            }
            {...register("sequence", {
              required: "Urutan bagian tidak boleh kosong!",
              min: {
                message: "Urutan tidak boleh lebih kecil dari 1!",
                value: 1,
              },
              max: {
                message: `Urutan bagian tidak boleh melebihi ${props.maxSeq}`,
                value: props.maxSeq,
              },
            })}
          />
        </div>
      </div>
      {!props.submitting && (
        <ButtonWithIcon
          action={undefined}
          type="submit"
          class="mt-4 border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
          disabled={props.submitting}
        >
          <BookmarkIcon className="h-6 w-6 text-white" />
          <span>
            {!props.submitting ? (
              <>{props.method === "POST" ? "Tambahkan" : "Update"}</>
            ) : (
              "Menyimpan..."
            )}
          </span>
        </ButtonWithIcon>
      )}
    </form>
  )
}

export default CourseSectionManagementForm
