import React, { useEffect, useState } from "react"
import { getCMSSegments } from "../../../utils/helper"
import EmployeeDashboardWrapper from "../../../components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "../../../components/ui/breadcrumb/DefBreadcrumb"
import WebinarManagementForm, {
  CreateWebinarRequest,
  WebinarForm,
} from "../../../components/cms/WebinarManagementForm"
import { SubmitHandler } from "react-hook-form"
import { useLoaderData, useNavigate } from "react-router-dom"
import {
  CMSCreateWebinarResponse,
  CMSWebinarCategoryListResponse,
  GeneralAPIValidationResponse,
  ProductCategory,
  Webinar,
} from "../../../utils/types"
import api from "../../../utils/axiosInstance"
import to from "await-to-js"
import moment from "moment"

const WebinarManagementCreate: React.FC = () => {
  const loaderResponse =
    useLoaderData() as CMSWebinarCategoryListResponse<ProductCategory>
  const segments = getCMSSegments()
  const [serverValidationError, setServerValidation] = useState<{
    title: string | undefined
    description: string | undefined
    price: string | undefined
    quota: string | undefined
    extras: string | undefined
    start_date: string | undefined
    end_date: string | undefined
    category_ids: string | undefined
  }>({
    title: undefined,
    description: undefined,
    price: undefined,
    quota: undefined,
    extras: undefined,
    start_date: undefined,
    end_date: undefined,
    category_ids: undefined,
  })
  const [webinarSubmitting, setWebinarSubmit] = React.useState(false)
  const [categoryOptions, setCategoryOptions] = useState<
    {
      label: string
      value: number
    }[]
  >([])
  const navigate = useNavigate()

  useEffect(() => {
    if (!loaderResponse) return

    const options: {
      label: string
      value: number
    }[] = []
    loaderResponse.data.forEach((category) => {
      options.push({
        label: category.name,
        value: category.id,
      })
    })

    setCategoryOptions(options)
  }, [loaderResponse])

  const webinarSubmitHandler: SubmitHandler<WebinarForm> = async (data) => {
    setWebinarSubmit(true)

    const extra: {
      location: string
      online: boolean
      offline: boolean
      speakers: string[]
    } = {
      location: data.location,
      offline: data.execution_type.offline,
      online: data.execution_type.online,
      speakers: data.speakers.map((speaker) => speaker.value),
    }

    const request: CreateWebinarRequest = {
      ...data,
      start_date: moment(data.action_date.start_date).format(
        "YYYY-MM-DD HH:mm"
      ),
      end_date: moment(data.action_date.end_date).format("YYYY-MM-DD HH:mm"),
      extras: JSON.stringify(extra),
    }

    const [err, _] = await to<
      CMSCreateWebinarResponse<Webinar>,
      GeneralAPIValidationResponse
    >(api.cms.createWebinar(request))

    if (err) {
      const validationResponse = { ...serverValidationError }

      Object.keys(validationResponse).forEach((field) => {
        const errMessages: string[] | undefined = err!.errors![field]

        if (errMessages === undefined) return

        const message = (errMessages as string[]).join(". ")

        validationResponse[field] = message
      })

      setServerValidation(validationResponse)

      setWebinarSubmit(false)

      return
    }

    navigate("/cms/webinar", {
      state: {
        webinarUpsertSuccess: true,
      },
    })
  }

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix="cms"
      />
      <h2 className="text-xl text-primary font-semibold leading-loose tracking-wide">
        Tambah Konten Webinar
      </h2>
      <WebinarManagementForm
        method="POST"
        onSubmitHandler={webinarSubmitHandler}
        categoryOptions={categoryOptions}
        submitting={webinarSubmitting}
        serverValidationResponse={serverValidationError}
      />
    </EmployeeDashboardWrapper>
  )
}

export default WebinarManagementCreate
