import to from "await-to-js"
import React from "react"
import { SubmitHandler } from "react-hook-form"
import { LoaderFunction, useLoaderData, useNavigate } from "react-router-dom"
import WebinarCategoryManagementForm, {
  WebinarCategoryForm,
} from "../../../components/cms/WebinarCategoryManagementForm"
import EmployeeDashboardWrapper from "../../../components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "../../../components/ui/breadcrumb/DefBreadcrumb"
import api from "../../../utils/axiosInstance"
import { getCMSSegments } from "../../../utils/helper"
import {
  CMSCreateWebinarCategoryResponse,
  CMSGetWebinarCategoryResponse,
  GeneralAPIValidationResponse,
  ProductCategory,
} from "../../../utils/types"

const WebinarCategoryManagementEdit: React.FC = () => {
  const segments = getCMSSegments()
  const navigate = useNavigate()
  const response =
    useLoaderData() as CMSGetWebinarCategoryResponse<ProductCategory>
  const [serverValidationError, setServerValidation] = React.useState<{
    name: string | undefined
    description: string | undefined
  }>({
    name: undefined,
    description: undefined,
  })
  const [webinarCategorySubmitting, setWebinarCategorySubmit] =
    React.useState(false)

  const webinarCategoryUpdateSubmitHandler: SubmitHandler<
    WebinarCategoryForm
  > = async (data) => {
    setWebinarCategorySubmit(true)

    const [err, _] = await to<
      CMSCreateWebinarCategoryResponse<ProductCategory>,
      GeneralAPIValidationResponse
    >(api.cms.updateWebinarCategory(response.data.id, data))

    if (err) {
      const validationResponse: {
        name: string | undefined
        description: string | undefined
      } = { ...serverValidationError }

      Object.keys(validationResponse).forEach((field) => {
        const errMessages: string[] | undefined = err.errors![field]

        if (errMessages === undefined) return

        const message = (errMessages as string[]).join(". ")

        validationResponse[field] = message
      })

      setServerValidation(validationResponse)
    }

    navigate("/cms/master/webinar-category", {
      state: { webinarCategoryUpsertSuccess: true },
    })

    setWebinarCategorySubmit(false)
  }

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        prefix="cms"
        segments={segments}
      />
      <h2 className="text-xl text-primary font-semibold leading-loose tracking-wide">
        Ubah Kategori Webinar
      </h2>
      <WebinarCategoryManagementForm
        method="PUT"
        onSubmitHandler={webinarCategoryUpdateSubmitHandler}
        serverValidationResponse={serverValidationError}
        submitting={webinarCategorySubmitting}
        initialValues={{
          name: response.data.name,
          description: response.data.description,
        }}
      />
    </EmployeeDashboardWrapper>
  )
}

export default WebinarCategoryManagementEdit

export const loadWebinarCategory: LoaderFunction = async ({
  params,
}): Promise<CMSGetWebinarCategoryResponse<ProductCategory>> => {
  const webinarCategoryID = params.id

  if (webinarCategoryID === undefined || isNaN(+webinarCategoryID!))
    throw new Response("Not Found", { status: 404 })

  const response = await api.cms.getWebinarCategory(
    +(webinarCategoryID as string)
  )

  return response
}
