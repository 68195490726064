import { useQuery } from "@tanstack/react-query"

import { getTransactions } from "../functions"
import { CmsQueryKey, getTransactionsParams } from "../../types"

const useGetTransactions = (params?: getTransactionsParams) => {
  return useQuery({
    queryKey: [CmsQueryKey.Transactions, params?.qParams ?? {}],
    queryFn: () => getTransactions(params?.qParams),
    ...params?.options,
  })
}

export default useGetTransactions
