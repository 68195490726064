import { useQuery } from "@tanstack/react-query"
import { CmsQueryKey } from "../../types"
import { getUserDetail } from "../functions"

const useGetUserDetail = (userId: number) => {
  return useQuery({
    queryKey: [CmsQueryKey.Users, userId],
    queryFn: () => getUserDetail(userId),
  })
}

export default useGetUserDetail
