import { requestUser } from "utils/axiosInstance"
import { PublicGetSertifikasiResponse } from "../../types"

const getSlideImagePreview = async () => {
    const res = await requestUser.get<PublicGetSertifikasiResponse>(
        `/sertifikasi`,
    )

    return res
}

export default getSlideImagePreview
