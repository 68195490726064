import { useMutation } from "@tanstack/react-query"

import {
  PartnershipAuthenticationReq,
  PartnershipAuthenticationResponse,
  GeneralMutationParams,
} from "utils/types"
import { login } from "../functions"

const useMutationLogin = (
  options?: GeneralMutationParams<
    PartnershipAuthenticationResponse,
    PartnershipAuthenticationReq
  >
) => {
  return useMutation({
    mutationFn: (payload) => login(payload),
    ...options,
  })
}

export default useMutationLogin
