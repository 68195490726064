import { useQuery } from "@tanstack/react-query"
import { CmsQueryKey } from "../../types"
import { getDashboard } from "../functions"

const useGetDashboard = (startDate: string, endDate: string) => {
  return useQuery({
    queryKey: [CmsQueryKey.Dashboard, startDate, endDate],
    queryFn: () => getDashboard(startDate, endDate),
  })
}

export default useGetDashboard
