import { useParams } from "react-router-dom"
import Status from "components/ui/status"
import { useGetWebinarDetail } from "utils/api/cms/queries/hooks"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import Typography from "components/ui/typography"
import DOMPurify from "dompurify"

const WebinarDetail = () => {
  const params = useParams()

  const { data: dataDetailWebinar } = useGetWebinarDetail({
    id: params.id ?? "",
  })

  const dataWebinar = dataDetailWebinar?.data

  return (
    <EmployeeDashboardWrapper>
      <div className="flex gap-10 justify-between">
        <div className="mt-5 flex flex-col gap-5">
          <div>
            <Typography
              variant="subheading3"
              as="h1"
              className="text-primary"
            >
              Deskripsi
            </Typography>

            <Typography
              variant="text3"
              className="mb-3 text-white"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(dataWebinar?.description ?? ``, {
                    USE_PROFILES: { svg: false, html: true },
                  }),
                }}
              ></div>
            </Typography>
          </div>
          <div>
            <Typography
              variant="subheading3"
              as="h1"
              className="text-primary"
            >
              Slug
            </Typography>
            <Typography
              variant="text3"
              className="text-white"
            >
              {dataWebinar?.slug}
            </Typography>
          </div>
          <div>
            <Typography
              variant="subheading3"
              as="h1"
              className="text-primary"
            >
              Quota
            </Typography>
            <Typography
              variant="text3"
              className="text-white"
            >
              {dataWebinar?.quota}
            </Typography>
          </div>
          <div>
            <Typography
              variant="subheading3"
              as="h1"
              className="text-primary"
            >
              Tanggal Mulai
            </Typography>
            <Typography
              variant="text3"
              className="text-white"
            >
              {dataWebinar?.start_date}
            </Typography>
          </div>
        </div>
        <div className="my-6 md:w-1/3">
          <img
            src={dataWebinar?.thumbnail}
            alt="thumbnail"
            width={120}
            height={120}
            className="object-cover h-[120px] mb-5"
          />
          <Typography
            variant="heading3"
            as="h1"
            className="text-primary"
          >
            {dataWebinar?.title}
          </Typography>
          <div className="mt-5 flex flex-col gap-3">
            <div className="flex justify-between">
              <Typography
                variant="text3"
                className="mb-4 font-medium text-white"
              >
                Kategori:
              </Typography>
              {dataWebinar?.categories?.map((category) => (
                <Typography
                  variant="text3"
                  className="mb-2 font-medium text-green-300 px-2.5 py-0.5 bg-green-900 rounded"
                >
                  {category.name}
                </Typography>
              ))}
            </div>
            <div className="flex justify-between">
              <Typography
                variant="text3"
                className="mb-2 font-medium text-white"
              >
                Harga:
              </Typography>
              <Typography
                variant="text3"
                className="mb-2 font-medium text-green-300 px-2.5 py-0.5 bg-green-900 rounded"
              >
                {dataWebinar?.price.currency_text}{" "}
                {dataWebinar?.price.current_price_text}
              </Typography>
            </div>
            <div className="flex justify-between">
              <Typography
                variant="text3"
                className="mb-2 font-medium text-white"
              >
                Status Webinar:
              </Typography>
              <Status isActive={dataWebinar?.is_active ?? false} />
            </div>
          </div>
        </div>
      </div>
    </EmployeeDashboardWrapper>
  )
}

export default WebinarDetail
