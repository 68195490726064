import { useMutation } from "@tanstack/react-query"

import {
  UploadIconResponse,
  UploadThumbnailCompanyIconRequest,
} from "../../types"
import { uploadThumbnailCompanyIcon } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationUploadThumbnailCompanyIcon = (
  options?: GeneralMutationParams<
    UploadIconResponse,
    UploadThumbnailCompanyIconRequest
  >
) => {
  return useMutation({
    mutationFn: ({ careerId, payload }) =>
      uploadThumbnailCompanyIcon(careerId, payload),
    ...options,
  })
}

export default useMutationUploadThumbnailCompanyIcon
