import { requestUser } from "utils/axiosInstance"
import { QueryParams } from "utils/types"
import { PublicGetCoursesResponse } from "../../types"

const getCourses = async (qParams?: QueryParams) => {
  const res = await requestUser.get<PublicGetCoursesResponse>(
    "/courses",
    qParams
  )
  return res
}

export default getCourses
