import clsx from "clsx"

import CardTitle from "./CardTitle"
import CardCaption from "./CardCaption"
import CardContainer from "./CardContainer"

interface VerticalCardProps {
  imgSrc?: string
  imgAlt?: string
  height?: number
  width?: number
  maxHeight?: number
  maxWidth?: number
  to?: string
  className?: string
  scale?: boolean
  children?: React.ReactNode
}

const VerticalCard = ({
  imgSrc,
  imgAlt,
  height,
  width,
  maxHeight,
  maxWidth,
  to,
  className,
  scale,
  children,
}: VerticalCardProps) => {
  return (
    <CardContainer
      to={to}
      className={className}
      scale={scale}
      width={width}
      height={height}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
    >
      {imgSrc && (
        <img
          src={imgSrc}
          alt={imgAlt}
          className={clsx(
            "h-[181px] w-full",
            "object-cover",
            "rounded-t-lg",
            "shadow-md"
          )}
        />
      )}
      <div className={clsx("flex flex-col gap-4", "p-5", "bg-white")}>
        {children}
      </div>
    </CardContainer>
  )
}

VerticalCard.Title = CardTitle
VerticalCard.Caption = CardCaption

export default VerticalCard
