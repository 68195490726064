import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import Typography from "components/ui/typography"
import { getCMSSegments } from "utils/helper"
import CareerDetail from "components/cms/CareerDetail"

const CareerManagementDetail = () => {
  const segments = getCMSSegments()

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix="cms"
      />
      <div className="inline-flex items-center">
        <Typography
          variant="subheading4"
          as="h1"
          className="text-primary"
        >
          Pengaturan Detail Karir
        </Typography>
      </div>
      <CareerDetail />
    </EmployeeDashboardWrapper>
  )
}

export default CareerManagementDetail
