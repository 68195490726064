import { requestCMS } from "utils/axiosInstance"
import { GeneralApiResponse, QueryParams } from "utils/types"
import { Sertifikat } from "../../types"

const getSertifikat = async (qParams?: QueryParams) => {
    const res = await requestCMS.get<GeneralApiResponse<Sertifikat[]>>(
        "/employee/sertifikat",
        qParams
    )
    return res
}

export default getSertifikat
