import { useEffect } from "react"
import { useLocation } from "react-router-dom"

type WithScrollToTopType = <T extends {}>(
  Component: React.ComponentType<T>
) => React.FC<T>

const withScrollToTop: WithScrollToTopType = (Component) => (props) => {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  return <Component {...props} />
}

export default withScrollToTop
