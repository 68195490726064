import { SubmitHandler, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import TextInput from "components/ui/form/TextInput"
import { useMutationCreateCareerCategory } from "utils/api/cms/mutations/hooks"
import { GeneralPartnershipRequest } from "utils/api/cms/types"
import {
  BookmarkIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"
import useMutationUpdateCareerCategory from "utils/api/cms/mutations/hooks/useMutationUpdateCareerCategory"
import useGetCareerCategoryDetail from "utils/api/cms/queries/hooks/useGetCareerCategoryDetail"
import { useEffect } from "react"
import useMutationCreatePartnership from "utils/api/cms/mutations/hooks/useMutationCreatePartnership"
import useGetPartnershipDetail from "utils/api/cms/queries/hooks/useGetPartnershipDetail"
import useMutationUpdatePartnership from "utils/api/cms/mutations/hooks/useMutationUpdatePartnership"

interface PartnershipForm {
  full_name: string
  email: string
  password: string
}

const PartnershipForm = () => {
  const navigate = useNavigate()
  const params = useParams()

  const isUpdate = !!params.id

  const {
    handleSubmit,
    register,
    control,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<PartnershipForm>({})

  const { data: dataDetail } = useGetPartnershipDetail({
    id: params.id ?? "",
  })

  const { mutate: mutateCreatePartnership } = useMutationCreatePartnership({
    onSuccess(data) {
      if (!data.success) {
        return
      }
      toast.success("Partnership berhasil dibuat")
      setTimeout(() => {
        navigate("/cms/partnership")
      }, 1000)
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  const { mutate: mutateUpdatePartnership } = useMutationUpdatePartnership({
    onSuccess(data) {
      if (!data.success) {
        return
      }
      toast.success("Partnership berhasil diubah")
      setTimeout(() => {
        navigate("/cms/partnership")
      }, 1000)
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  useEffect(() => {
    if (!dataDetail) return

    const partnership = dataDetail.data

    reset({
      full_name: partnership.full_name,
      email: partnership.email,
    })
  }, [dataDetail])

  const onSubmit: SubmitHandler<PartnershipForm> = (data) => {
    const payload: GeneralPartnershipRequest = {
      full_name: data.full_name,
      email: data.email,
      password: data.password,
    }

    if (!isUpdate) {
      return mutateCreatePartnership(payload)
    }

    mutateUpdatePartnership({
      id: params.id ?? "",
      payload: payload,
    })
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-4 pb-4 lg:min-w-[42rem]"
    >
      <TextInput
        type="text"
        className="cms-base-input"
        labelClass="cms-base-label"
        isRequired
        label="Nama Lengkap"
        errorMessage={errors.full_name?.message}
        errorIcon={
          <ExclamationTriangleIcon
            height={24}
            width={24}
            className="text-red-800"
          />
        }
        {...register("full_name", {
          required: {
            value: true,
            message: "Nama lengkap wajib diisi",
          },
        })}
      />
      <TextInput
        type="text"
        className="cms-base-input"
        labelClass="cms-base-label"
        isRequired
        label="Email"
        errorMessage={errors.email?.message}
        errorIcon={
          <ExclamationTriangleIcon
            height={24}
            width={24}
            className="text-red-800"
          />
        }
        {...register("email", {
          required: {
            value: true,
            message: "Email wajib diisi",
          },
        })}
      />
      {!isUpdate && (
        <TextInput
          type="password"
          className="cms-base-input"
          labelClass="cms-base-label"
          isRequired
          label="Password"
          errorMessage={errors.password?.message}
          errorIcon={
            <ExclamationTriangleIcon
              height={24}
              width={24}
              className="text-red-800"
            />
          }
          {...register("password", {
            required: {
              value: true,
              message: "Password wajib diisi",
            },
          })}
        />
      )}

      <ButtonWithIcon
        action={undefined}
        type="submit"
        class="self-start mt-12 border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
        disabled={isSubmitting}
      >
        <BookmarkIcon className="h-6 w-6 text-white" />
        <span>
          {!isSubmitting ? (
            <>{isUpdate ? "Simpan" : "Tambahkan"}</>
          ) : (
            "Menyimpan..."
          )}
        </span>
      </ButtonWithIcon>
    </form>
  )
}

export default PartnershipForm
