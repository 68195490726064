import { useState } from "react"
import { useNavigate } from "react-router-dom"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import { getCMSSegments } from "../../../utils/helper"
import {
  DocumentPlusIcon,
  PencilSquareIcon,
  KeyIcon
} from "@heroicons/react/24/solid"
import type { Partnership } from "utils/api/cms/types"
import ReactTable from "components/ui/react-table/ReactTable"
import CMSDefaultTableListFilter from "components/cms/CMSDefaultTableListFilter"
import { PaginationState, createColumnHelper } from "@tanstack/react-table"
import useGetPartnership from "utils/api/cms/queries/hooks/useGetPartnership"
import Typography from "components/ui/typography"
import Button from "components/ui/button/Button"
import { Link } from "react-router-dom"
import { Tooltip } from "flowbite-react"
import Modal from "components/ui/modal/Modal"
import { toast } from "react-toastify"
import useUpdatePasswordPartnership from "utils/api/cms/mutations/hooks/useUpdatePasswordPartnership"

const PartnershipManagement: React.FC = () => {
  const segments = getCMSSegments()

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 1,
  })
  const [searchFilter, setSearchFilter] = useState("")
  const [perPageLimit, setPerPageLimit] = useState(10)
  const [modalPartnership, setModalPartnership] = useState<{
    isOpen: boolean
    partnershipId: string,
    email: string
  }>()
  const { data } = useGetPartnership({
    options: {
      staleTime: 0,
    },
    qParams: {
      limit: perPageLimit,
      page: pagination.pageIndex + 1,
      keyword: searchFilter,
    },
  })

  const handleSearch = (keyword: string) => {
    setSearchFilter(keyword)
  }

  const handleModalPartnership = (partnershipId?: string, email?: string) => {
    setModalPartnership((prev) => ({
      isOpen: !prev?.isOpen,
      partnershipId: partnershipId ?? "",
      email: email ?? ""
    }))
  }
  const { mutate: updatePassword, isLoading } = useUpdatePasswordPartnership();

  const handleUpdatePassword = (id, email) => {
    const requestData = {
      id: id,
      payload: {
        password: "12345678",
      },
    };
    updatePassword(requestData, {
      onSuccess: () => {
        handleModalPartnership()
        toast.success("Berhasil update password partnership email " + email)
      },
    });
  };


  const navigate = useNavigate()
  const userColHelper = createColumnHelper<Partnership>()
  const userColumns = [
    userColHelper.accessor("full_name", {
      header: () => "Nama",
    }),
    userColHelper.accessor("email", {
      header: () => "Email",
    }),
    userColHelper.accessor("created_at", {
      header: () => "Dibuat pada",
    }),
    userColHelper.display({
      id: "actions",
      header: () => "AKSI",
      cell: (props) => (
        <div className="flex flex-row w-fit space-x-3">
          <Tooltip content="Edit">
            <ButtonWithIcon
              class="mx-auto border-2 border-transparent bg-yellow-400 text-white hover:bg-yellow-300 hover:border-yellow-300"
              type="button"
              action={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault()
                return navigate(`edit/${props.row.original.id}`)
              }}
              sizeClass="p-1"
            >
              <PencilSquareIcon className="h-5 w-5" />
            </ButtonWithIcon>
          </Tooltip>
          <Tooltip content="Password">
            <ButtonWithIcon
              class="mx-auto border-2 border-transparent bg-blue-600 text-white hover:bg-blue-500 hover:border-blue-500"
              type="button"
              action={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault()
                handleModalPartnership(
                  props.row.original.id.toString(),
                  props.row.original.email
                )
              }}
              sizeClass="p-1"
            >

              <KeyIcon className="h-5 w-5" />
            </ButtonWithIcon>
          </Tooltip>
        </div>
      ),
    }),
  ]

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix="cms"
      />

        <Typography
          variant="subheading4"
          as="h1"
          className="text-primary mt-4"
        >
          Daftar Partnership
        </Typography>
      <div className="flex flex-wrap justify-between gap-4 my-6">
        <ButtonWithIcon
          action={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            return navigate("create")
          }}
          type="submit"
          class="border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
        >
          <DocumentPlusIcon className="h-6 w-6 text-white" />
          <span>Buat Partnership</span>
        </ButtonWithIcon>
        <CMSDefaultTableListFilter
          pageSelector
          searchPlaceHolder="Cari nama pengguna"
          wrapperClass="ml-auto flex flex-row space-x-6"
          onSearchHandler={handleSearch}
          perPageHandler={(selected) => setPerPageLimit(selected)}
        />
      </div>
      <ReactTable
        columns={userColumns}
        data={data?.data ?? []}
        pagination={pagination}
        onPaginationChange={setPagination}
        pageCount={data?.meta?.last_page ?? 1}
      />

      <Modal
        header="Ganti Password Partnership"
        onclose={handleModalPartnership}
        show={!!modalPartnership?.isOpen}
        body={
          <Typography className="text-white">
            Apakah Anda yakin untuk update password ini?
          </Typography>
        }
        footer={
          <div className="space-x-2 ml-auto">
            <Button
              onClick={() => handleModalPartnership()}
              variant="outlined"
              className="text-gray-300 border-gray-300"
            >
              Batal
            </Button>
            <Button onClick={() => handleUpdatePassword(modalPartnership?.partnershipId, modalPartnership?.email)} disabled={isLoading}>
              Update Password
            </Button>

          </div>
        }
      />
    </EmployeeDashboardWrapper>
  )
}

export default PartnershipManagement
