import { useMutation } from "@tanstack/react-query"

import {
  UploadThumbnailResponse,
  UploadThumbnailSertifikatRequest,
} from "../../types"
import { GeneralMutationParams } from "utils/types"
import uploadSertifikat from "../functions/sertifikat/uploadSertifikat"

const useMutationUploadSertifikat = (
  options?: GeneralMutationParams<
    UploadThumbnailResponse,
    UploadThumbnailSertifikatRequest
  >
) => {
  return useMutation({
    mutationFn: ({ sertifikatId, payload }) =>
      uploadSertifikat(sertifikatId, payload),
    ...options,
  })
}

export default useMutationUploadSertifikat
