import SertifikatPesertaForm from "components/cms/Sertifikat/SertifikatPesertaForm"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import Typography from "components/ui/typography"
import { getCMSSegments } from "utils/helper"

const SertifikatPesertaEdit = () => {
    const segments = getCMSSegments()

    return (
        <EmployeeDashboardWrapper>
            <DefBreadcrumb
                segments={segments}
                prefix="cms"
            />
            <Typography
                variant="subheading4"
                as="h1"
                className="text-primary"
            >
                Update Status Sertifikat
            </Typography>
            <SertifikatPesertaForm />
        </EmployeeDashboardWrapper>
    )
}

export default SertifikatPesertaEdit
