import { useQuery } from "@tanstack/react-query"

import { getVideoMaxSequence } from "../functions"
import { CmsQueryKey, GetVideoMaxSequenceParams } from "../../types"

const useGetVideoMaxSequence = (params?: GetVideoMaxSequenceParams) => {
  return useQuery({
    queryKey: [CmsQueryKey.MaxSequence, params?.sectionID ?? ""],
    queryFn: () => getVideoMaxSequence(params?.sectionID ?? ""),
    ...params?.options,
  })
}

export default useGetVideoMaxSequence
