import { requestUser } from "utils/axiosInstance"
import {
  PublicUpdatePasswordResponse,
  UpdatePasswordRequest,
} from "../../types"

const updatePassword = async (payload: UpdatePasswordRequest) => {
  const res = await requestUser.post<PublicUpdatePasswordResponse>(
    "/user/password/update",
    payload
  )
  return res
}

export default updatePassword
