import { useEffect, useState } from "react"
import { SubmitHandler } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"

import CourseVideoManagementForm, {
  CourseVideoForm,
} from "components/cms/CourseVideoManagementForm"
import CourseVideoManagementFormTempUpload, {
  TempVideoUpload,
} from "components/cms/CourseVideoManagementFormTempUpload"
import CourseVideoManagementPlayer from "components/cms/CourseVideoManagementPlayer"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import api from "utils/axiosInstance"
import { getCMSSegments } from "utils/helper"
import { getCmsToken } from "utils/auth"
import { useMutationCreateVideo } from "utils/api/cms/mutations/hooks"
import { useGetVideoMaxSequence } from "utils/api/cms/queries/hooks"

const CourseVideoManagementUpload: React.FC = () => {
  const segments = getCMSSegments()
  const params = useParams()
  const navigation = useNavigate()

  const UPLOADED_VIDEO_KEY = `course_video_upload_inprogress_${params.id}_${params.sectionID}`

  const [uploadProgress, setUploadProgress] = useState<number>(0)
  const [uploading, setUploading] = useState<boolean>(false)
  const [videoUploaded, setUploaded] = useState<boolean>(false)
  const [uploadedUrl, setUploadedURL] = useState<any>(undefined)
  const [courseVideoSubmitting, setCourseVideoSubmitting] =
    useState<boolean>(false)
  const [uploadedVideoId, setUploadedVideId] = useState("")

  const { mutate: mutateCreateVideo } = useMutationCreateVideo({
    onSuccess() {
      toast.success("Video berhasil ditambahkan")
      window.localStorage.removeItem(UPLOADED_VIDEO_KEY)
      setUploadedVideId("")
      navigation(`/cms/course/detail/${params.id}/detail/${params.sectionID}`)
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  const { data: dataMaxSequence } = useGetVideoMaxSequence({
    options: {
      staleTime: 0,
    },
    sectionID: params.sectionID ?? "",
  })

  const temporaryUploadHandler: SubmitHandler<TempVideoUpload> = async (
    data
  ) => {
    setUploading(true)
    const formData = new FormData()

    formData.append("video", data.video[0])

    const res = await api.cms.temporaryUploadVideo(formData, {
      onUploadProgress: (progressEvent) =>
        setUploadProgress(
          Math.round((progressEvent.loaded * 100) / progressEvent.total!)
        ),
    })

    setUploading(false)
    setUploadProgress(0)
    setUploaded(true)
    setUploadedVideId(res.data.id)
    localStorage.setItem(
      UPLOADED_VIDEO_KEY,
      JSON.stringify({
        id: res.data.id,
        section: Number(params.sectionID),
      })
    )
  }

  const videoSubmitHandler: SubmitHandler<CourseVideoForm> = async (data) => {
    setCourseVideoSubmitting(true)

    mutateCreateVideo({
      ...data,
      section_id: Number(params.sectionID),
      video_id: uploadedVideoId,
    })
    setCourseVideoSubmitting(false)
  }

  useEffect(() => {
    const uploadIsInProgress = localStorage.getItem(UPLOADED_VIDEO_KEY)
    if (!uploadIsInProgress) return

    const tempUploadedVideo: {
      id: string
      section: number
    } = JSON.parse(uploadIsInProgress)

    if (!tempUploadedVideo.id) return
    setUploadedVideId(tempUploadedVideo.id)
    setUploaded(true)
  }, [])

  useEffect(() => {
    if (!videoUploaded) return

    const inProgress: {
      id: string
      section: number
    } = JSON.parse(localStorage.getItem(UPLOADED_VIDEO_KEY) as string)

    const fetchTempVideo = async () => {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL!}/employee/course/section/video/get/${
          inProgress.id
        }`,
        {
          headers: {
            Authorization: `Bearer ${getCmsToken()}`,
          },
        }
      )
      const blob = await res.blob()
      const objectURL = URL.createObjectURL(blob)
      setUploadedURL(objectURL)
    }

    fetchTempVideo()
  }, [videoUploaded])

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        prefix="cms"
        segments={segments}
      />
      <h2 className="text-xl text-primary font-semibold leading-loose tracking-wide">
        Upload Video
      </h2>
      {!videoUploaded && (
        <CourseVideoManagementFormTempUpload
          method="POST"
          onSubmitHandler={temporaryUploadHandler}
          submitting={uploading}
          uploadProgress={uploadProgress}
        />
      )}
      {videoUploaded && uploadedUrl === undefined && (
        <div
          role="status"
          className="flex items-center justify-center h-56 max-w-sm bg-gray-300 rounded-lg animate-pulse dark:bg-gray-700"
        >
          <svg
            className="w-12 h-12 text-gray-200 dark:text-gray-600"
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 384 512"
          >
            <path d="M361 215C375.3 223.8 384 239.3 384 256C384 272.7 375.3 288.2 361 296.1L73.03 472.1C58.21 482 39.66 482.4 24.52 473.9C9.377 465.4 0 449.4 0 432V80C0 62.64 9.377 46.63 24.52 38.13C39.66 29.64 58.21 29.99 73.03 39.04L361 215z" />
          </svg>
          <span className="sr-only">Loading...</span>
        </div>
      )}
      {videoUploaded && uploadedUrl && (
        <>
          <span className="cms-base-label">Preview Video</span>
          <CourseVideoManagementPlayer
            options={{
              controls: true,
              sources: [
                {
                  src: uploadedUrl,
                  type: "video/mp4",
                },
              ],
            }}
          />
          <CourseVideoManagementForm
            maxSeq={(dataMaxSequence?.data ?? 0) + 1}
            onSubmitHandler={videoSubmitHandler}
            submitting={courseVideoSubmitting}
          />
        </>
      )}
    </EmployeeDashboardWrapper>
  )
}

export default CourseVideoManagementUpload
