import { Link } from "react-router-dom"
import { CSSProperties } from "react"

import clsxm from "utils/clsxm"

interface CardContainerProps {
  to?: string
  scale?: boolean
  height?: number
  width?: number
  maxHeight?: number
  maxWidth?: number
  className?: string
  children: React.ReactNode
  paddingTop?: string
  paddingBottom?: string
  paddingLeft?: string
}

const CardContainer = ({
  to,
  scale,
  height,
  width,
  maxHeight,
  maxWidth,
  className,
  children,
  paddingTop,
  paddingBottom,
  paddingLeft
}: CardContainerProps) => {
  if (!!to) {
    return (
      <Link
        style={
          {
            "--height": !!height && height + "px",
            "--width": !!width && width + "px",
            "--maxHeight": !!maxHeight && maxHeight + "px",
            "--maxWidth": !!maxWidth && maxWidth + "px",
          } as CSSProperties
        }
        className={clsxm(
          "h-fit w-fit",
          "border rounded-lg",
          "shadow",
          scale && "hover:scale-105 transition-transform",
          !!height && "h-[--height]",
          !!width && "w-[--width]",
          !!maxHeight && "h-[--maxHeight]",
          !!maxWidth && "w-[--maxWidth]",
          className
        )}
        to={to}
      >
        {children}
      </Link>
    )
  }

  return (
    <div
      style={
        {
          "--height": !!height && height + "px",
          "--width": !!width && width + "px",
          "--maxHeight": !!maxHeight && maxHeight + "px",
          "--maxWidth": !!maxWidth && maxWidth + "px",
          "--paddingTop": !!paddingTop && paddingTop + "px",
          "--paddingBottom": !!paddingBottom && paddingBottom + "px",
          "--paddingLeft": !!paddingLeft && paddingLeft + "px",
        } as CSSProperties
      }
      className={clsxm(
        "h-fit w-fit",
        "border rounded-lg",
        "shadow",
        scale && "hover:scale-105 transition-transform",
        !!height && "h-[--height]",
        !!width && "w-[--width]",
        !!maxHeight && "h-[--maxHeight]",
        !!maxWidth && "w-[--maxWidth]",
        className
      )}
    >
      {children}
    </div>
  )
}

export default CardContainer
