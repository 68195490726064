import { requestCMS } from "utils/axiosInstance"
import type { GeneralPartnershipResponse } from "../../types"

const getPartnershipDetail = async (id?: string) => {
  const res = await requestCMS.get<GeneralPartnershipResponse>(
    `/employee/partnership/${id}`
  )
  return res
}

export default getPartnershipDetail
