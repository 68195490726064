interface CardProps {
  title: string
  total?: number
  rupiah?: string
}

const Card = ({ title, total, rupiah }: CardProps) => {
  const displayValue =
    rupiah !== undefined ? rupiah : total !== undefined ? total : ""
  return (
    <div className="max-w-sm bg-primary rounded-lg border border-primary shadow-md ">
      <div className="p-5">
        <h5 className="text-white text-xl font-medium mb-2">{title}</h5>
        <p className="text-white text-base mb-4">{displayValue}</p>
      </div>
    </div>
  )
}

export default Card
