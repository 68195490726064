import { useMutation } from "@tanstack/react-query"

import { GeneralSertifikatResponse, UpdateSertifikatRequest } from "../../types"
import { updateSertifikat } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationUpdateSertifikat = (
    options?: GeneralMutationParams<
        GeneralSertifikatResponse,
        UpdateSertifikatRequest
    >
) => {
    return useMutation({
        mutationFn: ({ id, payload }) => updateSertifikat(id, payload),
        ...options,
    })
}

export default useMutationUpdateSertifikat
