import { create } from "zustand"
import { StateStorage, createJSONStorage, persist } from "zustand/middleware"
import CryptoJS from "crypto-js"

import { Role } from "utils/types"

interface EmployeeInfo {
  id: number
  email: string
  fullName: string
  phone: string
  roles: Role[]
}

interface EmployeeInfoState {
  employeeInfo: EmployeeInfo
  setEmployeeInfo: (state: EmployeeInfo) => void
  resetEmployeeInfo: () => void
}

const encrpytedStorage: StateStorage = {
  getItem(name) {
    const item = localStorage.getItem(name)
    const decrypted = CryptoJS.AES.decrypt(
      item ?? "",
      process.env.REACT_APP_ENCRYPTION_SECRET ?? ""
    )
    return decrypted.toString(CryptoJS.enc.Utf8)
  },
  setItem(name, value) {
    const encrypted = CryptoJS.AES.encrypt(
      value,
      process.env.REACT_APP_ENCRYPTION_SECRET ?? ""
    ).toString()
    localStorage.setItem(name, encrypted)
  },
  removeItem(name) {
    localStorage.removeItem(name)
  },
}

export const useEmployeeInfoStore = create(
  persist<EmployeeInfoState>(
    (set) => ({
      employeeInfo: {
        id: 0,
        email: "",
        fullName: "",
        phone: "",
        roles: [],
      },
      setEmployeeInfo(state) {
        set({ employeeInfo: state })
      },
      resetEmployeeInfo() {
        set({
          employeeInfo: {
            email: "",
            fullName: "",
            id: 0,
            phone: "",
            roles: [],
          },
        })
      },
    }),
    {
      name: "employee-info",
      storage: createJSONStorage(() => encrpytedStorage),
    }
  )
)
