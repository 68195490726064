import clsx from "clsx"
import { ArrowUpTrayIcon, EyeIcon, TrashIcon } from "@heroicons/react/24/solid"

interface FileInputProps extends React.ComponentPropsWithoutRef<"input"> {
  label: string
  value?: string
  onDelete?: () => void
  onPreview?: () => void
}

const FileInput = ({
  label,
  value,
  onDelete,
  onPreview,
  ...restProps
}: FileInputProps) => {
  return !!value ? (
    <>
      <img
        className="object-cover h-[80px]"
        src={value}
        alt="thumbnail"
        width={120}
        height={80}
      />
      <div
        className={clsx(
          "flex justify-center items-center gap-4",
          "absolute",
          "top-0 left-0",
          "w-full h-full",
          "transition hover:bg-black hover:bg-opacity-80",
          "group"
        )}
      >
        <button
          onClick={onDelete}
          className="hidden group-hover:flex"
        >
          <TrashIcon
            width={16}
            height={16}
          />
        </button>
        <button
          onClick={onPreview}
          className="hidden group-hover:flex"
        >
          <EyeIcon
            width={16}
            height={16}
          />
        </button>
      </div>
    </>
  ) : (
    <>
      <label
        htmlFor="upload"
        className={clsx(
          "relative",
          "flex flex-col justify-center items-center",
          "space-y-1",
          "border border-dashed",
          "p-2",
          "cursor-pointer"
        )}
      >
        <ArrowUpTrayIcon
          width={20}
          height={20}
        />
        <span className="text-xs text-center">{label}</span>
      </label>
      <input
        id="upload"
        type="file"
        className="hidden"
        {...restProps}
      />
    </>
  )
}

export default FileInput
