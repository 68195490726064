import { RouteObject } from "react-router-dom"

import UserPrivateRouter from "../private-router/UserPrivateRouter"
import PaymentSuccess from "pages/payment/PaymentSuccess"
import PaymentFailed from "pages/payment/PaymentFailed"

const paymentRoute: RouteObject = {
  element: <UserPrivateRouter />,
  children: [
    {
      path: "/payment/success",
      element: <PaymentSuccess />,
    },
    {
      path: "/payment/failed",
      element: <PaymentFailed />,
    },
  ],
}

export default paymentRoute
