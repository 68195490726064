import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"

const useFlashMessage = (
  type: "success" | "error",
  stateName: string,
  message: string,
  timeToFlash?: number
) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [flashing, _] = useState(location.state?.[stateName])

  useEffect(() => {
    if (flashing === null || !flashing) return

    Swal.mixin({
      toast: true,
      position: "top-right",
      iconColor: "white",
      customClass: {
        popup: "colored-toast",
      },
      showConfirmButton: false,
      timer: timeToFlash ? timeToFlash : 1500,
      timerProgressBar: true,
    }).fire({
      icon: type,
      title: message,
    })

    navigate(".", { replace: true })
  }, [flashing])
}

export default useFlashMessage
