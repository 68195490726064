import { requestCMS } from "utils/axiosInstance"
import { GeneralSertifikasiSkkniResponse } from "../../types"

const deleteSertifikasiSkkni = async (sertifikasiId: string) => {
    const res = await requestCMS.delete<GeneralSertifikasiSkkniResponse>(
        `/employee/sertifikat/skkni/${sertifikasiId}`
    )
    return res
}

export default deleteSertifikasiSkkni
