import { Helmet } from "react-helmet"

import TransactionHistory from "components/public/transaction-history/Transactions"

const TransactionHistoryPage = () => {
  return (
    <div>
      <Helmet>
        <title>Riwayat Pembayaran</title>
      </Helmet>
      <TransactionHistory />
    </div>
  )
}

export default TransactionHistoryPage
