import { requestUser } from "utils/axiosInstance"
import type { PublicCareerUserResponse } from "../../types"

const careerUser = async (payload: FormData) => {
  const res = await requestUser.post<PublicCareerUserResponse>(
    `/career-users`,
    payload
  )
  return res
}

export default careerUser
