import { useQuery } from "@tanstack/react-query"

import { getCourses } from "../functions"
import { PublicQueryKey, PublicGetCoursesParams } from "../../types"

const useGetCourses = (params?: PublicGetCoursesParams) => {
  return useQuery({
    queryKey: [PublicQueryKey.Courses, params?.qParams ?? {}],
    queryFn: () => getCourses(params?.qParams),
    ...params?.options,
  })
}

export default useGetCourses
