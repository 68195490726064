import { requestCMS } from "utils/axiosInstance"
import type { GeneralCareerCategoryResponse } from "../../types"

const getCareerCategoryDetail = async (id?: string) => {
  const res = await requestCMS.get<GeneralCareerCategoryResponse>(
    `/employee/career-categories/detail/${id}`
  )
  return res
}

export default getCareerCategoryDetail
