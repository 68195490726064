import { useQuery } from "@tanstack/react-query"

import { PublicQueryKey, PublicGetWorkshopsParams } from "../../types"
import getWorkshops from "../functions/getWorkshops"

const useGetWorkshops = (params: PublicGetWorkshopsParams) => {
  return useQuery({
    queryKey: [PublicQueryKey.Workshops, params?.qParams ?? {}],
    queryFn: () => getWorkshops(params?.qParams),
    ...params?.options,
  })
}

export default useGetWorkshops
