import { requestUser } from "utils/axiosInstance"
import { PublicGetProductCategoriesResponse } from "../../types"

const getCategories = async () => {
  const res = await requestUser.get<PublicGetProductCategoriesResponse>(
    "/course-categories"
  )
  return res
}

export default getCategories
