
export enum PartnershipQueryKey {
    Dashboard = "dashboard",
    DetailPartnership = "detail-partnership"
}
export interface DashboardData {
    course: number
    sold: number
    pending: number
    failed: number
    partnership: Array<{
        full_name: string
        courses_count: number
    }>
    top: Array<{
        title: string
        price: {
            currency_text: string
            current_price_text: string
        }
        success_transactions: number
    }>
    list: Array<{
        title: string
    }>
    revenue: number
    partner: number
}

export interface DashboardResponse {
    data: {
        course: number
        sold: number
        pending: number
        failed: number
        revenue: number
        partner: number
        list: Array<{
            title: string
        }>
    }
    message: string
    success: boolean
}

export interface DetailPartnership {
    data: {
        user: {
            id: number
        }
    }
}

export interface GeneralNewPassword {
    password: string
}

export interface UpdatePasswordRequest {
    id: string
    payload: GeneralNewPassword
}