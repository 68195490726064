import { useQuery } from "@tanstack/react-query"

import { getCareers } from "../functions"
import { CmsQueryKey, GetCareersParams } from "../../types"

const useGetCareers = (params?: GetCareersParams) => {
  return useQuery({
    queryKey: [CmsQueryKey.Careers, params?.qParams ?? ""],
    queryFn: () => getCareers(params?.qParams),
    ...params?.options,
  })
}

export default useGetCareers
