import * as React from "react";

function TiTick(props) {
    return <svg
        stroke="currentColor"
        fill="currentColor"
        className={props.className}
        strokeWidth={0} baseProfile="tiny"
        viewBox="0 0 24 24" height="1em"
        width="1em" {...props}>
        <path d="M16.972 6.251c-.967-.538-2.185-.188-2.72.777l-3.713 6.682-2.125-2.125c-.781-.781-2.047-.781-2.828 0-.781.781-.781 2.047 0 2.828l4 4c.378.379.888.587 1.414.587l.277-.02c.621-.087 1.166-.46 1.471-1.009l5-9c.537-.966.189-2.183-.776-2.72z" />
    </svg>;
}

export default TiTick;