import { useState } from "react"
import { Link, LoaderFunction, useNavigate, useParams } from "react-router-dom"
import { createColumnHelper } from "@tanstack/react-table"
import {
  DocumentPlusIcon,
  PencilSquareIcon,
  TrashIcon,
} from "@heroicons/react/24/solid"
import DOMPurify from "dompurify"
import { toast } from "react-toastify"

import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import api from "utils/axiosInstance"
import { getCMSSegments } from "utils/helper"
import { useGetVideos } from "utils/api/cms/queries/hooks"
import ReactTable from "components/ui/react-table/ReactTable"
import { CMSCourseSectionVideo } from "utils/api/cms/types"
import Button from "components/ui/button/Button"
import Typography from "components/ui/typography"
import Modal from "components/ui/modal/Modal"
import { useDeleteVideo } from "utils/api/cms/mutations/hooks"
import EncodingStatus from "components/ui/status/EncodingStatus"

const CourseVideoManagement: React.FC = () => {
  const segments = getCMSSegments()
  const navigate = useNavigate()
  const params = useParams<{ sectionID: string }>()
  const [modalDelete, setModalDelete] = useState({
    open: false,
    title: "",
    videoID: "",
  })

  const columnHelper = createColumnHelper<CMSCourseSectionVideo>()
  const columns = [
    columnHelper.accessor("title", {
      header: "Judul",
      cell: (props) => props.getValue(),
    }),
    columnHelper.accessor("notes", {
      header: "Notes",
      cell: (props) => (
        <div className="line-clamp-4">
          <Typography
            variant="text3"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(props.getValue() ?? "", {
                USE_PROFILES: { svg: false },
              }),
            }}
          />
        </div>
      ),
    }),
    columnHelper.accessor("is_preview", {
      header: "Preview",
      cell: (props) => (props.getValue() ? "Yes" : "No"),
    }),
    columnHelper.accessor("encoding", {
      header: "Encoding Status",
      cell: (props) => {
        const value = props.getValue()
        let status: "in_progress" | "completed" | "failed" = "in_progress"
        if (value.completed) {
          status = "completed"
        } else if (value.failed) {
          status = "failed"
        }

        return (
          <EncodingStatus
            status={status}
            percentage={value.percentage}
          />
        )
      },
    }),
    columnHelper.display({
      id: "actions",
      header: "Aksi",
      cell: (props) => (
        <div className="flex flex-row w-fit space-x-3">
          <Button
            className="bg-yellow-400 border-yellow-400"
            as={Link}
            to={`edit-video/${props.row.original.id}`}
          >
            <PencilSquareIcon
              width={20}
              height={20}
            />
          </Button>
          <Button
            onClick={() =>
              handleModalDelete(
                props.row.original.id.toString(),
                props.row.original.title
              )
            }
            className="bg-red-600 border-red-600"
          >
            <TrashIcon
              width={20}
              height={20}
            />
          </Button>
        </div>
      ),
    }),
  ]

  const { data: dataVideos, refetch: refetchDataVideos } = useGetVideos({
    options: {
      staleTime: 0,
      refetchInterval(data) {
        const encodingInterval =
          (Number(process.env.REACT_APP_CMS_ENCODING_VIDEO_INTERVAL) ?? 10) *
          1000
        const isEncodingInProgress = data?.data.some(
          (video) => !video.encoding.completed && !video.encoding.failed
        )

        return isEncodingInProgress ? encodingInterval : 0
      },
    },
    sectionID: params.sectionID ?? "",
  })

  const handleModalDelete = (videoID?: string, title?: string) => {
    setModalDelete((prev) => ({
      open: !prev.open,
      videoID: videoID ?? "",
      title: title ?? "",
    }))
  }

  const { mutate: mutateDeleteVideo } = useDeleteVideo({
    onSuccess() {
      toast.success("Video berhasil dihapus")
      refetchDataVideos()
      setModalDelete({
        open: false,
        title: "",
        videoID: "",
      })
    },
    onError(err) {
      toast.error(err.message)
    },
  })

  return (
    <>
      <EmployeeDashboardWrapper>
        <DefBreadcrumb
          prefix="cms"
          segments={segments}
        />
        <h2 className="text-xl text-primary font-semibold leading-loose tracking-wide">
          Konten Video Belajar
        </h2>
        <ButtonWithIcon
          action={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            return navigate("upload")
          }}
          type="submit"
          class="ml-auto border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
        >
          <DocumentPlusIcon className="h-6 w-6 text-white" />
          <span>Upload Video</span>
        </ButtonWithIcon>

        <ReactTable
          columns={columns}
          data={dataVideos?.data ?? []}
        />
      </EmployeeDashboardWrapper>

      <Modal
        header="Hapus Video"
        onclose={handleModalDelete}
        show={modalDelete.open}
        body={
          <Typography className="text-white">
            Apakah Anda yakin ingin menghapus video <b>{modalDelete.title}</b>?
          </Typography>
        }
        footer={
          <div className="space-x-2 ml-auto">
            <Button
              onClick={() => handleModalDelete()}
              variant="outlined"
              className="text-gray-300 border-gray-300"
            >
              Batal
            </Button>
            <Button onClick={() => mutateDeleteVideo(modalDelete.videoID)}>
              Hapus
            </Button>
          </div>
        }
      />
    </>
  )
}

export default CourseVideoManagement

export const courseVideoManagementLoader: LoaderFunction = async ({
  params,
}) => {
  const sectionID = params.sectionID

  const loadSection = async () => {
    const sectionResponse = await api.cms.getCourseSection(
      +(sectionID as string)
    )

    return sectionResponse
  }

  return {
    section: await loadSection(),
  }
}
