import { Link } from "react-router-dom"
import { UserIcon } from "@heroicons/react/20/solid"

import Card from "components/ui/card/horizontal"
import Typography from "components/ui/typography"
import Button from "components/ui/button/Button"

interface WorkshopCardProps {
  quota: number
  title: string
  date: string
  month: string
  slug: string
}

const WorkshopCard = ({
  quota,
  title,
  date,
  month,
  slug,
}: WorkshopCardProps) => {
  return (
    <Card
      height={130}
      width={436}
    >
      <div className="flex flex-col gap-1 justify-start items-start max-w-[238px]">
        <Card.Caption
          className="font-normal"
          icon={
            <UserIcon
              className="text-primary font-medium"
              height={23}
              width={23}
            />
          }
          text={`${quota}`}
        />
        <Card.Title>{title}</Card.Title>
      </div>
      <div className="flex flex-col justify-center items-center">
        <Typography
          variant="heading3"
          className="text-primary font-semibold"
        >
          {date}
        </Typography>
        <Typography
          variant="text2"
          className="font-semibold mb-1"
        >
          {month}
        </Typography>
        <Button
          as={Link}
          to={`/workshops/${slug}`}
          size="sm"
        >
          Beli Tiket
        </Button>
      </div>
    </Card>
  )
}

export default WorkshopCard
