import { useMutation } from "@tanstack/react-query"

import { CreateVideoResponse, CreateVideoRequest } from "../../types"
import { createVideo } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationCreateVideo = (
  options?: GeneralMutationParams<CreateVideoResponse, CreateVideoRequest>
) => {
  return useMutation({
    mutationFn: (payload) => createVideo(payload),
    ...options,
  })
}

export default useMutationCreateVideo
