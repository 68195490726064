import { useMutation } from "@tanstack/react-query"

import {
  GeneralCareerCategoryResponse,
  GeneralCareerCategoryRequest,
} from "../../types"
import { createCareerCategory } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationCreateCareerCategory = (
  options?: GeneralMutationParams<
    GeneralCareerCategoryResponse,
    GeneralCareerCategoryRequest
  >
) => {
  return useMutation({
    mutationFn: (payload) => createCareerCategory(payload),
    ...options,
  })
}

export default useMutationCreateCareerCategory
