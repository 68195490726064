import { useMutation } from "@tanstack/react-query"

import {
  GeneralSlideResponse,
} from "../../types"
import { GeneralMutationParams } from "utils/types"
import createSlide from "../functions/createSlide"

const useMutationCreateSlide = (
  options?: GeneralMutationParams<
    GeneralSlideResponse,
    FormData
  >
) => {
  return useMutation({
    mutationFn: (payload) => createSlide(payload),
    ...options,
  })
}

export default useMutationCreateSlide
