import { useEffect, useMemo, useRef, useState } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"
import DOMPurify from "dompurify"
import { Helmet } from "react-helmet"

import Typography from "components/ui/typography"
import VideoButton from "components/public/course/detail-course/VideoButton"
import Mentor from "components/public/course/detail-course/Mentor"
import CourseContent from "components/public/course/detail-course/CourseContent"
import Button from "components/ui/button/Button"
import {
  useGetCourseDetail,
  useGetCoursePreviewVideo,
  useGetCourseRoadmap,
} from "utils/api/public/queries/hooks"
import { useUserInfoStore } from "utils/store/user-info"
import VideoPlayer from "components/ui/video-player"
import { FaPlayCircle, FaDotCircle, FaPlay } from "react-icons/fa"
import { Badge } from "flowbite-react"
import { useMediaQuery } from "usehooks-ts"
import Section from "components/ui/section/Section"
import ModalVideo from "components/ui/video-modal"
import { TiTick } from "assets/icons"

const CourseDetail = () => {
  const desktop = useMediaQuery("(min-width: 1024px)")
  const mobile = !desktop

  const { slug } = useParams()
  const isLoggedIn = useUserInfoStore((state) => state.isLoggedIn)
  const navigate = useNavigate()

  const [selectedCourse, setSelectedCourse] = useState<{
    videoID: number
    mediaID: string
  }>()

  const { data: dataDetailCourse } = useGetCourseDetail({ slug: slug ?? "" })

  const { data: dataCourseRoadmaps } = useGetCourseRoadmap({
    options: {
      staleTime: 0,
      enabled: !!dataDetailCourse,
    },
    courseID: dataDetailCourse?.data.id.toString() ?? "",
  })

  const { data: dataCoursePreviewVideos } = useGetCoursePreviewVideo({
    options: {
      staleTime: 0,
      enabled: !!dataDetailCourse,
    },
    courseID: dataDetailCourse?.data.id.toString() ?? "",
  })

  // set initial selected course
  useEffect(() => {
    if (!dataCoursePreviewVideos || dataCoursePreviewVideos.data.length === 0) {
      return
    }

    const course = dataCoursePreviewVideos.data[0]
    setSelectedCourse({
      videoID: course.id,
      mediaID: course.media_id,
    })
  }, [dataCoursePreviewVideos])

  const handleButtonCourse = (videoID: number, mediaID: string) => {
    setSelectedCourse({
      videoID,
      mediaID,
    })
  }

  const handleCheckout = () => {
    if (!dataDetailCourse?.data?.id) return
    navigate(`/checkout/courses/${dataDetailCourse.data.slug}`)
  }

  const totalVideos = useMemo(
    () =>
      dataCourseRoadmaps?.data.reduce<number>((prev, current) => {
        if (current.videos.length > 0) {
          return prev + current.videos.length
        }
        return prev
      }, 0),
    [dataCourseRoadmaps]
  )

  const dataCourse = dataDetailCourse?.data
  const isDiscount =
    dataCourse?.price.master_price_text !== dataCourse?.price.current_price_text

  const discountPercentage = useMemo(() => {
    if (!isDiscount || !dataCourse?.price.master_price_text || !dataCourse?.price.current_price_text) {
      return 0;
    }
    const masterPrice = parseFloat(dataCourse?.price.master_price_text.replace(/\D/g, ''));
    const currentPrice = parseFloat(dataCourse?.price.current_price_text.replace(/\D/g, ''));

    if (isNaN(masterPrice) || isNaN(currentPrice) || masterPrice <= currentPrice) {
      return 0;
    }

    return Math.round(((masterPrice - currentPrice) / masterPrice) * 100);
  }, [dataCourse, isDiscount]);

  const [openModal, setOpenModal] = useState(false);

  // show or hidden text in mobile responsive 

  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  const descriptions = dataCourse?.description ?? "";
  const truncatedDescriptions = showMore ? descriptions : descriptions.slice(0, 200);
  const isLongDescription = descriptions.length < 200;
  return (
    <>
      <Helmet>
        <title>Kelas: {dataDetailCourse?.data.title ?? ""}</title>
      </Helmet>
      <Section withContainer
        className="pt-8 lg:pt-12">
        <div className="flex items-center lg:flex-row flex-col-reverse gap-4 lg:gap-12">
          <div className="flex flex-col justify-between py-4 w-full">
            <div className="w-24 bg-yellow-300 px-2 p-1  text-center text-black">
              Terbaru
            </div>
            <Typography
              variant="heading3"
              className="mb-3 lg:leading-relaxed max-sm:text-2xl my-4"
            >
              {dataCourse?.title}
            </Typography>
            <div className="flex lg:flex-row flex-col gap-4 lg:gap-12">
              <div className="flex flex-col ">
                <Typography
                  variant="text3"
                  className="font-medium mb-2"
                >
                  Mentor :
                </Typography>
                <Typography
                  variant="text2"
                  className="font-medium"
                >
                  {dataCourse?.lecturer}
                </Typography>
              </div>
              <div className="flex flex-col">
                <Typography
                  variant="text3"
                  className="font-medium mb-2"
                >
                  Kategori :
                </Typography>
                <div className="flex flex-wrap gap-2">
                  {dataCourse?.categories?.map((category) => (
                    <Typography
                      key={category.id}
                      variant="text3"
                      className="p-1.5 rounded-md bg-primary font-medium text-white"
                    >
                      #{category.name}
                    </Typography>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {desktop ? (
            <div onClick={() => setOpenModal(true)} className="bg-black w-full h-64 lg:w-[700px] rounded-lg overflow-hidden relative cursor-pointer">
              <img className="object-cover object-center w-full h-full opacity-50" src={dataCourse?.thumbnail} alt="" />
              <div className="grid gap-2 justify-items-center absolute top-[40%] left-[45%] text-white">
                <FaPlayCircle className=" w-8 h-8" />
                <Typography
                  variant="text3"

                >
                  Preview
                </Typography>
              </div>
            </div>
          ) : (
            <div className="bg-black w-full h-64 lg:w-[700px] rounded-lg overflow-hidden relative cursor-pointer">
              {selectedCourse && (
                <VideoPlayer
                  url={`${process.env.REACT_APP_API_URL}/course-videos/preview/${selectedCourse.videoID}/${selectedCourse.mediaID}`}
                />
              )}
            </div>
          )}
          {selectedCourse && (
            <ModalVideo
              openModals={openModal}
              setOpenModals={setOpenModal}
              headers={dataCourse?.title}
              urls={`${process.env.REACT_APP_API_URL}/course-videos/preview/${selectedCourse.videoID}/${selectedCourse.mediaID}`}
            />
          )}
        </div>
      </Section>
      <div className="container lg:my-12 my-12 mt-6 ">
        <div className="my-6">
          <Typography
            variant="heading6"
            as="h2"
            className="mb-4"
          >
            Deskripsi
          </Typography>
          <Typography
            variant="text3"
            className="mb-3"
          >
            <p
              className="text-base font-medium text-justify hidden lg:block"
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(dataCourse?.description ?? "", {
                  USE_PROFILES: { svg: false },
                }),
              }}
            />
            <p
              className=" block lg:hidden"
              dangerouslySetInnerHTML={{ __html: truncatedDescriptions }}
              style={{ overflow: "hidden" }}
            />
            {!isLongDescription && (
              <button
                className="block lg:hidden text-primary font-semibold mt-2"
                onClick={toggleShowMore}
              >
                {showMore ? "Sembunyikan" : "Tampilkan lebih banyak"}
              </button>
            )}
          </Typography>
        </div>
        <div className="">
          <CourseContent
            totalVideos={totalVideos}
            sections={dataCourseRoadmaps?.data ?? []}
          />
        </div>
        <div className="fixed bottom-0 left-0 right-0 z-20  w-full">
          <div className="lg:px-32 px-6 py-3 flex justify-between bg-white shadow-lg border-2">
            <div className="flex gap-2 items-center">
              <span className="font-bold text-lg text-primary">
                {dataCourse?.price.currency_text}
                {dataCourse?.price.current_price_text}
              </span>
              <div className="flex gap-2 items-center">
                <Badge className="text-xs" color="failure">{Math.round(discountPercentage)}%</Badge>
                {isDiscount && (
                  <span className="line-through decoration-2 font-medium text-xs">
                    {dataCourse?.price.currency_text}
                    {dataCourse?.price.master_price_text}
                  </span>
                )}
              </div>
            </div>
            <Button
              size="lg"
              className="text-sm text-center px-2"
              variant="contained"
              as={isLoggedIn ? "button" : Link}
              disabled={isLoggedIn && !dataDetailCourse?.data.buyable}
              {...(isLoggedIn
                ? {
                  onClick: handleCheckout,
                }
                : {
                  to: "/register",
                })}
            >
              {isLoggedIn ?
                (dataDetailCourse?.data.buyable ? "Beli Sekarang" : "Terbeli")
                : "Daftar & Beli Kelas"}
            </Button>
          </div>
        </div>
        {/* <div className="mt-6">
          <Typography
            variant="heading6"
            as="h2"
            className="mb-4"
          >
            Materi yang Anda Dapatkan
          </Typography>
          {desktop && (
            <div className="grid grid-rows-4 grid-cols-2 grid-flow-col gap-2">
              {dataCourseRoadmaps?.data.map((section) => (
                section?.videos.map((video) => (
                  <div className="flex gap-2 w-full items-center" key={video.id}>
                    <div className="rounded-full bg-primary/10 p-1">
                      <TiTick className='text-primary' />
                    </div>
                    {video.title}</div>
                ))
              ))}
            </div>
          )}
          {mobile && (
            <div className="grid gap-2">
              {dataCourseRoadmaps?.data.map((section) => (
                section?.videos.map((video) => (
                  <div className="flex gap-4 w-full items-center" key={video.id}>
                    <div className="rounded-full bg-primary/10 p-1">
                      <TiTick className='text-primary' />
                    </div>
                    {video.title}</div>
                ))
              ))}
            </div>
          )
          }
        </div> */}
      </div>
    </>
  )
}

export default CourseDetail
