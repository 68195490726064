import { requestCMS } from "utils/axiosInstance"
import { GeneralSertifikatProgramResponse } from "utils/api/cms/types"

const deleteSertifikatProgram = async (sertifikatId: string) => {
    const res = await requestCMS.delete<GeneralSertifikatProgramResponse>(
        `/employee/program-sertifikat/${sertifikatId}`
    )
    return res
}

export default deleteSertifikatProgram
