import { useEffect, useState } from "react"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import { getCMSSegments } from "../../../utils/helper"
import { EyeIcon, PencilSquareIcon } from "@heroicons/react/24/solid"
import ReactTable from "components/ui/react-table/ReactTable"
import CMSDefaultTableListFilter from "components/cms/CMSDefaultTableListFilter"
import { PaginationState, createColumnHelper } from "@tanstack/react-table"

import clsxm from "utils/clsxm"
import { useGetTransactions } from "utils/api/cms/queries/hooks"
import { Transaction, TransactionDetail } from "utils/api/cms/types"

import { Tooltip } from "flowbite-react"

const formatter = new Intl.NumberFormat("id-ID", {
  style: "currency",
  currency: "IDR",
})

const UserTransactions: React.FC = () => {
  const navigate = useNavigate()
  const segments = getCMSSegments()
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 1,
  })

  const params = new URLSearchParams(window.location.search)
  const email = params.get("email")
  const [searchFilter, setSearchFilter] = useState("")
  const [perPageLimit, setPerPageLimit] = useState(10)
  const { data } = useGetTransactions({
    options: {
      staleTime: 0,
    },
    qParams: {
      email: email,
      limit: 5,
      page: pagination.pageIndex + 1,
    },
  })

  const handleSearch = (keyword: string) => {
    setSearchFilter(keyword)
  }

  const userTransactionsColHelper = createColumnHelper<Transaction>()
  const userTransactionsColumns = [
    userTransactionsColHelper.accessor("trx_id", {
      header: () => "Nomor Transaksi",
    }),
    userTransactionsColHelper.accessor("transaction_detail", {
      header: () => "Judul",
      cell: (props) => {
        const transactionDetails = props.cell.row.original
          .transaction_detail as TransactionDetail
        const title = transactionDetails.transactionable?.title || "N/A"
        return <span>{title}</span>
      },
    }),
    userTransactionsColHelper.accessor("amount", {
      header: () => "Harga",
      cell: (props) => {
        const amount = props.row.original.amount
        const formattedAmount = formatter.format(Number(amount))

        return <span>{formattedAmount}</span>
      },
    }),
    userTransactionsColHelper.accessor("buyer", {
      header: () => "Pembeli",
      cell: (props) => {
        const { first_name, middle_name, last_name } = props.row.original.buyer
        const fullname = [first_name, middle_name, last_name]
          .filter(Boolean)
          .join(" ")
        return <span>{fullname}</span>
      },
    }),
    userTransactionsColHelper.accessor("created_at", {
      header: () => "Dibuat pada",
    }),
    userTransactionsColHelper.accessor("status", {
      header: () => "Status",
      cell: (props) => {
        const statusOptions = ["PENDING", "SUCCESS", "EXPIRED"]
        const randomIndex = Math.floor(Math.random() * statusOptions.length)
        const status = props.row.original.status
        return (
          <span
            className={clsxm("p-1 rounded", [
              status === "PENDING" && "bg-yellow-500",
              status === "SUCCESS" && "bg-green-500",
              status === "EXPIRED" && "bg-red-500",
            ])}
          >
            {status}
          </span>
        )
      },
    }),
    userTransactionsColHelper.accessor("status", {
      header: () => "Lihat",
      cell: (props) => {
        return (
          <div className="flex flex-row w-fit space-x-3">
            <Tooltip content="Lihat Detail Transaksi">
              <ButtonWithIcon
                class="mx-auto border-2 border-transparent bg-blue-600 text-white hover:bg-blue-500 hover:border-blue-500"
                type="button"
                action={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault()
                  return navigate(`${props.row.original.trx_id}`)
                }}
                sizeClass="p-1"
              >
                <EyeIcon className="h-5 w-5" />
              </ButtonWithIcon>
            </Tooltip>
          </div>
        )
      },
    }),
  ]

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix="cms"
      />
      <h2 className="text-xl text-primary font-semibold leading-loose tracking-wide">
        Daftar Transaksi Pengguna
      </h2>
      <CMSDefaultTableListFilter
        pageSelector
        searchPlaceHolder="Cari nama transaksi"
        wrapperClass="ml-auto flex flex-row space-x-6"
        onSearchHandler={handleSearch}
        perPageHandler={(selected) => setPerPageLimit(selected)}
      />
      <ReactTable
        columns={userTransactionsColumns}
        data={data?.data ?? []}
        pagination={pagination}
        onPaginationChange={setPagination}
        pageCount={data?.meta?.last_page ?? 1}
      />
    </EmployeeDashboardWrapper>
  )
}

export default UserTransactions
