import { Modal } from 'flowbite-react'
import React from 'react'
import VideoPlayer from '../video-player'

const ModalVideo = (prop) => {
  return (
    <>
      <Modal position='center'
        show={prop.openModals} onClose={() => prop.setOpenModals(false)} >
        <Modal.Header className='pb-0  border-none text-lg lg:text-xl lg:gap-6'>{prop.headers}</Modal.Header>
        <Modal.Body className="overflow-y-auto pt-3 px-1 pb-1 lg:p-2">
          <div className="w-full h-72 lg:h-96">
            <VideoPlayer
              url={prop.urls}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default ModalVideo