import { useMutation } from "@tanstack/react-query"

import {
  UploadThumbnailResponse,
  UploadThumbnailCourseRequest,
} from "../../types"
import { uploadThumbnailCourse } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationUploadThumbnailCourse = (
  options?: GeneralMutationParams<
    UploadThumbnailResponse,
    UploadThumbnailCourseRequest
  >
) => {
  return useMutation({
    mutationFn: ({ courseID, payload }) =>
      uploadThumbnailCourse(courseID, payload),
    ...options,
  })
}

export default useMutationUploadThumbnailCourse
