import Button from "components/ui/button/Button"
import Section from "components/ui/section/Section"
import React from "react"
import Img from "assets/images/registered.png"
import { ButtonBase } from "flowbite-react/lib/esm/components/Button/ButtonBase"
import { Link } from "react-router-dom"
import { motion } from "framer-motion"
import { fadeIn } from "components/effect/variant";
const RegisterNow = () => {
  return (
    <>
      <Section withContainer>
        <div className="grid lg:grid-cols-2 gap-6 lg:mx-12 mb-12 items-center overflow-hidden">
          <motion.img
            variants={fadeIn("right", 0.2)}
            initial="initial"
            whileInView={"show"}
            src={Img}
            alt=""
          />
          <motion.div
            variants={fadeIn("left", 0.2)}
            initial="initial"
            whileInView={"show"}
            className="flex flex-col gap-4 h-full justify-center max-sm:mt-8">
            <h1 className="font-bold text-2xl text-tertiary ">
              Melangkahlah ke tahap berikutnya menuju tujuan pribadi dan
              profesionalmu dengan BeePlus
            </h1>
            <h2 className="text-lg font-medium text-gray-600 ">
              Daftar sekarang dan dapatkan rekomendasi khusus dari katalog
              lengkap BeePlus untuk memajukan karirmu
            </h2>
            <Link
              to="/register"
              className="lg:w-[180px] w-full px-4 py-3 text-center bg-primary hover:bg-primary/95 rounded-md text-white text-semibold"
            >
              Daftar Sekarang
            </Link>
          </motion.div>
        </div>
      </Section>
    </>
  )
}

export default RegisterNow
