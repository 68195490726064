import { requestCMS } from "utils/axiosInstance"

import type {
  GeneralReferralRequest,
  GeneralReferralResponse,
} from "../../types"

const updateReferral = async (id: string, payload: GeneralReferralRequest) => {
  const res = await requestCMS.put<GeneralReferralResponse>(
    `/employee/referral/update/${id}`,
    payload
  )
  return res
}

export default updateReferral
