import type {
  UseInfiniteQueryOptions,
  UseQueryOptions,
} from "@tanstack/react-query"

import type {
  GeneralApiResponse,
  Course,
  Webinar,
  ProductCategory,
  GeneralQueryParams,
  Career,
  Slide,
  ProductPrice,
} from "../../types"

export enum PublicQueryKey {
  User = "user",
  Courses = "courses",
  Workshops = "webinars",
  Career = "career",
  CourseCategories = "course-categories",
  WorkshopCategories = "webinar-categories",
  CourseRoadmaps = "course-roadmaps",
  CoursePreviewVideos = "course-preview-videos",
  Slides = 'slide',
  Sertifikasi = 'sertifikasi',
  SertifikasiDetail = 'sertifikasi-detail',
  SertifikasiMateri = 'sertifikasi-materi',
  SertifikatSkkni = "sertifikat-skkni",
}

export interface CourseRoadmap {
  id: number
  title: string
  sequence: number
  videos: Video[]
}

export interface Video {
  id: number
  title: string
  media_id: string
}

export interface Sertifikasi {
  id: number
  name: string
  slug: string
  description: string
  price: ProductPrice
  thumbnail?: string
  materials?:SertifikasiMateri[]
  skkni?:SertifikatSkkni[]
  created_at: string
  updated_at: string
}

export interface SertifikasiMateri {
  id: number
  sertifikat_id: number
  name: string
  code: string
  type: string
  created_at: string
  updated_at: string
}
export interface SertifikatSkkni {
  id: number
  name: string
  sertifikat_id: number
  thumbnail: string
  created_at: string
  updated_at?: string
}

export interface GeneralSertifikasiRequest {
  sertifikat_id: number
  name: string
  email: string
  phone: string
  profesi: string
  program: string
}

export interface GeneralSertifikasiResponse {
  data: {
    sertifikat_id: number
    name: string
    email: string
    phone: string
    profesi: string
    program: string
  }
}

export type PublicGetCourseRoadmapResponse = Omit<
  GeneralApiResponse<CourseRoadmap[]>,
  "success" | "links" | "meta"
>

export type PublicGetCoursePreviewVideoResponse = Omit<
  GeneralApiResponse<Video[]>,
  "success" | "links" | "meta"
>
export type GeneralSertifikasiSkkniResponse = Omit<
  GeneralApiResponse<SertifikatSkkni>,
  "links" | "meta"
>

export type PublicGetCoursesResponse = GeneralApiResponse<Course[]>

export type PublicGetSertifikasiMateriResponse = GeneralApiResponse<SertifikasiMateri[]>

export type PublicGetSertifikasiSkkniResponse = GeneralApiResponse<SertifikatSkkni[]>

export type PublicGetWorkshopsResponse = GeneralApiResponse<Webinar[]>

export type PublicGetCareerReponse = GeneralApiResponse<Career[]>

export type PublicGetCareerResponse = GeneralApiResponse<Career[]>

export type PublicGetCourseDetailResponse = GeneralApiResponse<Course>

export type PublicGetSertifikasiDetailResponse = GeneralApiResponse<Sertifikasi>

export type PublicGetWorkshopDetailResponse = GeneralApiResponse<Webinar>

export type PublicGetCareerDetailResponse = GeneralApiResponse<Career>

export type PublicGetProductCategoriesResponse = GeneralApiResponse<
  ProductCategory[]
>
export type PublicGetSlidePreviewImageResponse = GeneralApiResponse<Slide[]>
export type PublicGetSertifikasiResponse = GeneralApiResponse<Sertifikasi[]>

export type PublicGetProductCategoriesParams = Omit<
  GeneralQueryParams<UseQueryOptions<PublicGetProductCategoriesResponse>>,
  "qParams"
>

export type PublicGetCareerParams = GeneralQueryParams<
  UseQueryOptions<PublicGetCareerReponse>
>

export type PublicGetCoursesParams = GeneralQueryParams<
  UseQueryOptions<PublicGetCoursesResponse>
>

export type PublicGetSertifikasiMateriParams = GeneralQueryParams<
  UseQueryOptions<PublicGetSertifikasiMateriResponse>
>
export type PublicGetSertifikasiSkkniParams = GeneralQueryParams<
  UseQueryOptions<GeneralApiResponse<SertifikatSkkni[]>>
>

export type PublicGetCourseDetailParams = Omit<
  GeneralQueryParams<UseQueryOptions<PublicGetCourseDetailResponse>>,
  "qParams"
> & {
  slug: string
}

export type PublicGetSertifikasiDetailParams = Omit<
  GeneralQueryParams<UseQueryOptions<PublicGetSertifikasiDetailResponse>>,
  "qParams"
> & {
  slug: string
}

export type PublicGetWorkshopDetailParams = Omit<
  GeneralQueryParams<UseQueryOptions<PublicGetWorkshopDetailResponse>>,
  "qParams"
> & {
  slug: string
}

export type PublicGetWorkshopsParams = GeneralQueryParams<
  UseQueryOptions<PublicGetWorkshopsResponse>
>

export type PublicGetCareerDetailParams = Omit<
  GeneralQueryParams<UseQueryOptions<PublicGetCareerDetailResponse>>,
  "qParams"
> & {
  slug: string
}

export type PublicGetInfiniteCoursesParams = GeneralQueryParams<
  UseInfiniteQueryOptions<PublicGetCoursesResponse>
>

export type PublicGetInfiniteWorkshopsParams = GeneralQueryParams<
  UseInfiniteQueryOptions<PublicGetWorkshopsResponse>
>

export type PublicGetInfiniteCareersParams = GeneralQueryParams<
  UseInfiniteQueryOptions<PublicGetCareerResponse>
>

export type PublicGetCourseRoadmapParams = Omit<
  GeneralQueryParams<UseQueryOptions<PublicGetCourseRoadmapResponse>>,
  "qParams"
> & {
  courseID: string
}

export type PublicGetCoursePreviewVideoParams = Omit<
  GeneralQueryParams<UseQueryOptions<PublicGetCoursePreviewVideoResponse>>,
  "qParams"
> & {
  courseID: string
}

export type PublicGetSlidePreviewImageParams =
  GeneralQueryParams<UseQueryOptions<PublicGetSlidePreviewImageResponse>
  >

export type PublicGetSertifikasiParams =
  GeneralQueryParams<UseQueryOptions<PublicGetSertifikasiResponse>
  >
