import { useEffect, useMemo } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"

import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import SelectInput from "components/ui/form/SelectInput"
import TextInput from "components/ui/form/TextInput"
import {
  useGetCareerDetail,
  useGetCareerCategories,
} from "utils/api/cms/queries/hooks"
import {
  useMutationCreateCareer,
  useMutationUpdateCareer,
} from "utils/api/cms/mutations/hooks"
import { GeneralCareerRequest } from "utils/api/cms/types"
import {
  BookmarkIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"

import clsx from "clsx"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import classes from "../../assets/styles/ui/Input.module.css"

type Option = {
  label: string
  value: string | number
}

interface CareerForm {
  title: string
  description: string
  page: string
  location: string
  working_type: Option
  category_ids: Option[]
  company: string
  expiration: string
  status_pekerjaan?: Option
}

const WORKING_TYPE_OPTIONS = [
  { label: "Remote", value: "REMOTE" },
  { label: "On Site", value: "ON_SITE" },
]

const PEKERJAAN_TYPE_OPTIONS = [
  { label: "Full Time", value: "Full Time" },
  { label: "Part Time", value: "Part Time" },
  { label: "Internship", value: "Internship" },
  { label: "Contract", value: "Contract" },
]

const CareerForm = () => {
  const navigate = useNavigate()
  const params = useParams()

  const isUpdate = !!params.id

  const {
    handleSubmit,
    register,
    control,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<CareerForm>({
    defaultValues: {
      working_type: {
        label: "Remote",
        value: "REMOTE",
      },
    },
  })

  const { data: dataCareerCategories } = useGetCareerCategories({})

  const { data: dataDetailCareer } = useGetCareerDetail({
    id: params.id ?? "",
    options: {
      enabled: isUpdate,
    },
  })

  const { mutate: mutateCreateCareer } = useMutationCreateCareer({
    onSuccess(data) {
      if (!data.success) return
      toast.success("Karir berhasil dibuat")
      setTimeout(() => {
        navigate("/cms/career")
      }, 1000)
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  const { mutate: mutateUpdateCareer } = useMutationUpdateCareer({
    onSuccess(data) {
      if (!data.success) return
      toast.success("Karir berhasil diubah")
      setTimeout(() => {
        navigate("/cms/career")
      }, 1000)
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  useEffect(() => {
    if (!dataDetailCareer || !isUpdate) return

    const detailCareer = dataDetailCareer.data

    reset({
      title: detailCareer.title,
      description: detailCareer.description,
      page: detailCareer.page,
      location: detailCareer.location,
      working_type: WORKING_TYPE_OPTIONS.find(
        (option) => option.value === detailCareer.working_type
      ),
      company: detailCareer.company,
      expiration: detailCareer.expiration,
      category_ids: detailCareer.categories.map((c) => ({
        label: c.name,
        value: c.id,
      })),
      status_pekerjaan: PEKERJAAN_TYPE_OPTIONS.find(
        (option) => option.value === detailCareer.status_pekerjaan
      ),
    })
  }, [dataDetailCareer])

  const careerCategoryOptions = useMemo(
    () =>
      dataCareerCategories?.data.map((category) => ({
        label: category.name,
        value: category.id,
      })),
    [dataCareerCategories]
  )

  const onSubmit: SubmitHandler<CareerForm> = (data) => {
    if (
      data.working_type.value !== "ON_SITE" &&
      data.working_type.value !== "REMOTE"
    ) {
      return
    }


    const payload: GeneralCareerRequest = {
      title: data.title,
      description: data.description,
      page: data.page,
      location: data.location,
      working_type: data.working_type.value,
      company: data.company,
      category_ids: [data.category_ids["value"]],
      status_pekerjaan: data.status_pekerjaan ? String(data.status_pekerjaan.value) : ""
    }

    if (!isUpdate) {
      return mutateCreateCareer(payload)
    }

    mutateUpdateCareer({
      id: params.id ?? "",
      payload: payload,
    })
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-4 pb-4 min-w-[42rem]"
    >
      <TextInput
        type="text"
        className="cms-base-input"
        labelClass="cms-base-label"
        isRequired
        label="Title Karir"
        errorMessage={errors.title?.message}
        errorIcon={
          <ExclamationTriangleIcon
            height={24}
            width={24}
            className="text-red-800"
          />
        }
        {...register("title", {
          required: {
            value: true,
            message: "Nama karir wajib diisi",
          },
        })}
      />
      <Controller
        control={control}
        name="working_type"
        rules={{
          required: { value: true, message: "Tipe pekerjaan wajib dipilih" },
        }}
        render={({ field }) => (
          <SelectInput
            {...field}
            label="Pilih Tipe Pekerjaan"
            labelClass="cms-base-label"
            errorMessage={errors.working_type?.message}
            errorIcon={
              <ExclamationTriangleIcon
                height={24}
                width={24}
                className="text-red-800"
              />
            }
            classNames={{
              container: () => "max-w-2xl",
            }}
            options={WORKING_TYPE_OPTIONS}
            isRequired
          />
        )}
      />
      <Controller
        control={control}
        name="status_pekerjaan"
        render={({ field }) => (
          <SelectInput
            {...field}
            label="Pilih Status Pekerjaan"
            labelClass="cms-base-label"
            errorMessage={errors.status_pekerjaan?.message}
            errorIcon={
              <ExclamationTriangleIcon
                height={24}
                width={24}
                className="text-red-800"
              />
            }
            classNames={{
              container: () => "max-w-2xl",
            }}
            options={PEKERJAAN_TYPE_OPTIONS}
          />
        )}
      />
      <Controller
        control={control}
        name="category_ids"
        rules={{
          required: { value: true, message: "Kategori karir dipilih" },
        }}
        render={({ field }) => (
          <SelectInput
            {...field}
            label="Pilih Kategori Karir"
            labelClass="cms-base-label"
            errorMessage={errors.category_ids?.message}
            errorIcon={
              <ExclamationTriangleIcon
                height={24}
                width={24}
                className="text-red-800"
              />
            }
            classNames={{
              container: () => "max-w-2xl",
            }}
            options={careerCategoryOptions}
            isRequired
          />
        )}
      />
      <div className="sm:col-span-2">
        <label className={clsx("cms-base-label", classes.required)}>
          Deskripsi Karir
        </label>
        <div
          className={clsx(
            "cms-base-input max-w-2xl",
            errors.description && "mb-2"
          )}
        >
          <Controller
            control={control}
            defaultValue=""
            name="description"
            rules={{
              required: "Deskripsi tidak boleh kosong!",
              minLength: {
                message: "Deskripsi minimal harus 20 karakter!",
                value: 20,
              },
            }}
            render={({ field: { onChange, value, ref, onBlur } }) => (
              <ReactQuill
                ref={ref}
                onBlur={onBlur}
                value={value}
                onChange={(text) => onChange(text)}
                theme="snow"
                modules={{
                  toolbar: [
                    [{ header: [1, 2, false] }],
                    ["bold", "italic", "underline", "strike", "blockquote"],
                    [
                      { list: "ordered" },
                      { list: "bullet" },
                      { indent: "-1" },
                      { indent: "+1" },
                    ],
                    ["link"],
                    ["clean"],
                  ],
                }}
                formats={[
                  "header",
                  "bold",
                  "italic",
                  "underline",
                  "strike",
                  "blockquote",
                  "list",
                  "bullet",
                  "indent",
                  "link",
                ]}
              />
            )}
          />
        </div>
      </div>
      <TextInput
        type="text"
        className="cms-base-input"
        labelClass="cms-base-label"
        isRequired
        label="Halaman"
        errorMessage={errors.page?.message}
        errorIcon={
          <ExclamationTriangleIcon
            height={24}
            width={24}
            className="text-red-800"
          />
        }
        {...register("page", {
          required: {
            value: true,
            message: "Halaman wajib diisi",
          },
        })}
      />
      <TextInput
        type="text"
        className="cms-base-input"
        labelClass="cms-base-label"
        isRequired
        label="Perusahaan"
        errorMessage={errors.company?.message}
        errorIcon={
          <ExclamationTriangleIcon
            height={24}
            width={24}
            className="text-red-800"
          />
        }
        {...register("company", {
          required: {
            value: true,
            message: "Perusahaan wajib diisi",
          },
        })}
      />
      <TextInput
        type="text"
        className="cms-base-input"
        labelClass="cms-base-label"
        isRequired
        label="Lokasi"
        errorMessage={errors.location?.message}
        errorIcon={
          <ExclamationTriangleIcon
            height={24}
            width={24}
            className="text-red-800"
          />
        }
        {...register("location", {
          required: {
            value: true,
            message: "Lokasi wajib diisi",
          },
        })}
      />
      <ButtonWithIcon
        action={undefined}
        type="submit"
        class="self-start mt-12 border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
        disabled={isSubmitting}
      >
        <BookmarkIcon className="h-6 w-6 text-white" />
        <span>
          {!isSubmitting ? (
            <>{isUpdate ? "Simpan" : "Tambahkan"}</>
          ) : (
            "Menyimpan..."
          )}
        </span>
      </ButtonWithIcon>
    </form>
  )
}

export default CareerForm
