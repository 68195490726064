import clsx from "clsx"
import React from "react"

const BasicValidationMessage: React.FC<{
  message: string
  type: "success" | "error" | "warn" | "info"
  icon?: React.ReactNode
}> = (props) => {
  return (
    <div
      className={clsx(
        props.type === "error" && "text-red-800",
        "flex text-sm items-center space-x-2"
      )}
    >
      {props.icon}
      <span className="font-medium">{props.message}</span>
    </div>
  )
}

export default BasicValidationMessage
