import { Spinner } from "flowbite-react"

import clsxm from "utils/clsxm"

type ButtonProps<T extends React.ElementType> = {
  as?: T
  variant?: "contained" | "outlined"
  color?: "primary" | "secondary" | "tertiary"
  size?: "sm" | "md" | "lg"
  fullWidth?: boolean
  className?: string
  isLoading?: boolean
  children: React.ReactNode
} & React.ComponentPropsWithoutRef<T>

type ButtonComponent = <T extends React.ElementType = "button">(
  props: ButtonProps<T>
) => React.ReactElement | null

const Button: ButtonComponent = <T extends React.ElementType = "button">({
  as,
  variant = "contained",
  color = "primary",
  size = "md",
  fullWidth = false,
  className,
  isLoading,
  children,
  ...restProps
}: ButtonProps<T>) => {
  const Component = as || "button"
  return (
    <Component
      className={clsxm(
        "font-medium",
        "rounded",
        "transition-all",
        "hover:brightness-95",
        "active:brightness-90",
        [
          variant === "contained" && [
            ["text-white"],
            color === "primary" && ["bg-primary border border-primary"],
            color === "secondary" && ["bg-secondary border border-secondary"],
            color === "tertiary" && ["bg-tertiary border border-tertiary"],
            [
              (restProps.disabled || isLoading) &&
                "text-gray-500 bg-gray-300 border-gray-300 active:brightness-100 hover:brightness-100",
            ],
          ],
          variant === "outlined" && [
            color === "primary" && ["text-primary border border-primary"],
            color === "secondary" && ["text-secondary border border-secondary"],
            color === "tertiary" && ["text-tertiary border border-tertiary"],
            [
              (restProps.disabled || isLoading) &&
                "text-gray-500 border-gray-300 active:brightness-100 hover:brightness-100",
            ],
          ],
          [fullWidth && "w-full"],
        ],
        [
          size === "sm" && ["text-xs px-2 py-1"],
          size === "md" && ["text-base px-3 py-1.5"],
          size === "lg" && ["text-xl px-4 py-2"],
        ],
        className
      )}
      disabled={restProps.disabled || isLoading}
      {...restProps}
    >
      {isLoading && <Spinner className="mr-2" />}
      {children}
    </Component>
  )
}

export default Button
