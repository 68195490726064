import { useQuery } from "@tanstack/react-query"
import { CmsQueryKey, GetWebinarDetailParams } from "../../types"
import { getWebinarDetail } from "../functions"

const useGetWebinarDetail = (params?: GetWebinarDetailParams) => {
  return useQuery({
    queryKey: [CmsQueryKey.WebinarDetail, params?.id ?? ""],
    queryFn: () => getWebinarDetail(params?.id),
  })
}

export default useGetWebinarDetail
