import { requestCMS } from "utils/axiosInstance"
import type { GeneralCourseCategoryResponse } from "../../types"

const getCourseCategoryDetail = async (id?: string) => {
  const res = await requestCMS.get<GeneralCourseCategoryResponse>(
    `/employee/course-categories/view/${id}`
  )
  return res
}

export default getCourseCategoryDetail
