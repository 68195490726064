import { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
    PencilSquareIcon,
    EyeIcon,
    BookOpenIcon,
    DocumentIcon,
    DocumentPlusIcon,
} from "@heroicons/react/24/solid"
import { PaginationState, createColumnHelper } from "@tanstack/react-table"
import { Tooltip } from "flowbite-react"
import ReactTable from "components/ui/react-table/ReactTable"
import { useGetSertifikat } from "utils/api/cms/queries/hooks"
import type { Sertifikat } from "utils/api/cms/types"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import FileInput from "components/ui/form/FileInput"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import ReactSimpleImageViewer from "react-simple-image-viewer"
import { toast } from "react-toastify"
import {
    useMutationUploadSertifikat,
} from "utils/api/cms/mutations/hooks"
import { CMSSertifikatListResponse } from "utils/types"
import CMSDefaultTableListFilter from "../CMSDefaultTableListFilter"
import Button from "components/ui/button/Button"

const SertifikatList = () => {
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 1,
    })
    const formatter = new Intl.NumberFormat("id-ID")
    const [lastPage, setLastPage] = useState(1)
    const [sertifikatResponse, setSertifikatResponse] =
        useState<CMSSertifikatListResponse<Sertifikat>>()
    const [searchFilter, setSearchFilter] = useState("")
    const [perPageLimit, setPerPageLimit] = useState(10)
    const navigate = useNavigate()
    const [previewThumbnailID, setPreviewThumbnailID] = useState<number>()
    const [previewImages, setPreviewImages] = useState<string[]>([])
    const [isPreviewOpen, setIsPreviewOpen] = useState(false)
    const [dataSertifikat, setDataSertifikat] = useState<Sertifikat[]>([])

    const { data: resultResponse } = useGetSertifikat({
        options: {
            staleTime: 0,
        },
        qParams: {
            limit: perPageLimit,
            page: pagination.pageIndex + 1,
            keyword: searchFilter,
        },
    })

    useEffect(() => {
        setSertifikatResponse(resultResponse)
        setDataSertifikat(resultResponse?.data || [])
        setLastPage(resultResponse?.meta?.last_page || 1)
    }, [resultResponse])

    useEffect(() => {
        const dataIcons = dataSertifikat
            ?.map((sertifikat) => sertifikat.thumbnail)
            .filter((thumbnail): thumbnail is string => thumbnail !== undefined);
        setPreviewImages(dataIcons || []);
    }, [dataSertifikat]);


    const { mutate: mutateUploadFile } = useMutationUploadSertifikat({
        onSuccess(data, variables) {
            if (!data.success) return

            toast.success("Berhasil upload thumbnail")
            setDataSertifikat((prev) => {
                const tempData = prev.map((d) => {
                    if (d.id === Number(variables.sertifikatId)) {
                        d.thumbnail = ""
                    }
                    return d
                })
                return tempData
            });
        },
    })

    const handleDeleteFile = async (sertifikatId: number) => {
        setDataSertifikat((prev) => {
            const tempData = prev.map((d) => {
                if (d.id == sertifikatId) {
                    d.thumbnail = ""
                }
                return d
            })
            return tempData
        })
    }

    const handleChangeFile =
        (sertifikatId: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
            const file = e.target.files?.[0]
            if (!file) return
            mutateUploadFile({
                sertifikatId: sertifikatId,
                payload: { thumbnail: file },
            })
        }

    const handlePreview = (index?: number) => {
        setPreviewThumbnailID(index)
        setIsPreviewOpen((prev) => !prev)
    }

    const sertifikatColumnHelper = createColumnHelper<Sertifikat>()

    const sertifikatColumns = [
        sertifikatColumnHelper.accessor("name", {
            header: "Nama",
            cell: (props) => props.getValue()
        }),
        sertifikatColumnHelper.accessor("thumbnail", {
            header: "Ikon",
            cell: (props) => (
                <div className="relative w-[120px] h-[85px]">
                    <FileInput
                        label="Upload Gambar"
                        name="thumbnail"
                        value={props.getValue()}
                        onChange={handleChangeFile(props.row.original.id.toString())}
                        onDelete={() => handleDeleteFile(props.row.original.id)}
                        onPreview={() => handlePreview(props.row.index)}
                        accept="image/png, image/jpg"
                    />
                </div>
            ),
        }),
        sertifikatColumnHelper.accessor("price", {
            header: "Harga",
            cell: (props) => (
                <dl className="max-w-md text-white divide-y divide-gray-200">
                    <div className="flex flex-col pb-3">
                        <dt className="mb-1 text-gray-400 md:text-lg">Harga Asli</dt>
                        <dd className="text-lg font-semibold">{`${props.getValue().currency_text
                            } ${props.getValue().master_price_text}`}</dd>
                    </div>
                    <div className="flex flex-col pb-3">
                        <dt className="mb-1 text-gray-400 md:text-lg">Harga Terkini</dt>
                        <dd className="text-lg font-semibold">{`${props.getValue().currency_text
                            } ${props.getValue().current_price_text}`}</dd>
                    </div>
                </dl>
            )
        }),
        sertifikatColumnHelper.accessor("created_at", {
            header: "Dibuat Pada",
            cell: (props) => props.getValue(),
        }),
        sertifikatColumnHelper.display({
            id: "actions",
            header: "Aksi",
            cell: (props) => (
                <div className="flex flex-row w-fit space-x-3">
                    <Tooltip content="Edit">
                        <ButtonWithIcon
                            class="mx-auto border-2 border-transparent bg-yellow-400 text-white hover:bg-yellow-300 hover:border-yellow-300"
                            type="button"
                            action={(e: React.MouseEvent<HTMLButtonElement>) => {
                                e.preventDefault()
                                return navigate(`edit/${props.row.original.id}`)
                            }}
                            sizeClass="p-1"
                        >
                            <PencilSquareIcon className="h-5 w-5" />
                        </ButtonWithIcon>
                    </Tooltip>
                    <Tooltip content="Detail">
                        <ButtonWithIcon
                            class="mx-auto border-2 border-transparent bg-blue-600 text-white hover:bg-blue-500 hover:border-blue-500"
                            type="button"
                            action={(e: React.MouseEvent<HTMLButtonElement>) => {
                                e.preventDefault()
                                return navigate(`detail/${props.row.original.id}`)
                            }}
                            sizeClass="p-1"
                        >
                            <EyeIcon className="h-5 w-5" />
                        </ButtonWithIcon>
                    </Tooltip>
                    <Tooltip content="Peserta">
                        <ButtonWithIcon
                            class="mx-auto border-2 border-transparent bg-purple-600 text-white hover:bg-purple-500 hover:border-purple-500"
                            type="button"
                            action={(e: React.MouseEvent<HTMLButtonElement>) => {
                                e.preventDefault()
                                return navigate(`peserta/${props.row.original.id}`)
                            }}
                            sizeClass="p-1"
                        >
                            <BookOpenIcon className="h-5 w-5" />
                        </ButtonWithIcon>
                    </Tooltip>
                    <Tooltip content="Dokumen SKKNI">
                        <ButtonWithIcon
                            class="mx-auto border-2 border-transparent bg-blue-600 text-white hover:bg-blue-500 hover:border-blue-500"
                            type="button"
                            action={(e: React.MouseEvent<HTMLButtonElement>) => {
                                e.preventDefault()
                                return navigate(`document/${props.row.original.id}`)
                            }}
                            sizeClass="p-1"
                        >
                            <DocumentIcon className="h-5 w-5" />
                        </ButtonWithIcon>
                    </Tooltip>
                </div>
            ),
        }),
    ]

    const handleSearch = (keyword: string) => {
        setSearchFilter(keyword)
        setPagination((prevPagination) => ({ ...prevPagination, pageIndex: 0 }))
    }

    return (
        <>
            <div className="flex flex-wrap justify-between gap-4 my-6">
                <Button
                    as={Link}
                    to="create"
                    className="border-2 flex items-center border-transparent bg-primary text-white gap-2 hover:bg-transparent hover:text-white hover:border-primary"
                >
                    <DocumentPlusIcon className="h-6 w-6 text-white" />
                    <span>Tambah Sertifikasi</span>
                </Button>
                <CMSDefaultTableListFilter
                    pageSelector
                    searchPlaceHolder="Cari nama sertifikasi"
                    wrapperClass="ml-auto flex flex-row space-x-6"
                    onSearchHandler={handleSearch}
                    perPageHandler={(selected) => setPerPageLimit(selected)}
                />
            </div>
            <ReactTable
                columns={sertifikatColumns}
                data={dataSertifikat ?? []}
                pagination={pagination}
                onPaginationChange={setPagination}
                pageCount={lastPage}
            />

            {isPreviewOpen && (
                <ReactSimpleImageViewer
                    src={previewImages ?? []}
                    currentIndex={previewThumbnailID}
                    onClose={handlePreview}
                    disableScroll
                    backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)",
                    }}
                    closeOnClickOutside={true}
                />
            )}
        </>
    )
}

export default SertifikatList
