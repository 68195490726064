import { useMutation } from "@tanstack/react-query"

import { DeleteSectionResponse } from "../../types"
import { deleteSection } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useDeleteSection = (
  options?: GeneralMutationParams<DeleteSectionResponse, string>
) => {
  return useMutation({
    mutationFn: (sectionID) => deleteSection(sectionID),
    ...options,
  })
}

export default useDeleteSection
