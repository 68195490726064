import ReferralForm from "components/cms/ReferralForm"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import Typography from "components/ui/typography"
import { getCMSSegments } from "utils/helper"

const ReferralUpdate = () => {
  const segments = getCMSSegments()

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix="cms"
      />
      <Typography
        variant="subheading4"
        as="h1"
        className="text-primary"
      >
        Update Referral
      </Typography>

      <ReferralForm />
    </EmployeeDashboardWrapper>
  )
}

export default ReferralUpdate
