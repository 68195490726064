import { requestCMS } from "utils/axiosInstance"
import type {
  GeneralCourseCategoryRequest,
  GeneralCourseCategoryResponse,
} from "../../types"

const updateCareerCategory = async (
  id: string,
  payload: GeneralCourseCategoryRequest
) => {
  const res = await requestCMS.put<GeneralCourseCategoryResponse>(
    `/employee/course-categories/update/${id}`,
    payload
  )
  return res
}

export default updateCareerCategory
