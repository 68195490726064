import { requestCMS } from "utils/axiosInstance"
import type { DiscountCourseResponse } from "../../types"
import { QueryParams } from "utils/types"

const getCoursesDiscount = async (qParams?: QueryParams) => {
  const res = await requestCMS.get<DiscountCourseResponse>(
    "/employee/discount/courses",
    qParams
  )
  return res
}

export default getCoursesDiscount
