import { Link } from "react-router-dom"
import { useMediaQuery } from "usehooks-ts"
import Typography from "components/ui/typography"
import noImage from "assets/images/no-image.jpg"
import { Badge } from "flowbite-react"

interface CourseCardProps {
    to: string
    imgSrc: string
    title: string
    currency?: string
    masterPrice?: string
    currentPrice?: string
    width?: number
    height?: number
    className?: string
}

const SertifikatCard = ({
    to,
    imgSrc,
    title,
    currency,
    masterPrice,
    currentPrice,
    width,
    height,
    className
}: CourseCardProps) => {
    const desktop = useMediaQuery("(min-width: 1024px)")
    const masterPriceNumeric = parseFloat(masterPrice || "")
    const currentPriceNumeric = parseFloat(currentPrice || "")
    const isDiscount = masterPriceNumeric !== currentPriceNumeric
    const discountPercentage = isDiscount
        ? ((masterPriceNumeric - currentPriceNumeric) / masterPriceNumeric) * 100
        : 0

    return (
        <Link to={to}
            className={`${desktop ? 'w-[290px] w-100 max-h-[320px]' : 'w-full w-100'} mb-4 rounded-lg hover:shadow-lg flex flex-col transition-all duration-300`}>
            <div className="rounded-lg overflow-hidden ">
                <div className={`rounded-lg overflow-hidden shadow-lg  ${desktop ? 'h-72' : 'h-[250px]'}`}>
                    <img className="w-full" src={imgSrc ?? noImage} alt={title} />
                </div>
            </div>
            <div className="w-full lg:h-full flex flex-col justify-between border rounded-b-lg">
                <div className="pt-4 pb-2 flex flex-col gap-2 px-4">
                    <Typography
                        className="text-primary font-bold flex space-x-1">
                        <span>
                            {currency}
                            {currentPrice}
                        </span>
                        {isDiscount && (
                            <span className="line-through decoration-2 text-gray-400 font-medium text-base">
                                {currency}
                                {masterPrice}
                            </span>
                        )}
                        {isDiscount && (
                            <Badge color="failure">{discountPercentage.toFixed(0)}%</Badge>
                        )}
                    </Typography>
                    <Typography
                        variant="text2"
                        className="text-tertiary font-bold">
                        {title}
                    </Typography>
                </div>
                <Link to="daftar" className="w-full mt-auto border-t text-white rounded-b-lg py-3 lg:py-2 bg-primary lg:text-tertiary lg:bg-white lg:hover:bg-primary hover:text-white transition-all duration-300">
                    <Typography
                        variant="text2"
                        className="font-semibold text-center">
                        DAFTAR SEKARANG
                    </Typography>
                </Link>
            </div>
        </Link>
    )
}

export default SertifikatCard
