import clsx from "clsx";
import React, { FC } from "react";

const ButtonWithIcon: FC<{
  type: "button" | "submit" | "reset" | undefined;
  action: React.MouseEventHandler<HTMLButtonElement> | undefined;
  children: React.ReactNode;
  class?: string;
  disabled?: boolean;
  sizeClass?: string;
  isLoading?: boolean; // Tambahkan properti isLoading dalam definisi tipe
}> = (props) => {
  return (
    <button
      type={props.type}
      onClick={props.action}
      className={clsx(
        props.class,
        !props.sizeClass && "px-5 py-2.5",
        props.sizeClass && props.sizeClass,
        "font-medium rounded-lg text-sm text-center inline-flex items-center",
        {
          "bg-slate-400 hover:bg-slate-400 hover:border-slate-400":
            props.disabled || props.isLoading, // Sesuaikan kondisi disabled
        }
      )}
      disabled={props.disabled || props.isLoading} // Sesuaikan kondisi disabled
    >
      {props.children}
    </button>
  );
};

export default ButtonWithIcon;
