import { requestUser } from "utils/axiosInstance"
import { ReferralReq, ReferralResponse } from "utils/types"

const referral = async (payload: ReferralReq) => {
  const res = await requestUser.post<ReferralResponse>(
    "/user/buy/apply-referral",
    payload
  )
  return res
}

export default referral
