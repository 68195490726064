import { useQuery } from "@tanstack/react-query"

import { getVideoDetail } from "../functions"
import { CmsQueryKey, GetVideoDetailParams } from "../../types"

const useGetVideoDetail = (params?: GetVideoDetailParams) => {
  return useQuery({
    queryKey: [CmsQueryKey.VideoDetail, params?.videoID ?? ""],
    queryFn: () => getVideoDetail(params?.videoID ?? ""),
    ...params?.options,
  })
}

export default useGetVideoDetail
