import { UseInfiniteQueryOptions, UseQueryOptions } from "@tanstack/react-query"
import {
  CourseSection,
  CourseSectionVideo,
  GeneralApiResponse,
  GeneralQueryParams,
} from "utils/types"
import { CareerUsers } from "../cms/types"

export enum UserQueryKey {
  PurchasedCourses = "purchased-courses",
  PurchasedWebinar = "purchased-webinars",
  CourseSections = "course-sections",
  CourseSectionVideos = "course-section-videos",
  CourseVideo = "course-video",
  TransactionHistories = "transaction",
}

export interface UserInfo {
  id: number
  email: string
  first_name: string
  middle_name: string
  last_name: string
  phone?: string
  created_at: string
  updated_at: string
}

export interface PurchasedCourse {
  id: number
  title: string
  slug: string
  description: string
  thumbnail: string | null
  lecturer: string | null
}

export interface PurchasedWebinar {
  id: number
  title: string
  slug: string
  description: string
  thumbnail: string | null
}

export interface TransactionHistory {
  id: number
  trx_id: string
  amount: number
  status: string
  snap_token: string
  external_status: string
  paid_at: string
  created_at: string
  updated_at: string
  transaction_detail: TransactionDetail
  no_invoice: string
}

export interface TransactionDetail {
  id: number
  transactionable_type: string
  original_price: number
  price: number
  transactionable?: Transcationable
}

export interface Transcationable {
  id: number
  title: string
  slug: string
}

export interface AuthenticationData {
  user: UserInfo
  token: string
}

export interface AuthenticationRequest {
  email: string
  password: string
}

export interface RegisterNamePayload {
  first_name: string
  middle_name?: string
  last_name: string
}

export interface RegisterRequest {
  email: string
  first_name: string
  middle_name?: string
  last_name: string
  password: string
  phone: string
  password_confirmation: string
}

export interface KarirUserRequest {
  career_id: number
  email: string
  name: string
  address: string
  cv: File
  portofolio?: File
}

export interface ActivationRequest {
  email?: string | null
  verify_token?: string
}

export interface ResendRequest {
  email?: string | null
}

export interface RegisterFormPayload {
  email: string
  full_name: string
  phone: string
  password: string
  password_confirmation: string
}

export interface KarirUserPayload {
  career_id: number
  email: string
  name: string
  address: string
  cv: File
  portofolio?: File
}

export interface SertifikasiPayload {
  sertifikat_id: number
  name: string
  email: string
  phone: string
  profesi: string
  program: string
}

export interface ForgotPasswordRequest {
  email: string
}

export interface ValidateResetPasswordRequest {
  email: string
  token: string
}

export interface ValidateResetPasswordData {
  valid: boolean
}

export interface GeneralResetPasswordPayload {
  email: string
  password: string
  password_confirmation: string
}

export interface ResetPasswordRequest {
  token: string
  payload: GeneralResetPasswordPayload
}

export interface UpdatePasswordRequest {
  current_password: string
  new_password: string
  new_password_confirmation: string
}

export type GetPurchasedCoursesParams = GeneralQueryParams<
  UseQueryOptions<GetPurchasedCoursesResponse>
>
export type getTransactionHistoriesParams = GeneralQueryParams<
  UseQueryOptions<GeneralApiResponse<TransactionHistory[]>>
>

export type GetCourseSectionParams = Omit<
  GeneralQueryParams<UseQueryOptions<GetCourseSectionResponse>>,
  "qParams"
> & { id: string }

export type GetCourseSectionVideosParams = Omit<
  GeneralQueryParams<UseQueryOptions<GetCourseSectionVideosResponse>>,
  "qParams"
> & { sectionID: string }

export type GetCourseVideoParams = Omit<
  GeneralQueryParams<UseQueryOptions<string>>,
  "qParams"
> & { videoID: string }

export type GetInfinitePurchasedCoursesParams = GeneralQueryParams<
  UseInfiniteQueryOptions<GetPurchasedCoursesResponse>
>

export type GetPurchasedCoursesResponse = GeneralApiResponse<PurchasedCourse[]>

export type GetInfinitePurchasedWebinarsParams = GeneralQueryParams<
  UseInfiniteQueryOptions<GetPurchasedWebinarResponse>
>

export type GetPurchasedWebinarResponse = GeneralApiResponse<PurchasedWebinar[]>

export type GetCourseSectionResponse = Omit<
  GeneralApiResponse<CourseSection[]>,
  "links" | "meta"
>

export type GetCourseSectionVideosResponse = Omit<
  GeneralApiResponse<CourseSectionVideo[]>,
  "links" | "meta"
>

export type GetTransactionHistoriesResponse = GeneralApiResponse<
  TransactionHistory[]
>

export type PublicUpdatePasswordResponse = Omit<
  GeneralApiResponse<null>,
  "links" | "meta" | "data"
>

export type PublicResetPasswordResponse = Omit<
  GeneralApiResponse<null>,
  "links" | "meta" | "data"
>

export type PublicRegisterUserResponse = Omit<
  GeneralApiResponse<UserInfo>,
  "links" | "meta"
>

export type PublicCareerUserResponse = Omit<
  GeneralApiResponse<CareerUsers[]>,
  "links" | "meta"
>

export type PublicActivationEmailResponse = Omit<
  GeneralApiResponse<null>,
  "links" | "meta" | "data"
>

export type PublicResendEmailResponse = Omit<
  GeneralApiResponse<null>,
  "links" | "meta" | "data"
>

export type PublicAuthenticateUserResponse = Omit<
  GeneralApiResponse<AuthenticationData>,
  "links" | "meta"
>

export type PublicForgotPasswordUserResponse = Omit<
  GeneralApiResponse<AuthenticationData>,
  "links" | "meta"
>

export type PublicValidateResetPasswordResponse = Omit<
  GeneralApiResponse<ValidateResetPasswordData>,
  "links" | "meta"
>
