import { requestCMS } from "utils/axiosInstance"
import { DeactivateDiscountResponse } from "../../types"

const deactivateDiscount = async (discountID: string) => {
  const res = await requestCMS.post<DeactivateDiscountResponse>(
    `/employee/discount/deactivate/${discountID}`,
    {}
  )
  return res
}

export default deactivateDiscount
