import { useQuery } from "@tanstack/react-query"
import { CmsQueryKey } from "../../types"
import { getReferralDetail } from "../functions"

const useGetReferralDetail = (referral_Id: number) => {
  return useQuery({
    queryKey: [CmsQueryKey.Users, referral_Id],
    queryFn: () => getReferralDetail(referral_Id),
  })
}

export default useGetReferralDetail
