import clsx from "clsx"

const CardHorizontalSkeleton = () => {
  return (
    <div
      className={clsx(
        "flex justify-between",
        "w-[436px]",
        "px-5 py-8",
        "rounded-lg",
        "shadow",
        "animate-pulse"
      )}
    >
      <div className="w-full space-y-2">
        <div className={clsx("w-1/6 h-4", "rounded-full", "bg-slate-200")} />
        <div className={clsx("w-2/3 h-5", "rounded-full", "bg-slate-200")} />
        <div className={clsx("w-1/2 h-5", "rounded-full", "bg-slate-200")} />
      </div>
      <div className="flex flex-col justify-center items-center w-1/3 space-y-2">
        <div className={clsx("w-1/2 h-8", "rounded-full", "bg-slate-200")} />
        <div className={clsx("w-2/3 h-3", "rounded-full", "bg-slate-200")} />
        <div className={clsx("w-full h-4", "rounded-full", "bg-slate-200")} />
      </div>
    </div>
  )
}

export default CardHorizontalSkeleton
