import clsx from "clsx"

import { ReactComponent as BPILogo } from "assets/svg/LogoBPI_Secondary.svg"
import Typography from "components/ui/typography"
import { LinkedInIcon, InstagramIcon, TiktokIcon } from "assets/icons"

const GeneralPublicFooter = () => {
  return (
    <footer
      className={clsx(
        "relative bg-white z-[1] text-center md:text-left",
        "py-12 md:p-24",
        "shadow-t-sm"
      )}
    >
      <div className="container flex flex-wrap items-center justify-center gap-16">
        <div
          className={clsx(
            "md:grid md:grid-cols-[1fr,5fr]",
            "min-h-[160px] max-w-[490px]"
          )}
        >
          <BPILogo
            className={clsx(
              "hidden self-center",
              "w-[72px] h-[72px]",
              "md:block"
            )}
          />
          <div>
            <Typography
              variant="heading6"
              as="h3"
              className="text-primary mb-5"
            >
              About
            </Typography>
            <Typography variant="text3" className="text-tertiary">
              BeePlus Institute (BPI) adalah platform belajar digital yang
              praktis dan inovatif untuk membantu mencapai peningkatan
              keterampilan para pekerja, new joiner, dan yang lainnya.{" "}
            </Typography>
          </div>
        </div>
        <div className="min-h-[160px]">
          <Typography
            variant="heading6"
            as="h3"
            className="text-primary mb-5"
          >
            Social Media
          </Typography>
          <ul className="flex flex-col items-center gap-6 md:items-start">
            <li className="flex gap-1.5">
              <TiktokIcon />
              <a
                href="https://www.tiktok.com/@beeplus.id"
                className="text-tertiary"
                target="_blank"
              >
                @beeplus.id
              </a>
            </li>
            <li className="flex gap-1.5">
              <InstagramIcon />
              <a
                href="https://www.instagram.com/beeplus.id/"
                className="text-tertiary"
                target="_blank"
              >
                @beeplus.id
              </a>
            </li>
            <li className="flex gap-1.5">
              <LinkedInIcon />
              <a
                href="https://www.linkedin.com/company/bee-plus-institute/"
                target="_blank"
              >
                Bee Plus Institute
              </a>
            </li>
          </ul>
        </div>
        <div className="min-h-[160px]">
          <Typography
            variant="heading6"
            as="h3"
            className="text-primary mb-5"
          >
            Contact Us!
          </Typography>
          <Typography
            as="a"
            variant="text3"
            href="https://wa.me/+6288289567528"
            target="_blank"
          >
            0882-8956-7529
          </Typography>
        </div>
      </div>
    </footer>
  )
}

export default GeneralPublicFooter
