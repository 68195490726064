import { useMutation } from "@tanstack/react-query"

import { GeneralMutationParams } from "utils/types"
import { GeneralNewPassword, UpdatePasswordRequest } from "../type"
import { updateNewPassword } from "../functions"

const useMutationNewPassword = (
    options?: GeneralMutationParams<
        GeneralNewPassword,
        UpdatePasswordRequest
    >
) => {
    return useMutation({
        mutationFn: ({ id, payload }) => updateNewPassword(id, payload),
        ...options,
    })
}

export default useMutationNewPassword
