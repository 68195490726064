import { Outlet } from "react-router-dom"
import clsx from "clsx"

import { useUserInfoStore } from "utils/store/user-info"
import UserDashboardNavigation from "components/navigations/UserDashboardNavigation"

const UserDashboardLayout = () => {
  const userInfo = useUserInfoStore((state) => state.userInfo)

  return (
    <div
      className={clsx(
        "flex justify-center gap-12",
        "w-full min-h-screen",
        "container my-12"
      )}
    >
      <UserDashboardNavigation
        firstName={userInfo.first_name}
        middleName={userInfo.middle_name}
        lastName={userInfo.last_name}
        email={userInfo.email}
      />
      <div className="w-full md:w-[70%]">
        <Outlet />
      </div>
    </div>
  )
}

export default UserDashboardLayout
