import { useMemo, useRef } from "react"
import clsx from "clsx"

import CareerCard from "components/public/components/CareerCard"
import { useInfiniteGetCareers } from "utils/api/public/queries/hooks"
import CardHorizontalSkeleton from "components/public/components/CardHorizontalSkeleton"

interface CategoryCareersProps {
  categoryId: number
  categoryName: string
  searchValue: string
}
const CategoryCareers: React.FC<CategoryCareersProps> = ({
  categoryId,
  categoryName,
  searchValue,
}) => {
  const componentRef = useRef<HTMLDivElement | null>(null)
  const {
    data: dataCareers,
    isLoading: isLoadingCareers,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteGetCareers({
    qParams: {
      category_id: categoryId,
      keyword: searchValue || undefined,
      limit: 10,
    },
  })
  const categorizedCareers = useMemo(() => {
    const categorize = {}

    dataCareers?.pages.forEach((page) => {
      page.data.forEach((career) => {
        career.categories?.forEach((category) => {
          const categoryId = category.id
          if (!categorize[categoryId]) {
            categorize[categoryId] = []
          }
          categorize[categoryId].push(career)
        })
      })
    })

    return categorize
  }, [dataCareers])

  const loadMore = () => {
    fetchNextPage()
  }

  return (
    <div
      key={categoryId}
      ref={componentRef}
      className=" text-3xl flex flex-wrap gap-4 font-bold"
    >
      {categorizedCareers[categoryId]?.length > 0 && (
        <>
          <div
            className={clsx(
              "w-full",
              "lg:grid flex items-center justify-center flex-col lg:grid-cols-2 xl:grid-cols-3 gap-4 mt-3"
            )}
          >
            {isLoadingCareers
              ? [...Array(8)].map((_, index) => (
                  <CardHorizontalSkeleton key={index} />
                ))
              : categorizedCareers[categoryId]?.length > 0 &&
                categorizedCareers[categoryId].map((career) => (
                  <CareerCard
                    key={career.id}
                    title={career.title}
                    slug={career.slug}
                    is_active={career.is_active}
                    description={career.description}
                    page={career.page}
                    location={career.location}
                    working_type={career.working_type}
                    company={career.company}
                    company_icon={career.company_icon}
                    thumb_width="w-44"
                  />
                ))}
          </div>

          {hasNextPage && (
            <div className="flex justify-center lg:justify-end mt-4 lg:mr-40">
              <button
                onClick={loadMore}
                className="text-primary text-lg cursor-pointer"
              >
                Load More
              </button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default CategoryCareers
