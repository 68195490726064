import { useEffect, useMemo, useState } from "react"
import {
  BookmarkIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useDebounce } from "usehooks-ts"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"

import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import SelectInput from "components/ui/form/SelectInput"
import TextInput from "components/ui/form/TextInput"
import {
  useGetCourses,
  useGetReferralDetail,
} from "utils/api/cms/queries/hooks"
import { useMutationUpdateReferral } from "utils/api/cms/mutations/hooks"
import { GeneralReferralRequest } from "utils/api/cms/types"
import { Course, Webinar } from "utils/types"

type Option = {
  label: string
  value: string | number
}

type Status = {
  label: string
  value: boolean
}

interface ReferralForm {
  name: string
  code: string
  type: Option
  value: number
  is_active: Status
  course_ids: Option[]
}

const REFERRAL_TYPE_OPTIONS = [
  { label: "Potongan Harga (Rp.)", value: "REBATE" },
  { label: "Persen (%)", value: "PERCENT" },
]

const STATUS_OPTIONS = [
  { label: "Aktif", value: true },
  { label: "Tidak Aktif", value: false },
]

const ReferralForm = () => {
  const navigate = useNavigate()
  const params = useParams()

  const isUpdate = !!params.id
  const [search, setSearch] = useState("")
  const debouncedSearch = useDebounce(search, 800)

  const {
    handleSubmit,
    register,
    control,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ReferralForm>()

  const { data: dataCourses, isFetching: isFetchingCourse } = useGetCourses({
    qParams: {
      limit: 10,
      keyword: debouncedSearch,
    },
  })
  const referralID = params.id ?? ""
  const { data: dataDetailReferral } = useGetReferralDetail(Number(referralID))

  const { mutate: mutateUpdateReferral } = useMutationUpdateReferral({
    onSuccess(data) {
      if (!data.success) return
      toast.success("Referral berhasil diiubah")
      setTimeout(() => {
        navigate("/cms/master/referral")
      }, 1000)
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  useEffect(() => {
    if (!dataDetailReferral || !isUpdate) return

    const DetailReferral = dataDetailReferral.data
    const productType = DetailReferral.courses.length > 0 ? "COURSE" : "WEBINAR"

    reset({
      name: DetailReferral.name,
      value: DetailReferral.value,
      type: REFERRAL_TYPE_OPTIONS.find(
        (option) => option.value === DetailReferral.type
      ),
      course_ids: DetailReferral[
        productType === "COURSE" ? "courses" : "webinars"
      ]?.map((course: Webinar | Course) => ({
        label: course.title,
        value: course.id,
      })),
    })
  }, [dataDetailReferral])

  const courseOptions = useMemo(
    () =>
      dataCourses?.data.map((course) => ({
        label: course.title,
        value: course.id,
      })),
    [dataCourses]
  )

  const handleSearchProduct = (value: string) => {
    setSearch(value)
  }

  const onSubmit: SubmitHandler<ReferralForm> = (data) => {
    const course_ids: number[] = data.course_ids.reduce<number[]>(
      (prevValue, currentValue) => {
        if (typeof currentValue.value !== "number") return prevValue
        return [...prevValue, currentValue.value]
      },
      []
    )

    if (data.type.value !== "REBATE" && data.type.value !== "PERCENT") {
      return
    }

    const is_active = data.is_active.value

    const payload: GeneralReferralRequest = {
      name: data.name,
      code: data.code,
      value: data.value,
      type: data.type.value,
      is_active: data.is_active.value,
      course_ids: course_ids,
    }

    if (!isUpdate) {
      return
    }

    mutateUpdateReferral({
      id: params.id ?? "",
      payload: payload,
    })
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-4 pb-4 min-w-[42rem]"
    >
      <TextInput
        type="text"
        className="cms-base-input"
        labelClass="cms-base-label"
        isRequired
        label="Nama Referral"
        errorMessage={errors.name?.message}
        errorIcon={
          <ExclamationTriangleIcon
            height={24}
            width={24}
            className="text-red-800"
          />
        }
        {...register("name", {
          required: {
            value: true,
            message: "Nama referral wajib diisi",
          },
        })}
      />
      <TextInput
        type="text"
        className="cms-base-input"
        labelClass="cms-base-label"
        isRequired
        label="Kode Referral"
        errorMessage={errors.code?.message}
        errorIcon={
          <ExclamationTriangleIcon
            height={24}
            width={24}
            className="text-red-800"
          />
        }
        {...register("code", {
          required: {
            value: true,
            message: "Kode referral wajib diisi",
          },
        })}
      />
      <Controller
        control={control}
        name="course_ids"
        rules={{
          required: { value: true, message: `Kelas wajib dipilih` },
        }}
        render={({ field }) => (
          <SelectInput
            {...field}
            label={`Pilih Kelas`}
            labelClass="cms-base-label"
            onInputChange={handleSearchProduct}
            isLoading={isFetchingCourse}
            errorMessage={errors.course_ids?.message}
            errorIcon={
              <ExclamationTriangleIcon
                height={24}
                width={24}
                className="text-red-800"
              />
            }
            classNames={{
              container: () => "max-w-2xl",
            }}
            options={courseOptions}
            isRequired
            isMulti
          />
        )}
      />
      <div className="flex items-center gap-4">
        <TextInput
          type="number"
          className="cms-base-input"
          labelClass="cms-base-label"
          isRequired
          label="Jumlah Diskon"
          errorMessage={errors.value?.message}
          errorIcon={
            <ExclamationTriangleIcon
              height={24}
              width={24}
              className="text-red-800"
            />
          }
          {...register("value", {
            required: {
              value: true,
              message: "Jumlah diskon wajib diisi",
            },
          })}
        />
        <Controller
          control={control}
          name="type"
          rules={{
            required: { value: true, message: "Tipe diskon wajib dipilih" },
          }}
          render={({ field }) => (
            <SelectInput
              {...field}
              label="Pilih Tipe Diskon"
              labelClass="cms-base-label"
              errorMessage={errors.type?.message}
              errorIcon={
                <ExclamationTriangleIcon
                  height={24}
                  width={24}
                  className="text-red-800"
                />
              }
              classNames={{
                container: () => "max-w-2xl",
              }}
              options={REFERRAL_TYPE_OPTIONS}
              isRequired
            />
          )}
        />
        <Controller
          control={control}
          name="is_active"
          rules={{
            required: { value: true, message: "Status referral wajib dipilih" },
          }}
          render={({ field }) => (
            <SelectInput
              {...field}
              label="Status"
              labelClass="cms-base-label"
              errorMessage={errors.is_active?.message}
              errorIcon={
                <ExclamationTriangleIcon
                  height={24}
                  width={24}
                  className="text-red-800"
                />
              }
              classNames={{
                container: () => "max-w-2xl",
              }}
              options={STATUS_OPTIONS}
              isRequired
            />
          )}
        />
      </div>
      <ButtonWithIcon
        action={undefined}
        type="submit"
        class="self-start mt-4 border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
        disabled={isSubmitting}
      >
        <BookmarkIcon className="h-6 w-6 text-white" />
        <span>{!isSubmitting ? "Simpan" : "Menyimpan..."}</span>
      </ButtonWithIcon>
    </form>
  )
}

export default ReferralForm
