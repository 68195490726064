import { useNavigate } from "react-router-dom"
import {
  BookmarkIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"
import { useForm, SubmitHandler } from "react-hook-form"
import { toast } from "react-toastify"

import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import TextInput from "components/ui/form/TextInput"
import { UpdatePasswordRequest } from "utils/api/cms/types"
import { useMutationUpdatePassword } from "utils/api/cms/mutations/hooks"

const UpdatePasswordForm = () => {
  const navigate = useNavigate()

  const {
    register,
    watch,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<UpdatePasswordRequest>()

  const { mutate } = useMutationUpdatePassword({
    onSuccess(data) {
      if (!data.success) return
      toast.success("Update password berhasil")
      setTimeout(() => {
        navigate("/cms/settings")
      }, 1000)
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  const onSubmit: SubmitHandler<UpdatePasswordRequest> = (data) => {
    mutate(data)
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-4 pb-4 min-w-[42rem]"
    >
      <TextInput
        type="password"
        className="cms-base-input"
        labelClass="cms-base-label"
        isRequired
        label="Password Saat Ini"
        errorMessage={errors.current_password?.message}
        errorIcon={
          <ExclamationTriangleIcon
            height={24}
            width={24}
            className="text-red-800"
          />
        }
        {...register("current_password", {
          required: {
            value: true,
            message: "Password saat ini wajib diisi",
          },
        })}
      />
      <TextInput
        type="password"
        className="cms-base-input"
        labelClass="cms-base-label"
        isRequired
        label="Password Baru"
        errorMessage={errors.new_password?.message}
        errorIcon={
          <ExclamationTriangleIcon
            height={24}
            width={24}
            className="text-red-800"
          />
        }
        {...register("new_password", {
          required: {
            value: true,
            message: "Password baru wajib diisi",
          },
          pattern: {
            value: /^(?=.*[A-Za-z])(?=.*\d).{8,}$/,
            message: `Pastikan password yang Anda masukkan terdiri dari minimal 8 karakter dan setidaknya mengandung 1 huruf dan 1 angka`,
          },
        })}
      />
      <TextInput
        type="password"
        className="cms-base-input"
        labelClass="cms-base-label"
        isRequired
        label="Konfirmasi Password Baru"
        errorMessage={errors.new_password_confirmation?.message}
        errorIcon={
          <ExclamationTriangleIcon
            height={24}
            width={24}
            className="text-red-800"
          />
        }
        {...register("new_password_confirmation", {
          required: {
            value: true,
            message: "Konfirmasi password baru wajib diisi",
          },
          validate: (value) => {
            if (watch("new_password") !== value) {
              return "Konfirmasi password tidak sesuai. Pastikan kedua password yang Anda masukkan sama"
            }
            return
          },
        })}
      />

      <ButtonWithIcon
        action={undefined}
        type="submit"
        class="self-start mt-4 border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
        disabled={isSubmitting}
      >
        <BookmarkIcon className="h-6 w-6 text-white" />
        <span>{!isSubmitting ? "Update" : "Menyimpan..."}</span>
      </ButtonWithIcon>
    </form>
  )
}

export default UpdatePasswordForm
