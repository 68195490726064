import { requestCMS } from "utils/axiosInstance"
import { QueryParams } from "utils/types"
import { GeneralTransactionsResponse } from "../../types"

const getTransactions = async (qParams?: QueryParams) => {
  const res = await requestCMS.get<GeneralTransactionsResponse>(
    "/employee/transaction",
    qParams
  )
  return res
}

export default getTransactions
