import { requestCMS } from "utils/axiosInstance"

import type { GeneralCareerRequest, GeneralCareerResponse } from "../../types"

const createCareer = async (payload: GeneralCareerRequest) => {
  const res = await requestCMS.post<GeneralCareerResponse>(
    "/employee/career/create",
    payload
  )
  return res
}

export default createCareer
