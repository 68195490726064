import { useQuery } from "@tanstack/react-query"
import { CmsQueryKey, GetCareerDetailParams } from "../../types"
import { getCareerCategoryDetail } from "../functions"

const useGetCareerCategoryDetail = (params?: GetCareerDetailParams) => {
  return useQuery({
    queryKey: [CmsQueryKey.CareerCategories, params?.id ?? ""],
    queryFn: () => getCareerCategoryDetail(params?.id),
  })
}

export default useGetCareerCategoryDetail
