import { createBrowserRouter, RouterProvider } from "react-router-dom"

import cmsRoute from "./cms"
import publicRoute from "./public"
import paymentRoute from "./payment"
import partnershipRoute from "./partnership"
import NotfoundRoute from "./notfound"

const router = createBrowserRouter([
  publicRoute,
  paymentRoute,
  cmsRoute,
  partnershipRoute,
  NotfoundRoute,
])

export default () => <RouterProvider router={router} />
