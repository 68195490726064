import { SubmitHandler } from "react-hook-form"
import { useNavigate, useParams } from "react-router-dom"
import { toast } from "react-toastify"

import CourseVideoManagementForm, {
  CourseVideoForm,
} from "components/cms/CourseVideoManagementForm"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import { getCMSSegments } from "utils/helper"
import {
  useGetVideoDetail,
  useGetVideoMaxSequence,
} from "utils/api/cms/queries/hooks"
import VideoPlayer from "components/ui/video-player"
import { getCmsToken } from "utils/auth"
import { useUpdateVideo } from "utils/api/cms/mutations/hooks"

const CourseVideoEdit = () => {
  const segments = getCMSSegments()
  const params = useParams<{ id: string; videoID: string; sectionID: string }>()
  const navigate = useNavigate()

  const { data: dataVideoDetail, isFetching: isFetchingVideoDetail } =
    useGetVideoDetail({
      options: {
        staleTime: 0,
      },
      videoID: params.videoID ?? "",
    })

  const { mutate: mutateUpdateVideo, isLoading: isLoadingUpdateVideo } =
    useUpdateVideo({
      onSuccess() {
        toast.success("Video berhasil diubah")
        navigate(`/cms/course/detail/${params.id}/detail/${params.sectionID}`)
      },
      onError(error) {
        toast.error(error.message)
      },
    })

  const { data: dataMaxSequence } = useGetVideoMaxSequence({
    options: {
      staleTime: 0,
    },
    sectionID: params.sectionID ?? "",
  })

  const videoSubmitHandler: SubmitHandler<CourseVideoForm> = async (data) => {
    mutateUpdateVideo({
      videoID: dataVideoDetail?.data.id.toString() ?? "",
      payload: {
        ...data,
        section_id: Number(params.sectionID),
        video_id: dataVideoDetail?.data.media_id ?? "",
      },
    })
  }

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        prefix="cms"
        segments={segments}
      />
      <h2 className="text-xl text-primary font-semibold leading-loose tracking-wide">
        Edit Video Belajar
      </h2>

      {!isFetchingVideoDetail && dataVideoDetail && (
        <>
          <span className="cms-base-label">Preview Video</span>
          <div className="bg-black w-full h-80 md:w-3/5">
            <VideoPlayer
              token={getCmsToken() ?? ""}
              url={`${process.env.REACT_APP_API_URL}/employee/course/section/video/stream/${dataVideoDetail.data.id}/${dataVideoDetail.data.media_id}`}
            />
          </div>
          <CourseVideoManagementForm
            maxSeq={dataMaxSequence?.data ?? 1}
            onSubmitHandler={videoSubmitHandler}
            initialValue={{
              title: dataVideoDetail.data.title,
              notes: dataVideoDetail.data.notes,
              is_preview: dataVideoDetail.data.is_preview,
              sequence: dataVideoDetail.data.sequence,
            }}
            submitting={isLoadingUpdateVideo}
            isUpdate
          />
        </>
      )}
    </EmployeeDashboardWrapper>
  )
}

export default CourseVideoEdit
