import { Helmet } from "react-helmet"

import Section from "components/ui/section/Section"
import bannerDesktop from "assets/images/bannerSertifikat.png"
import bannerMobile from "assets/images/mobile-banner.png"
import { ReactComponent as Bnsp } from "assets/svg/Bnsp.svg"
import Typography from "components/ui/typography"
import Button from "components/ui/button/Button"
import { useMediaQuery } from "usehooks-ts"
import React from "react"
import useGetSertifikasi from "utils/api/public/queries/hooks/useGetSertifikasi"
import SertifikatCard from "components/public/components/SertifikasiCard"
import noImage from "assets/images/no-image.jpg"
import { Link } from "react-router-dom"
import { HandleSmoothScroll } from "components/effect/smoothScroll"

const Sertifikasi = () => {
    const desktop = useMediaQuery("(min-width: 1024px)")
    const { data: dataSertifikasi } = useGetSertifikasi()

    return (
        <>
            <Helmet>
                <title>Sertifikasi</title>
            </Helmet>
            <div className="w-full mb-20">
                <Section
                    className=" h-full w-full justify-between flex items-center bg-primary/5 py-0 relative">
                    <div className="flex flex-col container relative lg:pl-28 z-10 py-12">
                        <Typography
                            variant="subheading1"
                            as="h1"
                            className="mb-2 text-slate-950 font-bold max-sm:text-3xl"
                        >
                            Perkaya <span className="text-primary">keahlian</span>,
                        </Typography>
                        <Typography
                            variant="subheading1"
                            as="h1"
                            className="mb-2 text-slate-950 font-bold lg:mb-4 max-sm:text-3xl"
                        >
                            hantarkan <span className="text-primary">perjalanan</span>
                        </Typography>
                        <Typography className="text-lg font-medium text-gray-600 lg:w-[75%]">
                            Sertifikasi di Indonesia yang menciptakan ahli telekomunikasi digital
                            <span className="text-slate-950"> berkompetensi tinggi</span>
                        </Typography>
                        <div className="flex gap-2 mt-10 text-base lg:text-lg">
                            <Button onClick={(e) => HandleSmoothScroll(e, "Sertifikasi")}
                                className="mr-2 lg:px-5 py-3 rounded-md">
                                Explore Skema
                            </Button>
                            <Link to={`/sertifikasi/daftar`}>
                                <Button className="h-full lg:px-5 py-3 rounded-md" variant="outlined">
                                    Daftar Sekarang
                                </Button>
                            </Link>
                        </div>
                        <div className="mt-10">
                            <p className="text-gray-500 mt-5">Lisenced By</p>
                            <Bnsp className="mt-3" />
                        </div>
                    </div>
                    <img src={bannerDesktop} alt="" className={`h-[685px] ${desktop ? 'block' : 'hidden'}`} />
                    <img src={bannerMobile} alt="" className={`absolute -z-10 top-0 h-full opacity-25 ${desktop ? 'hidden' : 'block'}`} />
                </Section>

                <Section
                    withContainer
                    className={`${desktop ? 'py-24' : 'py-8'}`}
                >
                    <Typography
                        id="Sertifikasi"
                        variant="subheading3"
                        className="text-2xl font-bold m-0 text-tertiary"
                    >
                        Buka Potensimu dengan Sertifikasi bersama Bee plus
                    </Typography>
                    <Typography
                        className="text-lg font-medium text-gray-600 mt-2"
                    >
                        Tingkatkan Keahlianmu, Dongkrak Karier, Bergabunglah dengan Para Ahli di Industri
                    </Typography>
                    <div className="flex flex-wrap mb-4 mt-10 gap-3">
                        {dataSertifikasi?.data.map((sertifikasi) => (
                            <SertifikatCard
                                key={sertifikasi.id}
                                to={`/sertifikasi/${sertifikasi.slug}`}
                                imgSrc={sertifikasi.thumbnail || noImage}
                                title={sertifikasi.name}
                                currency={sertifikasi.price.currency_text}
                                currentPrice={sertifikasi.price.current_price_text}
                                masterPrice={sertifikasi.price.master_price_text}
                            />
                        ))}
                    </div>
                </Section>
            </div >
        </>
    )
}

export default Sertifikasi
