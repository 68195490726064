import { requestUser } from "utils/axiosInstance"
import { PublicGetSertifikasiDetailResponse } from "../../types"

const getSertifikasiDetail = async (slug: string) => {
    const res = await requestUser.get<PublicGetSertifikasiDetailResponse>(
        `/sertifikasi/${slug}`
    )
    return res
}

export default getSertifikasiDetail
