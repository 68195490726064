import { ChevronRightIcon } from "@heroicons/react/20/solid"
import { Link } from "react-router-dom"

interface ShowAllLinkProps {
  to: string
  children: React.ReactNode
}

const ShowAllLink = ({ to, children }: ShowAllLinkProps) => {
  return (
    <div className="flex justify-center">
      <Link
        to={to}
        className="text-md text-primary font-semibold"
      >
        {children}
        <ChevronRightIcon
          className="inline-block"
          height={24}
          width={24}
        />
      </Link>
    </div>
  )
}

export default ShowAllLink
