import { useInfiniteQuery } from "@tanstack/react-query"

import { getPurchasedCourses } from "../functions"
import { UserQueryKey, GetInfinitePurchasedCoursesParams } from "../../types"

const useInfiniteGetPurchasedCourses = (
  params?: GetInfinitePurchasedCoursesParams
) => {
  return useInfiniteQuery({
    queryKey: [UserQueryKey.PurchasedCourses, params?.qParams ?? {}],
    queryFn: ({ pageParam }) =>
      getPurchasedCourses({ ...params?.qParams, page: pageParam }),
    getNextPageParam: (lastPage) => {
      if (!lastPage.meta) return

      const isLastPage = lastPage.meta.current_page === lastPage.meta?.last_page
      if (isLastPage) return

      return lastPage.meta.current_page + 1
    },
    ...params?.options,
  })
}

export default useInfiniteGetPurchasedCourses
