import { PaginationState, createColumnHelper } from "@tanstack/react-table"
import { useState, Suspense } from "react"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import { CustomFlowbiteTheme, Tabs } from "flowbite-react"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import clsx from "clsx"
import { getCMSSegments } from "utils/helper"
import type {
  TransactionHistory,
  TransactionDetail,
} from "utils/api/user/types"
import { useGetTransactionHistories } from "utils/api/user/queries/hooks"
import clsxm from "utils/clsxm"
import CardTransaction from "./CardTransaction"
import Typography from "components/ui/typography"
import { Helmet } from "react-helmet"

const formatter = new Intl.NumberFormat("id-ID", {
  style: "currency",
  currency: "IDR",
})

const userColHelper = createColumnHelper<TransactionHistory>()
const userColumns = [
  userColHelper.accessor("no_invoice", {
    header: () => "Nomor Transaksi",
  }),
  userColHelper.accessor("transaction_detail", {
    header: () => "Judul",
    cell: (props) => {
      const transactionDetails = props.cell.row.original
        .transaction_detail as TransactionDetail
      const title = transactionDetails.transactionable?.title || "N/A"
      return <span>{title}</span>
    },
  }),
  userColHelper.accessor("amount", {
    header: () => "Harga",
    cell: (props) => {
      const amount = props.row.original.amount
      const formattedAmount = formatter.format(Number(amount))

      return <span>{formattedAmount}</span>
    },
  }),
  userColHelper.accessor("created_at", {
    header: () => "Tanggal Order",
    cell: (props) => {
      const createdAt = new Date(props.row.original.created_at)
      const formattedExpiration = moment(createdAt).format("D MMMM YYYY")

      return <span>{formattedExpiration}</span>
    },
  }),

  userColHelper.accessor("status", {
    header: () => "Status",
    cell: (props) => {
      const status = props.row.original.status
      return (
        <span
          className={clsxm("p-1 rounded", [
            status === "PENDING" && "bg-yellow-300",
            status === "SUCCESS" && "bg-green-300",
            status === "EXPIRED" && "bg-red-300",
          ])}
        >
          {status}
        </span>
      )
    },
  }),
]

const Transaction: React.FC = () => {
  const navigate = useNavigate()

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 1,
  })
  const { data: dataTransactionHistories } = useGetTransactionHistories({
    options: {
      staleTime: 0,
    },
    qParams: {
      limit: 5,
      page: pagination.pageIndex + 1,
    },
  })

  return (
    <EmployeeDashboardWrapper>
      <Helmet>
        <title>Riwayat Pembayaran</title>
      </Helmet>
      <Typography
        as="h1"
        variant="heading6"
        className="mb-8"
      >
        Riwayat Pembayaran
      </Typography>
      <Tabs.Group
        aria-label="Profile tabs"
        style="underline"
        theme={theme}
        className="flex-nowrap"
      >
        <Tabs.Item
          active
          title="Semua Pembayaran"
        >
          <Suspense>
            <CardTransaction
              columns={userColumns}
              data={dataTransactionHistories?.data ?? []}
              onPaginationChange={setPagination}
              onRowClick={({ snapToken, slug, type }) =>
                navigate(`/payment/${type}/${slug}/show/${snapToken}`)
              }
              pagination={pagination}
              pageCount={dataTransactionHistories?.meta?.last_page ?? 1}
            />
          </Suspense>
        </Tabs.Item>
      </Tabs.Group>

    </EmployeeDashboardWrapper>
  )
}

const theme: CustomFlowbiteTheme["tab"] = {
  tablist: {
    tabitem: {
      base: clsx(
        "flex items-center justify-center",
        "p-4",
        "text-sm md:text-md font-semibold",
        "first:ml-0",
        "disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 focus:outline-none"
      ),
      styles: {
        underline: {
          active: {
            on: "text-primary border-b-2 border-primary active dark:text-primary dark:border-primary",
            off: "border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300",
          },
        },
      },
    },
  },
}

export default Transaction
