import { requestCMS } from "utils/axiosInstance"
import { QueryParams } from "utils/types"
import type { GeneralCareerCategoriesResponse } from "../../types"

const getCareerCategories = async (qParams?: QueryParams) => {
  const res = await requestCMS.get<GeneralCareerCategoriesResponse>(
    "/career-categories",
    qParams
  )
  return res
}

export default getCareerCategories
