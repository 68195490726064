import { useEffect } from "react"
import { Outlet } from "react-router-dom"
import jwt_decode, { JwtPayload } from "jwt-decode"
import moment from "moment"
import Cookies from "js-cookie"
import GeneralPublicNavigation from "components/navigations/GeneratlPublicNavigation"
import withScrollToTop from "utils/withScrollToTop"
import GeneralPublicFooter from "components/footer/GeneralPublicFooter"
import { useUserInfoStore } from "utils/store/user-info"
import { getUserToken } from "utils/auth"

const GeneralPublicLayout: React.FC = () => {
  const { resetUserInfo } = useUserInfoStore()

  useEffect(() => {
    const token = getUserToken()
    if (!token) {
      resetUserInfo()
      return
    }

    const decodedToken = jwt_decode<JwtPayload>(token)
    const tokenExpiration = new Date((decodedToken.exp ?? 0) * 1000)
    const currentDate = new Date()

    const diff = moment(tokenExpiration).diff(currentDate, "milliseconds")

    const logoutTimer = setTimeout(() => {
      Cookies.remove("user_auth_token")
      resetUserInfo()
    }, diff)

    return () => {
      clearTimeout(logoutTimer)
    }
  }, [resetUserInfo])
  return (
    <>
      <GeneralPublicNavigation />
      <main>
        <Outlet />
      </main>
      <GeneralPublicFooter />
    </>
  )
}

export default withScrollToTop(GeneralPublicLayout)
