import { useMutation } from "@tanstack/react-query"

import { GeneralMutationParams } from "utils/types"
import { resendEmail } from "../functions"
import { ResendRequest, PublicResendEmailResponse } from "../../types"

const useMutationResendEmail = (
  options?: GeneralMutationParams<PublicResendEmailResponse, ResendRequest>
) => {
  return useMutation({
    mutationFn: (payload) => resendEmail(payload),
    ...options,
  })
}

export default useMutationResendEmail
