import React, { useRef, useState } from "react"
import Carousel from "react-multi-carousel"
import "react-multi-carousel/lib/styles.css"
import TestimoniCard from "../testimoni-card"
import "./style.css"
import ajengkanthiIMG from "assets/images/testimoni/Ajengkanthi.jpg"
import bahirahIMG from "assets/images/testimoni/bahirah.jpeg"
import faturIMG from "assets/images/testimoni/fatur.jpeg"
import shaljanIMG from "assets/images/testimoni/shaljan.jpeg"
import tegarIMG from "assets/images/testimoni/tegar.jpeg"

const MultiCardCarousel: React.FC = () => {
  const testimoni = [
    {
      link: "/courses/kupas-tuntas-microsoft-office-word-untuk-pemula",
      text: "Jujur video course di Beeplus Institute bener-bener recommended buat yang mau belajar tanpa harus keluar budget besar. Murah tapi nggak murahan, beneran worth it buat belajar!",
      nama: "Tegar Nugroho",
      type: "Microfost Word",
      img: tegarIMG,
    },
    {
      link: "/courses/google-forms",
      text: "Ngerasa dapet jackpot banget pas nyoba kelas video course di Beeplus Institute. Harganya terjangkau tapi kualitasnya nggak main-main, cocok buat yang baru belajar gimana caranya bikin formulir digital pakai Google Forms.",
      nama: "Ajengkanthi Prabaningjati",
      type: "Google Forms",
      img: ajengkanthiIMG,
    },
    {
      link: "/courses/google-slides",
      text: "Setelah nyoba ambil kelas Google Slides di Beeplus ternyata mantap banget! Materinya nggak cuma mudah dipahami, tapi juga bikin excited buat terus belajar. Jadi tau gimana caranya buat presentasi yang menarik dan profesional.",
      nama: "Ahmad Shaljan Rangkuty ",
      type: "Google Slides",
      img: shaljanIMG,
    },
    {
      link: "/courses/google-spreadsheet",
      text: "Materinya lengkap, penjelasannya mudah dimengerti, pokoknya gak nyesel deh! Buat pemula yang bingung mau belajar per-googlean, terutama soal Google Spreadsheet. Ini solusinya!",
      nama: "Hafizhah Bahirah",
      type: "Google Spreadsheets",
      img: bahirahIMG,
    },
    {
      link: "/courses/google-docs",
      text: "Gue sempet ragu mau ikutan kelas video di Beeplus Institute, tapi setelah nyobain, beneran worth it! Materinya lengkap, penjelasannya gampang banget dipahami buat pemula kayak gue.",
      nama: "Fathurrachman Zuhdi",
      type: "Google Docs",
      img: faturIMG,
    },
  ]
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 30,
    },
  }

  return (
    <>
      <Carousel
        additionalTransfrom={0}
        arrows
        autoPlaySpeed={3000}
        centerMode={false}
        className=""
        containerClass="container-with-dots"
        dotListClass=""
        draggable
        focusOnSelect
        infinite={false}
        itemClass=""
        keyBoardControl
        minimumTouchDrag={80}
        pauseOnHover
        renderArrowsWhenDisabled={false}
        renderButtonGroupOutside={false}
        renderDotsOutside={false}
        responsive={responsive}
        rewind={false}
        rewindWithAnimation={false}
        rtl={false}
        shouldResetAutoplay
        showDots={false}
        sliderClass=""
        slidesToSlide={1}
        swipeable
      >
        {testimoni.map((item, index) => (
          <TestimoniCard
            key={index}
            content={item}
          />
        ))}
      </Carousel>
    </>
  )
}

export default MultiCardCarousel
