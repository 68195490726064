import { useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import jwt_decode, { JwtPayload } from "jwt-decode"
import moment from "moment"

import { usePartnershipInfoStore } from "utils/store/partnership-info"
import { getPartnershipToken } from "utils/auth"
import { useMutationLogout } from "utils/api/employee/mutations/hooks"
import PartnershipNavigation from "components/navigations/PartnershipNavigation"

const PartnershipDashboardLayout: React.FC = () => {
    const navigate = useNavigate()
    const resetPartnershipInfo = usePartnershipInfoStore(
        (state) => state.resetPartnershipInfo
    )

    useEffect(() => {
        document.documentElement.classList.add("dark")
        return () => document.documentElement.classList.remove("dark")
    }, [])

    const { mutate: mutateLogout } = useMutationLogout({
        onSuccess() {
            resetPartnershipInfo()
        },
    })

    useEffect(() => {
        const token = getPartnershipToken()

        if (!token) return resetPartnershipInfo()

        const decodedToken = jwt_decode<JwtPayload>(token)
        const tokenExpiration = new Date((decodedToken.exp ?? 0) * 1000)
        const currentDate = new Date()

        const diff = moment(tokenExpiration).diff(currentDate, "milliseconds")

        setTimeout(() => {
            mutateLogout({})
        }, diff)

    }, [])

    return (
        <>
            <PartnershipNavigation />
            <main>
                <Outlet />
            </main>
        </>
    )
}

export default PartnershipDashboardLayout
