import { requestUser } from "utils/axiosInstance"
import { PublicGetCoursePreviewVideoResponse } from "../../types"

const getCourseVideoPreview = async (courseID: string) => {
  const res = await requestUser.get<PublicGetCoursePreviewVideoResponse>(
    `/courses/${courseID}/preview-videos`
  )
  return res
}

export default getCourseVideoPreview
