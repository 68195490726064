import { useQuery } from "@tanstack/react-query"

import { getSertifikasiSkkni } from "../functions"
import { CmsQueryKey, GetSertifikasiSkkniParams } from "../../types"

const useGetSertifikasiSkkni = (params?: GetSertifikasiSkkniParams) => {
    return useQuery({
        queryKey: [CmsQueryKey.SertifikatSkkni, params?.qParams ?? ""],
        queryFn: () => getSertifikasiSkkni(params?.qParams),
        ...params?.options,
    })
}

export default useGetSertifikasiSkkni
