import { useMutation } from "@tanstack/react-query"

import { GeneralMutationParams } from "utils/types"
import { BuyProductResponse, BuyProductRequest } from "../../types"
import { buyProduct } from "../functions"

const useMutationBuyProduct = (
  options?: GeneralMutationParams<BuyProductResponse, BuyProductRequest>
) => {
  return useMutation({
    mutationFn: (payload) => buyProduct(payload),
    ...options,
  })
}

export default useMutationBuyProduct
