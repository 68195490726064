import {  requestUser } from "utils/axiosInstance"
import { PublicGetSlidePreviewImageResponse } from "../../types"

const getSlideImagePreview = async () => {
  const res = await requestUser.get<PublicGetSlidePreviewImageResponse>(
    `/slide`,
  )

  return res
}

export default getSlideImagePreview
