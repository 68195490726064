import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import Typography from "components/ui/typography"
import { getCMSSegments } from "utils/helper"
import Button from "components/ui/button/Button"
import SertifikatList from "components/cms/Sertifikat/SertifikatList"

const SertifikatManagement = () => {
    const segments = getCMSSegments()

    return (
        <EmployeeDashboardWrapper>
            <DefBreadcrumb
                segments={segments}
                prefix="cms"
            />
            <div className="inline-flex items-center">
                <Typography
                    variant="subheading4"
                    as="h1"
                    className="text-primary mt-4"
                >
                    Daftar Sertifikasi
                </Typography>
            </div>
            <SertifikatList />
        </EmployeeDashboardWrapper>
    )
}

export default SertifikatManagement
