import { requestUser } from "utils/axiosInstance"
import {
  PublicAuthenticateUserResponse,
  ForgotPasswordRequest,
} from "../../types"

const forgotPassword = async (payload: ForgotPasswordRequest) => {
  const res = await requestUser.post<PublicAuthenticateUserResponse>(
    "/reset-password/request",
    payload
  )
  return res
}

export default forgotPassword
