import { useMutation } from "@tanstack/react-query"

import { DeleteCourseCategoryResponse } from "../../types"
import { deleteCourseCategory } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useDeleteCourseCategory = (
  options?: GeneralMutationParams<DeleteCourseCategoryResponse, string>
) => {
  return useMutation({
    mutationFn: (courseCategoryId) => deleteCourseCategory(courseCategoryId),
    ...options,
  })
}

export default useDeleteCourseCategory
