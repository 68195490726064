import { requestUser } from "utils/axiosInstance"
import {
  AuthenticationRequest,
  PublicAuthenticateUserResponse,
} from "../../types"

const authenticateUser = async (payload: AuthenticationRequest) => {
  const res = await requestUser.post<PublicAuthenticateUserResponse>(
    "/authenticate",
    payload
  )
  return res
}

export default authenticateUser
