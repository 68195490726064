import { requestUser } from "utils/axiosInstance"
import type { GetPurchasedWebinarResponse } from "../../types"
import { QueryParams } from "utils/types"

const getPurchasedWebinars = async (qParams?: QueryParams) => {
  const res = await requestUser.get<GetPurchasedWebinarResponse>(
    "/user/webinars",
    qParams
  )
  return res
}

export default getPurchasedWebinars
