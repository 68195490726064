import { useNavigate, useParams, Link } from "react-router-dom"
import { toast } from "react-toastify"
import {
  useGetCourseDetail,
  useGetWorkshopDetail,
} from "utils/api/public/queries/hooks"
import { Helmet } from "react-helmet"
import Typography from "components/ui/typography"
import { ShoppingCartIcon } from "@heroicons/react/24/solid"
import { useState } from "react"
import TextInput from "components/ui/form/TextInput"
import useMutationReferral from "utils/api/employee/mutations/hooks/useMutationReferral"
import { ReferralResponse } from "utils/types"
import Button from "components/ui/button/Button"
import { useUserInfoStore } from "utils/store/user-info"
import { useMutationBuyProduct } from "utils/api/payment/mutations/hooks"
import { BuyProductRequest } from "utils/api/payment/types"
import { useMediaQuery } from "usehooks-ts"

const CheckoutIndex = () => {
  const params = useParams<{ type: string; slug: string }>()
  const desktop = useMediaQuery("(min-width: 1024px)")
  const mobile = !desktop
  const navigate = useNavigate()
  const formatter = new Intl.NumberFormat("id-ID")
  const isLoggedIn = useUserInfoStore((state) => state.isLoggedIn)
  const [isAddReferral, setIsAddReferral] = useState(false)
  const [isCouponApplied, setIsCouponApplied] = useState(false)
  const [couponData, setCouponData] = useState<ReferralResponse | null>(null)
  const [referral, setReferral] = useState("")
  const buttonClass = params.type === "webinar" ? "w-full mt-20" : "w-full"

  const { mutate: mutateCoupon } = useMutationReferral({
    onSuccess(data) {
      toast.success("Referral berhasil ditambahkan!")
      setCouponData(data)
      setIsCouponApplied(true)
    },
    onError(err) {
      toast.error(err.message)
    },
  })

  const { data: dataDetailCourse } = useGetCourseDetail({
    options: {
      enabled: params.type === "course",
    },
    slug: params.slug ?? "",
  })

  const { data: dataDetailWebinar } = useGetWorkshopDetail({
    options: {
      enabled: params.type === "webinar",
    },
    slug: params.slug ?? "",
  })

  const productName = params.type === "course" ? "Kelas" : "Webinar"
  const dataProduct = dataDetailCourse ?? dataDetailWebinar

  const sendReferral = () => {
    const courseId = dataProduct?.data.id.toString() || ""
    const encodedCourseId = btoa(courseId)

    const payload = {
      course_id: encodedCourseId,
      referral_code: referral,
    }
    mutateCoupon(payload)
  }

  const isDiscount =
    dataProduct?.data.price.current_price !==
    dataProduct?.data.price.master_price
  const calculateTotalWithReferral = () => {
    const originalPrice = Number(dataProduct?.data.price.current_price) || 0
    const referralDiscount = couponData ? Number(couponData.data.value) : 0
    const totalWithReferral = originalPrice - referralDiscount

    return Math.max(totalWithReferral, 0)
  }

  const totalPayment = calculateTotalWithReferral()
  const formattedTotalPayment = formatter.format(totalPayment)
  const { mutate: mutateBuyProduct, isLoading: isLoadingBuyProduct } =
    useMutationBuyProduct({
      onSuccess(data) {
        if (!dataProduct) return
        navigate(
          `/payment/${params.type}/${dataProduct.data.slug}/show/${data.data.payment_token}`
        )
      },
    })

  const handleCheckout = () => {
    if (!dataProduct?.data?.id) return
    const encodedId = btoa(String(dataProduct?.data.id))

    const productData: BuyProductRequest = {
      buyable: encodedId,
      type: params.type === "courses" ? "COURSE" : "WEBINAR",
      referral_code: isCouponApplied ? referral : undefined,
    }

    mutateBuyProduct(productData)
  }
  return (
    <>
      <Helmet>
        <title>Checkout: {dataProduct?.data.title ?? ""}</title>
      </Helmet>
      <div className={`container ${desktop ? "my-20" : "my-10"}`}>
        <Typography
          as="h1"
          variant="heading4"
          className="flex text-primary space-x-2 items-center justify-center"
        >
          <ShoppingCartIcon
            width={34}
            height={34}
          />
          <span>Checkout</span>
        </Typography>
        <Typography
          as="h2"
          variant="heading6"
          className="flex text-primary space-x-2 items-center justify-center mt-5"
        >
          <span>{dataProduct?.data.title}</span>
        </Typography>
      </div>
      <div className="container mx-auto px-4 mb-20">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <img
              src={dataProduct?.data.thumbnail}
              alt={dataProduct?.data.title}
            />
          </div>
          <div>
            <Typography
              as="h2"
              variant="heading6"
              className="mb-6"
            >
              Detail Pembayaran
            </Typography>
            <ul className="space-y-1.5 w-full">
              <li className={`flex justify-between ${mobile ? "my-5" : ""}`}>
                <Typography>{dataProduct?.data.title}</Typography>
              </li>
              <li className="flex justify-between">
                <Typography>Harga</Typography>
                <Typography>
                  {dataProduct?.data.price.currency_text}
                  {dataProduct?.data.price.master_price_text}
                </Typography>
              </li>
              {isCouponApplied && (
                <li className="flex justify-between">
                  <Typography>Referral</Typography>
                  <Typography>
                    Rp.-
                    {couponData &&
                      formatter.format(Number(couponData?.data.value))}
                  </Typography>
                </li>
              )}
              {isDiscount && (
                <li className="flex justify-between">
                  <Typography>Potongan</Typography>
                  <Typography>
                    -{dataProduct?.data.price.currency_text}
                    {formatter.format(
                      (dataProduct?.data.price.master_price ?? 0) -
                      (dataProduct?.data.price.current_price ?? 0)
                    )}
                  </Typography>
                </li>
              )}
              <li className="flex justify-between">
                <Typography className="font-semibold">
                  Total Pembayaran
                </Typography>
                <Typography className="font-semibold">
                  {dataProduct?.data.price.currency_text}
                  {formattedTotalPayment}
                </Typography>
              </li>
            </ul>
            {params.type === "courses" && (
              <Typography
                as="h6"
                variant="heading6"
                className="mb-6 mt-5"
                onClick={() => setIsAddReferral((v) => !v)}
              >
                Punya Kode Referral?
              </Typography>
            )}
            {params.type === "courses" && (
              <div className="flex gap-2 mb-6">
                <div className="flex-auto">
                  <TextInput
                    name="referral"
                    value={referral}
                    onChange={(e) => setReferral(e.target.value)}
                  ></TextInput>
                </div>
                <Button
                  type="submit"
                  variant="contained"
                  onClick={sendReferral}
                >
                  Tambahkan
                </Button>
              </div>
            )}
            <Button
              size="md"
              className={buttonClass}
              variant="outlined"
              as={isLoggedIn ? "button" : Link}
              isLoading={isLoadingBuyProduct}
              disabled={isLoggedIn && !dataProduct?.data.buyable}
              {...(isLoggedIn
                ? {
                  onClick: handleCheckout,
                }
                : {
                  to: "/register",
                })}
            >
              {isLoggedIn ? "Pembayaran" : "Daftar & Beli"}
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default CheckoutIndex
