import { requestUser } from "utils/axiosInstance"
import { PublicResendEmailResponse, ResendRequest } from "../../types"

const resendEmail = async (payload: ResendRequest) => {
  const res = await requestUser.post<PublicResendEmailResponse>(
    `/verify-user/resend`,
    payload
  )
  return res
}

export default resendEmail
