import { requestUser } from "utils/axiosInstance"
import { PublicActivationEmailResponse, ActivationRequest } from "../../types"

const activateEmail = async (payload: ActivationRequest) => {
  const res = await requestUser.post<PublicActivationEmailResponse>(
    `/verify-user/verify/${payload.verify_token}`,
    payload
  )

  return res
}

export default activateEmail
