import { useState } from "react"
import { SubmitHandler } from "react-hook-form"
import to from "await-to-js"
import { LoaderFunction, useLoaderData, useNavigate } from "react-router-dom"
import Swal from "sweetalert2"

import CourseSectionManagementForm, {
  CourseSectionForm,
} from "components/cms/CourseSectionManagementForm"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import { getCMSSegments } from "utils/helper"
import {
  CMSCreateCourseSectionResponse,
  CMSGetCourseResponse,
  CMSGetCourseSectionResponse,
  Course,
  CourseSection,
  GeneralAPIValidationResponse,
  GeneralApiResponse,
} from "utils/types"
import api from "utils/axiosInstance"

const CourseSectionManagementEdit = () => {
  const navigate = useNavigate()
  const segments = getCMSSegments()
  const loaderResponse = useLoaderData() as {
    maxSequence: GeneralApiResponse<number>
    course: CMSGetCourseResponse<Course>
    section: CMSGetCourseSectionResponse<CourseSection>
  }

  const [courseSectionSubmitting, setCourseSectionSubmitting] =
    useState<boolean>(false)
  const [serverValidationError, setServerValidation] = useState<{
    title: string | undefined
    sequence: string | undefined
  }>({
    title: undefined,
    sequence: undefined,
  })

  const sectionSubmitHandler: SubmitHandler<CourseSectionForm> = async (
    data
  ) => {
    setCourseSectionSubmitting(true)
    const courseID = loaderResponse.course.data.id
    const sectionID = loaderResponse.section.data.id
    const [err, _] = await to<
      CMSCreateCourseSectionResponse,
      GeneralAPIValidationResponse
    >(api.cms.updateCourseSection(courseID, sectionID, data))

    if (!err) {
      navigate(`/cms/course/detail/${courseID}`, {
        state: { courseSectionUpsertSuccess: true },
      })
      return
    }

    if (!err.hasOwnProperty("errors")) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err.message,
      })

      setCourseSectionSubmitting(false)
      return
    }

    const validationResponse = { ...serverValidationError }

    Object.keys(validationResponse).forEach((field) => {
      const errMessages: string[] | undefined = err!.errors![field]

      if (errMessages === undefined) return

      const message = (errMessages as string[]).join(". ")

      validationResponse[field] = message
    })

    setServerValidation(validationResponse)
    setCourseSectionSubmitting(false)
  }

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix="cms"
      />
      <h2 className="text-xl text-primary font-semibold leading-loose tracking-wide">
        Edit Konten Bagian Belajar
      </h2>
      <CourseSectionManagementForm
        method="PUT"
        maxSeq={loaderResponse.maxSequence.data + 1}
        initialValue={{
          title: loaderResponse.section.data.title,
          sequence: loaderResponse.section.data.sequence,
        }}
        onSubmitHandler={sectionSubmitHandler}
        serverValidationResponse={serverValidationError}
        submitting={courseSectionSubmitting}
      />
    </EmployeeDashboardWrapper>
  )
}

export default CourseSectionManagementEdit

export const courseSectionEditLoader: LoaderFunction = async ({ params }) => {
  const courseID = params.id
  const sectionID = params.sectionId

  const loadMaxSeq = async () => {
    const maxResponse = await api.cms.getCourseSectionMaxSeq(
      Number(courseID ?? "")
    )
    return maxResponse
  }

  const loadCourse = async () => {
    const courseResponse = await api.cms.getCourse(Number(courseID ?? ""))
    return courseResponse
  }

  const loadSection = async () => {
    const sectionResponse = await api.cms.getCourseSection(
      Number(sectionID ?? "")
    )
    return sectionResponse
  }

  return {
    maxSequence: await loadMaxSeq(),
    course: await loadCourse(),
    section: await loadSection(),
  }
}
