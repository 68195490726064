import { useMemo } from "react"
import { Navigate, Outlet } from "react-router-dom"

import { useEmployeeInfoStore } from "utils/store/employee-info"

const SuperAdminPrivateRouter = () => {
  const employeeInfo = useEmployeeInfoStore((state) => state.employeeInfo)

  const isSuperAdmin = useMemo(
    () => employeeInfo.roles.some((role) => role.name === "SUPERADMIN"),
    [employeeInfo]
  )

  return isSuperAdmin ? <Outlet /> : <Navigate to="/cms/dashboard" />
}

export default SuperAdminPrivateRouter
