import React, { useState, useEffect } from "react"
import { Carousel, Flowbite } from "flowbite-react"
import Section from "components/ui/section/Section"
import { useMediaQuery } from "usehooks-ts"
import useGetSlideImagePreview from "utils/api/public/queries/hooks/useGetSlideImagePreview"
import { Link } from "react-router-dom"

const HeaderHome = () => {
  const { data: response, isLoading: isLoadingdataSlide } = useGetSlideImagePreview();
  const dataSlide = response?.data;
  const desktop = useMediaQuery("(min-width: 1024px)")
  const mobile = !desktop
  return (
    <>
      <Section
        withContainer
        className="lg:pt-8"
      >
        {desktop && (
          <div className="h-56 sm:h-64 xl:h-80 2xl:h-96 ">
            <Flowbite
              theme={{
                theme: {
                  carousel: {
                    root: {
                      base: "relative h-full w-full",
                      leftControl:
                        "absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none",
                      rightControl:
                        "absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none",
                    },
                    indicators: {
                      active: {
                        off: "bg-gray-300 hover:bg-gray-500",
                        on: "bg-gray-500",
                      },
                      base: "h-2 w-2 rounded-full",
                      wrapper:
                        "absolute -bottom-5 left-1/2 flex -translate-x-1/2 space-x-3",
                    },
                    item: {
                      base: "absolute top-1/2 left-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
                      // wrapper: {
                      //   off: "w-full flex-shrink-0 transform cursor-default snap-center",
                      //   on: "w-full flex-shrink-0 transform cursor-grab snap-center"
                      // }
                    },
                    control: {
                      base: "inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500 group-hover:bg-gray-600/50 group-focus:outline-none bg-opacity-50  sm:h-10 sm:w-10",
                      icon: "h-5 w-5 text-white sm:h-6 sm:w-6",
                    },
                    scrollContainer: {
                      base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-lg",
                      snap: "snap-x",
                    },
                  },
                },
              }}
            >
              <Carousel slideInterval={5000}>
                {dataSlide?.map((slide, index) => (
                  <Link key={index} to={slide.link}>
                    <img src={slide?.image_desktop} alt="..." />
                  </Link>
                ))}
              </Carousel>
            </Flowbite>
          </div>
        )}
        {mobile && (
          <div className="h-56 sm:h-64 xl:h-80 2xl:h-96">
            <Flowbite
              theme={{
                theme: {
                  carousel: {
                    root: {
                      base: "relative h-full w-full",
                      leftControl:
                        "absolute top-0 left-0 flex h-full items-center justify-center px-4 focus:outline-none",
                      rightControl:
                        "absolute top-0 right-0 flex h-full items-center justify-center px-4 focus:outline-none",
                    },
                    indicators: {
                      active: {
                        off: "hidden",
                        on: "hidden",
                      },
                      base: "hidden",
                      wrapper:
                        "absolute bottom-5 left-1/2 flex -translate-x-1/2 space-x-3",
                    },
                    item: {
                      base: "absolute top-1/2 left-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
                      // wrapper: {
                      //   off: "w-full flex-shrink-0 transform cursor-default snap-center",
                      //   on: "w-full flex-shrink-0 transform cursor-grab snap-center"
                      // }
                    },
                    control: {
                      base: "inline-flex h-8 w-8 items-center justify-center rounded-full bg-gray-500 group-hover:bg-gray-600/50 group-focus:outline-none bg-opacity-50  sm:h-10 sm:w-10",
                      icon: "h-5 w-5 text-white sm:h-6 sm:w-6",
                    },
                    scrollContainer: {
                      base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-lg",
                      snap: "snap-x",
                    },
                  },
                },
              }}
            >
              <Carousel slideInterval={5000}>
                {dataSlide?.map((slide, index) => (
                  <Link key={index} to={slide.link}>
                    <img src={slide?.image_mobile} alt="..." />
                  </Link>
                ))}
              </Carousel>
            </Flowbite>
          </div>
        )}
      </Section>
    </>
  )
}

export default HeaderHome
