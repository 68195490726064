import { requestCMS } from "utils/axiosInstance"

import type {
  GeneralDiscountRequest,
  GeneralDiscountResponse,
} from "../../types"

const createDiscount = async (payload: GeneralDiscountRequest) => {
  const res = await requestCMS.post<GeneralDiscountResponse>(
    "/employee/discount/create",
    payload
  )
  return res
}

export default createDiscount
