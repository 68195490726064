import { useQuery } from "@tanstack/react-query"

import { getDetailDiscount } from "../functions"
import { CmsQueryKey, GetDiscountDetailParams } from "../../types"

const useGetDetailDiscount = (params?: GetDiscountDetailParams) => {
  return useQuery({
    queryKey: [CmsQueryKey.DiscountCourse, params?.id ?? ""],
    queryFn: () => getDetailDiscount(params?.id),
    ...params?.options,
  })
}

export default useGetDetailDiscount
