import DiscountCourseForm from "components/cms/DiscountCourseForm"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import Typography from "components/ui/typography"
import { getCMSSegments } from "utils/helper"

const DiscountCreate = () => {
  const segments = getCMSSegments()

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix="cms"
      />
      <Typography
        variant="subheading4"
        as="h1"
        className="text-primary mt-3 mb-3"
      >
        Buat Diskon
      </Typography>

      <DiscountCourseForm />
    </EmployeeDashboardWrapper>
  )
}

export default DiscountCreate
