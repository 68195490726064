import { useQuery } from "@tanstack/react-query"
import { detailPartnership } from "../functions"
import { PartnershipQueryKey } from "../type"

const useGetUserDetail = () => {
    return useQuery({
        queryKey: [PartnershipQueryKey.DetailPartnership],
        queryFn: () => detailPartnership(),
    })
}

export default useGetUserDetail
