import { Link, useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { SubmitHandler, useForm } from "react-hook-form"
import { Helmet } from "react-helmet"

import Button from "components/ui/button/Button"
import TextInput from "components/ui/form/TextInput"
import Typography from "components/ui/typography"
import type { UpdatePasswordRequest } from "utils/api/user/types"
import { useMutationUpdatePassword } from "utils/api/user/mutations/hooks"

const ChangePassword = () => {
  const navigate = useNavigate()

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<UpdatePasswordRequest>()

  const { mutate } = useMutationUpdatePassword({
    onSuccess(data) {
      if (!data.success) return
      toast.success("Update password berhasil")
      setTimeout(() => {
        navigate("/settings")
      }, 1000)
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  const onSubmit: SubmitHandler<UpdatePasswordRequest> = (data) => mutate(data)

  return (
    <div>
      <Helmet>
        <title>Pengaturan: Ubah Password</title>
      </Helmet>

      <Typography
        as="h1"
        variant="heading5"
        className="mb-8"
      >
        Ubah Password
      </Typography>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col gap-4 p-8 rounded shadow md:w-[70%]"
      >
        <TextInput
          type="password"
          label="Password saat ini"
          isRequired
          errorMessage={errors.current_password?.message}
          {...register("current_password", {
            required: {
              value: true,
              message: "Password saat ini wajib diisi",
            },
          })}
        />
        <TextInput
          type="password"
          label="Password baru"
          isRequired
          errorMessage={errors.new_password?.message}
          {...register("new_password", {
            required: {
              value: true,
              message: "Password baru wajib diisi",
            },
            pattern: {
              value: /^(?=.*[A-Za-z])(?=.*\d).{8,}$/,
              message: `Pastikan password yang Anda masukkan terdiri dari minimal 8 karakter dan setidaknya mengandung 1 huruf dan 1 angka`,
            },
          })}
        />
        <TextInput
          type="password"
          label="Konfirmasi password baru"
          isRequired
          errorMessage={errors.new_password_confirmation?.message}
          {...register("new_password_confirmation", {
            required: {
              value: true,
              message: "Konfirmasi password baru wajib diisi",
            },
            validate: (value) => {
              if (watch("new_password") !== value) {
                return "Konfirmasi password tidak sesuai. Pastikan kedua password yang Anda masukkan sama"
              }
              return
            },
          })}
        />
        <div className="space-x-2 mt-2">
          <Button
            as={Link}
            to="/settings"
            variant="outlined"
            color="tertiary"
          >
            Kembali
          </Button>
          <Button type="submit">Simpan</Button>
        </div>
      </form>
    </div>
  )
}

export default ChangePassword
