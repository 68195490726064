import { requestCMS } from "utils/axiosInstance"
import type { DeleteVideoResponse } from "../../types"

const deleteVideo = async (videoID: string) => {
  const res = await requestCMS.delete<DeleteVideoResponse>(
    `employee/course/section/video/delete/${videoID}`
  )
  return res
}

export default deleteVideo
