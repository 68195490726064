import { requestCMS } from "utils/axiosInstance"
import type { DeleteSectionResponse } from "../../types"

const deleteSection = async (sectionID: string) => {
  const res = await requestCMS.delete<DeleteSectionResponse>(
    `/employee/course/section/delete/${sectionID}`
  )
  return res
}

export default deleteSection
