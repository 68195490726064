import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { ToastContainer } from "react-toastify"
import { Helmet } from "react-helmet"

import Router from "./utils/router"
import queryClient from "utils/queryClient"
import React from "react"

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Helmet
        defaultTitle="BeePlus Institute"
        titleTemplate="%s | BeePlus Institute"
      />
      <Router />
      <ToastContainer
        theme="colored"
        position="top-center"
        autoClose={3000}
      />
      <ReactQueryDevtools />
    </QueryClientProvider>
  )
}

export default App
