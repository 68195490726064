import React, { useEffect } from "react"
import Section from "../../ui/section/Section"
import { ReactComponent as FAQIllus } from "../../../assets/illustrations/Questions-pana 1.svg"
import { Accordion, Flowbite } from "flowbite-react"
import { Link } from "react-router-dom"
import { useMediaQuery } from "usehooks-ts"
import { motion, useAnimate, useInView } from "framer-motion"

const HomeFaqArea = () => {
  const desktop = useMediaQuery("(min-width: 1024px)")
  const mobile = !desktop
  const [scope, animate] = useAnimate()
  const isInView = useInView(scope, { once: true })

  useEffect(() => {
    if (isInView) {
      animate(scope.current, { opacity: 1, y: 0 })
    }
  }, [isInView])
  return (
    <Section
      space="top-bottom"
      withContainer
    >
      <div className="flex flex-col gap-8 items-center ">
        <motion.div
          ref={scope}
          initial={{ y: 80, opacity: 0 }}
          className="flex flex-col gap-2 items-center">
          <h2 className="text-2xl font-bold m-0 text-tertiary">
            Pertanyaan Seputar Kelas di BPI
          </h2>
          <h3 className="text-base font-semibold m-0  text-gray-600 text-center">
            Jelajahi Jawaban dan Informasi Mendalam tentang Berbagai Aspek BPI
          </h3>
        </motion.div>
        {desktop && (
          <div className="grid lg:grid-rows-1 lg:grid-flow-col gap-4 lg:mx-24 ">
            <Flowbite
              theme={{
                theme: {
                  accordion: {
                    root: {
                      base: "flex flex-col space-y-6",
                      flush: {
                        off: "border-none",
                        on: "border-none",
                      },
                    },
                    content: {
                      base: "py-5 px-5 rounded border border-gray-100",
                    },
                    title: {
                      arrow: {
                        base: "h-6 w-6 shrink-0",
                        open: {
                          off: "",
                          on: "rotate-180",
                        },
                      },
                      base: "flex w-full rounded border border-gray-100 items-center justify-between py-5 px-5 text-left font-medium text-primary",
                      flush: {
                        off: "hover:bg-primary hover:text-white transition-all duration-200 focus:ring-2 focus:ring-primary",
                        on: "bg-white",
                      },
                      heading: "",
                      open: {
                        off: "",
                        on: "text-white bg-primary",
                      },
                    },
                  },
                },
              }}
            >
              <Accordion collapseAll>
                <Accordion.Panel>
                  <Accordion.Title>
                    Bagaimana pembelajaran ini disampaikan?
                  </Accordion.Title>
                  <Accordion.Content>
                    <p className="text-gray-500">
                      Kursus dapat disampaikan secara daring (video course).
                      Dalam pembelajaran ini, peserta dapat mengakses materi dan
                      tugas melalui platform video course.
                    </p>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                  <Accordion.Title>
                    Apa saja materi yang akan diajarkan dalam pembelajaran ini?
                  </Accordion.Title>
                  <Accordion.Content>
                    Materi yang diajarkan akan bervariasi tergantung pada kelas
                    kursusnya. Untuk informasi lebih lanjut, Anda dapat
                    mengeksplorasi daftar kelas kami yang mencakup penjelasan
                    singkat dari setiap kursus{" "}
                    <Link
                      className="text-secondary text-md hover:underline"
                      to="/courses"
                    >
                      di sini
                    </Link>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                  <Accordion.Title>
                    Apakah ada tugas akhir dalam kursus? Bagaimana BeePlus dapat
                    meningkatkan nilai tambah bagi audiens saya selain dari
                    video kursus?
                  </Accordion.Title>
                  <Accordion.Content>
                    <p className="text-gray-500">
                      Ya, ada tugas akhir/proyek dalam kursus untuk mengukur
                      pemahaman dan kemampuan peserta. Ini bisa berupa tugas
                      proyek, atau tergantung pada kursusnya. Selain itu,
                      BeePlus juga menyediakan latihan-latihan tambahan untuk
                      membantu teman-teman mengonfirmasi pemahaman mereka
                      terhadap materi yang telah dipelajari.
                    </p>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>

              <Accordion collapseAll>
                <Accordion.Panel>
                  <Accordion.Title>
                    Apakah video yang sudah dibeli dapat diakses selamanya?
                  </Accordion.Title>
                  <Accordion.Content>
                    <p className="text-gray-500">
                      Ya, setelah Anda membeli video, Anda dapat mengaksesnya
                      selamanya.
                    </p>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                  <Accordion.Title>
                    Apakah Video yang dibeli dapat diakses selamanya?
                  </Accordion.Title>
                  <Accordion.Content>
                    <p className="text-gray-500">
                      Ya, ada tugas akhir/proyek dalam kursus untuk mengukur
                      pemahaman dan kemampuan peserta. Ini bisa berupa tugas
                      proyek, atau tergantung pada kursusnyai.
                    </p>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                  <Accordion.Title>
                    Bagaimana cara membeli video?
                  </Accordion.Title>
                  <Accordion.Content>
                    <p className="text-gray-500">
                      Jika Anda belum terdaftar, silakan daftar terlebih dahulu.
                      Jika sudah terdaftar, silakan login untuk membeli video.
                    </p>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>
            </Flowbite>
          </div>
        )}
        {mobile && (
          <div className="grid lg:grid-rows-1 lg:grid-flow-col gap-4 lg:mx-24 ">
            <Flowbite
              theme={{
                theme: {
                  accordion: {
                    root: {
                      base: "flex flex-col space-y-6",
                      flush: {
                        off: "border-none",
                        on: "border-none",
                      },
                    },
                    content: {
                      base: "py-5 px-5 rounded border border-gray-100",
                    },
                    title: {
                      arrow: {
                        base: "h-6 w-6 shrink-0",
                        open: {
                          off: "",
                          on: "rotate-180",
                        },
                      },
                      base: "flex w-full rounded border border-gray-100 items-center justify-between py-5 px-5 text-left font-medium text-primary",
                      flush: {
                        off: "hover:bg-primary hover:text-white focus:ring-2 focus:ring-primary",
                        on: "bg-white",
                      },
                      heading: "",
                      open: {
                        off: "",
                        on: "text-white bg-primary",
                      },
                    },
                  },
                },
              }}
            >
              <Accordion>
                <Accordion.Panel>
                  <Accordion.Title>
                    Bagaimana pembelajaran ini disampaikan?
                  </Accordion.Title>
                  <Accordion.Content>
                    <p className="text-gray-500">
                      Kursus dapat disampaikan secara daring (video course).
                      Dalam pembelajaran ini, peserta dapat mengakses materi dan
                      tugas melalui platform video course.
                    </p>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                  <Accordion.Title>
                    Apa saja materi yang akan diajarkan dalam pembelajaran ini?
                  </Accordion.Title>
                  <Accordion.Content>
                    Materi yang diajarkan akan bervariasi tergantung pada kelas
                    kursusnya. Untuk informasi lebih lanjut, Anda dapat
                    mengeksplorasi daftar kelas kami yang mencakup penjelasan
                    singkat dari setiap kursus{" "}
                    <Link
                      className="text-secondary text-md hover:underline"
                      to="/courses"
                    >
                      di sini
                    </Link>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                  <Accordion.Title>
                    Apakah ada tugas akhir dalam kursus? Bagaimana BeePlus dapat
                    meningkatkan nilai tambah bagi audiens saya selain dari
                    video kursus?
                  </Accordion.Title>
                  <Accordion.Content>
                    <p className="text-gray-500">
                      Ya, ada tugas akhir/proyek dalam kursus untuk mengukur
                      pemahaman dan kemampuan peserta. Ini bisa berupa tugas
                      proyek, atau tergantung pada kursusnya. Selain itu,
                      BeePlus juga menyediakan latihan-latihan tambahan untuk
                      membantu teman-teman mengonfirmasi pemahaman mereka
                      terhadap materi yang telah dipelajari.
                    </p>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                  <Accordion.Title>
                    Apakah video yang sudah dibeli dapat diakses selamanya?
                  </Accordion.Title>
                  <Accordion.Content>
                    <p className="text-gray-500">
                      Ya, setelah Anda membeli video, Anda dapat mengaksesnya
                      selamanya.
                    </p>
                  </Accordion.Content>
                </Accordion.Panel>
                <Accordion.Panel>
                  <Accordion.Title>
                    Bagaimana cara membeli video?
                  </Accordion.Title>
                  <Accordion.Content>
                    <p className="text-gray-500">
                      Jika Anda belum terdaftar, silakan daftar terlebih dahulu.
                      Jika sudah terdaftar, silakan login untuk membeli video.
                    </p>
                  </Accordion.Content>
                </Accordion.Panel>
              </Accordion>
            </Flowbite>
          </div>
        )}
      </div>
    </Section>
  )
}

export default HomeFaqArea
