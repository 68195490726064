import { requestUser } from "utils/axiosInstance"
import type { GetTransactionHistoriesResponse } from "../../types"
import { QueryParams } from "utils/types"

const getTransactionHistories = async (qParams?: QueryParams) => {
  const res = await requestUser.get<GetTransactionHistoriesResponse>(
    "/user/transactions",
    qParams
  )
  return res
}

export default getTransactionHistories
