import { useMutation } from "@tanstack/react-query"

import { GeneralSertifikatProgramResponse } from "../../types"
import { GeneralMutationParams } from "utils/types"
import deleteSlide from "../functions/deleteSlide"
import { deleteSertifikatProgram } from "../functions"

const useDeleteProgramSertifikat = (
    options?: GeneralMutationParams<GeneralSertifikatProgramResponse, { sertifikatId: string }>
) => {
    return useMutation({
        mutationFn: ({ sertifikatId }) => deleteSertifikatProgram(sertifikatId),
        ...options,
    })
}

export default useDeleteProgramSertifikat
