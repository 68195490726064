import { forwardRef } from "react"

import clsxm from "utils/clsxm"

const CLASS_VARIANTS = {
  heading1: "text-6xl font-bold",
  heading2: "text-5xl font-bold",
  heading3: "text-4xl font-bold",
  heading4: "text-3xl font-bold",
  heading5: "text-2xl font-bold",
  heading6: "text-xl font-bold",
  subheading1: "text-4xl font-semibold",
  subheading2: "text-3xl font-semibold",
  subheading3: "text-2xl font-semibold",
  subheading4: "text-xl font-semibold",
  text1: "text-lg",
  text2: "text-base",
  text3: "text-sm",
  text4: "text-xs",
} as const

interface TypographyProps<T extends React.ElementType>
  extends React.ComponentPropsWithoutRef<"p"> {
  /** @default <p> tag */
  as?: T
  className?: string
  /**
   * | Variant       | Font Size | Font Weight |
   * | :------------ | :-------- | :---------- |
   * | heading1      | 60px      | 700         |
   * | heading2      | 48px      | 700         |
   * | heading3      | 36px      | 700         |
   * | heading4      | 30px      | 700         |
   * | heading5      | 24px      | 700         |
   * | heading6      | 20px      | 700         |
   * | subheading1   | 36px      | 600         |
   * | subheading2   | 30px      | 600         |
   * | subheading3   | 24px      | 600         |
   * | subheading4   | 20px      | 600         |
   * | text1         | 18px      | 400         |
   * | text2         | 16px      | 400         |
   * | text3         | 14px      | 400         |
   * | text4         | 12px      | 400         |
   */
  variant?: keyof typeof CLASS_VARIANTS
  children?: React.ReactNode
  href?: string
  target?: string
}

const Typography = forwardRef(
  <T extends React.ElementType = "p">(
    {
      as,
      variant = "text2",
      className,
      children,
      href,
      target,
      ...restProps
    }: TypographyProps<T>,
    ref?: React.ComponentPropsWithRef<T>["ref"]
  ) => {
    const Component = as || "p"

    const additionalProps = Component === "a" ? { href, target } : {}

    return (
      <Component
        ref={ref}
        className={clsxm(CLASS_VARIANTS[variant], className)}
        {...restProps}
        {...additionalProps}
      >
        {children}
      </Component>
    )
  }
)

export default Typography
