import { useMutation } from "@tanstack/react-query"

import { UpdatePasswordResponse, UpdatePasswordRequest } from "../../types"
import { updatePassword } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationUpdatePassword = (
  options?: GeneralMutationParams<UpdatePasswordResponse, UpdatePasswordRequest>
) => {
  return useMutation({
    mutationFn: (payload) => updatePassword(payload),
    ...options,
  })
}

export default useMutationUpdatePassword
