import { useParams } from "react-router-dom"
import Status from "components/ui/status"
import { useGetCareerDetail } from "utils/api/cms/queries/hooks"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import Typography from "components/ui/typography"
import DOMPurify from "dompurify"

const CareerDetail = () => {
  const params = useParams()

  const { data: dataDetailCareer } = useGetCareerDetail({
    id: params.id ?? "",
  })

  const dataCareer = dataDetailCareer?.data

  return (
    <EmployeeDashboardWrapper>
      <div className="flex gap-10 justify-between">
        <div className="mt-5 flex flex-col gap-5">
          <div>
            <Typography
              variant="subheading3"
              as="h1"
              className="text-primary"
            >
              Deskripsi
            </Typography>

            <Typography
              variant="text3"
              className="mb-3 text-white"
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    dataCareer?.description ?? ``, // `<p><strong><u>Lorem Ipsum is</u></strong> simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the<strong><u> 1500s,</u></strong> when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p><p><br></p><p>Berikut link materi yang dapat di download :</p><p><a href="https://drive.google.com/file/d/1aE_CJZ9CCVmDHOz8ZYq4I28bluZMYt9r/view?usp=sharing" rel="noopener noreferrer" target="_blank"><u>https://drive.google.com/file/d/1aE_CJZ9CCVmDHOz8ZYq4I28bluZMYt9r/view?usp=sharing</u></a></p>`
                    {
                      USE_PROFILES: { svg: false, html: true },
                    }
                  ),
                }}
              ></div>
            </Typography>
          </div>
          <div>
            <Typography
              variant="subheading3"
              as="h1"
              className="text-primary"
            >
              Halaman
            </Typography>
            <Typography
              variant="text3"
              className="text-white"
            >
              {dataCareer?.page}
            </Typography>
          </div>
          <div>
            <Typography
              variant="subheading3"
              as="h1"
              className="text-primary"
            >
              Diperbarui pada
            </Typography>
            <Typography
              variant="text3"
              className="text-white"
            >
              {dataCareer?.updated_at}
            </Typography>
          </div>
          <div>
            <Typography
              variant="subheading3"
              as="h1"
              className="text-primary"
            >
              Dibuat pada
            </Typography>
            <Typography
              variant="text3"
              className="text-white"
            >
              {dataCareer?.created_at}
            </Typography>
          </div>
        </div>
        <div className="my-6 md:w-1/3">
          <img
            src={dataCareer?.company_icon}
            alt="thumbnail"
            width={120}
            height={120}
            className="object-cover h-[120px] mb-5"
          />
          <Typography
            variant="heading3"
            as="h1"
            className="text-primary"
          >
            {dataCareer?.title}
          </Typography>
          <div className="mt-5 flex flex-col gap-3">
            <div className="flex justify-between">
              <Typography
                variant="text3"
                className="mb-4 font-medium text-white"
              >
                Kategori:
              </Typography>
              {dataCareer?.categories?.map((category) => (
                <Typography
                  key={category.id}
                  variant="text3"
                  className="mb-2 font-medium text-green-300 px-2.5 py-0.5 bg-green-900 rounded"
                >
                  {category.name}
                </Typography>
              ))}
            </div>
            <div className="flex justify-between">
              <Typography
                key={dataCareer?.id}
                variant="text3"
                className="mb-2 font-medium text-white"
              >
                Lokasi:
              </Typography>
              <Typography
                key={dataCareer?.id}
                variant="text3"
                className="mb-2 font-medium text-green-300 px-2.5 py-0.5 bg-green-900 rounded"
              >
                {dataCareer?.location}
              </Typography>
            </div>
            <div className="flex justify-between">
              <Typography
                key={dataCareer?.id}
                variant="text3"
                className="mb-2 font-medium text-white"
              >
                Status Karir:
              </Typography>
              <Status isActive={dataCareer?.is_active ?? false} />
            </div>
            <div className="flex justify-between">
              <Typography
                key={dataCareer?.id}
                variant="text3"
                className="mb-2 font-medium text-white"
              >
                Status Penerbitan:
              </Typography>
              <Status isActive={dataCareer?.publishable ?? false} />
            </div>
          </div>
        </div>
      </div>
    </EmployeeDashboardWrapper>
  )
}

export default CareerDetail
