import { requestCMS } from "utils/axiosInstance"
import type { UpdatePasswordRequest, UpdatePasswordResponse } from "../../types"

const updatePassword = async (payload: UpdatePasswordRequest) => {
  const res = await requestCMS.post<UpdatePasswordResponse>(
    "/employee/password/update",
    payload
  )
  return res
}

export default updatePassword
