import Section from "components/ui/section/Section"
import EmployeeDashboardWrapper from "../../../components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "../../../components/ui/breadcrumb/DefBreadcrumb"
import { Course, Webinar } from "../../../utils/types"
import { getCMSSegments } from "../../../utils/helper"
import Typography from "components/ui/typography"
import { createColumnHelper } from "@tanstack/react-table"
import ReactTable from "../../../components/ui/react-table/ReactTable"
import Status from "components/ui/status"
import { User } from "utils/api/cms/types"
import { useGetUserDetail } from "utils/api/cms/queries/hooks"
import { useParams } from "react-router-dom"
import clsxm from "utils/clsxm"

const UserManagementDetail = () => {
  const segments = getCMSSegments()

  const { id } = useParams()

  const { data } = useGetUserDetail(Number(id))

  const userColHelper = createColumnHelper<User>()
  const userDetailColumns = [
    userColHelper.accessor("first_name", {
      header: () => "Nama",
    }),
    userColHelper.accessor("email", {
      header: () => "Email",
    }),
    userColHelper.accessor("phone", {
      header: () => "No.Telepon",
    }),
    userColHelper.accessor("created_at", {
      header: () => "Dibuat pada",
    }),
  ]

  const courseColHelper = createColumnHelper<Course>()
  const courseColumns = [
    courseColHelper.accessor("title", {
      header: () => "Judul Modul Belajar",
    }),
    courseColHelper.accessor("description", {
      header: () => "Deskripsi",
      cell: (props) => props.getValue()?.replace(/(<([^>]+)>)/gi, ""),
    }),
    courseColHelper.accessor("price", {
      header: () => "Harga",
      cell: (props) => (
        <dl className="max-w-md text-white divide-y divide-gray-200">
          <div className="flex flex-col pb-3">
            <dt className="mb-1 text-gray-400 md:text-lg">Harga Asli</dt>
            <dd className="text-lg font-semibold">{`${props.getValue()
              ?.currency_text} ${props.getValue()?.master_price_text}`}</dd>
          </div>
          <div className="flex flex-col pb-3">
            <dt className="mb-1 text-gray-400 md:text-lg">Harga Terkini</dt>
            <dd className="text-lg font-semibold">{`${props.getValue()
              ?.currency_text} ${props.getValue()?.current_price_text}`}</dd>
          </div>
        </dl>
      ),
    }),
    courseColHelper.accessor("is_active", {
      header: () => "Status Modul",
      cell: (props) => <Status isActive={props.getValue()} />,
    }),
    courseColHelper.accessor("categories", {
      header: () => "Kategori",
      cell: (props) => {
        return (
          <ul className="max-w-md space-y-1 text-white list-none list-inside">
            {props
              .getValue()
              ?.map((category) => <li key={category.id}>{category.name}</li>)}
          </ul>
        )
      },
    }),
  ]

  const webinarColHelper = createColumnHelper<Webinar>()
  const webinarColumns = [
    webinarColHelper.accessor("title", {
      header: () => "Judul Modul Belajar",
    }),
    webinarColHelper.accessor("description", {
      header: () => "Deskripsi",
      cell: (props) => props.getValue()?.replace(/(<([^>]+)>)/gi, ""),
    }),
    webinarColHelper.accessor("price", {
      header: () => "Harga",
      cell: (props) => (
        <dl className="max-w-md text-white divide-y divide-gray-200">
          <div className="flex flex-col pb-3">
            <dt className="mb-1 text-gray-400 md:text-lg">Harga Asli</dt>
            <dd className="text-lg font-semibold">{`${props.getValue()
              ?.currency_text} ${props.getValue()?.master_price_text}`}</dd>
          </div>
          <div className="flex flex-col pb-3">
            <dt className="mb-1 text-gray-400 md:text-lg">Harga Terkini</dt>
            <dd className="text-lg font-semibold">{`${props.getValue()
              ?.currency_text} ${props.getValue()?.current_price_text}`}</dd>
          </div>
        </dl>
      ),
    }),
    webinarColHelper.accessor("is_active", {
      header: () => "Status Modul",
      cell: (props) => <Status isActive={props.getValue()} />,
    }),
    webinarColHelper.accessor("categories", {
      header: () => "Kategori",
      cell: (props) => {
        return (
          <ul className="max-w-md space-y-1 text-white list-none list-inside">
            {props
              .getValue()
              ?.map((category) => <li key={category.id}>{category.name}</li>)}
          </ul>
        )
      },
    }),
  ]

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix={"cms"}
      />
      <Typography
        as="h4"
        variant="text4"
        className="font-bold text-primary text-lg"
      >
        User Detail
      </Typography>
      <ReactTable
        columns={userDetailColumns}
        data={data?.data ? [data.data] : []}
      />
      <div className="container">
        <Section space="top-bottom">
          <Section.Title className={clsxm("text-center lg:text-left")}>
            Purchased <span className="text-primary">Courses</span>
          </Section.Title>
          <ReactTable
            columns={courseColumns}
            data={data?.data ? data.data.purchased_courses : []}
          />
        </Section>
        <Section space="top-bottom">
          <Section.Title className={clsxm("text-center lg:text-left")}>
            Purchased <span className="text-primary">Webinars</span>
          </Section.Title>
          <ReactTable
            columns={webinarColumns}
            data={data?.data ? data.data.purchased_webinars : []}
          />
        </Section>
      </div>
    </EmployeeDashboardWrapper>
  )
}

export default UserManagementDetail
