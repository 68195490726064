import { useState, useEffect } from "react"
import { Link, useNavigate } from "react-router-dom"
import {
  PencilSquareIcon,
  EyeIcon,
  PaperAirplaneIcon,
  BookOpenIcon,
  NoSymbolIcon,
  DocumentPlusIcon,
} from "@heroicons/react/24/solid"
import { PaginationState, createColumnHelper } from "@tanstack/react-table"
import { Tooltip } from "flowbite-react"
import Modal from "components/ui/modal/Modal"
import ReactTable from "components/ui/react-table/ReactTable"
import Button from "components/ui/button/Button"
import Status from "components/ui/status"
import { useGetCareers } from "utils/api/cms/queries/hooks"
import type { Career } from "utils/api/cms/types"
import CMSDefaultTableListFilter from "./CMSDefaultTableListFilter"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import FileInput from "components/ui/form/FileInput"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import ReactSimpleImageViewer from "react-simple-image-viewer"
import Typography from "components/ui/typography"
import { toast } from "react-toastify"
import {
  useMutationUploadCompanyIcon,
  useMutationPublishCareer,
  useMutationDisableCareer,
} from "utils/api/cms/mutations/hooks"
import { CMSCareerListResponse } from "utils/types"

const CareerList = () => {
  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 1,
  })
  const [lastPage, setLastPage] = useState(1)
  const [careerResponse, setCareerResponse] =
    useState<CMSCareerListResponse<Career>>()
  const [searchFilter, setSearchFilter] = useState("")
  const [perPageLimit, setPerPageLimit] = useState(10)
  const navigate = useNavigate()
  const [previewThumbnailID, setPreviewThumbnailID] = useState<number>()
  const [previewImages, setPreviewImages] = useState<string[]>([])
  const [isPreviewOpen, setIsPreviewOpen] = useState(false)
  const [dataCareers, setDataCareers] = useState<Career[]>([])
  const [modalPublish, setModalPublish] = useState<{
    isOpen: boolean
    careerID: string
    careerName: string
  }>()

  const [modalDisable, setModalDisable] = useState<{
    isOpen: boolean
    careerID: string
    careerName: string
  }>()

  const { data: resultResponse } = useGetCareers({
    options: {
      staleTime: 0,
    },
    qParams: {
      limit: perPageLimit,
      page: pagination.pageIndex + 1,
      keyword: searchFilter,
    },
  })

  useEffect(() => {
    setCareerResponse(resultResponse)
    setDataCareers(resultResponse?.data || [])
    setLastPage(resultResponse?.meta?.last_page || 1)
  }, [resultResponse])

  useEffect(() => {
    const dataIcons = dataCareers?.map((career) => career.company_icon)
    setPreviewImages(dataIcons || [])
  }, [dataCareers])

  const { mutate: mutateUploadFile } = useMutationUploadCompanyIcon({
    onSuccess(data, variables) {
      if (!data.success) return

      toast.success("Berhasil upload thumbnail")
      setDataCareers((prev) => {
        const tempData = prev.map((d) => {
          if (d.id === Number(variables.careerId)) {
            d.company_icon = data.data.icon
          }
          return d
        })
        return tempData
      })
    },
  })

  const handleDeleteFile = async (careerID: number) => {
    setDataCareers((prev) => {
      const tempData = prev.map((d) => {
        if (d.id == careerID) {
          d.company_icon = ""
        }
        return d
      })
      return tempData
    })
  }

  const { mutate: mutatePublishCareer } = useMutationPublishCareer({
    onSuccess(data, variables) {
      toast.success("Berhasil publish career")
      handleModalPublish()
      setDataCareers((prev) => {
        const tempData = prev.map((d) => {
          if (d.id === Number(variables.careerID)) {
            d.is_active = true
          }
          return d
        })
        return tempData
      })
    },
  })

  const { mutate: mutateDisableCareer } = useMutationDisableCareer({
    onSuccess(data, variables) {
      toast.success("Berhasil disable career")
      handleModalDisable()
      setDataCareers((prev) => {
        const tempData = prev.map((d) => {
          if (d.id === Number(variables.careerID)) {
            d.is_active = false
          }
          return d
        })
        return tempData
      })
    },
  })

  const handleModalPublish = (careerID?: string, careerName?: string) => {
    setModalPublish((prev) => ({
      isOpen: !prev?.isOpen,
      careerID: careerID ?? "",
      careerName: careerName ?? "",
    }))
  }

  const handleModalDisable = (careerID?: string, careerName?: string) => {
    setModalDisable((prev) => ({
      isOpen: !prev?.isOpen,
      careerID: careerID ?? "",
      careerName: careerName ?? "",
    }))
  }

  const handleChangeFile =
    (careerID: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0]
      if (!file) return
      mutateUploadFile({
        careerId: careerID,
        payload: { icon: file },
      })
    }

  const handlePreview = (index?: number) => {
    setPreviewThumbnailID(index)
    setIsPreviewOpen((prev) => !prev)
  }

  const careerColumnHelper = createColumnHelper<Career>()

  const careerColumns = [
    careerColumnHelper.accessor("title", {
      header: "Judul",
      cell: (props) => props.getValue(),
    }),
    careerColumnHelper.accessor("company_icon", {
      header: "Ikon Perusahaan",
      cell: (props) => (
        <div className="relative w-[120px] h-[85px]">
          <FileInput
            label="Upload Gambar"
            name="thumbnail"
            value={props.getValue()}
            onChange={handleChangeFile(props.row.original.id.toString())}
            onDelete={() => handleDeleteFile(props.row.original.id)}
            onPreview={() => handlePreview(props.row.index)}
            accept="image/png, image/jpg"
          />
        </div>
      ),
    }),
    careerColumnHelper.accessor("is_active", {
      header: "Status Karir",
      cell: (props) => <Status isActive={props.getValue()} />,
    }),
    careerColumnHelper.accessor("publishable", {
      header: "Status Penerbitan",
      cell: (props) => <Status isActive={props.getValue()} />,
    }),
    careerColumnHelper.accessor("status_pekerjaan", {
      header: "Status Pekerjaan",
      cell: (props) => props.getValue() ?? '-',
    }),
    careerColumnHelper.accessor("company", {
      header: "Perusahaan",
      cell: (props) => props.getValue(),
    }),
    careerColumnHelper.accessor("career_users", {
      header: "Total Pelamar",
      cell: (props) => props.row.original.career_users?.length || 0,
    }),
    careerColumnHelper.accessor("working_type", {
      header: "Tipe Pekerjaan",
      cell: (props) => props.getValue(),
    }),
    careerColumnHelper.display({
      id: "actions",
      header: "Aksi",
      cell: (props) => (
        <div className="flex flex-row w-fit space-x-3">
          <Tooltip content="Edit">
            <ButtonWithIcon
              class="mx-auto border-2 border-transparent bg-yellow-400 text-white hover:bg-yellow-300 hover:border-yellow-300"
              type="button"
              action={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault()
                return navigate(`edit/${props.row.original.id}`)
              }}
              sizeClass="p-1"
            >
              <PencilSquareIcon className="h-5 w-5" />
            </ButtonWithIcon>
          </Tooltip>
          <Tooltip content="Detail">
            <ButtonWithIcon
              class="mx-auto border-2 border-transparent bg-blue-600 text-white hover:bg-blue-500 hover:border-blue-500"
              type="button"
              action={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault()
                return navigate(`detail/${props.row.original.id}`)
              }}
              sizeClass="p-1"
            >
              <EyeIcon className="h-5 w-5" />
            </ButtonWithIcon>
          </Tooltip>
          <Tooltip content="Peserta">
            <ButtonWithIcon
              class="mx-auto border-2 border-transparent bg-purple-600 text-white hover:bg-purple-500 hover:border-purple-500"
              type="button"
              action={(e: React.MouseEvent<HTMLButtonElement>) => {
                e.preventDefault()
                return navigate(`peserta/${props.row.original.id}`)
              }}
              sizeClass="p-1"
            >
              <BookOpenIcon className="h-5 w-5" />
            </ButtonWithIcon>
          </Tooltip>
          {!props.row.original.is_active ? (
            <Tooltip content="Publish">
              <ButtonWithIcon
                disabled={!props.row.original.publishable}
                class="mx-auto border-2 border-transparent bg-green-600 text-white hover:bg-green-500 hover:border-green-500"
                type="button"
                action={(e: React.MouseEvent<HTMLButtonElement>) => {
                  e.preventDefault()
                  handleModalPublish(
                    props.row.original.id.toString(),
                    props.row.original.title
                  )
                }}
                sizeClass="p-1"
              >
                <PaperAirplaneIcon className="h-5 w-5" />
              </ButtonWithIcon>
            </Tooltip>
          ) : (
            <div>
              <Tooltip content="Disable">
                <ButtonWithIcon
                  disabled={!props.row.original.publishable}
                  class="mx-auto border-2 border-transparent bg-red-600 text-white hover:bg-red-500 hover:border-red-500"
                  type="button"
                  action={(e: React.MouseEvent<HTMLButtonElement>) => {
                    e.preventDefault()
                    handleModalDisable(
                      props.row.original.id.toString(),
                      props.row.original.title
                    )
                  }}
                  sizeClass="p-1"
                >
                  <NoSymbolIcon className="h-5 w-5" />
                </ButtonWithIcon>
              </Tooltip>
            </div>
          )}
        </div>
      ),
    }),
  ]

  const handleSearch = (keyword: string) => {
    setSearchFilter(keyword)
    setPagination((prevPagination) => ({ ...prevPagination, pageIndex: 0 }))
  }

  return (
    <>
      <div className="flex flex-wrap justify-between gap-4 my-6">
        <Button
          as={Link}
          to="create"
          className="border-2 flex items-center border-transparent bg-primary text-white gap-2 hover:bg-transparent hover:text-white hover:border-primary"
        >
          <DocumentPlusIcon className="h-6 w-6 text-white" />
          <span>Tambah Karir</span>
        </Button>
        <CMSDefaultTableListFilter
          pageSelector
          searchPlaceHolder="Cari judul karir"
          wrapperClass="ml-auto flex flex-row space-x-6 "
          onSearchHandler={handleSearch}
          perPageHandler={(selected) => setPerPageLimit(selected)}
        />
      </div>
      <ReactTable
        columns={careerColumns}
        data={dataCareers ?? []}
        pagination={pagination}
        onPaginationChange={setPagination}
        pageCount={lastPage}
      />

      {isPreviewOpen && (
        <ReactSimpleImageViewer
          src={previewImages ?? []}
          currentIndex={previewThumbnailID}
          onClose={handlePreview}
          disableScroll
          backgroundStyle={{
            backgroundColor: "rgba(0,0,0,0.9)",
          }}
          closeOnClickOutside={true}
        />
      )}

      <Modal
        header="Publish Karir"
        onclose={handleModalPublish}
        show={!!modalPublish?.isOpen}
        body={
          <Typography className="text-white">
            Apakah Anda yakin untuk publish karir{" "}
            <b>{modalPublish?.careerName}</b>?
          </Typography>
        }
        footer={
          <div className="space-x-2 ml-auto">
            <Button
              onClick={() => handleModalPublish()}
              variant="outlined"
              className="text-gray-300 border-gray-300"
            >
              Batal
            </Button>
            <Button
              onClick={() =>
                mutatePublishCareer({
                  careerID: modalPublish?.careerID ?? "",
                })
              }
            >
              Publish
            </Button>
          </div>
        }
      />

      <Modal
        header="Disable Karir"
        onclose={handleModalDisable}
        show={!!modalDisable?.isOpen}
        body={
          <Typography className="text-white">
            Apakah Anda yakin untuk disable karir{" "}
            <b>{modalDisable?.careerName}</b>?
          </Typography>
        }
        footer={
          <div className="space-x-2 ml-auto">
            <Button
              onClick={() => handleModalDisable()}
              variant="outlined"
              className="text-gray-300 border-gray-300"
            >
              Batal
            </Button>
            <Button
              onClick={() =>
                mutateDisableCareer({
                  careerID: modalDisable?.careerID ?? "",
                })
              }
            >
              Disable
            </Button>
          </div>
        }
      />
    </>
  )
}

export default CareerList
