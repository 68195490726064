import { useState } from "react"
import { LoaderFunction, useLoaderData, useNavigate } from "react-router-dom"
import to from "await-to-js"
import { SubmitHandler } from "react-hook-form"
import Swal from "sweetalert2"

import CourseSectionManagementForm, {
  CourseSectionForm,
} from "components/cms/CourseSectionManagementForm"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import api from "utils/axiosInstance"
import { getCMSSegments } from "utils/helper"
import {
  CMSCreateCourseSectionResponse,
  CMSGetCourseResponse,
  Course,
  GeneralApiResponse,
  GeneralAPIValidationResponse,
} from "utils/types"

const CourseSectionManagementCreate: React.FC = () => {
  const segments = getCMSSegments()
  const navigate = useNavigate()
  const loaderResponse = useLoaderData() as {
    maxSequence: GeneralApiResponse<number>
    course: CMSGetCourseResponse<Course>
  }
  const [courseSectionSubmitting, setCourseSectionSubmitting] =
    useState<boolean>(false)
  const [serverValidationError, setServerValidation] = useState<{
    title: string | undefined
    sequence: string | undefined
  }>({
    title: undefined,
    sequence: undefined,
  })

  const sectionSubmitHandler: SubmitHandler<CourseSectionForm> = async (
    data
  ) => {
    setCourseSectionSubmitting(true)
    const courseID = loaderResponse.course.data.id
    const [err, _] = await to<
      CMSCreateCourseSectionResponse,
      GeneralAPIValidationResponse
    >(api.cms.createCourseSection(courseID, data))

    if (!err) {
      navigate(`/cms/course/detail/${courseID}`, {
        state: { courseSectionUpsertSuccess: true },
      })

      return
    }

    if (!err.hasOwnProperty("errors")) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: err.message,
      })

      setCourseSectionSubmitting(false)

      return
    }

    const validationResponse = { ...serverValidationError }

    Object.keys(validationResponse).forEach((field) => {
      const errMessages: string[] | undefined = err!.errors![field]

      if (errMessages === undefined) return

      const message = (errMessages as string[]).join(". ")

      validationResponse[field] = message
    })

    setServerValidation(validationResponse)

    setCourseSectionSubmitting(false)
  }

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix="cms"
      />
      <h2 className="text-xl text-primary font-semibold leading-loose tracking-wide">
        Tambah Konten Bagian Belajar
      </h2>
      <CourseSectionManagementForm
        method="POST"
        maxSeq={loaderResponse.maxSequence.data + 1}
        onSubmitHandler={sectionSubmitHandler}
        serverValidationResponse={serverValidationError}
        submitting={courseSectionSubmitting}
      />
    </EmployeeDashboardWrapper>
  )
}

export default CourseSectionManagementCreate

export const courseSectionCreateLoader: LoaderFunction = async ({ params }) => {
  const courseID = params.id

  const loadMaxSeq = async () => {
    const maxResponse = await api.cms.getCourseSectionMaxSeq(
      +(courseID as string)
    )

    return maxResponse
  }

  const loadCourse = async () => {
    const courseResponse = await api.cms.getCourse(+(courseID as string))

    return courseResponse
  }

  return {
    maxSequence: await loadMaxSeq(),
    course: await loadCourse(),
  }
}
