import { requestCMS } from "utils/axiosInstance"
import { GeneralSlideResponse } from "../../types"

const deleteSlide = async (slideId: string) => {
  const res = await requestCMS.delete<GeneralSlideResponse>(
    `/employee/slide/${slideId}`
  )
  return res
}

export default deleteSlide
