import { useQuery } from "@tanstack/react-query"

import { getSertifikatProgram } from "../functions"
import { CmsQueryKey, GetSertifikatProgramParams } from "../../types"

const useGetSertifikatProgram = (params?: GetSertifikatProgramParams) => {
    return useQuery({
        queryKey: [CmsQueryKey.SertifikatProgram, params?.qParams ?? ""],
        queryFn: () => getSertifikatProgram(params?.qParams),
        ...params?.options,
    })
}

export default useGetSertifikatProgram
