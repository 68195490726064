import { SubmitHandler, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import TextInput from "components/ui/form/TextInput"
import { useMutationUpdateSertifikat } from "utils/api/cms/mutations/hooks"
import { GeneralSertifikatProgramRequest } from "utils/api/cms/types"
import {
    BookmarkIcon,
    ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"
import { useEffect } from "react"
import useMutationCreateSertifikat from "utils/api/cms/mutations/hooks/useMutationCreateSertifikat"
import { useGetSertifikatDetail } from "utils/api/cms/queries/hooks"
import useMutationCreateSertifikatProgram from "utils/api/cms/mutations/hooks/useMutationCreateSertifikatProgram"

interface SertifikatForm {
    name: string
    code: string
    type: string
}

const SertifikatFormProgram = () => {
    const navigate = useNavigate()
    const params = useParams()

    const {
        handleSubmit,
        register,
        control,
        watch,
        getValues,
        setValue,
        reset,
        formState: { errors, isSubmitting },
    } = useForm<SertifikatForm>({})

    const { mutate: mutateCreateSertifikat } =
        useMutationCreateSertifikatProgram({
            onSuccess(data) {
                if (!data.success) {
                    return
                }
                toast.success("Sertifikat program berhasil dibuat")
                setTimeout(() => {
                    navigate(`/cms/sertifikat/detail/${params.id}`)
                }, 1000)
            },
            onError(error) {
                toast.error(error.message)
            },
        })

    const onSubmit: SubmitHandler<SertifikatForm> = (data) => {
        const payload: GeneralSertifikatProgramRequest = {
            sertifikat_id: Number(params.id),
            name: data.name,
            code: data.code,
            type: data.type
        }

        return mutateCreateSertifikat(payload)
    }

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-4 pb-4 min-w-[42rem]"
        >
            <TextInput
                type="text"
                className="cms-base-input"
                labelClass="cms-base-label"
                isRequired
                label="Nama"
                errorMessage={errors.name?.message}
                errorIcon={
                    <ExclamationTriangleIcon
                        height={24}
                        width={24}
                        className="text-red-800"
                    />
                }
                {...register("name", {
                    required: {
                        value: true,
                        message: "Nama wajib diisi",
                    },
                })}
            />
            <TextInput
                type="text"
                className="cms-base-input"
                labelClass="cms-base-label"
                isRequired
                label="Kode"
                errorMessage={errors.code?.message}
                errorIcon={
                    <ExclamationTriangleIcon
                        height={24}
                        width={24}
                        className="text-red-800"
                    />
                }
                {...register("code", {
                    required: {
                        value: true,
                        message: "Kode wajib diisi",
                    },
                })}
            />
            <TextInput
                type="text"
                className="cms-base-input"
                labelClass="cms-base-label"
                isRequired
                label="Tipe"
                errorMessage={errors.type?.message}
                errorIcon={
                    <ExclamationTriangleIcon
                        height={24}
                        width={24}
                        className="text-red-800"
                    />
                }
                {...register("type", {
                    required: {
                        value: true,
                        message: "Tipe wajib diisi",
                    },
                })}
            />
            <ButtonWithIcon
                action={undefined}
                type="submit"
                class="self-start mt-12 border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
                disabled={isSubmitting}
            >
                <BookmarkIcon className="h-6 w-6 text-white" />
                <span>
                    {!isSubmitting ? (
                        <>{"Tambahkan"}</>
                    ) : (
                        "Menyimpan..."
                    )}
                </span>
            </ButtonWithIcon>
        </form>
    )
}

export default SertifikatFormProgram
