import { requestCMS } from "utils/axiosInstance"
import type { UploadThumbnail, UploadThumbnailResponse } from "../../types"

const uploadThumbnailCourse = async (
  courseID: string,
  payload: UploadThumbnail
) => {
  const res = await requestCMS.upload<UploadThumbnailResponse>(
    `/employee/course/upload-thumbnail/${courseID}`,
    payload,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  )
  return res
}

export default uploadThumbnailCourse
