import { GeneralSertifikatRequest, GeneralSertifikatResponse } from "utils/api/cms/types"
import { requestCMS } from "utils/axiosInstance"

const createSertifikat = async (payload: GeneralSertifikatRequest) => {
    const res = await requestCMS.post<GeneralSertifikatResponse>(
        "/employee/sertifikat",
        payload
    )
    return res
}

export default createSertifikat
