import { useQuery } from "@tanstack/react-query"
import { CmsQueryKey, GetCareerDetailParams } from "../../types"
import { getCourseCategoryDetail } from "../functions"

const useGetCourseCategoryDetail = (params?: GetCareerDetailParams) => {
  return useQuery({
    queryKey: [CmsQueryKey.CourseCategory, params?.id ?? ""],
    queryFn: () => getCourseCategoryDetail(params?.id),
  })
}

export default useGetCourseCategoryDetail
