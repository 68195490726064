import React, { useState, useEffect, useMemo } from "react"
import clsx from "clsx"
import "react-tabs-scrollable/dist/rts.css"
import { useGetCourseCategories } from "utils/api/public/queries/hooks"
import { useInfiniteGetCourses } from "utils/api/public/queries/hooks"
import { useForm } from "react-hook-form"
import { useMediaQuery } from "usehooks-ts"
import CardVerticalSkeleton from "components/public/components/CardVerticalSkeleton"
import { Fragment } from "react"
import CourseCard from "components/public/components/CourseCard"
import TabsFilter from "../tabs-filter"
import "./style.css"
import Carousel from "react-multi-carousel"
import clsxm from "utils/clsxm"

export default function TabsScroll() {
  const desktop = useMediaQuery("(min-width: 1024px)")
  const { register, watch } = useForm()
  const { data: dataCategories, isLoading: isLoadingCategories } =
    useGetCourseCategories({
      options: {
        staleTime: 5 * 60 * 1000,
      },
    })

  const {
    data: dataCourses,
    isLoading: isLoadingCourses,
  } = useInfiniteGetCourses({
    qParams: {
      category_id: Number(watch("category")) || undefined,
      limit: 9,
    },
  })

  const categoryItems = dataCategories?.data.map((category) => ({
    name: category.name,
    value: category.id,
  }))
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      partialVisibilityGutter: 40,
    },
    mobile: {
      breakpoint: { max: 460, min: 0 },
      items: 1,
      partialVisibilityGutter: 30,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      partialVisibilityGutter: 30,
    },
  }
  return (
    <div className="flex flex-col gap-6">
      <TabsFilter
        sections={[
          {
            category: "Career",
            register: register("category"),
            items: [
              { name: "Semua Kategori", value: 0 },
              ...(categoryItems ?? []),
            ],
          },
        ]}
      />
      {isLoadingCourses
        ? [...Array(1)].map((_, index) => (
          <div className="flex">
            <CardVerticalSkeleton />
          </div>
        ))
        :
        dataCourses?.pages.map((course, index) => (
          <Carousel
            key={index}
            additionalTransfrom={0}
            arrows
            autoPlaySpeed={3000}
            centerMode={false}
            className=""
            containerClass="container-with-dots"
            dotListClass=""
            draggable
            focusOnSelect
            infinite={false}
            itemClass=""
            keyBoardControl
            minimumTouchDrag={80}
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
            responsive={responsive}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={false}
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {course.data.map((course) => (
              <CourseCard
                key={course.id}
                to={`/courses/${course.slug}`}
                imgSrc={course.thumbnail}
                title={course.title}
                currency={course.price.currency_text}
                currentPrice={course.price.current_price_text}
                masterPrice={course.price.master_price_text}
                lecturer={course.lecturer}
                totalVideo={(course.videos_count ?? 0) + " Video"}
                className="hover:translate-y-1 transition-all duration-300"
              />
            ))}
          </Carousel>
        ))}

    </div >
  )
}
