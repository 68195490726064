import { requestPartnership } from "utils/axiosInstance"
import { GeneralNewPassword } from "../type"
import { GeneralPartnershipRequest } from "utils/api/cms/types"

const updatePassword = async (
    id: string,
    payload: GeneralNewPassword
) => {
    const res = await requestPartnership.put<GeneralPartnershipRequest>(
        `/partnership/new-password/${id}`,
        payload
    )
    return res
}

export default updatePassword
