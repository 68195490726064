import { useMutation } from "@tanstack/react-query"

import { DeactivateCourseResponse } from "../../types"
import { deactivateCourse } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationDeactivateCourse = (
  options?: GeneralMutationParams<
    DeactivateCourseResponse,
    { courseID: string }
  >
) => {
  return useMutation({
    mutationFn: ({ courseID }) => deactivateCourse(courseID),
    ...options,
  })
}

export default useMutationDeactivateCourse
