import { useInfiniteQuery } from "@tanstack/react-query"

import { getWorkshops } from "../functions"
import { PublicQueryKey, PublicGetInfiniteWorkshopsParams } from "../../types"

const useInfiniteGetWorkshops = (params?: PublicGetInfiniteWorkshopsParams) => {
  return useInfiniteQuery({
    queryKey: [PublicQueryKey.Workshops, params?.qParams ?? {}],
    queryFn: ({ pageParam }) =>
      getWorkshops({ ...params?.qParams, page: pageParam }),
    getNextPageParam: (lastPage) => {
      if (!lastPage.meta) return

      const isLastPage = lastPage.meta.current_page === lastPage.meta?.last_page
      if (isLastPage) return

      return lastPage.meta.current_page + 1
    },
    ...params?.options,
  })
}

export default useInfiniteGetWorkshops
