import { NavLink, useLocation } from "react-router-dom"
import {
  Cog6ToothIcon,
  CubeIcon,
  CurrencyDollarIcon,
  RocketLaunchIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid"
import clsx from "clsx"
import { useMediaQuery } from "usehooks-ts"

import Typography from "components/ui/typography"

interface UserDashboardNavigationProps {
  firstName: string
  middleName: string
  lastName: string
  email: string
}

const UserDashboardNavigation = ({
  firstName,
  middleName,
  lastName,
  email,
}: UserDashboardNavigationProps) => {
  const mdSize = useMediaQuery("(min-width: 768px)")
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  return (
    <>
      {mdSize && (
        <div className={clsx("w-[30%] min-w-[240px]", " shadow-md border-t-2 border-gray-100", "rounded-xl", "p-4")}>
          <div className=" flex flex-col items-center ">
            <UserCircleIcon
              className="text-primary"
              width={76}
              height={76}
            />
            <div className="text-center mb-4">
              <Typography className="font-medium capitalize">
                {firstName} {middleName} {lastName}
              </Typography>
              <Typography
                variant="text3"
                className="text-typo-gray"
              >
                {email}
              </Typography>
            </div>
            <ul className="font-medium text-base space-y-4 pt-6 border-t-2">
              <li>
                <NavLink
                  className={({ isActive }) =>
                    clsx("flex text-gray-500 items-center gap-3", [
                      isActive && "text-primary",
                    ])
                  }
                  to="/my-course"
                >
                  <div className={splitLocation[1] === "my-course"
                    ? "bg-primary text-white border rounded-lg p-2"
                    : "border rounded-lg bg-gray-500 text-white p-2"
                  }>
                    <RocketLaunchIcon
                      width={24}
                      height={24}
                      className={splitLocation[1] === "my-course"
                        ? "-rotate-45 transition-all duration-300 delay-150"
                        : "transition-all duration-300"}
                    />
                  </div>
                  Kelas Saya
                </NavLink>
              </li>
              <li className="flex items-center gap-3">
                <NavLink
                  className={({ isActive }) =>
                    clsx("flex text-gray-500 items-center gap-3", [
                      isActive && "text-primary",
                    ])
                  }
                  to="/my-workshop"
                >
                  <div className={splitLocation[1] === "my-workshop"
                    ? "bg-primary text-white border rounded-lg p-2"
                    : "border rounded-lg bg-gray-500 text-white p-2"
                  }>
                    <CubeIcon
                      width={24}
                      height={24}
                      className={splitLocation[1] === "my-workshop"
                        ? "-rotate-0 transition-all duration-300 delay-150"
                        : "rotate-45 transition-all duration-300"} />
                  </div>
                  Workshop Saya
                </NavLink>
              </li>
              <li className="flex items-center gap-3">
                <NavLink
                  className={({ isActive }) =>
                    clsx("flex text-gray-500 items-center gap-3", [
                      isActive && "text-primary",
                    ])
                  }
                  to="/transaction-history"
                >
                  <div className={splitLocation[1] === "transaction-history"
                    ? "bg-primary text-white border rounded-lg p-2"
                    : "border rounded-lg bg-gray-500 text-white p-2"
                  }>
                    <CurrencyDollarIcon
                      width={24}
                      height={24}
                      className={splitLocation[1] === "transaction-history"
                        ? "-rotate-0 transition-all duration-300 delay-150"
                        : "rotate-45 transition-all duration-300"} />
                  </div>
                  Riwayat Pembayaran
                </NavLink>
              </li>
              <li>
                <NavLink
                  className={({ isActive }) =>
                    clsx("flex text-gray-500 items-center gap-3", [
                      isActive && "text-primary",
                    ])
                  }
                  to="/settings"
                >
                  <div className={splitLocation[1] === "settings"
                    ? "bg-primary text-white border rounded-lg p-2"
                    : "border rounded-lg bg-gray-500 text-white p-2"
                  }>
                    <Cog6ToothIcon
                      width={24}
                      height={24}
                      className={splitLocation[1] === "settings"
                        ? "-rotate-45 transition-all duration-300 delay-150"
                        : "transition-all duration-300"}
                    />
                  </div>
                  Pengaturan
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  )
}

export default UserDashboardNavigation
