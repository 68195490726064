import { useQuery } from "@tanstack/react-query"

import { getSlide } from "../functions"
import { CmsQueryKey, GetSlideParams } from "../../types"

const useGetSlide = (params?: GetSlideParams) => {
  return useQuery({
    queryKey: [CmsQueryKey.Slide, params?.qParams ?? ""],
    queryFn: () => getSlide(params?.qParams),
    ...params?.options,
  })
}

export default useGetSlide
