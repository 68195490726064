import { useMutation } from "@tanstack/react-query"

import {
    GeneralSertifikasiSkkniResponse,
} from "../../types"
import { GeneralMutationParams } from "utils/types"
import { createSertifikasiSkkni } from "../functions"

const useMutationCreateSkkni = (
    options?: GeneralMutationParams<
        GeneralSertifikasiSkkniResponse,
        FormData
    >
) => {
    return useMutation({
        mutationFn: (payload) => createSertifikasiSkkni(payload),
        ...options,
    })
}

export default useMutationCreateSkkni
