import React from "react"
import ReactApexChart from "react-apexcharts"
import { ApexOptions } from "apexcharts"

interface ChartProps {
  coursesData?: number[] | null
}

const PieChart: React.FC<ChartProps> = ({ coursesData }) => {
  const seriesData: number[] = Array.isArray(coursesData) ? coursesData : []

  const chartData: { series: number[]; options: ApexOptions } = {
    series: seriesData,
    options: {
      labels: ["Pending", "Success", "Failed"],
      chart: {
        type: "pie",
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],

      colors: ["#FFC300", "#198754", "#FF5733"],
    },
  }

  return (
    <div id="chart">
      <ReactApexChart
        options={chartData.options}
        series={chartData.series}
        type="pie"
        width={380}
      />
    </div>
  )
}

export default PieChart
