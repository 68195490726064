import { useQuery } from "@tanstack/react-query"

import { getCourseRoadmap } from "../functions"
import { PublicQueryKey, PublicGetCourseRoadmapParams } from "../../types"

const useGetCourseRoadmap = (params?: PublicGetCourseRoadmapParams) => {
  return useQuery({
    queryKey: [PublicQueryKey.CourseRoadmaps, params?.courseID ?? ""],
    queryFn: () => getCourseRoadmap(params?.courseID ?? ""),
    ...params?.options,
  })
}

export default useGetCourseRoadmap
