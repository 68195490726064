import { useMutation } from "@tanstack/react-query"

import { GeneralReferralResponse, UpdateReferralRequest } from "../../types"
import { updateReferral } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationUpdateReferral = (
  options?: GeneralMutationParams<
    GeneralReferralResponse,
    UpdateReferralRequest
  >
) => {
  return useMutation({
    mutationFn: ({ id, payload }) => updateReferral(id, payload),
    ...options,
  })
}

export default useMutationUpdateReferral
