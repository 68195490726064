import { requestUser } from "utils/axiosInstance"
import { PublicGetProductCategoriesResponse } from "../../types"

const getWorkshopCategories = async () => {
  const res = await requestUser.get<PublicGetProductCategoriesResponse>(
    "/webinar-categories"
  )
  return res
}

export default getWorkshopCategories
