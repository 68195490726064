import { useEffect, useMemo, useState } from "react"
import {
  BookmarkIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import moment from "moment"
import { useDebounce } from "usehooks-ts"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import ReactDatePicker from "react-datepicker"

import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import SelectInput from "components/ui/form/SelectInput"
import TextInput from "components/ui/form/TextInput"
import BasicValidationMessage from "components/ui/validation/BasicValidationMessage"
import {
  useGetCourses,
  useGetDetailDiscount,
  useGetWebinars,
} from "utils/api/cms/queries/hooks"
import {
  useMutationCreateDiscount,
  useMutationUpdateDiscount,
} from "utils/api/cms/mutations/hooks"
import { GeneralDiscountRequest } from "utils/api/cms/types"
import { useGetSertifikasi } from "utils/api/public/queries/hooks"

type Option = {
  label: string
  value: string | number
}

interface DiscountForm {
  name: string
  type: Option
  discountable_type: Option
  value: number
  period: {
    start_active_at: string
    valid_until: string
  }
  product_ids: Option[]
}

const DISCOUNT_TYPE_OPTIONS = [
  { label: "Potongan Harga (Rp.)", value: "REBATE" },
  { label: "Persen (%)", value: "PERCENT" },
]

const PRODUCT_TYPE_OPTIONS = [
  { label: "Kelas", value: "COURSE" },
  { label: "Webinar", value: "WEBINAR" },
  { label: "Sertifikasi", value: "SERTIFIKASI" },
]

interface BaseProduct {
  id: number;
}

interface Course extends BaseProduct {
  title: string;
}

interface Webinar extends BaseProduct {
  title: string;
}

interface Sertifikasi extends BaseProduct {
  name: string; // Different from Course and Webinar
}

const DiscountCourseForm = () => {
  const navigate = useNavigate()
  const params = useParams()

  const isUpdate = !!params.id

  const payloadDateFormat = "YYYY-MM-DD HH:mm"
  const now = new Date()
  const start = moment(
    new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0, 0)
  )
  const end = moment(start).add(1, "days").subtract(1, "seconds")

  const [search, setSearch] = useState("")
  const debouncedSearch = useDebounce(search, 800)

  const {
    handleSubmit,
    register,
    control,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<DiscountForm>({
    defaultValues: {
      discountable_type: {
        label: "Kelas",
        value: "COURSE",
      },
      period: {
        start_active_at: start.format(payloadDateFormat),
        valid_until: end.format(payloadDateFormat),
      },
    },
  })

  const { data: dataCourses, isFetching: isFetchingCourse } = useGetCourses({
    options: {
      enabled: watch("discountable_type").value === "COURSE",
    },
    qParams: {
      limit: 10,
      keyword: debouncedSearch,
    },
  })

  const { data: dataWebinars, isFetching: isFetchingWebinar } = useGetWebinars({
    options: {
      enabled: watch("discountable_type").value === "WEBINAR",
    },
    qParams: {
      limit: 10,
      keyword: debouncedSearch,
    },
  })

  const { data: dataSertifikasi, isFetching: isFetchingSertifikasi } = useGetSertifikasi({
    options: {
      enabled: watch("discountable_type").value === "SERTIFIKASI",
    },
    qParams: {
      limit: 10,
      keyword: debouncedSearch,
    },
  })

  const { data: dataDetailDiscount } = useGetDetailDiscount({
    id: params.id ?? "",
    options: {
      enabled: isUpdate,
    },
  })

  const { mutate: mutateCreateDiscount } = useMutationCreateDiscount({
    onSuccess(data) {
      if (!data.success) return
      toast.success("Diskon berhasil dibuat")
      setTimeout(() => {
        navigate("/cms/master/discount")
      }, 1000)
    },
    onError(error) {
      if (error.message === "The value field must be at least 1.") {
        toast.error("Jumlah diskon harus bernilai positif")
        return
      }
      if (
        error.message ===
        "requested Discount will cause a minus price on webinar"
      ) {
        toast.error("Jumlah diskon tidak boleh membuat harga menjadi minus !")
        return
      }
      toast.error(error.message)
    },
  })

  const { mutate: mutateUpdateDiscount } = useMutationUpdateDiscount({
    onSuccess(data) {
      if (!data.success) return
      toast.success("Diskon berhasil diiubah")
      navigate("/cms/master/discount")
    },
    onError(error) {
      if (error.message === "The value field must be at least 1.") {
        toast.error("Jumlah diskon harus bernilai positif !")
        return
      }
      if (
        error.message ===
        "requested Discount will cause a minus price on webinar"
      ) {
        toast.error("Jumlah diskon tidak boleh membuat harga menjadi minus !")
        return
      }

      toast.error(error.message)
    },
  })

  type ProductItem = Course | Webinar | Sertifikasi;

  function isSertifikasi(item: ProductItem): item is Sertifikasi {
    return (item as Sertifikasi).name !== undefined;
  }

  useEffect(() => {
    if (!dataDetailDiscount || !isUpdate) return

    const detailDiscount = dataDetailDiscount.data
    const productType = detailDiscount.courses.length > 0
      ? "COURSE"
      : detailDiscount.webinars.length > 0
        ? "WEBINAR"
        : detailDiscount.certifications && detailDiscount.certifications.length > 0
          ? "SERTIFIKASI"
          : "UNKNOWN";

    reset({
      name: detailDiscount.name,
      value: detailDiscount.value,
      period: {
        start_active_at: detailDiscount.start_active_at,
        valid_until: detailDiscount.valid_until,
      },
      type: DISCOUNT_TYPE_OPTIONS.find(
        (option) => option.value === detailDiscount.type
      ),
      discountable_type: PRODUCT_TYPE_OPTIONS.find(
        (option) => option.value === productType
      ),
      product_ids: detailDiscount[
        getProductKey(productType)
      ]?.map((item: ProductItem) => ({
        label: isSertifikasi(item) ? item.name : item.title,
        value: item.id,
      })),

    })
  }, [dataDetailDiscount])

  function getProductKey(productType) {
    switch (productType) {
      case "COURSE":
        return "courses";
      case "WEBINAR":
        return "webinars";
      case "SERTIFIKASI":
        return "certifications";
      default:
        return "courses";
    }
  }

  const discountableTypeValue = watch("discountable_type").value;
  let discountType = "";

  if (discountableTypeValue === "COURSE") {
    discountType = "Kelas";
  } else if (discountableTypeValue === "WEBINAR") {
    discountType = "Webinar";
  } else if (discountableTypeValue === "SERTIFIKASI") {
    discountType = "Sertifikasi";
  }


  const courseOptions = useMemo(
    () =>
      dataCourses?.data.map((course) => ({
        label: course.title,
        value: course.id,
      })),
    [dataCourses]
  )

  const webinarOptions = useMemo(
    () =>
      dataWebinars?.data.map((webinar) => ({
        label: webinar.title,
        value: webinar.id,
      })),
    [dataWebinars]
  )

  const sertifikasiOptions = useMemo(
    () =>
      dataSertifikasi?.data.map((sertifikasi) => ({
        label: sertifikasi.name,
        value: sertifikasi.id,
      })),
    [dataSertifikasi]
  )

  const discountableType = watch("discountable_type").value;
  console.log(discountableType);

  let productOptions: Option[] = [];

  if (discountableType === "COURSE") {
    productOptions = courseOptions ?? [];
  } else if (discountableType === "WEBINAR") {
    productOptions = webinarOptions ?? [];
  } else if (discountableType === "SERTIFIKASI") {
    productOptions = sertifikasiOptions ?? [];
  }

  const handleSearchProduct = (value: string) => {
    setSearch(value)
  }

  const onSubmit: SubmitHandler<DiscountForm> = (data) => {
    const product_ids: number[] = data.product_ids.reduce<number[]>(
      (prevValue, currentValue) => {
        if (typeof currentValue.value !== "number") return prevValue
        return [...prevValue, currentValue.value]
      },
      []
    )

    if (
      (data.discountable_type.value !== "COURSE" &&
        data.discountable_type.value !== "WEBINAR" &&
        data.discountable_type.value !== "SERTIFIKASI") ||
      (data.type.value !== "REBATE" && data.type.value !== "PERCENT")
    ) {
      return;
    }


    const payload: GeneralDiscountRequest = {
      name: data.name,
      value: data.value,
      type: data.type.value,
      discountable_type: data.discountable_type.value,
      start_active_at: data.period.start_active_at,
      valid_until: data.period.valid_until,
      ...(data.discountable_type.value === "COURSE" ? {
        course_ids: product_ids,
      } : data.discountable_type.value === "WEBINAR" ? {
        webinar_ids: product_ids,
      } : {
        sertifikasi_ids: product_ids,
      }),
    };


    if (!isUpdate) {
      return mutateCreateDiscount(payload)
    }

    mutateUpdateDiscount({
      id: params.id ?? "",
      payload: payload,
    })
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-4 pb-4 min-w-[42rem]"
    >
      <TextInput
        type="text"
        className="cms-base-input"
        labelClass="cms-base-label"
        isRequired
        label="Nama Diskon"
        errorMessage={errors.name?.message}
        errorIcon={
          <ExclamationTriangleIcon
            height={24}
            width={24}
            className="text-red-800"
          />
        }
        {...register("name", {
          required: {
            value: true,
            message: "Nama diskon wajib diisi",
          },
        })}
      />
      <Controller
        control={control}
        name="discountable_type"
        rules={{
          required: { value: true, message: "Tipe produk wajib dipilih" },
        }}
        render={({ field }) => (
          <SelectInput
            {...field}
            onChange={(newValue) => {
              field.onChange(newValue)
              setValue("product_ids", [])
            }}
            label="Pilih Tipe Produk"
            labelClass="cms-base-label"
            errorMessage={errors.discountable_type?.message}
            errorIcon={
              <ExclamationTriangleIcon
                height={24}
                width={24}
                className="text-red-800"
              />
            }
            classNames={{
              container: () => "max-w-2xl",
            }}
            options={PRODUCT_TYPE_OPTIONS}
            isRequired
          />
        )}
      />
      <Controller
        control={control}
        name="product_ids"
        rules={{
          required: { value: true, message: `${discountType} wajib dipilih` },
        }}
        render={({ field }) => (
          <SelectInput
            {...field}
            label={`Pilih ${discountType}`}
            labelClass="cms-base-label"
            onInputChange={handleSearchProduct}
            isLoading={isFetchingCourse || isFetchingWebinar || isFetchingSertifikasi}
            errorMessage={errors.product_ids?.message}
            errorIcon={
              <ExclamationTriangleIcon
                height={24}
                width={24}
                className="text-red-800"
              />
            }
            classNames={{
              container: () => "max-w-2xl",
            }}
            options={productOptions}
            isRequired
            isMulti
          />
        )}
      />
      <div className="flex items-center gap-4">
        <TextInput
          type="number"
          className="cms-base-input"
          labelClass="cms-base-label"
          isRequired
          label="Jumlah Diskon"
          errorMessage={errors.value?.message}
          errorIcon={
            <ExclamationTriangleIcon
              height={24}
              width={24}
              className="text-red-800"
            />
          }
          {...register("value", {
            required: {
              value: true,
              message: "Jumlah diskon wajib diisi",
            },
            pattern: {
              value: /^(?!-).*$/, // Ensure the value does not start with a minus sign
              message: "Nilai diskon tidak boleh negatif",
            },
            validate: (value) => {
              const numericValue = parseFloat(value.toString())
              const discountType = control._getWatch("type")
              if (
                discountType.value === "PERCENT" &&
                (isNaN(numericValue) || numericValue > 100)
              ) {
                return "Nilai diskon percent tidak boleh lebih dari 100"
              }
              return true
            },
          })}
        />
        <Controller
          control={control}
          name="type"
          rules={{
            required: { value: true, message: "Tipe diskon wajib dipilih" },
          }}
          render={({ field }) => (
            <SelectInput
              {...field}
              label="Pilih Tipe Diskon"
              labelClass="cms-base-label"
              errorMessage={errors.type?.message}
              errorIcon={
                <ExclamationTriangleIcon
                  height={24}
                  width={24}
                  className="text-red-800"
                />
              }
              classNames={{
                container: () => "max-w-2xl",
              }}
              options={DISCOUNT_TYPE_OPTIONS}
              isRequired
            />
          )}
        />
      </div>
      <div className="sm:col-span-2 max-w-[42rem]">
        <label className="cms-base-label after:content-['*'] after:ml-1 after:text-red-500">
          Periode Diskon
        </label>
        <div>
          <Controller
            control={control}
            name="period"
            rules={{
              validate: (period) => {
                const endSameOrSmaller = moment(
                  period.valid_until,
                  payloadDateFormat
                ).isSameOrBefore(
                  moment(period.start_active_at, payloadDateFormat)
                )

                if (endSameOrSmaller) {
                  return "Tanggal selesai tidak boleh sebelum atau sama dengan tanggal dimulai!"
                }
                if (
                  typeof period === "undefined" ||
                  !period.valid_until ||
                  !period.start_active_at
                ) {
                  return "Tanggal pelaksanaan tidak boleh kosong!"
                }
                return
              },
            }}
            render={({ field: { onChange } }) => (
              <div className="flex items-center w-full gap-2">
                <div className="w-full">
                  <ReactDatePicker
                    className="w-full rounded-lg"
                    wrapperClassName="w-full"
                    selected={new Date(getValues("period.start_active_at"))}
                    selectsStart
                    startDate={new Date(getValues("period.start_active_at"))}
                    endDate={new Date(getValues("period.valid_until"))}
                    dateFormat="MMMM d, yyyy h:mm aa"
                    onChange={(update) => {
                      onChange({
                        start_active_at:
                          moment(update).format(payloadDateFormat),
                        valid_until: getValues("period.valid_until")
                          ? moment(getValues("period.valid_until")).format(
                            payloadDateFormat
                          )
                          : null,
                      })
                    }}
                    withPortal
                    showTimeSelect
                  />
                </div>
                <span className="text-white">-</span>
                <div className="w-full">
                  <ReactDatePicker
                    className="w-full rounded-lg"
                    selected={
                      getValues("period.valid_until")
                        ? new Date(getValues("period.valid_until"))
                        : null
                    }
                    selectsEnd
                    startDate={
                      getValues("period.start_active_at")
                        ? new Date(getValues("period.start_active_at"))
                        : null
                    }
                    endDate={
                      getValues("period.valid_until")
                        ? new Date(getValues("period.valid_until"))
                        : null
                    }
                    dateFormat="MMMM d, yyyy h:mm aa"
                    onChange={(update) => {
                      onChange({
                        start_active_at: getValues("period.start_active_at"),
                        valid_until: moment(update).format(payloadDateFormat),
                      })
                    }}
                    withPortal
                    showTimeSelect
                  />
                </div>
              </div>
            )}
          />
        </div>
        {errors.period && (
          <div className="mt-3">
            <BasicValidationMessage
              type="error"
              message={errors.period.message as string}
              icon={
                <ExclamationTriangleIcon className="h-6 w-6 text-red-800" />
              }
            />
          </div>
        )}
      </div>

      <ButtonWithIcon
        action={undefined}
        type="submit"
        class="self-start mt-4 border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
        disabled={isSubmitting}
      >
        <BookmarkIcon className="h-6 w-6 text-white" />
        <span>
          {!isSubmitting ? (
            <>{isUpdate ? "Simpan" : "Tambahkan"}</>
          ) : (
            "Menyimpan..."
          )}
        </span>
      </ButtonWithIcon>
    </form>
  )
}

export default DiscountCourseForm
