import SertifikatFormProgram from "components/cms/Sertifikat/SertifikatFormProgram"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import Typography from "components/ui/typography"
import { getCMSSegments } from "utils/helper"

const SertifikatCreateProgram = () => {
    const segments = getCMSSegments()

    return (
        <EmployeeDashboardWrapper>
            <DefBreadcrumb
                segments={segments}
                prefix="cms"
            />
            <Typography
                variant="subheading4"
                as="h1"
                className="text-primary"
            >
                Buat Sertifikasi Program
            </Typography>

            <SertifikatFormProgram />
        </EmployeeDashboardWrapper>
    )
}

export default SertifikatCreateProgram
