import { Helmet } from "react-helmet"

import ListCareer from "components/public/career/list-career"
import Section from "components/ui/section/Section"
import { ReactComponent as Decoration } from "assets/svg/Public_Decoration.svg"
import { ReactComponent as MobileDecoration } from "assets/svg/Public_Decoration_2.svg"
import Typography from "components/ui/typography"

const Careers = () => {
  return (
    <>
      <Helmet>
        <title>Karir</title>
      </Helmet>
      <div className="w-full mb-20">
        <div className="w-full h-40">
          <div className="flex flex-col justify-center text-center relative z-10 mt-5 md:mt-16 px-10">
            <Typography
              variant="heading4"
              as="h1"
              className="text-white mb-2 lg:mb-4 md:text-4xl text-lg md:font-bold font-semibold"
            >
              Careers
            </Typography>
            <Typography
              variant="text2"
              className="text-white font-light md:text-md"
            >
              Tunjukan keahlian yang diperlukan untuk memasuki dunia kerja
              dengan percaya diri.
            </Typography>
          </div>
          <Decoration className="absolute md:top-5 left-0 right-0 w-full z-0 md:h-96 top-0 md:block hidden" />
          <MobileDecoration className="absolute left-0 right-0 w-full z-0 md:h-96 top-20 md:hidden block" />
        </div>
        <div className="container">
          <Section
            space="top-bottom"
            className="mb-56 lg:mt-32 mt-5"
          >
            <ListCareer />
          </Section>
        </div>
      </div>
    </>
  )
}

export default Careers
