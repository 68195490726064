import { requestCMS } from "utils/axiosInstance"
import type { SertifikatSkkni, GeneralApiResponse, QueryParams } from "utils/types"

const getSertifikasiSkkni = async (qParams?: QueryParams) => {
    const res = await requestCMS.get<GeneralApiResponse<SertifikatSkkni[]>>(
        "/employee/sertifikat/skkni",
        qParams
    )
    return res
}

export default getSertifikasiSkkni
