import CardContainer from "./CardContainer"
import CardTitle from "./CardTitle"
import CardDescription from "./CardDescription"
import CardCaption from "./CardCaption"
import clsxm from "utils/clsxm"

interface HorizontalCardProps {
  thumbnail?: string
  to?: string
  height?: number
  width?: number
  maxHeight?: number
  maxWidth?: number
  className?: string
  bodyClass?: string
  thumbWidth?: string
  scale?: boolean
  imgAlt?: string
  children?: React.ReactNode
  paddingTop?: string
  paddingBottom?: string
  paddingLeft?: string
  desktop?: boolean
}

const imageStyle: React.CSSProperties = {
  // objectFit: 'cover',
  // paddingTop: '8px',
  // paddingBottom: '8px',
  // paddingLeft: '10px'
};

const HorizontalCard = ({
  thumbnail,
  height,
  width,
  maxHeight,
  maxWidth,
  thumbWidth,
  to,
  className,
  bodyClass,
  scale,
  imgAlt,
  children,
  paddingTop,
  paddingBottom,
  paddingLeft,
  desktop
}: HorizontalCardProps) => {
  return (

    <CardContainer
      to={to}
      className={clsxm("flex justify-between p-3 relative", className)}
      scale={scale}
      width={width}
      height={height}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      paddingTop={paddingTop}
      paddingBottom={paddingBottom}
      paddingLeft={paddingLeft}
    >
      {thumbnail && (
        <img
          className={clsxm(
            "object-cover",
            thumbWidth,
            " lg:h-auto",
            "rounded-lg",
            // "hidden lg:block"
          )}
          src={thumbnail}
          alt={imgAlt}
          style={desktop ? undefined : imageStyle}
        />
      )}
      <div
        className={clsxm("flex ps-2 lg:ps-5 lg:px-5 lg:py-2 w-full h-full", bodyClass)}
      >
        {children}
      </div>
    </CardContainer>
  )
}

HorizontalCard.Title = CardTitle
HorizontalCard.Description = CardDescription
HorizontalCard.Caption = CardCaption

export default HorizontalCard
