import { useMutation } from "@tanstack/react-query"

import { GeneralCareerResponse } from "../../types"
import { disableCareer } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationDisableCareer = (
  options?: GeneralMutationParams<GeneralCareerResponse, { careerID: string }>
) => {
  return useMutation({
    mutationFn: ({ careerID }) => disableCareer(careerID),
    ...options,
  })
}

export default useMutationDisableCareer
