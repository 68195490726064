import { useQuery } from "@tanstack/react-query"
import { CmsQueryKey, GetSertifikatDetailParams } from "../../types"
import { detailSertifikat } from "../functions"

const getSertifikatDetail = (params?: GetSertifikatDetailParams) => {
    return useQuery({
        queryKey: [CmsQueryKey.Sertifikat, params?.id ?? ""],
        queryFn: () => detailSertifikat(params?.id),
    })
}

export default getSertifikatDetail
