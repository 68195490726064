import React, { useState } from "react"
import { Tabs, Tab } from "react-tabs-scrollable"
import "./style.css"
import { FiChevronRight, FiChevronLeft } from "react-icons/fi"
import { Section } from "./types"
import { Label, Radio } from "flowbite-react"

interface TabsFilterProps {
  sections: Section[]
}
const TabsFilter: React.FC<TabsFilterProps> = ({ sections }) => {
  const [activeTab, setActiveTab] = React.useState(0)
  const onTabClick = (e, index) => {
    setActiveTab(index)
  }
  return (
    <>
      {sections.map((section, index) => (
        <Tabs
          key={index}
          activeTab={activeTab}
          onTabClick={onTabClick}
          hideNavBtnsOnMobile={false}
          leftBtnIcon={<FiChevronLeft size={"1.5em"} />}
          rightBtnIcon={<FiChevronRight size={"1.5em"} />}
        >
          {section.items.map((item, index) => (
            <Tab key={index}>
              <Radio
                defaultChecked={index === 0}
                id={item.name}
                onClick={(e) => e.stopPropagation()}
                className="hidden"
                value={item.value}
                {...section.register}
              />
              <Label
                htmlFor={item.name}
                className={`cursor-pointer text-base font-semibold ${
                  activeTab === index ? "text-primary" : "text-gray-600"
                }`}
              >
                {section.category == "Career" ? <></> : <></>}
                <div key={index}>{item.name}</div>
              </Label>
            </Tab>
          ))}
        </Tabs>
      ))}
    </>
  )
}

export default TabsFilter
