import { useQuery } from "@tanstack/react-query"

import { getCourseCategories } from "../functions"
import { PublicQueryKey, PublicGetProductCategoriesParams } from "../../types"

const useGetCourseCategories = (params?: PublicGetProductCategoriesParams) => {
  return useQuery({
    queryKey: [PublicQueryKey.CourseCategories],
    queryFn: getCourseCategories,
    ...params?.options,
  })
}

export default useGetCourseCategories
