import Card from "components/ui/card/horizontal"

import { MapPinIcon, BriefcaseIcon } from "@heroicons/react/20/solid"

interface CareerCardProps {
  title: string
  slug: string
  is_active: boolean
  description: string
  page: string
  location: string
  working_type: string
  company: string
  company_icon: string
  thumb_width?: string
}

const CareerCard = ({
  title,
  slug,
  is_active,
  description,
  page,
  location,
  working_type,
  company,
  company_icon,
  thumb_width,
}: CareerCardProps) => {
  return (
    <Card
      thumbWidth={thumb_width}
      className="cursor-pointer  h-[170px] w-full md:h-[175px] md:w-[391px] hover:scale-105 transition-all duration-300 shadow-md hover:shadow-lg"
      to={`/careers/${slug}`}
    >
      <div className="flex flex-col  gap-2 justify-start items-start w-full">
        <div className="flex justify-start items-start w-full border-b-gray-300 border-b-2 pb-2">
          <div className="p-2 border-2 rounded-lg">
            <img
              className="w-12 h-12 "
              src={company_icon}
              alt=""
            />
          </div>
          <div className="ml-3 ">
            <Card.Title
              as="p"
              className="text-lg text-primary"
            >
              {title}
            </Card.Title>
            <Card.Caption
              className="text-base font-medium text-secondary"
              text={company}
            />
            <Card.Caption
              className="text-xs mt-2"
              icon={
                <MapPinIcon
                  height={16}
                  width={16}
                />
              }
              text={location}
            />
          </div>
        </div>
        <div className="flex py-4 items-center gap-4 justify-between w-full">
          <div className="flex items-center gap-4">
            <Card.Caption
              className="text-xs font-medium text-primary"
              text={`internship`}
            />
            <Card.Caption
              className="text-xs font-medium p-1 px-4 rounded-full bg-primary text-white"
              text={working_type}
            />
          </div>
        </div>
      </div>
    </Card>
  )
}

export default CareerCard
