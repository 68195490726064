import { requestUser } from "utils/axiosInstance"
import { GeneralApiResponse } from "utils/types"

const logout = async () => {
  const res = await requestUser.post<GeneralApiResponse<null>>(
    "/user/logout",
    {}
  )
  return res
}

export default logout
