import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DocumentPlusIcon,
} from "@heroicons/react/24/solid"
import { createColumnHelper } from "@tanstack/react-table"
import to from "await-to-js"
import moment from "moment"
import React, { useEffect, useState } from "react"
import {
  LoaderFunction,
  useLoaderData,
  useNavigate,
  useParams,
} from "react-router-dom"
import CMSDefaultTableListFilter from "../../../components/cms/CMSDefaultTableListFilter"
import EmployeeDashboardWrapper from "../../../components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "../../../components/ui/breadcrumb/DefBreadcrumb"
import DarkPagination from "../../../components/ui/pagination/DarkPagination"
import ReactTable from "../../../components/ui/react-table/ReactTable"
import useFlashMessage from "../../../hooks/useFlashMessage"
import usePagination from "../../../hooks/usePagination"
import api from "../../../utils/axiosInstance"
import { getCMSSegments } from "../../../utils/helper"
import {
  CMSGetUserTransactionsReferral,
  Transaction,
  TransactionDetail,
  Referral,
} from "../../../utils/types"
import { useGetReferralDetail } from "utils/api/cms/queries/hooks"
import clsxm from "utils/clsxm"

const TransactionReferralListManagement = () => {
  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  })

  const params = useParams()
  const segments = getCMSSegments()
  const referralID = params.id ?? ""
  const { data } = useGetReferralDetail(Number(referralID))
  const referralDetailColHelper = createColumnHelper<Referral>()
  const referralDetailColumns = [
    referralDetailColHelper.accessor("code", {
      header: () => "Kode",
    }),
    referralDetailColHelper.accessor("type", {
      header: () => "Tipe Referral",
    }),
    referralDetailColHelper.accessor("is_active", {
      header: "Status Referral",
      cell: (props) => {
        if (!props.getValue()) {
          return (
            <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded bg-yellow-900 text-yellow-300">
              PENDING
            </span>
          )
        }

        return (
          <span className="text-xs font-medium mr-2 px-2.5 py-0.5 rounded bg-green-900 text-green-300">
            Aktif
          </span>
        )
      },
    }),
    referralDetailColHelper.accessor("transaction_count", {
      header: () => "Jumlah Transaksi",
    }),
  ]

  const loaderResponse =
    useLoaderData() as CMSGetUserTransactionsReferral<Transaction>
  const [transactionResponse, setTransactionResponse] =
    useState<CMSGetUserTransactionsReferral<Transaction>>()
  const referralsFetcher = async () => {
    const page = Math.min(currentPage + 1, totalPages)
    const [_, resultResponse] = await to<
      CMSGetUserTransactionsReferral<Transaction>
    >(
      api.cms.getTransactionsReferralList(+referralID, {
        page: page,
        keyword: keyword,
        limit: perPage,
      })
    )
    setTotalPages(resultResponse!.meta!.last_page)
    setTransactionResponse(resultResponse)
  }
  const [keyword, setKeyword] = useState("")
  const [perPage, setPerpage] = useState(10)
  const {
    totalPages,
    currentPage,
    initialPage,
    forced,
    setTotalPages,
    setCurrentPage,
    setInitialPage,
    setShouldFetch,
    setForced,
  } = usePagination(referralsFetcher, [keyword, perPage])
  useFlashMessage(
    "success",
    "referralUpsertSuccess",
    "Berhasil menyimpan referral"
  )

  const referralNameSearchHandler = (keywordVal) => {
    setForced(true)
    setCurrentPage(0)
    setKeyword(keywordVal)
  }

  const perPageHandler = (selected: number) => {
    setForced(true)
    setCurrentPage(0)
    setPerpage(selected)
  }

  useEffect(() => {
    if (!loaderResponse) return

    setTransactionResponse(loaderResponse)
    setTotalPages(loaderResponse.meta!.last_page)

    if (loaderResponse.meta!.current_page > 1) {
      setInitialPage(loaderResponse.meta!.current_page - 1)
    }

    setShouldFetch(true)
  }, [loaderResponse])

  const colHelper = createColumnHelper<Transaction>()
  const cols = [
    colHelper.accessor("id", {
      header: "Id",
    }),
    colHelper.accessor("buyer", {
      header: "Email Pembeli",
      cell: (props) => props.getValue().email,
    }),
    colHelper.accessor("buyer", {
      header: "Nama Pembeli",
      cell: (props) => props.getValue().first_name,
    }),
    colHelper.accessor("amount", {
      header: () => "Harga",
      cell: (props) => {
        const amount = props.row.original.amount
        const formattedAmount = formatter.format(Number(amount))

        return <span>{formattedAmount}</span>
      },
    }),
    colHelper.accessor("transaction_detail", {
      header: () => "Judul",
      cell: (props) => {
        const transactionDetails = props.cell.row.original
          .transaction_detail as TransactionDetail
        const title = transactionDetails.transactionable?.title || "N/A"
        return <span>{title}</span>
      },
    }),
    colHelper.accessor("status", {
      header: () => "Status",
      cell: (props) => {
        const status = props.row.original.status
        return (
          <span
            className={clsxm("p-1 rounded", [
              status === "PENDING" && "bg-yellow-500",
              status === "SUCCESS" && "bg-green-500",
              status === "EXPIRED" && "bg-red-500",
            ])}
          >
            {status}
          </span>
        )
      },
    }),
    colHelper.accessor("paid_at", {
      header: "Dibayar pada saat",
    }),
    colHelper.accessor("created_at", {
      header: () => "Tanggal Dibuat",
      cell: (props) =>
        moment(props.getValue()).locale("id").format("dddd, D MMMM YYYY H:mm"),
    }),
    colHelper.accessor("updated_at", {
      header: () => "Tanggal Terakhir Diupdate",
      cell: (props) =>
        moment(props.getValue()).locale("id").format("dddd, D MMMM YYYY H:mm"),
    }),
  ]
  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix="cms"
      />
      <h2 className="text-xl text-primary font-semibold leading-loose tracking-wide">
        Pengaturan Referral
      </h2>
      <ReactTable
        columns={referralDetailColumns}
        data={data?.data ? [data.data] : []}
      />

      <CMSDefaultTableListFilter
        pageSelector
        searchPlaceHolder="Cari email atau nama"
        wrapperClass="ml-auto flex flex-row space-x-6"
        onSearchHandler={referralNameSearchHandler}
        perPageHandler={perPageHandler}
      />
      {transactionResponse && (
        <>
          <ReactTable
            data={transactionResponse.data}
            columns={cols}
          />
          <div className="flex pb-8 items-center ml-auto">
            <DarkPagination
              nexLabel={<ChevronRightIcon className="h-5 w-5 text-gray-400" />}
              previousLabel={
                <ChevronLeftIcon className="h-5 w-5 text-gray-400" />
              }
              pageCount={totalPages}
              pageRangeDisplayed={2}
              currentPage={currentPage}
              onPageChange={setCurrentPage}
              initialPage={initialPage}
              forcePage={forced ? currentPage : undefined}
            />
          </div>
        </>
      )}
    </EmployeeDashboardWrapper>
  )
}

export default TransactionReferralListManagement

export const transactionReferralManagementLoader: LoaderFunction = async ({
  params,
}) => {
  const referralID = params.id ?? ""
  const searchParams = new URLSearchParams(window.location.search)
  const response = await api.cms.getTransactionsReferralList(+referralID, {
    keyword: searchParams.has("search")
      ? (searchParams.get("search") as string)
      : "",
    page: searchParams.has("page") ? +(searchParams.get("page") as string) : 1,
    limit: 10,
  })

  return response
}
