import { useQuery } from "@tanstack/react-query"

import { getCourseVideoPreview } from "../functions"
import { PublicQueryKey, PublicGetCoursePreviewVideoParams } from "../../types"

const useGetCoursePreviewVideo = (
  params?: PublicGetCoursePreviewVideoParams
) => {
  return useQuery({
    queryKey: [PublicQueryKey.CoursePreviewVideos, params?.courseID ?? ""],
    queryFn: () => getCourseVideoPreview(params?.courseID ?? ""),
    ...params?.options,
  })
}

export default useGetCoursePreviewVideo
