import { requestCMS } from "utils/axiosInstance"
import type { CreateVideoResponse, CreateVideoRequest } from "../../types"

const createVideo = async (payload: CreateVideoRequest) => {
  const res = await requestCMS.post<CreateVideoResponse>(
    "/employee/course/section/video/create",
    payload
  )
  return res
}

export default createVideo
