import { Fragment, useEffect, useRef } from "react"
import {
  useDebounce,
  useIntersectionObserver,
  useMediaQuery,
} from "usehooks-ts"
import clsx from "clsx"
import { useForm } from "react-hook-form"
import moment from "moment"

import WorkshopCard from "components/public/components/WorkshopCard"
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid"
import SideFilter from "components/ui/filter/SideFilter"
import {
  useGetWorkshopCategories,
  useInfiniteGetWorkshops,
} from "utils/api/public/queries/hooks"
import Typography from "components/ui/typography"
import CardHorizontalSkeleton from "components/public/components/CardHorizontalSkeleton"
import Button from "components/ui/button/Button"

const ListWorkshop = () => {
  const desktop = useMediaQuery("(min-width: 1024px)")
  const ref = useRef<HTMLParagraphElement | null>(null)

  const entry = useIntersectionObserver(ref, {})

  const { register, watch } = useForm()

  const searchValue = useDebounce<string>(watch("search"), 500)

  const { data: dataCategories } = useGetWorkshopCategories({
    options: {
      staleTime: 5 * 60 * 1000,
    },
  })

  const {
    data: dataWorkshops,
    isLoading: isLoadingWorkshops,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteGetWorkshops({
    qParams: {
      category_id: Number(watch("category")) || undefined,
      keyword: searchValue || undefined,
      limit: 10,
    },
  })

  useEffect(() => {
    if (entry?.isIntersecting) {
      fetchNextPage()
    }
  }, [entry])

  const categoryItems = dataCategories?.data.map((category) => ({
    name: category.name,
    value: category.id,
  }))

  return (
    <div className="flex gap-4">
      {desktop && (
        <div className="sticky top-24 h-fit">
          <div className="flex relative mb-4 mr-10">
            <input
              className={clsx(
                "h-8 w-full",
                "pl-7",
                "border-b border-b-typo-gray",
                "focus:outline-none focus:border-b-primary focus:border-b-2 peer"
              )}
              placeholder="Cari workshop"
              {...register("search")}
            />
            <MagnifyingGlassIcon
              width={20}
              height={20}
              className="absolute bottom-2 left-0 text-typo-gray peer-focus:text-primary"
            />
          </div>
          <SideFilter
            sections={[
              {
                category: "Kategori",
                register: register("category"),
                items: [{ name: "Semua", value: 0 }, ...(categoryItems ?? [])],
              },
            ]}
          />
        </div>
      )}
      <div
        className={clsx(
          "w-full",
          "flex flex-wrap gap-4 justify-center lg:justify-start"
        )}
      >
        {isLoadingWorkshops
          ? [...Array(4)].map((_, index) => (
              <CardHorizontalSkeleton key={index} />
            ))
          : dataWorkshops?.pages.map((page, index) => (
              <Fragment key={index}>
                {page.data.length > 0 ? ( // Cek apakah page.data tidak kosong
                  page.data.map((workshop) => {
                    const formatedDate = moment(workshop.start_date).format(
                      "DD-MMMM"
                    )
                    const [date, month] = formatedDate.split("-")

                    return (
                      <WorkshopCard
                        key={workshop.id}
                        title={workshop.title}
                        quota={workshop.quota}
                        date={date}
                        month={month}
                        slug={workshop.slug}
                      />
                    )
                  })
                ) : (
                  <div className="w-full flex justify-center items-center">
                    <Typography
                      variant="subheading3"
                      as="h1"
                      className="text-tertiary w-full text-center items-center"
                    >
                      Belum ada workshop di waktu terdekat.
                    </Typography>{" "}
                  </div>
                )}
              </Fragment>
            ))}
        {hasNextPage && (
          <div
            ref={ref}
            className="col-span-3"
          >
            {isFetchingNextPage ? (
              <Typography className="text-primary">Muat Lebih...</Typography>
            ) : (
              <Button
                variant="outlined"
                onClick={() => fetchNextPage()}
              >
                Muat Lebih
              </Button>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default ListWorkshop
