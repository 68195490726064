import { requestCMS } from "utils/axiosInstance"

import type {
  GeneralDiscountRequest,
  GeneralDiscountResponse,
} from "../../types"

const updateDiscount = async (id: string, payload: GeneralDiscountRequest) => {
  const res = await requestCMS.put<GeneralDiscountResponse>(
    `/employee/discount/update/${id}`,
    payload
  )
  return res
}

export default updateDiscount
