import Cookies from "js-cookie"
import jwt_decode, { JwtPayload } from "jwt-decode"

export const getCmsToken = () => {
  const token = Cookies.get("cms_auth_token")
  return token
}

export const getPartnershipToken = () => {
  const token = Cookies.get("partnership_auth_token")
  return token
}

export const setCmsToken = (token: string) => {
  const decodedToken = jwt_decode<JwtPayload>(token)
  const tokenExpiration = new Date((decodedToken.exp ?? 0) * 1000)

  Cookies.set("cms_auth_token", token, { expires: tokenExpiration })
}

export const setPartnershipToken = (token: string) => {
  const decodedToken = jwt_decode<JwtPayload>(token)
  const tokenExpiration = new Date((decodedToken.exp ?? 0) * 1000)

  Cookies.set("partnership_auth_token", token, { expires: tokenExpiration })
}

export const getUserToken = () => {
  const token = Cookies.get("user_auth_token")
  return token
}

export const setUserToken = (token: string) => {
  const decodedToken = jwt_decode<JwtPayload>(token)
  const tokenExpiration = new Date((decodedToken.exp ?? 0) * 1000)

  Cookies.set("user_auth_token", token, { expires: tokenExpiration })
}
