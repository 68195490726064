import { requestCMS } from "utils/axiosInstance"
import type {
  GeneralCareerCategoryRequest,
  GeneralCareerCategoryResponse,
} from "../../types"

const updateCareerCategory = async (
  id: string,
  payload: GeneralCareerCategoryRequest
) => {
  const res = await requestCMS.put<GeneralCareerCategoryResponse>(
    `/employee/career-categories/update/${id}`,
    payload
  )
  return res
}

export default updateCareerCategory
