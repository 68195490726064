import { useQuery } from "@tanstack/react-query"

import { getCourseSections } from "../functions"
import { GetCourseSectionParams, UserQueryKey } from "../../types"

const useGetCourseSections = (params?: GetCourseSectionParams) => {
  return useQuery({
    queryKey: [UserQueryKey.CourseSections, params?.id ?? ""],
    queryFn: () => getCourseSections(params?.id ?? ""),
    ...params?.options,
  })
}

export default useGetCourseSections
