import { useQuery } from "@tanstack/react-query"

import { getWorkshopDetail } from "../functions"
import { PublicQueryKey, PublicGetWorkshopDetailParams } from "../../types"

const useGetWorkshopDetail = (params?: PublicGetWorkshopDetailParams) => {
  return useQuery({
    queryKey: [PublicQueryKey.Workshops, params?.slug ?? ""],
    queryFn: () => getWorkshopDetail(params?.slug ?? ""),
    ...params?.options,
  })
}

export default useGetWorkshopDetail
