import { forwardRef } from "react"
import Select, { ClassNamesConfig, GroupBase } from "react-select"
import StateManagedSelect from "react-select/dist/declarations/src/stateManager"

import clsxm from "utils/clsxm"
import Typography from "../typography"

interface SelectInputProps
  extends React.ComponentPropsWithoutRef<StateManagedSelect> {
  label: string
  name: string
  labelClass?: string
  isRequired?: boolean
  errorMessage?: string
  errorIcon?: React.ReactNode
  classNames?: ClassNamesConfig<unknown, boolean, GroupBase<unknown>>
}

const SelectInput = forwardRef(
  (
    {
      label,
      name,
      labelClass,
      isRequired,
      errorMessage,
      errorIcon,
      classNames,
      ...restProps
    }: SelectInputProps,
    ref: React.ComponentPropsWithRef<StateManagedSelect>["ref"]
  ) => {
    return (
      <div>
        {label && (
          <label
            htmlFor={`${name}-id`}
            className={clsxm(
              "block mb-1 font-semibold",
              [
                isRequired &&
                  "after:content-['*'] after:ml-1 after:text-red-500",
              ],
              labelClass
            )}
          >
            {label}
          </label>
        )}
        <Select
          ref={ref}
          name={name}
          classNames={{
            ...classNames,
            container: (props) =>
              clsxm(classNames?.container?.(props) ?? "", [
                !!errorMessage && "border-2 border-red-600 rounded-md",
              ]),
          }}
          {...restProps}
        />
        {!!errorMessage && (
          <div
            className={clsxm("inline-flex gap-2 items-center", [
              errorIcon && "mt-2",
            ])}
          >
            {errorIcon}
            <Typography
              variant="text3"
              className="text-red-600 first-letter:capitalize mt-1"
            >
              {errorMessage}
            </Typography>
          </div>
        )}
      </div>
    )
  }
)

export default SelectInput
