import { Form, useNavigate } from "react-router-dom"
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import clsx from "clsx"
import { toast } from "react-toastify"
import { SubmitHandler, useForm } from "react-hook-form"

import { useMutationLogin } from "utils/api/employee/mutations/hooks"
import adminLoginIllus from "assets/illustrations/undraw_add_notes_re_ln36.svg"
import BasicValidationMessage from "components/ui/validation/BasicValidationMessage"
import { setCmsToken } from "utils/auth"
import { useEmployeeInfoStore } from "utils/store/employee-info"

type AdminLoginForm = {
  email: string
  password: string
}

const AdminLoginPage: React.FC = () => {
  const navigate = useNavigate()
  const setEmployeeInfo = useEmployeeInfoStore((state) => state.setEmployeeInfo)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AdminLoginForm>({
    mode: "all",
  })

  const { mutate } = useMutationLogin({
    onSuccess(data) {
      const token = data.data.token

      setEmployeeInfo({
        id: data.data.employee.id,
        email: data.data.employee.email,
        fullName: data.data.employee.full_name,
        phone: data.data.employee.phone,
        roles: data.data.employee.roles,
      })
      setCmsToken(token)
      navigate("/cms/dashboard")
    },
    onError() {
      toast.error("Akun tidak ditemukan, email atau password salah!")
    },
  })

  const onSubmit: SubmitHandler<AdminLoginForm> = async (data) => mutate(data)

  return (
    <>
      <section className="h-screen">
        <div className="container h-full mx-auto px-8 py-12 xl:px-16 xl:py-32">
          <div className="flex h-full flex-col space-y-2 bg-white border border-gray-100 rounded-md xl:rounded-lg py-8">
            <h2 className="text-2xl font-bold text-primary leading-loose tracking-normal ml-6 mb-12 xl:text-4xl xl:tracking-wide">
              BPI - Admin CMS
            </h2>
            <div className="g-6 flex flex-wrap items-center justify-center">
              <div className="hidden mb-12 md:mb-0 md:w-8/12 lg:w-6/12 xl:block">
                <img
                  src={adminLoginIllus}
                  alt="Admin Login Illustrations"
                  className="m-auto"
                />
              </div>
              <div className="sm:w-3/4 lg:w-5/12 mx-auto">
                <Form
                  method="POST"
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="grid grid-rows grid-rows-2 space-y-2">
                    <div className="relative">
                      <input
                        type="email"
                        className={clsx(
                          "peer m-0 block h-[58px] w-full rounded border border-gray-200 bg-transparent bg-clip-padding px-3 py-4 text-base font-normal leading-tight text-neutral-700 transition duration-200 ease-linear placeholder:text-transparent focus:border-primary focus:pb-[0.625rem] focus:pt-[1.625rem] focus:text-neutral-700 focus:outline-none peer-focus:text-primary [&:not(:placeholder-shown)]:pb-[0.625rem] [&:not(:placeholder-shown)]:pt-[1.625rem]",
                          errors.email && "mb-2"
                        )}
                        id="emailInput"
                        placeholder="name@example.com"
                        {...register("email", {
                          required: "Email tidak boleh kosong!",
                          pattern: {
                            message: "Email tidak valid!",
                            value:
                              /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i,
                          },
                        })}
                      />
                      <label
                        htmlFor="emailInput"
                        className="pointer-events-none absolute left-0 top-0 origin-[0_0] border border-solid border-transparent px-3 py-4 text-neutral-500 transition-[opacity,_transform] duration-200 ease-linear peer-focus:-translate-y-2 peer-focus:translate-x-[0.15rem] peer-focus:scale-[0.85] peer-focus:text-primary peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:translate-x-[0.15rem] peer-[:not(:placeholder-shown)]:scale-[0.85] motion-reduce:transition-none"
                      >
                        Email address
                      </label>
                      {errors.email && (
                        <BasicValidationMessage
                          type="error"
                          message={errors.email.message as string}
                          icon={
                            <ExclamationTriangleIcon className="h-6 w-6 text-red-800" />
                          }
                        />
                      )}
                    </div>
                    <div className="relative">
                      <input
                        type="password"
                        className={clsx(
                          "peer m-0 block h-[58px] w-full rounded border border-gray-200 bg-transparent bg-clip-padding px-3 py-4 text-base font-normal leading-tight text-neutral-700 transition duration-200 ease-linear placeholder:text-transparent focus:border-primary focus:pb-[0.625rem] focus:pt-[1.625rem] focus:text-neutral-700 focus:outline-none peer-focus:text-primary [&:not(:placeholder-shown)]:pb-[0.625rem] [&:not(:placeholder-shown)]:pt-[1.625rem]",
                          errors.password && "mb-2"
                        )}
                        id="passwordInput"
                        placeholder="password"
                        {...register("password", {
                          required: "Password tidak boleh kosong!",
                        })}
                      />
                      <label
                        htmlFor="passwordInput"
                        className="pointer-events-none absolute left-0 top-0 origin-[0_0] border border-solid border-transparent px-3 py-4 text-neutral-500 transition-[opacity,_transform] duration-200 ease-linear peer-focus:-translate-y-2 peer-focus:translate-x-[0.15rem] peer-focus:scale-[0.85] peer-focus:text-primary peer-[:not(:placeholder-shown)]:-translate-y-2 peer-[:not(:placeholder-shown)]:translate-x-[0.15rem] peer-[:not(:placeholder-shown)]:scale-[0.85] motion-reduce:transition-none"
                      >
                        Password
                      </label>
                      {errors.password && (
                        <BasicValidationMessage
                          type="error"
                          message={errors.password.message as string}
                          icon={
                            <ExclamationTriangleIcon className="h-6 w-6 text-red-800" />
                          }
                        />
                      )}
                    </div>
                    <button
                      type="submit"
                      className="inline-block w-full rounded bg-primary px-7 pb-2.5 pt-3 text-sm font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
                      data-te-ripple-init
                      data-te-ripple-color="light"
                    >
                      Sign in
                    </button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AdminLoginPage
