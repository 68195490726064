import { useEffect, useState } from "react"
import { motion } from "framer-motion"

import { ReactComponent as BPILogo } from "assets/svg/BeePlusInstitute_PrimaryLogo.svg"

interface PageProgressbarProps {
  finished: boolean
}

const PageProgressbar = ({ finished }: PageProgressbarProps) => {
  const [animationState, setAnimationState] = useState("progress")

  useEffect(() => {
    if (finished) {
      setAnimationState("done")
    }
  }, [finished])

  const progressVariants = {
    progress: {
      width: "95%",
    },
    done: {
      width: "100%",
    },
  }

  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <BPILogo height={140} />
      <div className="w-[25%] bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
        <motion.div
          className="bg-primary h-2.5 rounded-full"
          initial={{
            width: "0%",
          }}
          variants={progressVariants}
          animate={animationState}
          transition={{
            duration: animationState === "done" ? 0.1 : 6,
            ease: "easeInOut",
          }}
        />
      </div>
    </div>
  )
}

export default PageProgressbar
