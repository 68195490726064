import { useQuery } from "@tanstack/react-query"
import { CmsQueryKey, GetCareerDetailParams } from "../../types"
import { getCareerDetail } from "../functions"

const useGetCareerDetail = (params?: GetCareerDetailParams) => {
  return useQuery({
    queryKey: [CmsQueryKey.CareerDetail, params?.id ?? ""],
    queryFn: () => getCareerDetail(params?.id),
  })
}

export default useGetCareerDetail
