import { useMutation } from "@tanstack/react-query"

import { GeneralMutationParams } from "utils/types"
import { authenticateUser } from "../functions"
import {
  AuthenticationRequest,
  PublicAuthenticateUserResponse,
} from "../../types"

const useMutationAuthenticateUser = (
  options?: GeneralMutationParams<
    PublicAuthenticateUserResponse,
    AuthenticationRequest
  >
) => {
  return useMutation({
    mutationFn: (payload) => authenticateUser(payload),
    ...options,
  })
}

export default useMutationAuthenticateUser
