import { requestCMS } from "utils/axiosInstance"
import type { TransactionDetailResponse } from "../../types"

const getReferralDetail = async (trx_id: String) => {
  const res = await requestCMS.get<TransactionDetailResponse>(
    `/employee/transaction/detail/${trx_id}`
  )
  return res
}

export default getReferralDetail
