import Typography from "components/ui/typography"

interface PaymentContentProps {
  totalPayment: string
  contents: { key: string; value: string }[]
}

const PaymentContent = ({ totalPayment, contents }: PaymentContentProps) => {
  return (
    <div className="space-y-3.5">
      <div className="flex flex-col lg:flex-row justify-between">
        <Typography className="font-semibold">Total Pembayaran</Typography>
        <Typography className="lg:font-semibold">{totalPayment}</Typography>
      </div>
      {contents.map((content, index) => (
        <div
          key={index}
          className="flex flex-col lg:flex-row justify-between"
        >
          <Typography className="font-semibold lg:font-normal">
            {content.key}
          </Typography>
          <Typography>{content.value}</Typography>
        </div>
      ))}
    </div>
  )
}

export default PaymentContent
