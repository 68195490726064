import { useQuery } from "@tanstack/react-query"

import { getSertifikasiDetail } from "../functions"
import { PublicQueryKey, PublicGetSertifikasiDetailParams } from "../../types"

const useGetSertifikasiSlug = (params?: PublicGetSertifikasiDetailParams) => {
    return useQuery({
        queryKey: [PublicQueryKey.Sertifikasi, params?.slug ?? ""],
        queryFn: () => getSertifikasiDetail(params?.slug ?? ""),
        ...params?.options,
    })
}

export default useGetSertifikasiSlug
