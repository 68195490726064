import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom"

import clsx from "clsx"
import { toast } from "react-toastify"
import React, { useEffect, useState } from "react"

import Section from "components/ui/section/Section"
import { useMutationActivationEmail } from "utils/api/user/mutations/hooks"
import type { ActivationRequest } from "utils/api/user/types"
import { useUserInfoStore } from "utils/store/user-info"
import PageProgressbar from "components/ui/loading/PageProgressbar"
import { response } from "express"

const EmailVerification = () => {
  const { verify_token } = useParams()
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const user_email = searchParams.get("email")
  const navigate = useNavigate()

  const isLoggedIn = useUserInfoStore((state) => state.isLoggedIn)

  const activationRequest: ActivationRequest = {
    email: user_email,
    verify_token: verify_token,
  }

  const [loadComponent, setLoadComponent] = useState(false)

  const { mutate, isLoading } = useMutationActivationEmail({
    onSuccess(data) {
      if (!data.success) {
        toast.error("Pedaftaran tidak berhasil")
        return
      }
      toast.success("Aktivasi akun berhasil")
      localStorage.removeItem("email_registration")
      navigate("/success")
    },
    onError(error) {
      toast.error("Aktivasi akun gagal")
      navigate("/fallback")
    },
  })

  useEffect(() => {
    mutate(activationRequest)
  }, [])

  useEffect(() => {
    if (isLoading) return
    setTimeout(() => {
      setLoadComponent(true)
    }, 400)
  }, [isLoading])

  if (isLoggedIn) {
    return <Navigate to="/" />
  }
  if (!loadComponent) return <PageProgressbar finished={!isLoading} />

  return (
    <Section space="top-bottom">
      <div
        className={clsx(
          "flex flex-col items-center justify-center",
          "mx-8 md:mx-auto",
          "max-w-5xl",
          "rounded",
          "space-y-4",
          "md:py-16 md:px-[200px] md:shadow"
        )}
      ></div>
    </Section>
  )
}

export default EmailVerification
