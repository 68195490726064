import { useMutation } from "@tanstack/react-query"

import {
  GeneralPartnershipResponse,
  GeneralPartnershipRequest,
} from "../../types"
import { GeneralMutationParams } from "utils/types"
import createPartnership from "../functions/createPartnership"

const useMutationCreatePartnership = (
  options?: GeneralMutationParams<
    GeneralPartnershipResponse,
    GeneralPartnershipRequest
  >
) => {
  return useMutation({
    mutationFn: (payload) => createPartnership(payload),
    ...options,
  })
}

export default useMutationCreatePartnership
