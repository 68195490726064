import { useMutation } from "@tanstack/react-query"

import { registerUser } from "../functions"
import { GeneralMutationParams } from "utils/types"
import { PublicRegisterUserResponse, RegisterRequest } from "../../types"

const useMutationRegisterUser = (
  options?: GeneralMutationParams<PublicRegisterUserResponse, RegisterRequest>
) => {
  return useMutation({
    mutationFn: (payload) => registerUser(payload),
    ...options,
  })
}

export default useMutationRegisterUser
