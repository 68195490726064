import { create } from "zustand"
import { StateStorage, createJSONStorage, persist } from "zustand/middleware"
import CryptoJS from "crypto-js"

interface PartnershipInfo {
  id: number
  email: string
  full_name: string
}

interface PartnershipState {
  isLoggedIn: boolean
  partnershipInfo: PartnershipInfo
}

interface PartnershipInfoState extends PartnershipState {
  setPartnershipInfo: (state: PartnershipInfo) => void
  resetPartnershipInfo: () => void
}

const encryptedStorage: StateStorage = {
  getItem(name) {
    const item = localStorage.getItem(name)
    if (item) {
      const decrypted = CryptoJS.AES.decrypt(
        item,
        process.env.REACT_APP_ENCRYPTION_SECRET ?? ""
      )
      return decrypted.toString(CryptoJS.enc.Utf8)
    }
    return null
  },
  setItem(name, value) {
    const encrypted = CryptoJS.AES.encrypt(
      value,
      process.env.REACT_APP_ENCRYPTION_SECRET ?? ""
    ).toString()
    localStorage.setItem(name, encrypted)
  },
  removeItem(name) {
    localStorage.removeItem(name)
  },
}

export const usePartnershipInfoStore = create(
  persist<PartnershipInfoState>(
    (set) => ({
      isLoggedIn: false,
      partnershipInfo: {
        id: 0,
        email: "",
        full_name: "",
      },
      setPartnershipInfo(state) {
        set({ partnershipInfo: state })
      },
      resetPartnershipInfo() {
        set({
          isLoggedIn: false,
          partnershipInfo: {
            id: 0,
            email: "",
            full_name: "",
          },
        })
      },
    }),
    {
      name: "partnership-info",
      storage: createJSONStorage(() => encryptedStorage),
    }
  )
)
