import { requestUser } from "utils/axiosInstance"
import { GetTransactionByIdResponse } from "../../types"

const getTransactionById = async (id: string) => {
  const res = await requestUser.get<GetTransactionByIdResponse>(
    `/user/transaction/${id}`
  )
  return res
}

export default getTransactionById
