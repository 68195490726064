import { KeyIcon, PowerIcon } from "@heroicons/react/24/solid"
import { Avatar, Dropdown } from "flowbite-react"
import Cookies from "js-cookie"
import { Link, NavLink, useNavigate } from "react-router-dom"
import { useMutationLogout } from "utils/api/partnership/hooks"
import { usePartnershipInfoStore } from "utils/store/partnership-info"

const PartnershipNavigation: React.FC = () => {
    const navigate = useNavigate()
    const resetPartnershipInfo = usePartnershipInfoStore(
        (state) => state.resetPartnershipInfo
    )

    const { mutate } = useMutationLogout({
        onSuccess() {
            Cookies.remove("partnership_auth_token")
            resetPartnershipInfo()
            navigate("/partnership/login")
        },
    })

    const handleLogout = () => {
        mutate({})
    }

    return (
        <>
            <nav className="border-gray-800 bg-gray-950">
                <div className="max-w-screen-xl flex flex-wrap items-center mx-auto p-4">
                    <span className="self-center text-2xl font-bold whitespace-nowrap text-primary">
                        BPI Partnership CMS
                    </span>
                    <div className="flex order-2">
                        <Dropdown
                            arrowIcon={false}
                            inline
                            label={
                                <Avatar
                                    placeholderInitials="PR"
                                    rounded
                                />
                            }
                        >
                            <Dropdown.Item
                                as={NavLink}
                                to="reset-sandi"
                                className="flex flex-row items-center space-x-2"
                            >
                                <KeyIcon className="h-4 w-4 text-gray-600" />
                                <span>Ganti Sandi</span>
                            </Dropdown.Item>
                            <Dropdown.Item
                                onClick={handleLogout}
                                className="flex flex-row items-center space-x-2"
                            >
                                <PowerIcon className="h-4 w-4 text-gray-600" />
                                <span>Keluar</span>
                            </Dropdown.Item>
                        </Dropdown>
                    </div>
                    <button
                        data-collapse-toggle="navbar-solid-bg"
                        type="button"
                        className="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
                        aria-controls="navbar-solid-bg"
                        aria-expanded="false"
                    >
                        <span className="sr-only">Open main menu</span>
                        <svg
                            className="w-6 h-6"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fillRule="evenodd"
                                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                                clipRule="evenodd"
                            ></path>
                        </svg>
                    </button>
                    <div
                        className="hidden w-full mx-auto md:block md:w-auto"
                        id="navbar-solid-bg"
                    >
                        <ul className="flex flex-col font-medium mt-4 rounded-lg bg-gray-50 md:flex-row md:space-x-8 md:mt-0 md:border-0 md:bg-transparent">

                        </ul>
                    </div>
                </div>
            </nav >
        </>
    )
}

export default PartnershipNavigation
