import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import Typography from "components/ui/typography"
import { getCMSSegments } from "utils/helper"
import SertifikatForm from "components/cms/Sertifikat/SertifikatForm"

const SertifikatEdit = () => {
    const segments = getCMSSegments()

    return (
        <EmployeeDashboardWrapper>
            <DefBreadcrumb
                segments={segments}
                prefix="cms"
            />
            <Typography
                variant="subheading4"
                as="h1"
                className="text-primary my-2"
            >
                Edit Sertifikasi
            </Typography>

            <SertifikatForm />
        </EmployeeDashboardWrapper>
    )
}

export default SertifikatEdit
