import { useParams } from "react-router-dom"
import clsx from "clsx"
import moment from "moment"
import noImage from "assets/images/no-image.jpg"

import ContentWorkshop from "components/public/workshop/detail-workshop/ContentWorkshop"
import { useGetWorkshopDetail } from "utils/api/public/queries/hooks"

type Extras = {
  offline: boolean
  online: boolean
  speakers: string[]
}

const WorkshopDetail = () => {
  const { slug } = useParams()

  const { data } = useGetWorkshopDetail({ slug: slug ?? "" })

  const dataWorkshop = data?.data
  const extras: Extras | undefined =
    dataWorkshop && JSON.parse(dataWorkshop.extras)

  return (
    <div
      className={clsx(
        "container",
        "flex flex-col-reverse items-center gap-12 lg:justify-between lg:gap-24 lg:flex-row",
        "my-24",
        "min-h-[calc(100vh-350px)]"
      )}
    >
      <ContentWorkshop
        id={String(dataWorkshop?.id)}
        slug={dataWorkshop?.slug ?? ""}
        title={dataWorkshop?.title ?? ""}
        description={dataWorkshop?.description ?? ""}
        categories={dataWorkshop?.categories ?? []}
        date={`${moment(dataWorkshop?.start_date).format(
          "DD MMM YYYY"
        )} - ${moment(dataWorkshop?.end_date).format("DD MMM YYYY")}`}
        currency={dataWorkshop?.price.currency_text ?? ""}
        masterPrice={dataWorkshop?.price.master_price_text ?? ""}
        currentPrice={dataWorkshop?.price.current_price_text ?? ""}
        quota={dataWorkshop?.quota ?? 0}
        speakers={extras?.speakers ?? []}
        buyable={dataWorkshop?.buyable ?? false}
      />
      <img
        className={clsx(
          "h-72 w-full lg:min-w-[550px] lg:max-w-[660px]",
          "rounded-2xl",
          "object-cover object-center",
          "shadow-sm"
        )}
        src={data?.data.thumbnail ?? noImage}
      />
    </div>
  )
}

export default WorkshopDetail
