import { useMutation } from "@tanstack/react-query"

import {
  ReferralResponse,
  GeneralMutationParams,
  ReferralReq,
} from "utils/types"
import { referral } from "../functions"

const useMutationReferral = (
  options?: GeneralMutationParams<ReferralResponse, ReferralReq>
) => {
  return useMutation({
    mutationFn: (payload) => referral(payload),
    ...options,
  })
}

export default useMutationReferral
