import { useMutation } from "@tanstack/react-query"

import { DisableReferralResponse } from "../../types"
import { disableReferral } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationDisableReferral = (
  options?: GeneralMutationParams<
    DisableReferralResponse,
    { referralID: string }
  >
) => {
  return useMutation({
    mutationFn: ({ referralID }) => disableReferral(referralID),
    ...options,
  })
}

export default useMutationDisableReferral
