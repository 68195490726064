import { RouteObject, redirect } from "react-router-dom"
import PartnershipLoginPage from "pages/partnership/Login"
import PartnershipDashboardLayout from "components/layouts/PartnershipDashboardLayout"
import { getPartnershipToken } from "utils/auth"
import DashboardIndex from "pages/partnership/dashboard/DashboardIndex"
import ResetSandi from "pages/partnership/password/PasswordIndex"

const partnershipRoute: RouteObject = {
  path: "partnership",
  children: [
    {
      path: "login",
      element: <PartnershipLoginPage />,
    },
    {
      element: <PartnershipDashboardLayout />,
      loader: () => {
        const token = getPartnershipToken()
        if (!token) return redirect("/")

        return null
      },
      children: [
        {
          path: "dashboard",
          element: <DashboardIndex />,
        },
        {
          path: "reset-sandi",
          element: <ResetSandi />,
        },
      ]
    },
  ]
}

export default partnershipRoute
