import { useNavigate } from "react-router-dom"
import {
  BookmarkIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { toast } from "react-toastify"

import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import SelectInput from "components/ui/form/SelectInput"
import TextInput from "components/ui/form/TextInput"
import { CreateEmployeeRequest } from "utils/api/cms/types"
import { useMutationCreateEmployee } from "utils/api/cms/mutations/hooks"

const ROLE_OPTIONS = [
  {
    label: "Admin",
    value: "REGULAR_ADMIN",
  },
  {
    label: "Super Admin",
    value: "SUPERADMIN",
  },
]

const CreateEmployeeForm = () => {
  const navigate = useNavigate()

  const {
    register,
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
  } = useForm<CreateEmployeeRequest>()

  const { mutate } = useMutationCreateEmployee({
    onSuccess(data) {
      if (!data.success) return
      toast.success("Admin berhasil dibuat")
      setTimeout(() => {
        navigate("/cms/settings")
      }, 1000)
    },
    onError(error) {
      toast.error(error.message)
    },
  })

  const onSubmit: SubmitHandler<CreateEmployeeRequest> = (data) => {
    mutate({
      ...data,
      roles: (data.roles as unknown as typeof ROLE_OPTIONS).map(
        (role) => role.value
      ),
    })
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-4 pb-4 min-w-[42rem]"
    >
      <TextInput
        type="email"
        className="cms-base-input"
        labelClass="cms-base-label"
        isRequired
        label="Email"
        errorMessage={errors.email?.message}
        errorIcon={
          <ExclamationTriangleIcon
            height={24}
            width={24}
            className="text-red-800"
          />
        }
        {...register("email", {
          required: {
            value: true,
            message: "Email wajib diisi",
          },
          pattern: {
            value: /\S+@\S+\.\S+/,
            message: 'Pastikan email Anda memiliki format "nama@example.com"',
          },
        })}
      />
      <TextInput
        type="text"
        className="cms-base-input"
        labelClass="cms-base-label"
        isRequired
        label="Nama Lengkap"
        errorMessage={errors.full_name?.message}
        errorIcon={
          <ExclamationTriangleIcon
            height={24}
            width={24}
            className="text-red-800"
          />
        }
        {...register("full_name", {
          required: {
            value: true,
            message: "Nama lengkap wajib diisi",
          },
        })}
      />
      <Controller
        control={control}
        name="roles"
        rules={{ required: { value: true, message: "Role wajib dipilih" } }}
        render={({ field }) => (
          <SelectInput
            {...field}
            label="Pilih Role"
            labelClass="cms-base-label"
            errorMessage={errors.roles?.message}
            errorIcon={
              <ExclamationTriangleIcon
                height={24}
                width={24}
                className="text-red-800"
              />
            }
            classNames={{
              container: () => "max-w-2xl",
            }}
            options={ROLE_OPTIONS}
            name="roles"
            isRequired
            isMulti
          />
        )}
      />
      <TextInput
        type="number"
        className="cms-base-input"
        labelClass="cms-base-label"
        isRequired
        label="Nomor Telepon"
        errorMessage={errors.phone?.message}
        errorIcon={
          <ExclamationTriangleIcon
            height={24}
            width={24}
            className="text-red-800"
          />
        }
        {...register("phone", {
          required: {
            value: true,
            message: "Nomor telepon wajib diisi",
          },
        })}
      />
      <TextInput
        type="password"
        className="cms-base-input"
        labelClass="cms-base-label"
        isRequired
        label="Password"
        errorMessage={errors.password?.message}
        errorIcon={
          <ExclamationTriangleIcon
            height={24}
            width={24}
            className="text-red-800"
          />
        }
        {...register("password", {
          required: {
            value: true,
            message: "Password wajib diisi",
          },
          pattern: {
            value: /^(?=.*[A-Za-z])(?=.*\d).{8,}$/,
            message: `Pastikan password yang Anda masukkan terdiri dari minimal 8 karakter dan setidaknya mengandung 1 huruf dan 1 angka`,
          },
        })}
      />

      <ButtonWithIcon
        action={undefined}
        type="submit"
        class="self-start mt-4 border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
        disabled={isSubmitting}
      >
        <BookmarkIcon className="h-6 w-6 text-white" />
        <span>{!isSubmitting ? "Tambahkan" : "Menyimpan..."}</span>
      </ButtonWithIcon>
    </form>
  )
}

export default CreateEmployeeForm
