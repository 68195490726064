import clsx from "clsx"
import React from "react"
import classes from "../../../assets/styles/ui/Input.module.css"

const BorderedRadioInput: React.FC<{
  label: string
  name: string
  inputClass: string
  labelClass: string
  value: string
  isRequired: boolean
  options: {
    value: string | number
    label: string
  }[]
  optionsWrapper?: string
  hooks?: any
  validationMessage?: React.ReactNode
  changeHandler?: React.ChangeEventHandler<HTMLInputElement> | undefined
}> = (props) => {
  return (
    <>
      <label
        htmlFor={`${props.name}_id`}
        className={clsx(props.labelClass, props.isRequired && classes.required)}
      >
        {props.label}
      </label>
      <div
        className={clsx(
          props.optionsWrapper && props.optionsWrapper,
          props.validationMessage && "mb-2"
        )}
      >
        {props.options.map((option, i) => (
          <div
            key={i}
            className="flex items-center pl-4 border rounded border-gray-700"
          >
            <input
              onChange={props.changeHandler}
              {...{ checked: props.value === option.value }}
              id={`${props.name}_${i}`}
              type="radio"
              value={option.value}
              name="bordered-radio"
              className="w-4 h-4 text-blue-600 ring-offset-gray-800 focus:ring-2 bg-gray-700 border-gray-600"
            />
            <label
              htmlFor={`${props.name}_${i}`}
              className="w-full py-4 ml-2 text-sm font-medium text-gray-300"
            >
              {option.label}
            </label>
          </div>
        ))}
      </div>
      {props.validationMessage}
    </>
  )
}

export default BorderedRadioInput
