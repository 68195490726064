import Section from "components/ui/section/Section"
import EmployeeDashboardWrapper from "../../../components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "../../../components/ui/breadcrumb/DefBreadcrumb"
import { Course, Webinar } from "../../../utils/types"
import { getCMSSegments } from "../../../utils/helper"
import Typography from "components/ui/typography"
import { createColumnHelper } from "@tanstack/react-table"
import ReactTable from "../../../components/ui/react-table/ReactTable"
import Status from "components/ui/status"
import { Transaction, User } from "utils/api/cms/types"
import { useGetTransactionDetail } from "utils/api/cms/queries/hooks"
import { useParams } from "react-router-dom"
import clsxm from "utils/clsxm"
import { useEffect } from "react"

const UserTransactionDetail = () => {
  const segments = getCMSSegments()
  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  })
  const { trx_id } = useParams()
  console.log(trx_id)

  const { data } = useGetTransactionDetail(String(trx_id))
  const userColHelper = createColumnHelper<User>()
  const userDetailColumns = [
    userColHelper.accessor("first_name", {
      header: () => "Nama Pertama",
    }),
    userColHelper.accessor("middle_name", {
      header: () => "Nama Tengah",
    }),
    userColHelper.accessor("last_name", {
      header: () => "Nama Belakang",
    }),
    userColHelper.accessor("created_at", {
      header: () => "Dibuat pada",
    }),
  ]
  let productColHelper

  if (data?.data?.transaction_detail.transactionable_type === "COURSE") {
    productColHelper = createColumnHelper<Course>()
  } else {
    productColHelper = createColumnHelper<Webinar>()
  }
  const productColoums = [
    productColHelper.accessor("title", {
      header: () => "Judul Modul Belajar",
    }),
    productColHelper.accessor("description", {
      header: () => "Deskripsi",
      cell: (props) => props.getValue()?.replace(/(<([^>]+)>)/gi, ""),
    }),
    productColHelper.accessor("price", {
      header: () => "Harga",
      cell: (props) => (
        <dl className="max-w-md text-white divide-y divide-gray-200">
          <div className="flex flex-col pb-3">
            <dt className="mb-1 text-gray-400 md:text-lg">Harga Asli</dt>
            <dd className="text-lg font-semibold">{`${props.getValue()
              ?.currency_text} ${props.getValue()?.master_price_text}`}</dd>
          </div>
          <div className="flex flex-col pb-3">
            <dt className="mb-1 text-gray-400 md:text-lg">Harga Terkini</dt>
            <dd className="text-lg font-semibold">{`${props.getValue()
              ?.currency_text} ${props.getValue()?.current_price_text}`}</dd>
          </div>
        </dl>
      ),
    }),
    productColHelper.accessor("is_active", {
      header: () => "Status",
      cell: (props) => <Status isActive={props.getValue()} />,
    }),
  ]

  const transactionColHelper = createColumnHelper<Transaction>()
  const transactionColumns = [
    transactionColHelper.accessor("trx_id", {
      header: () => "Nomor Transaksi",
    }),
    transactionColHelper.accessor("amount", {
      header: () => "Harga",
      cell: (props) => {
        const amount = props.row.original.amount
        const formattedAmount = formatter.format(Number(amount))

        return <span>{formattedAmount}</span>
      },
    }),
    transactionColHelper.accessor("created_at", {
      header: () => "Dibuat pada",
    }),
    transactionColHelper.accessor("status", {
      header: () => "Status",
      cell: (props) => {
        const statusOptions = ["PENDING", "SUCCESS", "EXPIRED"]
        const randomIndex = Math.floor(Math.random() * statusOptions.length)
        const status = props.row.original.status
        return (
          <span
            className={clsxm("p-1 rounded", [
              status === "PENDING" && "bg-yellow-500",
              status === "SUCCESS" && "bg-green-500",
              status === "EXPIRED" && "bg-red-500",
            ])}
          >
            {status}
          </span>
        )
      },
    }),
  ]

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix={"cms"}
      />
      <Typography
        as="h4"
        variant="text4"
        className="font-bold text-primary text-lg"
      >
        User Detail
      </Typography>
      <ReactTable
        columns={userDetailColumns}
        data={data?.data ? [data.data.buyer] : []}
      />
      <div className="container">
        <Section space="top-bottom">
          <Section.Title className={clsxm("text-center lg:text-left")}>
            Purchased <span className="text-primary">Transaction</span>
          </Section.Title>
          <ReactTable
            columns={transactionColumns}
            data={data?.data ? [data.data] : []}
          />
        </Section>
        <Section space="top-bottom">
          <Section.Title className={clsxm("text-center lg:text-left")}>
            Detail{" "}
            <span className="text-primary">
              {data?.data?.transaction_detail.transactionable_type}
            </span>
          </Section.Title>
          <ReactTable
            columns={productColoums}
            data={
              data?.data ? [data.data.transaction_detail.transactionable] : []
            }
          />
        </Section>
      </div>
    </EmployeeDashboardWrapper>
  )
}

export default UserTransactionDetail
