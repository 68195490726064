import { requestCMS } from "utils/axiosInstance"

import type {
    GeneralSertifikasiSkkniResponse,
} from "../../types"

const createSkkni = async (payload: FormData) => {
    const res = await requestCMS.post<GeneralSertifikasiSkkniResponse>(
        "/employee/sertifikat/skkni",
        payload
    )
    return res
}

export default createSkkni
