import { requestCMS } from "utils/axiosInstance"

import type {
  GeneralSlideResponse,
} from "../../types"

const createSlide = async (payload: FormData) => {
  const res = await requestCMS.post<GeneralSlideResponse>(
    "/employee/slide",
    payload
  )
  return res
}

export default createSlide
