import { SubmitHandler, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import TextInput from "components/ui/form/TextInput"
import { useMutationUpdateSertifikat } from "utils/api/cms/mutations/hooks"
import { GeneralSertifikatRequest } from "utils/api/cms/types"
import {
    BookmarkIcon,
    ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"
import { useEffect } from "react"
import useMutationCreateSertifikat from "utils/api/cms/mutations/hooks/useMutationCreateSertifikat"
import { useGetSertifikatDetail } from "utils/api/cms/queries/hooks"
import TextAreaInput from "components/ui/form/TextAreaInput"
import clsx from "clsx"

interface SertifikatForm {
    name: string
    description: string
    price: number
}

const SertifikatForm = () => {
    const navigate = useNavigate()
    const params = useParams()

    const isUpdate = !!params.id

    const {
        handleSubmit,
        register,
        control,
        watch,
        getValues,
        setValue,
        reset,
        formState: { errors, isSubmitting },
    } = useForm<SertifikatForm>({})

    const { data: dataDetail } = useGetSertifikatDetail({
        id: params.id ?? "",
    })

    const { mutate: mutateCreateSertifikat } =
        useMutationCreateSertifikat({
            onSuccess(data) {
                if (!data.success) {
                    return
                }
                toast.success("Sertifikat berhasil dibuat")
                setTimeout(() => {
                    navigate("/cms/sertifikat")
                }, 1000)
            },
            onError(error) {
                toast.error(error.message)
            },
        })

    const { mutate: mutateUpdateSertifikat } =
        useMutationUpdateSertifikat({
            onSuccess(data) {
                if (!data.success) {
                    return
                }
                toast.success("Sertifikat berhasil diubah")
                setTimeout(() => {
                    navigate("/cms/sertifikat")
                }, 1000)
            },
            onError(error) {
                toast.error(error.message)
            },
        })

    useEffect(() => {
        if (!dataDetail) return

        const sertifikat = dataDetail.data

        reset({
            name: sertifikat.name,
            description: sertifikat.description,
            price: Number(sertifikat.price?.master_price)
        })
    }, [dataDetail])

    const onSubmit: SubmitHandler<SertifikatForm> = (data) => {
        const payload: GeneralSertifikatRequest = {
            name: data.name,
            description: data.description,
            price: data.price
        }

        if (!isUpdate) {
            return mutateCreateSertifikat(payload)
        }

        mutateUpdateSertifikat({
            id: params.id ?? "",
            payload: payload,
        })
    }

    return (
        <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-4 pb-4 lg:min-w-[42rem]"
        >
            <TextInput
                type="text"
                className="cms-base-input"
                labelClass="cms-base-label"
                isRequired
                label="Nama Sertifikasi"
                errorMessage={errors.name?.message}
                errorIcon={
                    <ExclamationTriangleIcon
                        height={24}
                        width={24}
                        className="text-red-800"
                    />
                }
                {...register("name", {
                    required: {
                        value: true,
                        message: "Nama sertifikasi wajib diisi",
                    },
                })}
            />
            <TextAreaInput
                cols={6}
                rows={6}
                inputClass={clsx(
                    " mt-2 w-full resize-none",
                    errors.name && "cms-base-error-input",
                    !errors.name && "cms-base-input"
                )}
                labelClass="font-semibold text-white"
                label="Deskripsi"
                isRequired
                name="description"
                value=""
                hooks={{
                    ...register("description", {
                        required: "Profesi dan Asal Instansi tidak boleh kosong!",
                        minLength: {
                            message:
                                "Profesi dan Asal Instansi harus memiliki sedikitnya 9 karakter!",
                            value: 9,
                        },
                    }),
                }}
            />
            <TextInput
                type="number"
                className="cms-base-input"
                labelClass="cms-base-label"
                isRequired
                label="Harga"
                errorMessage={errors.price?.message}
                errorIcon={
                    <ExclamationTriangleIcon
                        height={24}
                        width={24}
                        className="text-red-800"
                    />
                }
                {...register("price", {
                    required: {
                        value: true,
                        message: "Harga wajib diisi",
                    },
                })}
            />
            <ButtonWithIcon
                action={undefined}
                type="submit"
                class="self-start mt-12 border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
                disabled={isSubmitting}
            >
                <BookmarkIcon className="h-6 w-6 text-white" />
                <span>
                    {!isSubmitting ? (
                        <>{isUpdate ? "Simpan" : "Tambahkan"}</>
                    ) : (
                        "Menyimpan..."
                    )}
                </span>
            </ButtonWithIcon>
        </form>
    )
}

export default SertifikatForm
