import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import Typography from "components/ui/typography"
import { getCMSSegments } from "utils/helper"
import SlideForm from "components/cms/Slide/SlideForm"

const SlideCreate = () => {
  const segments = getCMSSegments()

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix="cms"
      />
      <div className="inline-flex items-center">
        <Typography
          variant="subheading4"
          as="h1"
          className="text-primary my-2"
        >
          Tambah Slide
        </Typography>
      </div>
      <SlideForm />
    </EmployeeDashboardWrapper>
  )
}

export default SlideCreate
