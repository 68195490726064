import { requestCMS } from "utils/axiosInstance"
import type { VideoDetailResponse } from "../../types"

const getVideoDetail = async (videoID: string) => {
  const res = await requestCMS.get<VideoDetailResponse>(
    `employee/course/section/video/detail/${videoID}`
  )
  return res
}

export default getVideoDetail
