import React from "react"
import { FaQuoteLeft, FaPlayCircle } from "react-icons/fa"
import { Link } from "react-router-dom"
import Typography from "../typography"

interface TestimoniCardProps {
  content: {
    link: string
    text: string
    nama: string
    type: string
    img: string
  }
}

const TestimoniCard: React.FC<TestimoniCardProps> = ({ content }) => {
  return (
    <div className="flex justify-between flex-col gap-4 bg-white p-4 py-6 rounded-lg h-full hover:translate-y-1 transition-all duration-300">
      <div className="grid gap-4">
        <FaQuoteLeft className="text-primary" />
        <Typography variant="text2" className="text-black">
          {content.text}
        </Typography>
      </div>
      <div className="flex gap-4 items-center">
        <img
          src={content.img}
          alt=""
          className="rounded-full w-10 h-10 "
        />
        <div className="grid ">
          <h2 className="text-primary font-semibold text-base">
            {content.nama}
          </h2>
          <Link to={content.link}>
            <div className="flex gap-1 items-center">
              <FaPlayCircle className="w-4 h-4" />
              <h2 className="text-secondary underline font-medium text-sm">
                {content.type}
              </h2>
            </div>
          </Link>
        </div>
      </div>
    </div>
  )
}

export default TestimoniCard
