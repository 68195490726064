import { useEffect } from "react"
import Section from "components/ui/section/Section"
import { useAnimate, useInView, motion } from "framer-motion"
import FeedBackCard from "components/ui/testimoni-card"
import MultiCardCarousel from "components/ui/multiple-card"

const Testimoni = () => {
  const [scope, animate] = useAnimate()
  const isInView = useInView(scope, { once: true })

  useEffect(() => {
    if (isInView) {
      animate(scope.current, { opacity: 1, y: 0 })
    }
  }, [isInView])

  return (
    <Section
      space="top-bottom"
      withBgColor
      className="bg-[#F9F5F7] "
    >
      <Section.Title
        className="md:w-2/3"
        position="left"
      >
        <motion.div
          ref={scope}
          initial={{ y: 100, opacity: 0 }}
        >
          <p className="text-tertiary py-1 ">Apa kata mereka tentang kami</p>
          <p className="text-gray-600 py-1  text-base font-medium lg:w-2/3">
            Transformasi pembelajaran mulai dari pemula hingga Mahir dengan
            Bantuan Mentor Berpengalaman dan Video Adaptif
          </p>
        </motion.div>
      </Section.Title>
      <MultiCardCarousel />
    </Section>
  )
}

export default Testimoni
