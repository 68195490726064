import { requestUser } from "utils/axiosInstance"
import {
  PublicResetPasswordResponse,
  GeneralResetPasswordPayload,
} from "../../types"

const resetPassword = async (
  token: string,
  payload: GeneralResetPasswordPayload
) => {
  const res = await requestUser.post<PublicResetPasswordResponse>(
    `/reset-password/reset/${token}`,
    payload
  )
  return res
}

export default resetPassword
