import { Accordion } from "flowbite-react"
import { PlayCircleIcon } from "@heroicons/react/20/solid"

import Typography from "components/ui/typography"
import { CourseRoadmap } from "utils/api/public/types"

interface CourseContentProps {
  sections: CourseRoadmap[]
  totalVideos: React.ReactNode
}

const CourseContent = ({ totalVideos, sections }: CourseContentProps) => {
  return (
    <section id="course-content">
      <div className="flex flex-col">
        <Typography
          variant="heading6"
          as="h2"
          className="mb-3"
        >
          Video Kursus
        </Typography>
        <Typography
          variant="text3"
          className="mb-3 font-medium"
        >
          Materi - {totalVideos} Video
        </Typography>
      </div>
      <Accordion alwaysOpen>
        {sections.map((section) => (
          <Accordion.Panel key={section.id}>
            <Accordion.Title>{section.title}</Accordion.Title>
            <>
              {section.videos.map((video) => (
                <Accordion.Content key={video.id}>
                  <ul className="flex flex-col gap-4">
                    <li className="flex items-center gap-2">
                      <div className="w-6 h-6">
                        <PlayCircleIcon
                          width={18}
                          height={18}
                        />
                      </div>
                      <Typography className="w-full" variant="text2">{video.title}</Typography>
                    </li>
                  </ul>
                </Accordion.Content>
              ))}
            </>
          </Accordion.Panel>
        ))}
      </Accordion>
    </section>
  )
}

export default CourseContent
