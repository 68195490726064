import { Modal as FlowbiteModal } from "flowbite-react"

const Modal: React.FC<{
  show: boolean
  header: string
  onclose: () => void
  body: React.ReactNode
  footer?: React.ReactNode
}> = (props) => {
  return (
    <FlowbiteModal
      dismissible
      show={props.show}
      onClose={props.onclose}
    >
      <FlowbiteModal.Header>{props.header}</FlowbiteModal.Header>
      <FlowbiteModal.Body>{props.body}</FlowbiteModal.Body>
      {props.footer && (
        <FlowbiteModal.Footer>{props.footer}</FlowbiteModal.Footer>
      )}
    </FlowbiteModal>
  )
}

export default Modal
