import { useMutation } from "@tanstack/react-query"

import { resetPassword } from "../functions"
import { GeneralMutationParams } from "utils/types"
import { PublicResetPasswordResponse, ResetPasswordRequest } from "../../types"

const useMutationUpdatePassword = (
  options?: GeneralMutationParams<
    PublicResetPasswordResponse,
    ResetPasswordRequest
  >
) => {
  return useMutation({
    mutationFn: ({ token, payload }) => resetPassword(token, payload),
    ...options,
  })
}

export default useMutationUpdatePassword
