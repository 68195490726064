import { useQuery } from "@tanstack/react-query"

import { PublicQueryKey, PublicGetProductCategoriesParams } from "../../types"
import { getWorkshopCategories } from "../functions"

const useGetCourseCategories = (params?: PublicGetProductCategoriesParams) => {
  return useQuery({
    queryKey: [PublicQueryKey.WorkshopCategories],
    queryFn: getWorkshopCategories,
    ...params?.options,
  })
}

export default useGetCourseCategories
