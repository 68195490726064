import { DocumentPlusIcon } from "@heroicons/react/24/solid"
import { Link } from "react-router-dom"

import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import Typography from "components/ui/typography"
import { getCMSSegments } from "utils/helper"
import Button from "components/ui/button/Button"
import SlideList from "components/cms/Slide/SlideList"

const SlideManagement = () => {
  const segments = getCMSSegments()

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix="cms"
      />
      <div className="inline-flex items-center">
        <Typography
          variant="subheading4"
          as="h1"
          className="text-primary"
        >
          Daftar Slide
        </Typography>
        <Button
          as={Link}
          to="create"
          className="inline-flex items-center gap-2 py-[10px] px-5 ml-auto"
        >
          <DocumentPlusIcon className="h-6 w-6 text-white" />
          <span>Tambah Slide</span>
        </Button>
      </div>
      <SlideList />
    </EmployeeDashboardWrapper>
  )
}

export default SlideManagement
