import { Link, useNavigate } from "react-router-dom"
import DOMPurify from "dompurify"

import Button from "components/ui/button/Button"
import Typography from "components/ui/typography"
import Title from "./Title"
import { ProductCategory } from "utils/types"
import { useUserInfoStore } from "utils/store/user-info"

interface ContentWorkshopProps {
  id: string
  slug: string
  title: string
  description: string
  currency: string
  masterPrice: string
  currentPrice: string
  categories: ProductCategory[]
  date: string
  quota: number
  speakers: string[]
  buyable: boolean
}

const ContentWorkshop = ({
  id,
  slug,
  title,
  description,
  currency,
  masterPrice,
  currentPrice,
  categories,
  date,
  quota,
  speakers,
  buyable,
}: ContentWorkshopProps) => {
  const isLoggedIn = useUserInfoStore((state) => state.isLoggedIn)
  const navigate = useNavigate()

  const handleCheckout = () => {
    navigate(`/checkout/webinar/${slug}`)
  }

  const isDiscount = masterPrice !== currentPrice

  return (
    <div className="flex flex-col gap-6 items-start">
      <Title
        title={title}
        categories={categories}
        date={date}
        quota={quota}
      />
      <p
        dangerouslySetInnerHTML={{
          __html: DOMPurify.sanitize(description, {
            USE_PROFILES: { svg: false },
          }),
        }}
      />
      <div>
        <Typography
          variant="text1"
          as="h2"
          className="font-semibold"
        >
          Pembicara
        </Typography>
        <div className="flex gap-1">
          {speakers.map((speaker, index) => (
            <Typography
              key={index}
              className="capitalize after:content-[','] last:after:content-none"
            >
              {speaker}
            </Typography>
          ))}
        </div>
      </div>
      <div className="flex justify-center items-center gap-3">
        <Button
          as={isLoggedIn ? "button" : Link}
          disabled={isLoggedIn && !buyable}
          {...(isLoggedIn
            ? {
                onClick: handleCheckout,
              }
            : {
                to: "/register",
              })}
        >
          Daftar Workshop
        </Button>
        <Typography className="space-x-1 font-semibold text-primary">
          {isDiscount && (
            <span className="line-through decoration-2">
              {currency}
              {masterPrice}
            </span>
          )}
          <span>
            {currency}
            {currentPrice}
          </span>
        </Typography>
      </div>
    </div>
  )
}

export default ContentWorkshop
