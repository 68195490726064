const EmployeeDashboardWrapper: React.FC<{ children: React.ReactNode }> = (
  props
) => {
  return (
    <section className="min-h-screen">
      <div className="container h-full mx-auto my-0 pt-8">
        <div className="flex flex-col ">
          {props.children}
        </div>
      </div>
    </section>
  )
}

export default EmployeeDashboardWrapper
