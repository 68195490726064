import { Card, Carousel, Flowbite, Table } from 'flowbite-react'
import React from 'react'
import { useMediaQuery } from 'usehooks-ts'

interface datatableProps {
    dataTable: any
}
const SertifikasiTable = ({ dataTable }: datatableProps) => {
    const desktop = useMediaQuery('(min-width:1024px)')
    const noMaterials = 1;
    return (
        <>
            {desktop ? (
                <Flowbite
                    theme={{
                        theme: {
                            table: {
                                root: {
                                    base: "w-full text-left text-base text-gray-500 dark:text-gray-400",
                                    shadow: "absolute bg-white dark:bg-black w-full h-full top-0 left-0 rounded-lg drop-shadow-md -z-10",
                                    wrapper: "relative"
                                },
                                body: {
                                    base: "group/body",
                                    cell: {
                                        base: "group-first/body:group-first/row:first:rounded-tl-xl border group-first/body:group-first/row:last:rounded-tr-xl group-last/body:group-last/row:first:rounded-bl-lg group-last/body:group-last/row:last:rounded-br-lg px-6 py-4"
                                    }
                                },
                                head: {
                                    base: "group/head text-base uppercase text-gray-700 dark:text-gray-400 border",
                                    cell: {
                                        base: "group-first/head:first:rounded-tl-lg group-first/head:last:rounded-tr-lg bg-gray-50 dark:bg-gray-700 px-6 py-3 border"
                                    }
                                },
                                row: {
                                    base: "group/row",
                                    hovered: "hover:bg-gray-50 dark:hover:bg-gray-600",
                                    striped: "odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
                                }
                            }
                        }

                    }}
                >
                    <Table>
                        <Table.Head>
                            <Table.HeadCell>No</Table.HeadCell>
                            <Table.HeadCell>Kode Unit</Table.HeadCell>
                            <Table.HeadCell>Judul Unit</Table.HeadCell>
                            <Table.HeadCell>Jenis Standar</Table.HeadCell>
                        </Table.Head>
                        <Table.Body className="divide-y">
                            {dataTable?.data.materials?.map((materials, index) => (
                                <Table.Row className="bg-white dark:border-gray-700 dark:bg-gray-800">
                                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white">
                                        {noMaterials + index}
                                    </Table.Cell>
                                    <Table.Cell>{materials.code}</Table.Cell>
                                    <Table.Cell>{materials.name}</Table.Cell>
                                    <Table.Cell>{materials.type}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Flowbite>
            ) : (
                <div className="h-80">
                    <Flowbite
                        theme={{
                            theme: {
                                carousel: {
                                    root: {
                                        base: "relative h-full w-full",
                                        leftControl:
                                            "absolute -bottom-8 right-8 px-4 focus:outline-none",
                                        rightControl:
                                            "absolute -bottom-8 right-0 px-4 focus:outline-none",
                                    },
                                    indicators: {
                                        active: {
                                            off: "bg-gray-300 hover:bg-gray-500",
                                            on: "bg-gray-500",
                                        },
                                        base: "h-2 w-2 rounded-full",
                                        wrapper:
                                            "absolute -bottom-5 left-1/2 flex -translate-x-1/2 space-x-3",
                                    },
                                    item: {
                                        base: "absolute top-1/2 left-1/2 block w-full -translate-x-1/2 -translate-y-1/2",
                                        // wrapper: {
                                        //   off: "w-full flex-shrink-0 transform cursor-default snap-center",
                                        //   on: "w-full flex-shrink-0 transform cursor-grab snap-center"
                                        // }
                                    },
                                    control: {
                                        base: "inline-flex items-center justify-center  group-focus:outline-none bg-opacity-50  sm:h-10 sm:w-10",
                                        icon: "h-5 w-5 text-gray-400 sm:h-6 sm:w-6",
                                    },
                                    scrollContainer: {
                                        base: "flex h-full snap-mandatory overflow-y-hidden overflow-x-scroll scroll-smooth rounded-lg",
                                        snap: "snap-x",
                                    },
                                },
                            },
                        }}
                    >
                        <Carousel slideInterval={5000}>
                            {dataTable?.data.materials?.map((data, index) => (
                                <Card className="my-2">
                                    <h2 className="text-base pb-4 border-b font-normal text-gray-700 flex justify-between">
                                        <span className='font-semibold'>{data.name}</span>
                                    </h2>
                                    <h5 className="text-base pb-4 border-b font-normal text-gray-700 flex justify-between">
                                        Kode Unit <span className='font-semibold'>{data.code}</span>
                                    </h5>
                                    <h5 className="text-base pb-4 font-normal text-gray-700 flex justify-between">
                                        Jenis Standar <span className='font-semibold'>{data.type}</span>
                                    </h5>
                                </Card>
                            ))}
                        </Carousel>
                    </Flowbite>
                </div>
            )}
        </>
    )
}

export default SertifikasiTable