import { requestCMS } from "utils/axiosInstance"

import type { GeneralCareerRequest, GeneralCareerResponse } from "../../types"

const updateCareer = async (id: string, payload: GeneralCareerRequest) => {
  const res = await requestCMS.put<GeneralCareerResponse>(
    `/employee/career/update/${id}`,
    payload
  )
  return res
}

export default updateCareer
