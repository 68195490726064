import { requestCMS } from "utils/axiosInstance"
import { GeneralCareerResponse } from "../../types"

const disableCareer = async (careerID: string) => {
    const res = await requestCMS.post<GeneralCareerResponse>(
        `/employee/career/disable/${careerID}`,
        {}
    )
    return res
}

export default disableCareer
