import { requestCMS } from "utils/axiosInstance"
import { GeneralCareerResponse } from "../../types"

const publishCareer = async (careerID: string) => {
  const res = await requestCMS.post<GeneralCareerResponse>(
    `/employee/career/publish/${careerID}`,
    {}
  )
  return res
}

export default publishCareer
