import { requestCMS } from "utils/axiosInstance"
import { GeneralApiResponse, QueryParams } from "utils/types"
import { WebinarPeserta } from "../../types"

const getWebinarPeserta = async (qParams?: QueryParams) => {
    const res = await requestCMS.get<GeneralApiResponse<WebinarPeserta[]>>(
        "/employee/webinar/peserta",
        qParams
    )
    return res
}

export default getWebinarPeserta
