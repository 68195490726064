import clsx from "clsx"
import React from "react"
import classes from "../../../assets/styles/ui/Input.module.css"

const NumberInput: React.FC<{
  label: string
  name: string
  inputClass: string
  labelClass: string
  value: number | ""
  isRequired: boolean
  max?: number
  min: number
  changeHandler?: React.ChangeEventHandler<HTMLInputElement> | undefined
  blurHandler?: React.FocusEventHandler<HTMLInputElement> | undefined
  hooks?: any
  validationMessage?: React.ReactNode
}> = (props) => {
  return (
    <>
      <label
        htmlFor={`${props.name}_id`}
        className={clsx(props.labelClass, props.isRequired && classes.required)}
      >
        {props.label}
      </label>
      <input
        type="number"
        className={clsx(props.inputClass, props.validationMessage && "mb-2 w-full")}
        {...{ required: props.isRequired }}
        defaultValue={props.value}
        onChange={props.changeHandler}
        onBlur={props.changeHandler}
        name={props.name}
        id={`${props.name}_id`}
        max={props.max}
        min={props.min}
        {...props.hooks}
      />
      {props.validationMessage}
    </>
  )
}

export default NumberInput
