import { requestCMS } from "utils/axiosInstance"
import { GeneralApiResponse, QueryParams } from "utils/types"
import { Career } from "../../types"

const getCareers = async (qParams?: QueryParams) => {
  const res = await requestCMS.get<GeneralApiResponse<Career[]>>(
    "/employee/career",
    qParams
  )
  return res
}

export default getCareers
