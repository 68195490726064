import { createColumnHelper } from "@tanstack/react-table"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import Card from "components/ui/card/Card"
import Chart from "components/ui/chart/PieChart"
import DateRange from "components/ui/date/DateRange"
import React, { useState } from "react"
import { DashboardData } from "utils/api/cms/types"
import { useGetDashboard } from "utils/api/partnership/hooks"
import ReactTable from "components/ui/react-table/ReactTable"

const DashboardIndex: React.FC = () => {
  const formatter = new Intl.NumberFormat("id-ID")
  const segments = ['dashboard']

  const currentHour = new Date().getHours()
  let greeting: string
  const formatDate = (date: Date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${year}-${month}-${day}`;
  };
  const today = formatDate(new Date());

  const [startDate, setStartDate] = useState<string>(today);
  const [endDate, setEndDate] = useState<string>(today);

  const { data: dashboard } = useGetDashboard(startDate, endDate);
  const failedPayment = dashboard?.data?.failed
  const pendingPayment = dashboard?.data?.pending
  const successPayment = dashboard?.data?.sold
  const paymentData = [
    pendingPayment ?? 0,
    successPayment ?? 0,
    failedPayment ?? 0,
  ]

  const handleDateChange = (start: string, end: string) => {
    setStartDate(start)
    setEndDate(end)
  }

  if (currentHour < 11) {
    greeting = "Pagi"
  } else if (currentHour < 15) {
    greeting = "Siang"
  } else {
    greeting = "Malam"
  }

  const partnersColHelper =
    createColumnHelper<DashboardData["list"][number]>()

  const partnersColumns = [
    partnersColHelper.accessor("title", {
      id: "title",
      header: "Judul Kelas",
      cell: (info) => info.getValue(),
    }),
  ]

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix="partnership"
      />
      <h2 className="text-xl text-primary font-semibold leading-loose tracking-wide mb-2 mt-4">
        Selamat {greeting}
      </h2>
      <DateRange onDateChange={handleDateChange} />
      <div className="grid grid-cols-3 gap-5 mt-5">
        <Card
          title="Jumlah Course"
          total={dashboard?.data.course}
        />
        <Card
          title="Jumlah Terjual"
          total={dashboard?.data.sold}
        />
        <Card
          title="Jumlah Pendapatan"
          rupiah={`Rp. ${formatter.format(Number(dashboard?.data.revenue))}`}
        />
      </div>
      <div className="mt-20">
        <div className="grid grid-cols-2 gap-5">
          <div>
            <h3 className="text-lg text-primary font-semibold mb-5">
              Grafik Transaksi
            </h3>
            <Chart coursesData={paymentData} />
          </div>
          <div>
            <h3 className="text-lg text-primary font-semibold mb-5">
              Detail Kelas
            </h3>
            <ReactTable
              columns={partnersColumns}
              data={dashboard?.data.list ?? []}
            />
          </div>
        </div>

      </div>
    </EmployeeDashboardWrapper>
  )
}

export default DashboardIndex
