import { useEffect, useState } from "react"
import {
  DocumentPlusIcon,
  EyeIcon,
  TrashIcon,
  PencilSquareIcon,
} from "@heroicons/react/24/solid"
import { createColumnHelper } from "@tanstack/react-table"
import moment from "moment"
import {
  LoaderFunction,
  useLoaderData,
  useNavigate,
  useParams,
} from "react-router-dom"

import { toast } from "react-toastify"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import Button from "components/ui/button/Button"
import ReactTable from "components/ui/react-table/ReactTable"
import useFlashMessage from "hooks/useFlashMessage"
import api from "utils/axiosInstance"
import { getCMSSegments } from "utils/helper"
import {
  CMSGetCourseResponse,
  CMSGetCourseSectionsResponse,
  Course,
  CourseSection,
} from "utils/types"
import { useDeleteSection } from "utils/api/cms/mutations/hooks"
import Modal from "components/ui/modal/Modal"
import Typography from "components/ui/typography"

const CourseSectionManagement: React.FC = () => {
  const segments = getCMSSegments()
  const navigate = useNavigate()
  const [modalDelete, setModalDelete] = useState({
    open: false,
    title: "",
    sectionID: "",
  })
  const loaderResponse = useLoaderData() as {
    course: CMSGetCourseResponse<Course>
    sections: CMSGetCourseSectionsResponse<CourseSection>
  }
  const [course, setCourse] = useState<Course | undefined>(undefined)
  const [sections, setSections] = useState<CourseSection[] | undefined>(
    undefined
  )
  useFlashMessage(
    "success",
    "courseSectionUpsertSuccess",
    "Berhasil menyimpan bagian dari modul belajar"
  )

  useEffect(() => {
    if (!loaderResponse) return

    setCourse(loaderResponse.course.data)
    setSections(loaderResponse.sections.data)
  }, [loaderResponse])

  const colHelper = createColumnHelper<CourseSection>()
  const cols = [
    colHelper.accessor("title", {
      header: () => "Judul Bagian Belajar",
    }),
    colHelper.accessor("sequence", {
      header: () => "Prioritas Urutan",
    }),
    colHelper.accessor("created_at", {
      header: () => "Tanggal Dibuat",
      cell: (props) =>
        moment(props.getValue()).locale("id").format("dddd, D MMMM YYYY H:mm"),
    }),
    colHelper.accessor("updated_at", {
      header: () => "Tanggal Terakhir Diupdate",
      cell: (props) =>
        moment(props.getValue()).locale("id").format("dddd, D MMMM YYYY H:mm"),
    }),
    colHelper.display({
      id: "actions",
      header: () => "AKSI",
      cell: (props) => (
        <div className="flex flex-row w-fit space-x-3">
          <ButtonWithIcon
            class="mx-auto border-2 border-transparent bg-yellow-400 text-white hover:bg-yellow-300 hover:border-yellow-300"
            type="button"
            action={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault()
              return navigate(`edit/${props.row.original.id}`)
            }}
            sizeClass="p-1"
          >
            <PencilSquareIcon className="h-5 w-5" />
          </ButtonWithIcon>
          <ButtonWithIcon
            class="mx-auto border-2 border-transparent bg-blue-600 text-white hover:bg-blue-500 hover:border-blue-500"
            type="button"
            action={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault()
              return navigate(`detail/${props.row.original.id}`)
            }}
            sizeClass="p-1"
          >
            <EyeIcon className="h-5 w-5" />
          </ButtonWithIcon>
          <Button
            onClick={() =>
              handleModalDelete(
                props.row.original.id.toString(),
                props.row.original.title
              )
            }
            className="bg-red-600 border-red-600"
          >
            <TrashIcon
              width={20}
              height={20}
            />
          </Button>
        </div>
      ),
    }),
  ]
  const handleModalDelete = (sectionID?: string, title?: string) => {
    setModalDelete((prev) => ({
      open: !prev.open,
      sectionID: sectionID ?? "",
      title: title ?? "",
    }))
  }

  const { mutate: mutateDeleteSection } = useDeleteSection({
    onSuccess() {
      toast.success("Section berhasil dihapus")
      setModalDelete({
        open: false,
        title: "",
        sectionID: "",
      })

      navigate("")
    },
    onError(err) {
      toast.error(err.message)
    },
  })

  return (
    <>
      <EmployeeDashboardWrapper>
        <DefBreadcrumb
          segments={segments}
          prefix="cms"
        />
        <h2 className="text-xl text-primary font-semibold leading-loose tracking-wide">
          Pengaturan Konten Bagian Belajar {course?.title}
        </h2>
        <ButtonWithIcon
          action={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault()
            return navigate("create")
          }}
          type="submit"
          class="ml-auto border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
        >
          <DocumentPlusIcon className="h-6 w-6 text-white" />
          <span>Tambah Bagian</span>
        </ButtonWithIcon>
        {sections && (
          <ReactTable
            columns={cols}
            data={sections as CourseSection[]}
          />
        )}
      </EmployeeDashboardWrapper>
      <Modal
        header="Hapus Video"
        onclose={handleModalDelete}
        show={modalDelete.open}
        body={
          <Typography className="text-white">
            Apakah Anda yakin ingin menghapus video <b>{modalDelete.title}</b>?
          </Typography>
        }
        footer={
          <div className="space-x-2 ml-auto">
            <Button
              onClick={() => handleModalDelete()}
              variant="outlined"
              className="text-gray-300 border-gray-300"
            >
              Batal
            </Button>
            <Button onClick={() => mutateDeleteSection(modalDelete.sectionID)}>
              Hapus
            </Button>
          </div>
        }
      />
    </>
  )
}

export default CourseSectionManagement

export const courseSectionLoader: LoaderFunction = async ({ params }) => {
  const courseID = params.id

  if (courseID === undefined || isNaN(+courseID!))
    throw new Response("Not Found", { status: 404 })

  const loadCourse = async () => {
    const courseResponse = await api.cms.getCourse(+courseID)

    return courseResponse
  }

  const loadSections = async () => {
    const sectionsResponse = await api.cms.getCourseSections(+courseID, {
      page: 1,
      limit: 60,
    })

    return sectionsResponse
  }

  return {
    course: await loadCourse(),
    sections: await loadSections(),
  }
}
