import { useQuery } from "@tanstack/react-query"

import { GetTransactionByIdParams, TransactionQueryKey } from "../../types"
import { getTransactionById } from "../functions"

const useGetTransactionById = (params?: GetTransactionByIdParams) => {
  return useQuery({
    queryKey: [TransactionQueryKey.Transaction, params?.id ?? ""],
    queryFn: () => getTransactionById(params?.id ?? ""),
    ...params?.options,
  })
}

export default useGetTransactionById
