import { useMutation } from "@tanstack/react-query"

import { PublishCourseResponse } from "../../types"
import { publishCourse } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationPublishCourse = (
  options?: GeneralMutationParams<PublishCourseResponse, { courseID: string }>
) => {
  return useMutation({
    mutationFn: ({ courseID }) => publishCourse(courseID),
    ...options,
  })
}

export default useMutationPublishCourse
