import { useQuery } from "@tanstack/react-query"
import { CmsQueryKey, GetCareerUsersParams } from "../../types"
import getCareerUsers from "../functions/getCareerUser"

const useGetCareerUsers = (params?: GetCareerUsersParams) => {
  return useQuery({
    queryKey: [CmsQueryKey.CareerUsers, params?.qParams ?? ""],
    queryFn: () => getCareerUsers(params?.qParams),
    ...params?.options,
  })
}

export default useGetCareerUsers
