import { useMutation } from "@tanstack/react-query"

import { forgotPassword } from "../functions"
import { GeneralMutationParams } from "utils/types"
import {
  PublicForgotPasswordUserResponse,
  ForgotPasswordRequest,
} from "../../types"

const useMutationForgotPassword = (
  options?: GeneralMutationParams<
    PublicForgotPasswordUserResponse,
    ForgotPasswordRequest
  >
) => {
  return useMutation({
    mutationFn: (payload) => forgotPassword(payload),
    ...options,
  })
}

export default useMutationForgotPassword
