import { useQuery } from "@tanstack/react-query"

import { getSertifikat } from "../functions"
import { CmsQueryKey, GetSertifikatParams } from "../../types"

const useGetSertifikat = (params?: GetSertifikatParams) => {
    return useQuery({
        queryKey: [CmsQueryKey.Sertifikat, params?.qParams ?? ""],
        queryFn: () => getSertifikat(params?.qParams),
        ...params?.options,
    })
}

export default useGetSertifikat
