import { requestPartnership } from "utils/axiosInstance"
import {
  PartnershipAuthenticationReq,
  PartnershipAuthenticationResponse,
} from "utils/types"

const login = async (payload: PartnershipAuthenticationReq) => {
  const res = await requestPartnership.post<PartnershipAuthenticationResponse>(
    "/partnership/authenticate",
    payload
  )
  return res
}

export default login
