import { useParams } from "react-router-dom"

import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import Typography from "components/ui/typography"
import { getCMSSegments } from "utils/helper"
import { useGetCareerDetail } from "utils/api/cms/queries/hooks"
import CareerUsersList from "components/cms/CareerUsersList"

const CareerManagementUsers = () => {
  const segments = getCMSSegments()
  const params = useParams()

  const { data: dataDetailCareer } = useGetCareerDetail({
    id: params.id ?? "",
  })

  const dataCareer = dataDetailCareer?.data

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix="cms"
      />
      <div className="inline-flex items-center">
        <Typography
          variant="subheading4"
          as="h1"
          className="text-primary"
        >
          Peserta Career {dataCareer?.title}
        </Typography>
      </div>
      <CareerUsersList />
    </EmployeeDashboardWrapper>
  )
}

export default CareerManagementUsers
