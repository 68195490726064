import { Navigate, useNavigate } from "react-router-dom"
import clsx from "clsx"
import { SubmitHandler, useForm } from "react-hook-form"
import { toast } from "react-toastify"

import Section from "components/ui/section/Section"
import Typography from "components/ui/typography"
import Button from "components/ui/button/Button"
import type { ResendRequest } from "utils/api/user/types"
import { useMutationResendEmail } from "utils/api/user/mutations/hooks"
import { useUserInfoStore } from "utils/store/user-info"
import { useEffect, useState } from "react"

const ResendEmail = () => {
  const navigate = useNavigate()
  const regisEmail = localStorage.getItem("email_registration")
  if (!regisEmail) {
    navigate("/login")
  }
  const {
    handleSubmit,
    formState: { errors },
  } = useForm<ResendRequest>({
    defaultValues: {
      email: regisEmail,
    },
  })

  function formatTime(ms) {
    const minutes = Math.floor(ms / 60000)
    const seconds = Math.floor((ms % 60000) / 1000)
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`
  }

  const [isResendDisabled, setIsResendDisabled] = useState(
    localStorage.getItem("resendDisabled") === "true" ? true : false
  )

  const [remainingTime, setRemainingTime] = useState(0)

  const { isLoggedIn, setUserInfo } = useUserInfoStore((state) => ({
    isLoggedIn: state.isLoggedIn,
    setUserInfo: state.setUserInfo,
  }))

  const { mutate } = useMutationResendEmail({
    onSuccess(data) {
      if (!data.success) {
        toast.error("Pengiriman email gagal")
        return
      }
      handleResetButton()
      toast.success(
        "Pengiriman Email berhasil. Segera cek email dan klik tombol Verifikasi Email"
      )
    },
    onError(error) {
      if (error.message === "requested user already verified") {
        toast.warning(
          "Akun anda telah diaktivasi, anda akan dialihkan ke halaman login"
        )
        navigate("/login")
      }
      if (
        error.message ===
        "can't resend verification email more than once in 2 minutes"
      ) {
        toast.error("Email gagal dikirimkan, tunggu selama 2 menit.")
        handleResetButton()
      } else if (error.message === "The selected email is invalid.") {
        toast.error("Email yang anda masukkan tidak valid")
      } else {
        toast.error(error.message)
      }
    },
  })

  useEffect(() => {
    if (isResendDisabled) {
      setTimeout(() => {
        setIsResendDisabled(false)
        localStorage.removeItem("resendDisabled")
      }, 130000)
    }
  }, [isResendDisabled])

  useEffect(() => {
    let intervalId
    if (remainingTime > 0) {
      intervalId = setInterval(() => {
        setRemainingTime((prevTime) => prevTime - 1000)
      }, 1000)
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    }
  }, [remainingTime])

  useEffect(() => {
    if (isResendDisabled === true) {
      handleResetButton()
    }
  }, [])

  const handleResetButton = () => {
    setIsResendDisabled(true)
    setRemainingTime(130000)

    setTimeout(() => {
      setIsResendDisabled(false)
      setRemainingTime(0)
      localStorage.removeItem("resendDisabled")
    }, 130000)

    localStorage.setItem("resendDisabled", "true")
  }

  const onSubmit: SubmitHandler<ResendRequest> = (data) => {
    mutate(data)
  }

  if (isLoggedIn) {
    return <Navigate to="/" />
  }

  return (
    <Section space="top-bottom">
      <div
        className={clsx(
          "flex flex-col items-center justify-center",

          "mx-8 md:mx-auto",
          "max-w-5xl",
          "rounded",
          "space-y-4",
          "md:py-8 md:px-[200px] md:shadow"
        )}
      >
        <Typography
          as="h1"
          variant="heading3"
          className="text-secondary "
        >
          Cek <span className="text-primary">Email Kamu</span>
        </Typography>
        <Typography
          as="h1"
          variant="text1"
          className="text-secondary text-center mb-10 mt-20"
        >
          Link verifikasi telah dikirimkan ke email Anda. Segera cek email dan
          klik tombol "Verifikasi Email".
        </Typography>
        <hr className="h-1 mx-auto my-4 w-full mt-20 bg-gray-200 border-0 rounded" />

        <form
          className="w-full flex items-center mt-12 justify-center space-y-5"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="w-full flex flex-col items-center mt-3 justify-center">
            {remainingTime > 0 ? (
              <Typography
                as="h1"
                variant="text4"
                className="text-tertiary text-center items-center w-1/2"
              >
                Tunggu {formatTime(remainingTime)} sebelum dapat Resend Email
                kembali.
              </Typography>
            ) : (
              <Typography
                as="h1"
                variant="text4"
                className="text-tertiary text-center items-center w-1/2"
              >
                Jika Anda tidak menerima email untuk verifikasi, klik link di
                bawah ini untuk kirim ulang email.
              </Typography>
            )}
            <Button
              className="mt-2"
              type="submit"
              size="sm"
              disabled={isResendDisabled}
            >
              Resend Email
            </Button>
          </div>
        </form>
      </div>
    </Section>
  )
}

export default ResendEmail
