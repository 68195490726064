import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios"
import Cookies from "js-cookie"

import type {
  GeneralAPIValidationResponse,
  APIUnauthorizedResponse,
  ErrorResponse,
  GeneralAPIUnverifiedResponse,
} from "./types"
import cms from "./api/cms"
import { getCmsToken, getPartnershipToken } from "./auth"
import { useUserInfoStore } from "./store/user-info"
import { useEmployeeInfoStore } from "./store/employee-info"
import { usePartnershipInfoStore } from "./store/partnership-info"

/** ---------------- CMS Instance ---------------- */
export const cmsAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

cmsAxiosInstance.interceptors.request.use((config) => {
  const token = getCmsToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

cmsAxiosInstance.interceptors.response.use(
  (res) => res,
  (error: AxiosError) => {
    const { status } = error.response!
    switch (status) {
      case 400:
        throw error.response?.data as GeneralAPIValidationResponse
      case 401:
        Cookies.remove("cms_auth_token")
        useEmployeeInfoStore.setState({
          employeeInfo: {
            id: 0,
            email: "",
            fullName: "",
            phone: "",
            roles: [],
          },
        })
        window.location.replace("/")
        throw error.response?.data as APIUnauthorizedResponse
      case 404:
        console.error("/not-found")
        break
      case 422:
        throw error.response?.data as GeneralAPIValidationResponse
      case 500:
        console.error("/server-error")
        break
    }
    return Promise.reject(error as AxiosError<ErrorResponse>)
  }
)

/** ---------------- Partnership Instance ---------------- */
export const partnershipAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

partnershipAxiosInstance.interceptors.request.use((config) => {
  const token = getPartnershipToken()
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

partnershipAxiosInstance.interceptors.response.use(
  (res) => res,
  (error: AxiosError) => {
    const { status } = error.response!
    switch (status) {
      case 400:
        throw error.response?.data as GeneralAPIValidationResponse
      case 401:
        Cookies.remove("partnership_auth_token")
        usePartnershipInfoStore.setState({
          partnershipInfo: {
            id: 0,
            email: "",
            full_name: "",
          },
        })
        window.location.replace("/")
        throw error.response?.data as APIUnauthorizedResponse
      case 404:
        console.error("/not-found")
        break
      case 422:
        throw error.response?.data as GeneralAPIValidationResponse
      case 500:
        console.error("/server-error")
        break
    }
    return Promise.reject(error as AxiosError<ErrorResponse>)
  }
)

/** ---------------- User Instance ---------------- */
export const userAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

userAxiosInstance.interceptors.request.use((config) => {
  const token = Cookies.get("user_auth_token")
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
})

userAxiosInstance.interceptors.response.use(
  (res) => res,
  (error: AxiosError) => {
    const { status } = error.response!
    switch (status) {
      case 400:
        throw error.response?.data as GeneralAPIValidationResponse
      case 401:
        Cookies.remove("user_auth_token")
        useUserInfoStore.setState({
          isLoggedIn: false,
          userInfo: {
            id: 0,
            email: "",
            first_name: "",
            middle_name: "",
            last_name: "",
          },
        })
        window.location.replace("/login")
        throw error.response?.data as APIUnauthorizedResponse
      case 403:
        const response = error.response?.data as GeneralAPIUnverifiedResponse
        throw response.data as ErrorResponse
      case 404:
        console.error("/not-found")
        break
      case 422:
        throw error.response?.data as GeneralAPIValidationResponse
      case 429:
        throw error.response?.data as ErrorResponse
      case 500:
        console.error("/server-error")
        throw error.response?.data as ErrorResponse
    }
    return Promise.reject(error as AxiosError<ErrorResponse>)
  }
)

const responseBody = <T>(response: AxiosResponse<T>) => response.data

const generalRequest = (axiosInstance: AxiosInstance) => ({
  get: <T>(
    url: string,
    params?: {},
    config?: AxiosRequestConfig<any | undefined>
  ) =>
    axiosInstance.get<T>(url, { params: params, ...config }).then(responseBody),
  post: <T>(url: string, body: {}) =>
    axiosInstance.post<T>(url, body).then(responseBody),
  put: <T>(url: string, body: {}) =>
    axiosInstance.put<T>(url, body).then(responseBody),
  delete: <T>(url: string) => axiosInstance.delete<T>(url).then(responseBody),
  upload: <T>(
    url: string,
    body: {},
    config?: AxiosRequestConfig<any | undefined>
  ) => axiosInstance.post<T>(url, body, config).then(responseBody),
})

export const requestCMS = generalRequest(cmsAxiosInstance)
export const requestPartnership = generalRequest(partnershipAxiosInstance)
export const requestUser = generalRequest(userAxiosInstance)

const api = {
  cms,
}

export default api
