import { requestCMS } from "utils/axiosInstance"
import type {
  GeneralPartnershipRequest,
  GeneralPartnershipResponse,
} from "../../types"

const updatePartnership = async (
  id: string,
  payload: GeneralPartnershipRequest
) => {
  const res = await requestCMS.put<GeneralPartnershipResponse>(
    `/employee/partnership/${id}`,
    payload
  )
  return res
}

export default updatePartnership
