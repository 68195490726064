import { create } from "zustand"
import { StateStorage, createJSONStorage, persist } from "zustand/middleware"
import CryptoJS from "crypto-js"

interface UserInfo {
  id: number
  email: string
  first_name: string
  middle_name: string
  last_name: string
  phone?: string
}

interface UserState {
  isLoggedIn: boolean
  userInfo: UserInfo
}

interface UserInfoState {
  isLoggedIn: boolean
  userInfo: UserInfo
  setUserInfo: ({ isLoggedIn, userInfo }: UserState) => void
  resetUserInfo: () => void
}

const encrpytedStorage: StateStorage = {
  getItem(name) {
    const item = localStorage.getItem(name)
    const decrypted = CryptoJS.AES.decrypt(
      item ?? "",
      process.env.REACT_APP_ENCRYPTION_SECRET ?? ""
    )
    return decrypted.toString(CryptoJS.enc.Utf8)
  },
  setItem(name, value) {
    const encrypted = CryptoJS.AES.encrypt(
      value,
      process.env.REACT_APP_ENCRYPTION_SECRET ?? ""
    ).toString()
    localStorage.setItem(name, encrypted)
  },
  removeItem(name) {
    localStorage.removeItem(name)
  },
}

export const useUserInfoStore = create(
  persist<UserInfoState>(
    (set) => ({
      isLoggedIn: false,
      userInfo: {
        id: 0,
        email: "",
        first_name: "",
        middle_name: "",
        last_name: "",
        phone: "",
      },
      setUserInfo: ({ isLoggedIn, userInfo }) =>
        set({
          isLoggedIn,
          userInfo,
        }),
      resetUserInfo: () =>
        set({
          isLoggedIn: false,
          userInfo: {
            id: 0,
            email: "",
            first_name: "",
            middle_name: "",
            last_name: "",
            phone: "",
          },
        }),
    }),
    {
      name: "user-info",
      storage: createJSONStorage(() => encrpytedStorage),
    }
  )
)
