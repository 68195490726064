import { useState, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import {
    PencilSquareIcon,
} from "@heroicons/react/24/solid"
import { PaginationState, createColumnHelper } from "@tanstack/react-table"
import { Tooltip } from "flowbite-react"
import ReactTable from "components/ui/react-table/ReactTable"
import type { SertifikatUser } from "utils/api/cms/types"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import { CMSSertifikatUserListResponse } from "utils/types"
import CMSDefaultTableListFilter from "../CMSDefaultTableListFilter"
import { useGetSertifikatUser } from "utils/api/cms/queries/hooks"

const SertifikatPesertaList = () => {
    const param = useParams()
    const formatter = new Intl.NumberFormat("id-ID")
    const [lastPage, setLastPage] = useState(1)
    const [sertifikatResponse, setSertifikatResponse] =
        useState<CMSSertifikatUserListResponse<SertifikatUser>>()
    const [searchFilter, setSearchFilter] = useState("")
    const [perPageLimit, setPerPageLimit] = useState(10)
    const navigate = useNavigate()
    const [dataSertifikat, setDataSertifikat] = useState<SertifikatUser[]>([])
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 1,
    })
    const { data: resultResponse } = useGetSertifikatUser({
        options: {
            staleTime: 0,
        },
        qParams: {
            limit: perPageLimit,
            page: pagination.pageIndex + 1,
            keyword: searchFilter,
            sertifikat_id: Number(param.id)
        },
    })

    useEffect(() => {
        setSertifikatResponse(resultResponse)
        setDataSertifikat(resultResponse?.data || [])
        setLastPage(resultResponse?.meta?.last_page || 1)
    }, [resultResponse])


    const sertifikatColumnHelper = createColumnHelper<SertifikatUser>()

    const sertifikatColumns = [
        sertifikatColumnHelper.accessor("sertifikat.name", {
            header: "Nama Sertifikasi",
            cell: (props) => props.row.original.sertifikat.name
        }),
        sertifikatColumnHelper.accessor("name", {
            header: "Nama Peserta",
            cell: (props) => props.getValue()
        }),
        sertifikatColumnHelper.accessor("email", {
            header: "Email",
            cell: (props) => props.getValue()
        }),
        sertifikatColumnHelper.accessor("phone", {
            header: "No.Whatsapp",
            cell: (props) => props.getValue()
        }),
        sertifikatColumnHelper.accessor("status", {
            header: "Status",
            cell: (props) => props.getValue()
        }),
        sertifikatColumnHelper.accessor("created_at", {
            header: "Tanggal",
            cell: (props) => props.getValue()
        }),
        sertifikatColumnHelper.display({
            id: "actions",
            header: "Aksi",
            cell: (props) => {
                const isProses = props.row.original.status === "PROSES";

                return (
                    <div className="flex flex-row w-fit space-x-3">
                        {isProses && (
                            <Tooltip content="Edit">
                                <ButtonWithIcon
                                    class="mx-auto border-2 border-transparent bg-yellow-400 text-white hover:bg-yellow-300 hover:border-yellow-300"
                                    type="button"
                                    action={(e: React.MouseEvent<HTMLButtonElement>) => {
                                        e.preventDefault();
                                        return navigate(`edit/${props.row.original.id}`);
                                    }}
                                    sizeClass="p-1"
                                >
                                    <PencilSquareIcon className="h-5 w-5" />
                                </ButtonWithIcon>
                            </Tooltip>
                        )}
                    </div>
                );
            },
        }),
    ]

    const handleSearch = (keyword: string) => {
        setSearchFilter(keyword)
        setPagination((prevPagination) => ({ ...prevPagination, pageIndex: 0 }))
    }

    return (
        <EmployeeDashboardWrapper>
            <CMSDefaultTableListFilter
                pageSelector
                searchPlaceHolder="Cari"
                wrapperClass="ml-auto flex flex-row space-x-6 mb-3"
                onSearchHandler={handleSearch}
                perPageHandler={(selected) => setPerPageLimit(selected)}
            />
            <ReactTable
                columns={sertifikatColumns}
                data={dataSertifikat ?? []}
                pagination={pagination}
                onPaginationChange={setPagination}
                pageCount={lastPage}
            />
        </EmployeeDashboardWrapper>
    )
}

export default SertifikatPesertaList
