import { useQuery } from "@tanstack/react-query"

import { getCourses } from "../functions"
import { CmsQueryKey, GetCoursesParams } from "../../types"

const useGetCourses = (params?: GetCoursesParams) => {
  return useQuery({
    queryKey: [CmsQueryKey.Courses, params?.qParams ?? ""],
    queryFn: () => getCourses(params?.qParams),
    ...params?.options,
  })
}

export default useGetCourses
