import { requestCMS } from "utils/axiosInstance"

import type {
  GeneralPartnershipRequest,
  GeneralPartnershipResponse,
} from "../../types"

const createPartnership = async (payload: GeneralPartnershipRequest) => {
  const res = await requestCMS.post<GeneralPartnershipResponse>(
    "/employee/partnership",
    payload
  )
  return res
}

export default createPartnership
