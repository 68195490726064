import { Link } from "react-router-dom"
import { ArrowUturnLeftIcon, XCircleIcon } from "@heroicons/react/24/solid"

import Button from "components/ui/button/Button"
import Typography from "components/ui/typography"
import PaymentHeader from "components/payment/PaymentHeader"

const Fallback = () => {
  return (
    <div className="flex justify-center items-center bg-slate-100 w-screen h-screen">
      <section className="bg-white w-[80%] lg:w-1/2 rounded-xl p-8 shadow">
        <PaymentHeader
          status="failed"
          icon={
            <XCircleIcon
              width={50}
              height={50}
              className="relative text-red-600 z-10"
            />
          }
        >
          <Typography
            as="h1"
            variant="heading6"
            className="text-red-600 mb-2"
          >
            {"Reset Password Gagal :("}
          </Typography>
          <Typography>
            Terjadi kesalahan pada proses reset password Anda, reset password
            gagal diproses
          </Typography>
        </PaymentHeader>
        <div className="flex justify-center gap-2 mt-6 no-print">
          <Button
            as={Link}
            to="/login"
            variant="outlined"
            color="tertiary"
            className="inline-flex gap-2"
          >
            <ArrowUturnLeftIcon
              width={24}
              height={24}
            />
            Kembali
          </Button>
        </div>
      </section>
    </div>
  )
}

export default Fallback
