import { requestCMS } from "utils/axiosInstance"
import { GeneralApiResponse, QueryParams } from "utils/types"
import { SertifikatUser } from "../../types"

const getSertifikatUser = async (qParams?: QueryParams) => {
    const res = await requestCMS.get<GeneralApiResponse<SertifikatUser[]>>(
        "/employee/sertifikat/users",
        qParams
    )
    return res
}

export default getSertifikatUser
