import { useState } from "react"
import { Link, useNavigate } from "react-router-dom"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import { getCMSSegments } from "../../../utils/helper"
import { BookOpenIcon, EyeIcon } from "@heroicons/react/24/solid"
import type { User } from "utils/api/cms/types"
import ReactTable from "components/ui/react-table/ReactTable"
import CMSDefaultTableListFilter from "components/cms/CMSDefaultTableListFilter"
import useGetUsers from "utils/api/cms/queries/hooks/useGetUsers"
import { PaginationState, createColumnHelper } from "@tanstack/react-table"
import { FaFileExcel, FaWhatsapp } from "react-icons/fa"
import Typography from "components/ui/typography"
import Button from "components/ui/button/Button"
import * as XLSX from 'xlsx';

const UserManagement: React.FC = () => {
  const segments = getCMSSegments()

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 1,
  })
  const [searchFilter, setSearchFilter] = useState("")
  const [perPageLimit, setPerPageLimit] = useState(10)
  const { data } = useGetUsers({
    options: {
      staleTime: 0,
    },
    qParams: {
      limit: perPageLimit,
      page: pagination.pageIndex + 1,
      keyword: searchFilter,
    },
  })

  const handleSearch = (keyword: string) => {
    setSearchFilter(keyword)
  }

  const { data: dataExport } = useGetUsers({
    options: {
      staleTime: 0,
    },
  })


  const userData = dataExport?.data.map(item => ({
    nama: item.first_name,
    email: item.email,
    phone: item.phone,
  })) || [];


  const convertPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, '');
    if (cleaned.startsWith('08')) {
      return '+62' + cleaned.substring(1);
    }
    if (cleaned.startsWith('62')) {
      return '+' + cleaned;
    }
    if (cleaned.startsWith('+62')) {
      return cleaned;
    }
    return phoneNumber;
  };

  const handleExport = () => {

    const workbook = XLSX.utils.book_new();
    const worksheet = XLSX.utils.json_to_sheet(userData);

    const colWidths = userData.reduce<number[]>((widths, row) => {
      Object.keys(row).forEach((key, i) => {
        const contentLength = row[key] ? row[key].toString().length : 0;
        widths[i] = Math.max(widths[i] || 0, contentLength);
      });
      return widths;
    }, []).map(w => ({ wch: w + 2 }));

    worksheet['!cols'] = colWidths;

    XLSX.utils.book_append_sheet(workbook, worksheet, 'Daftar Pengguna');
    XLSX.writeFile(workbook, 'daftar-pengguna.xlsx');
  };

  const navigate = useNavigate()
  const userColHelper = createColumnHelper<User>()
  const userColumns = [
    userColHelper.accessor("first_name", {
      header: () => "Nama",
    }),
    userColHelper.accessor("email", {
      header: () => "Email",
    }),
    userColHelper.accessor("phone", {
      header: () => "No.Telepon",
      cell: (props) => {
        const phoneNumber = props.getValue() ?? "-";
        const whatsappPhoneNumber = convertPhoneNumber(phoneNumber);

        return (
          <div className="flex gap-2 items-center">
            {phoneNumber}
            <Link
              to={`https://wa.me/${whatsappPhoneNumber}`}
              target="_blank"
              className={`p-1 bg-green-400 rounded-md ${phoneNumber === "-" ? 'hidden' : ''}`}>
              <FaWhatsapp className="h-5 w-5" />
            </Link>
          </div>
        );
      },
    }),
    userColHelper.accessor("created_at", {
      header: () => "Dibuat pada",
    }),
    userColHelper.display({
      id: "actions",
      header: () => "AKSI",
      cell: (props) => (
        <div className="flex flex-row w-fit space-x-3">
          <ButtonWithIcon
            class="mx-auto border-2 border-transparent bg-blue-600 text-white hover:bg-blue-500 hover:border-blue-500"
            type="button"
            action={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault()
              return navigate(`detail/${props.row.original.id}`)
            }}
            sizeClass="p-1"
          >
            <EyeIcon className="h-5 w-5" />
          </ButtonWithIcon>
          <ButtonWithIcon
            class="mx-auto border-2 border-transparent bg-red-600 text-white hover:bg-red-500 hover:border-red-500"
            type="button"
            action={(e: React.MouseEvent<HTMLButtonElement>) => {
              e.preventDefault()
              return navigate(
                `transactionhistories?email=${props.row.original.email}`
              )
            }}
            sizeClass="p-1"
          >
            <BookOpenIcon className="h-5 w-5" />
          </ButtonWithIcon>
        </div>
      ),
    }),
  ]

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix="cms"
      />
      <Typography
        variant="subheading4"
        as="h1"
        className="text-primary mt-5"
      >
        Daftar  Pengguna
      </Typography>
      <div className="flex flex-wrap justify-between gap-4 my-6">
        <Button
          onClick={handleExport}
          className="inline-flex items-center gap-2 py-[10px] px-5"
        >
          <FaFileExcel className="h-6 w-6 text-white" />
          <span>Export Excel</span>
        </Button>
        <CMSDefaultTableListFilter
          pageSelector
          searchPlaceHolder="Cari nama pengguna"
          wrapperClass="flex flex-row space-x-6"
          onSearchHandler={handleSearch}
          perPageHandler={(selected) => setPerPageLimit(selected)}
        />
      </div>
      <ReactTable
        columns={userColumns}
        data={data?.data ?? []}
        pagination={pagination}
        onPaginationChange={setPagination}
        pageCount={data?.meta?.last_page ?? 1}
      />
    </EmployeeDashboardWrapper>
  )
}

export default UserManagement
