import { SubmitHandler, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import TextInput from "components/ui/form/TextInput"
import { useMutationCreateSlide } from "utils/api/cms/mutations/hooks"
import {
  BookmarkIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"
import { useEffect, useState } from "react"
import InputFile from "components/ui/form/InputFile"

interface SliderForm {
  ordering_number: string
  image_desktop: File
  image_mobile: File
  link?: string
}

const SlideForm = () => {
  const navigate = useNavigate()
  const params = useParams()

  const isUpdate = !!params.id
  const [fileDesktop, setFileDesktop] = useState<File | null>(null)
  const [fileMobile, setFileMobile] = useState<File | null>(null)

  const {
    handleSubmit,
    register,
    control,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors, isSubmitting, isLoading },
  } = useForm<SliderForm>({})

  const { mutate: mutateCreateSlide } =
    useMutationCreateSlide({
      onSuccess(data) {
        if (!data.success) return
        toast.success("Slide berhasil ditambahkan")
        setTimeout(() => {
          navigate("/cms/slide")
        }, 1000)
      },
      onError(error) {
        toast.error(error.message)
      },
    })

  const handleChangeFileDesktop = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0]

    if (selectedFile) {
      setFileDesktop(selectedFile)
    }
  }

  const handleChangeFileMobile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0]

    if (selectedFile) {
      setFileMobile(selectedFile)
    }
  }

  const onSubmit: SubmitHandler<SliderForm> = (formData) => {
    const formPayload = new FormData()
    formPayload.append("ordering_number", formData.ordering_number)

    if (fileDesktop) {
      formPayload.append("image_desktop", fileDesktop)
    }

    if (fileMobile) {
      formPayload.append("image_mobile", fileMobile)
    }

    if (formData.link !== undefined) {
      formPayload.append("link", formData.link);
    }

    if (!isUpdate) {
      return mutateCreateSlide(formPayload)
    }
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-4 pb-4 lg:min-w-[42rem]"
    >
      <TextInput
        type="text"
        className="cms-base-input"
        labelClass="cms-base-label"
        isRequired
        label="Nomor Urut"
        errorMessage={errors.ordering_number?.message}
        errorIcon={
          <ExclamationTriangleIcon
            height={24}
            width={24}
            className="text-red-800"
          />
        }
        {...register("ordering_number", {
          required: {
            value: true,
            message: "Nomor wajib diisi",
          },
        })}
      />
      <TextInput
        type="text"
        className="cms-base-input"
        labelClass="cms-base-label"
        label="Link"
        errorMessage={errors.link?.message}
        errorIcon={
          <ExclamationTriangleIcon
            height={24}
            width={24}
            className="text-red-800"
          />
        }
        {...register("link", {
          required: {
            value: false,
            message: "",
          },
        })}
      />
      <InputFile
        label="Gambar Desktop"
        className="cms-base-input"
        isRequired
        labelClass="cms-base-label"
        accept=".pdf,.png,.jpg"
        onChange={handleChangeFileDesktop}
        errorMessage={errors.image_desktop?.message}
        register={register("image_desktop", {
          required: {
            value: true,
            message: "Gambar Desktop wajib diisi",
          },
        })}
      />
      <InputFile
        label="Gambar Mobile"
        className="cms-base-input"
        isRequired
        labelClass="cms-base-label"
        accept=".pdf,.png,.jpg"
        onChange={handleChangeFileMobile}
        errorMessage={errors.image_mobile?.message}
        register={register("image_mobile", {
          required: {
            value: true,
            message: "Gambar Mobile wajib diisi",
          },
        })}
      />
      <ButtonWithIcon
        action={undefined}
        type="submit"
        class="self-start mt-12 border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
        disabled={isSubmitting}
        isLoading={isLoading}
      >
        <BookmarkIcon className="h-6 w-6 text-white" />
        <span>
          {!isSubmitting ? (
            <>{isUpdate ? "Simpan" : "Tambahkan"}</>
          ) : (
            "Menyimpan..."
          )}
        </span>
      </ButtonWithIcon>
    </form>
  )
}

export default SlideForm
