import CareerForm from "components/cms/CareerForm"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import Typography from "components/ui/typography"
import { getCMSSegments } from "utils/helper"

const CareerManagementEdit = () => {
  const segments = getCMSSegments()

  return (
    <EmployeeDashboardWrapper>
      <DefBreadcrumb
        segments={segments}
        prefix="cms"
      />
      <Typography
        variant="subheading4"
        as="h1"
        className="text-primary"
      >
        Edit Karir
      </Typography>

      <CareerForm />
    </EmployeeDashboardWrapper>
  )
}

export default CareerManagementEdit
