import clsx from "clsx"

import React from "react"

import Section from "components/ui/section/Section"
import Typography from "components/ui/typography"
import Button from "components/ui/button/Button"
import { NavLink } from "react-router-dom"

const VerificationSuccess = () => {
  return (
    <Section space="top-bottom">
      <div
        className={clsx(
          "flex flex-col items-center justify-center",
          "mx-8 md:mx-auto",
          "max-w-5xl",
          "rounded",
          "space-y-4",
          "md:py-16 md:px-[200px] md:shadow"
        )}
      >
        <Typography
          as="h1"
          variant="heading3"
          className="text-secondary mb-2"
        >
          <span className="font-bold text-primary ">Aktivasi berhasil.</span>
        </Typography>
        <Typography
          as="h1"
          variant="text2"
          className="text-tertiary text-center mb-2"
        >
          <span className="font-bold text-center text-tertiary ">
            Silakan login menggunakan akun email yang telah diaktivasi.
          </span>
        </Typography>
        <Button
          as={NavLink}
          variant="contained"
          to="/login"
        >
          Kembali ke Halaman Login
        </Button>
      </div>
    </Section>
  )
}

export default VerificationSuccess
