import { requestCMS } from "utils/axiosInstance"
import { GeneralApiResponse, QueryParams } from "utils/types"
import { CareerUsers } from "../../types"

const getCareerUsers = async (qParams?: QueryParams) => {
  const res = await requestCMS.get<GeneralApiResponse<CareerUsers[]>>(
    "/career-users",
    qParams
  )
  return res
}

export default getCareerUsers
