import { requestUser } from "utils/axiosInstance"
import type { GetPurchasedCoursesResponse } from "../../types"
import { QueryParams } from "utils/types"

const getPurchasedCourses = async (qParams?: QueryParams) => {
  const res = await requestUser.get<GetPurchasedCoursesResponse>(
    "/user/courses",
    qParams
  )
  return res
}

export default getPurchasedCourses
