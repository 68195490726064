import { requestUser } from "utils/axiosInstance"
import { QueryParams } from "utils/types"
import { PublicGetWorkshopsResponse } from "../../types"

const getWorkshops = async (qParams?: QueryParams) => {
  const res = await requestUser.get<PublicGetWorkshopsResponse>(
    "/webinars",
    qParams
  )
  return res
}

export default getWorkshops
