import { Helmet } from "react-helmet"

import Typography from "components/ui/typography"
import { Link, useParams } from "react-router-dom"
import { useGetSertifikasi, useGetSertifikasiMateri, useGetSertifikasiSkkni, useGetSertifikasiSlug } from "utils/api/public/queries/hooks"
import { useMediaQuery } from "usehooks-ts"
import SertifikasiForm from "./SertifikasiForm"
import Section from "components/ui/section/Section"
import images from "assets/images/registered.png"
import Button from "components/ui/button/Button"
import SertifikasiTable from "components/ui/table/TableResponsive"
import { HandleSmoothScroll } from "components/effect/smoothScroll"
const SertifikasiDetail = () => {
    const params = useParams()
    const { slug } = useParams()
    const desktop = useMediaQuery("(min-width: 1024px)")
    const { data: dataDetail } = useGetSertifikasiSlug({
        slug: slug ?? "",
    })
    // const { data: dataSertif } = useGetSertifikasi()
    // const { data: dataSertifs } = useGetSertifikasiSkkni(
    //     {
    //         options: {
    //             staleTime: 0,
    //         },
    //         qParams: {
    //             sertifikat_id: Number("6")
    //         },
    //     }
    // )

    // const { data: dataMateri } = useGetSertifikasiMateri({
    //     options: {
    //         staleTime: 0,
    //     },
    //     qParams: {
    //         sertifikat_id: Number("6")
    //     },
    // })
    return (
        <>
            <Helmet>
                <title>Detail Sertifikasi</title>
            </Helmet>
            <div className="w-full mb-20">
                <Section
                    withBgColor
                    className="bg-primary flex flex-col lg:flex-row lg:gap-10 gap-4 py-8 lg:py-16"
                >
                    <div className="w-full h-auto lg:h-[350px] rounded-xl overflow-hidden">
                        <img className="w-full h-full object-cover" src={dataDetail?.data.thumbnail} alt="" />
                    </div>
                    <div className="flex flex-col gap-6 w-full lg:w-2/3 bg-white p-8 rounded-xl">
                        <Typography
                            variant="subheading4"
                            className="text-primary"
                        >
                            Skema
                        </Typography>
                        <Typography
                            variant="heading6"
                        >
                            {dataDetail?.data.name}
                        </Typography>
                        <Typography
                            variant="text2"
                            className="text-base font-medium text-gray-600"
                        >
                            Tingkatkan Keterampilan Anda dengan Program Pelatihan & Sertifikasi <span>{dataDetail?.data.name}</span> Drivetester: Intensif, Kreatif, dan Memenuhi Standar Kualifikasi BNSP."
                        </Typography>
                        <a onClick={(e) => HandleSmoothScroll(e, "form-redirect-wa")}>
                            <Button className="py-3 rounded-lg">Daftar sekarang</Button>
                        </a>

                    </div>
                </Section>

                <Section
                    withContainer
                    space="top-bottom"
                    className="flex flex-col gap-8"
                >
                    <div className=" flex flex-col gap-2">
                        <Typography
                            variant="heading6"
                            className="mb-2 lg:mb-4  md:font-bold font-semibold"
                        >
                            Deskripsi
                        </Typography>
                        <p
                            className="text-base text-gray-600"
                        >
                            {dataDetail?.data.description}
                        </p>
                    </div>
                    <div className=" flex flex-col gap-2">
                        <Typography
                            variant="heading6"
                            className="mb-2 lg:mb-4  md:font-bold font-semibold"
                        >
                            Daftar Unit Kompetensi
                        </Typography>
                        <div className="">
                            <SertifikasiTable
                                dataTable={dataDetail}
                            />
                        </div>
                    </div>
                    <div className=" flex flex-col gap-2">
                        <Typography
                            variant="heading6"
                            className="mb-2 lg:mb-4  md:font-bold font-semibold"
                        >
                            SKKNI
                        </Typography>
                        <Typography
                            variant="subheading4"
                            className="md:text-md mt-5 font-light text-sm"
                        >
                            <ol className="space-y-2 text-base font-medium text-gray-600 lg:w-1/2"
                            >
                                {dataDetail?.data.skkni?.map((skkni) => (
                                    <li className="p-4 bg-primary/5 rounded-lg" key={skkni.id}>
                                        <a href={skkni.thumbnail} download target="_blank">
                                            {skkni.name}
                                        </a>
                                    </li>
                                ))}
                            </ol>
                        </Typography>
                    </div>
                    <div className="container-none">
                        <SertifikasiForm
                        //  id={dataDetail?.data.id}
                        />
                    </div>
                </Section>
            </div>
        </>
    )
}

export default SertifikasiDetail
