import { requestUser } from "utils/axiosInstance"
import {
  PublicValidateResetPasswordResponse,
  ValidateResetPasswordRequest,
} from "../../types"

const validateResetPassword = async (payload: ValidateResetPasswordRequest) => {
  const res = await requestUser.post<PublicValidateResetPasswordResponse>(
    "/reset-password/validate",
    payload
  )
  return res
}

export default validateResetPassword
