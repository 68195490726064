import React from "react"
import clsxm from "utils/clsxm"
import Typography from "../typography"

interface InputFileProps {
  label: string
  accept: string
  errorMessage?: string
  className?: string
  labelClass?: string
  isRequired?: boolean
  register?: any
  errorIcon?: React.ReactNode
  validationMessage?: React.ReactNode
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const InputFile: React.FC<InputFileProps> = ({
  label,
  accept,
  onChange,
  className,
  isRequired,
  labelClass,
  errorMessage,
  errorIcon,
  validationMessage,
  register,
  ...restProps
}) => {
  return (
    <div className="mb-4">
      {label && (
        <label
          htmlFor={register.name}
          className={clsxm(
            "block mb-1 font-semibold",
            [
              isRequired &&
                "after:content-['*'] after:ml-1 after:text-red-500",
            ],
            labelClass ? labelClass : 'font-medium text-gray-700'
          )}
        >
          {label}
        </label>
      )}
      <input
        type="file"
        className={clsxm(
          "w-full",
          "block p-2.5",
          "bg-gray-100",
          "border border-transparent",
          "rounded-md",
          "focus:ring-primary focus:border-primary",
          [!!errorMessage && "border-2 border-red-600"],
          [validationMessage && "mb-2"],
          className
        )}
        {...register} // Spread register props
        accept={accept}
        onChange={onChange}
        {...restProps}
      />
      {!!errorMessage && (
        <div
          className={clsxm("inline-flex gap-2 items-center", [
            errorIcon && "mt-2",
          ])}
        >
          {errorIcon}
          <Typography
            variant="text3"
            className="text-red-600 first-letter:capitalize mt-1"
          >
            {errorMessage}
          </Typography>
        </div>
      )}
      {validationMessage}
    </div>
  )
}

export default InputFile
