import { requestUser } from "utils/axiosInstance"
import { PublicRegisterUserResponse, RegisterRequest } from "../../types"

const registerUser = async (payload: RegisterRequest) => {
  const res = await requestUser.post<PublicRegisterUserResponse>(
    "/register",
    payload
  )
  return res
}

export default registerUser
