import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import {
    TrashIcon,
} from "@heroicons/react/24/solid"
import { PaginationState, createColumnHelper } from "@tanstack/react-table"
import { Tooltip } from "flowbite-react"
import Modal from "components/ui/modal/Modal"
import ReactTable from "components/ui/react-table/ReactTable"
import Button from "components/ui/button/Button"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import Typography from "components/ui/typography"
import { toast } from "react-toastify"
import { CMSSertikasiSkkniListResponse, SertifikatSkkni } from "utils/types"
import { useGetSertifikasiSkkni } from "utils/api/cms/queries/hooks"
import useMutationDeleteSkkni from "utils/api/cms/mutations/hooks/useMutationDeleteSkkni"

const SertifikatSkkniList = () => {
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 1,
    })
    const [lastPage, setLastPage] = useState(1)
    const params = useParams()
    const [sertifikasiResponse, setSertifikasiResponse] =
        useState<CMSSertikasiSkkniListResponse<SertifikatSkkni>>()
    const [searchFilter, setSearchFilter] = useState("")
    const [perPageLimit, setPerPageLimit] = useState(10)
    const [dataSertifikai, setDataSertifikasi] = useState<SertifikatSkkni[]>([])
    const [modalSertifikasi, setModalSertifikasi] = useState<{
        isOpen: boolean
        sertifikasiID: string
    }>()

    const { data: resultResponse } = useGetSertifikasiSkkni({
        options: {
            staleTime: 0,
        },
        qParams: {
            limit: perPageLimit,
            page: pagination.pageIndex + 1,
            keyword: searchFilter,
            sertifikat_id: Number(params.id)
        },
    })

    useEffect(() => {
        setSertifikasiResponse(resultResponse)
        setDataSertifikasi(resultResponse?.data || [])
        setLastPage(resultResponse?.meta?.last_page || 1)
    }, [resultResponse])

    const handleModalSlide = (sertifikasiID?: string) => {
        setModalSertifikasi((prev) => ({
            isOpen: !prev?.isOpen,
            sertifikasiID: sertifikasiID ?? ""
        }))
    }

    const { mutate: mutateDelete, isLoading } = useMutationDeleteSkkni({
        onSuccess(data, variables) {
            handleModalSlide()
            toast.success("Berhasil hapus data sertifikasi skkni")
            setDataSertifikasi((prev) => prev.filter((d) => d.id !== Number(variables.sertifikasiID)));
        },
    })

    const sertifikasilumnHelper = createColumnHelper<SertifikatSkkni>()

    const sertifikasiColumns = [
        sertifikasilumnHelper.accessor("name", {
            header: "Nama",
            cell: (props) => props.getValue(),
        }),
        sertifikasilumnHelper.display({
            header: "Dokumen",
            cell: (props) => (
                <div className="flex flex-row w-fit space-x-3">
                    {props.row.original.thumbnail ? (
                        <a href={props.row.original.thumbnail} className="text-blue-500" target="_blank" rel="noopener noreferrer">Lihat Dokumen</a>
                    ) : (
                        '-'
                    )}
                </div>
            ),
        }),
        sertifikasilumnHelper.accessor("created_at", {
            header: "Dibuat Pada",
            cell: (props) => props.getValue(),
        }),
        sertifikasilumnHelper.display({
            id: "actions",
            header: "Aksi",
            cell: (props) => (
                <div className="flex flex-row w-fit space-x-3">
                    <Tooltip content="Delete">
                        <ButtonWithIcon
                            class="mx-auto border-2 border-transparent bg-red-600 text-white hover:bg-red-500 hover:border-red-500"
                            type="button"
                            action={(e: React.MouseEvent<HTMLButtonElement>) => {
                                e.preventDefault()
                                handleModalSlide(
                                    props.row.original.id.toString(),
                                )
                            }}
                            sizeClass="p-1"
                        >
                            <TrashIcon className="h-5 w-5" />
                        </ButtonWithIcon>
                    </Tooltip>
                </div>

            ),
        }),
    ]

    return (
        <EmployeeDashboardWrapper>
            <ReactTable
                columns={sertifikasiColumns}
                data={dataSertifikai ?? []}
                pagination={pagination}
                onPaginationChange={setPagination}
                pageCount={lastPage}
            />

            <Modal
                header="Delete"
                onclose={handleModalSlide}
                show={!!modalSertifikasi?.isOpen}
                body={
                    <Typography className="text-white">
                        Apakah Anda yakin untuk menghapus data ini
                    </Typography>
                }
                footer={
                    <div className="space-x-2 ml-auto">
                        <Button
                            onClick={() => handleModalSlide()}
                            variant="outlined"
                            className="text-gray-300 border-gray-300"
                        >
                            Batal
                        </Button>
                        <Button
                            isLoading={isLoading}
                            onClick={() =>
                                mutateDelete({
                                    sertifikasiID: modalSertifikasi?.sertifikasiID ?? "",
                                })
                            }
                        >
                            Hapus
                        </Button>
                    </div>
                }
            />
        </EmployeeDashboardWrapper>
    )
}

export default SertifikatSkkniList
