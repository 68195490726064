import { useMutation } from "@tanstack/react-query"

import { GeneralCareerResponse } from "../../types"
import { publishCareer } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationPublishCareer = (
  options?: GeneralMutationParams<GeneralCareerResponse, { careerID: string }>
) => {
  return useMutation({
    mutationFn: ({ careerID }) => publishCareer(careerID),
    ...options,
  })
}

export default useMutationPublishCareer
