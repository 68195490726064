import { useEffect } from "react"
import { useAnimate, useInView, motion } from "framer-motion"

import Section from "components/ui/section/Section"
import ShowAllLink from "../../components/ShowAllLink"
import CourseCard from "../../components/CourseCard"
import useGetCourses from "utils/api/public/queries/hooks/useGetCourses"
import CardVerticalSkeleton from "components/public/components/CardVerticalSkeleton"
import Tabs from "components/ui/tabs-filter"
import Slider from "components/ui/slider"
import Card from "components/ui/card/Card"
import TabsScroll from "components/ui/tabs-scrollable"
// import cardContent from 'components/ui/tabs/content'
const LatestCourse = () => {
  const [scope, animate] = useAnimate()
  const isInView = useInView(scope, { once: true })

  useEffect(() => {
    if (isInView) {
      animate(scope.current, { opacity: 1, y: 0 })
    }
  }, [isInView])

  const { data: dataCourses, isLoading: isLoadingCourses } = useGetCourses({
    qParams: { limit: 4 },
  })
  // const tabs = [
  //   {
  //     label: 'Semua kelas',
  //     content:  <Card title='This is the content for Tab 1.'/>
  //   },
  //   {
  //     label: 'Bahasa & Komunikasi',
  //     content: <Card title='This is the content for Tab 2.'/>
  //   },
  //   {
  //     label: 'Ilmu Data & Analisis',
  //     content: <Card title='This is the content for Tab 3.'/>
  //   }
  // ];
  return (
    <Section
      space="top-bottom"
      withContainer
      className="mt-12"
    >
      <Section.Title
        className="md:w-2/3"
        position="left"
      >
        <motion.div
          ref={scope}
          initial={{ y: 100, opacity: 0 }}
        >
          <p className="text-tertiary py-1 ">
            Temukan Potensi Anda di Kelas Online Terbaik
          </p>
          <p className="text-gray-600 py-1 font-medium  text-base  lg:w-2/3">
            Apapun profesi atau hobi Anda, kelas online kami siap mengembangkan
            kemampuan Anda.
          </p>
        </motion.div>
      </Section.Title>
      <TabsScroll />
    </Section>
  )
}

export default LatestCourse
