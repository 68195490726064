import { Suspense } from "react"
import clsx from "clsx"
import { CustomFlowbiteTheme, Tabs } from "flowbite-react"
import { Helmet } from "react-helmet"

import Typography from "components/ui/typography"
import Courses from "components/public/profile/my-course/Courses"

const MyCourses = () => {
  return (
    <div className="pt-8 container">
      <Helmet>
        <title>Kelas Saya</title>
      </Helmet>
      <Typography
        as="h1"
        variant="heading6"
        className="mb-8"
      >
        Kelas Saya
      </Typography>

      <Tabs.Group
        aria-label="Profile tabs"
        style="underline"
        theme={theme}
        className="flex-nowrap"
      >
        <Tabs.Item
          active
          title="Semua Kelas"
        >
          <Suspense>
            <Courses />
          </Suspense>
        </Tabs.Item>
      </Tabs.Group>
    </div>
  )
}

const theme: CustomFlowbiteTheme["tab"] = {
  tablist: {
    tabitem: {
      base: clsx(
        "flex items-center justify-center",
        "p-4",
        "text-sm md:text-md font-semibold",
        "first:ml-0",
        "disabled:cursor-not-allowed disabled:text-gray-400 disabled:dark:text-gray-500 focus:outline-none"
      ),
      styles: {
        underline: {
          active: {
            on: "text-primary border-b-2 border-primary active dark:text-primary dark:border-primary",
            off: "border-b-2 border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-600 dark:text-gray-400 dark:hover:text-gray-300",
          },
        },
      },
    },
  },
}

export default MyCourses
