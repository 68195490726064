import { useInfiniteQuery } from "@tanstack/react-query"

import { getCareers } from "../functions"
import { PublicQueryKey, PublicGetInfiniteCareersParams } from "../../types"

const useInfititeGetCareers = (params?: PublicGetInfiniteCareersParams) => {
  return useInfiniteQuery({
    queryKey: [PublicQueryKey.Career, params?.qParams ?? {}],
    queryFn: ({ pageParam }) =>
      getCareers({ ...params?.qParams, page: pageParam }),
    getNextPageParam: (lastPage) => {
      if (!lastPage.meta) return

      const isLastPage = lastPage.meta.current_page === lastPage.meta?.last_page
      if (isLastPage) return

      return lastPage.meta.current_page + 1
    },
    ...params?.options,
  })
}

export default useInfititeGetCareers
