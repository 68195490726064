import SertifikatPesertaList from "components/cms/Sertifikat/SertifikatPesertaList"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import Typography from "components/ui/typography"
import { useParams } from "react-router-dom"
import { useGetSertifikatDetail } from "utils/api/cms/queries/hooks"
import { getCMSSegments } from "utils/helper"

const SertifikatPeserta = () => {
    const segments = getCMSSegments()
    const params = useParams()
    const { data: dataDetail } = useGetSertifikatDetail({
        id: params.id ?? "",
    })
    return (
        <EmployeeDashboardWrapper>
            <DefBreadcrumb
                segments={segments}
                prefix="cms"
            />
            <Typography
                variant="subheading4"
                as="h1"
                className="text-primary"
            >
                Peserta Sertifikasi {dataDetail?.data.name}
            </Typography>
            <SertifikatPesertaList />
        </EmployeeDashboardWrapper>
    )
}

export default SertifikatPeserta
