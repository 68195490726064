import React, { useState, useEffect } from "react"

const CMSDefaultTableListFilter: React.FC<{
  searchPlaceHolder: string
  wrapperClass: string
  pageSelector: boolean
  onSearchHandler: (keyword: string) => void
  perPageHandler?: (selected: number) => void
}> = (props) => {
  const [keyword, setKeyword] = useState("")
  const handleSearch = (searchKeyword: string) => {
    setKeyword(searchKeyword)
    if (searchKeyword === "") {
      props.onSearchHandler("")
    }
  }

  const searchHandler: React.MouseEventHandler<HTMLButtonElement> = (event) => {
    event.preventDefault()

    props.onSearchHandler(keyword)
  }

  return (
    <div className={props.wrapperClass}>
      <div className="relative min-w-[20rem]">
        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
          <svg
            aria-hidden="true"
            className="w-5 h-5 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
            ></path>
          </svg>
        </div>
        <input
          type="search"
          id="default-search"
          className="block w-full p-4 pl-10 text-sm border rounded-lg bg-gray-700 border-gray-600 placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
          placeholder={props.searchPlaceHolder}
          onChange={(event) => {
            handleSearch(event.target.value) // Memanggil handleSearch saat terjadi perubahan pada input
          }}
        />
        <button
          onClick={searchHandler}
          type="button"
          className="text-white absolute right-2.5 bottom-2.5 focus:ring-2 hover:ring-2 focus:outline-none font-medium rounded-lg text-sm px-4 py-2 bg-primary hover:ring-primary focus:ring-primary"
        >
          Cari
        </button>
      </div>
      {props.pageSelector && (
        <div>
          <select
            onChange={(event) => {
              props.pageSelector && props.perPageHandler!(+event.target.value)
            }}
            id="underline_select"
            className="block py-2.5 px-0 w-full text-sm bg-transparent border-0 border-b-2 appearance-none text-gray-400 border-gray-700 focus:outline-none focus:ring-0 focus:border-gray-200 peer"
            defaultValue=""
          >
            <option value={0}>Jumlah Perhalaman (default: 10)</option>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={30}>30</option>
            <option value={50}>50</option>
          </select>
        </div>
      )}
    </div>
  )
}

export default CMSDefaultTableListFilter
