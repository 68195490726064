import { useQuery } from "@tanstack/react-query"

import { getWebinars } from "../functions"
import { CmsQueryKey, GetWebinarsParams } from "../../types"

const useGetWebinars = (params?: GetWebinarsParams) => {
  return useQuery({
    queryKey: [CmsQueryKey.Webinars, params?.qParams ?? ""],
    queryFn: () => getWebinars(params?.qParams),
    ...params?.options,
  })
}

export default useGetWebinars
