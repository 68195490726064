import { useEffect, useState } from "react"

const usePagination = (loader?: () => Promise<void>, loaderDeps?: any[]) => {
  const [totalPages, setTotalPages] = useState<number>(0)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [initialPage, setInitialPage] = useState<number>(0)
  const [isFetching, setFetching] = useState<boolean>(false)
  const [shouldFetch, setShouldFetch] = useState(false)
  const [forced, setForced] = useState(false)

  useEffect(
    () => {
      if (!shouldFetch) return
      setFetching(true)

      loader && loader()

      setFetching(false)
    },
    loaderDeps ? [...loaderDeps, currentPage] : [currentPage]
  )

  return {
    totalPages,
    currentPage,
    initialPage,
    isFetching,
    forced,
    setTotalPages,
    setCurrentPage,
    setInitialPage,
    setShouldFetch,
    setForced,
  }
}

export default usePagination
