import { useEffect, useState } from "react"
import { Link, useSearchParams, Navigate } from "react-router-dom"
import moment from "moment"
import { ArrowUturnLeftIcon, CheckCircleIcon } from "@heroicons/react/24/solid"
import { Helmet } from "react-helmet"

import Button from "components/ui/button/Button"
import PaymentHeader from "components/payment/PaymentHeader"
import PaymentContent from "components/payment/PaymentContent"
import Typography from "components/ui/typography"
import { useGetTransactionById } from "utils/api/payment/queries/hooks"
import PageProgressbar from "components/ui/loading/PageProgressbar"

const PaymentSuccess = () => {
  const [searchParams] = useSearchParams()
  const [loadComponent, setLoadComponent] = useState(false)

  const formatter = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency: "IDR",
  })

  const { data, isError, isLoading } = useGetTransactionById({
    id: searchParams.get("order_id") ?? "",
  })

  useEffect(() => {
    if (isLoading) return
    setTimeout(() => {
      setLoadComponent(true)
    }, 400)
  }, [isLoading])

  const dataTransaction = data?.data

  if (!loadComponent) return <PageProgressbar finished={!isLoading} />

  if (
    !searchParams.get("order_id") ||
    searchParams.get("status_code") !== "200" ||
    isError
  ) {
    return <Navigate to="/" />
  }

  if (dataTransaction?.status !== "SUCCESS") {
    return <Navigate to="/transaction-history" />
  }

  return (
    <>
      <Helmet>
        <title>Pembayaran Berhasil</title>
      </Helmet>
      <div className="flex justify-center items-center bg-slate-100 w-screen h-screen">
        <section className="bg-white w-[80%] lg:w-1/2 rounded-xl p-8 shadow">
          <PaymentHeader
            status="success"
            icon={
              <CheckCircleIcon
                width={50}
                height={50}
                className="relative text-green-500 z-10"
              />
            }
          >
            <Typography
              as="h1"
              variant="heading6"
              className="text-green-500"
            >
              Pembayaran Berhasil!
            </Typography>
            <Typography
              variant="text3"
              className="text-typo-gray"
            >
              Order ID: {dataTransaction?.trx_id}
            </Typography>
          </PaymentHeader>
          <div className="my-6 relative">
            <div className="absolute -left-12 -top-3.5 bg-slate-100 w-8 h-8 rounded-full" />
            <hr className="border-t-2 border-dashed" />
            <div className="absolute -right-12 -top-3.5 bg-slate-100 w-8 h-8 rounded-full" />
          </div>
          <PaymentContent
            totalPayment={formatter.format(Number(dataTransaction?.amount))}
            contents={[
              { key: "Email", value: dataTransaction?.buyer.email ?? "" },
              {
                key:
                  dataTransaction?.transaction_detail.transactionable_type ===
                  "COURSE"
                    ? "Kelas"
                    : "Webinar",
                value:
                  dataTransaction?.transaction_detail.transactionable.title ??
                  "",
              },
              ...(dataTransaction?.paid_at
                ? [
                    {
                      key: "Tanggal",
                      value: moment(dataTransaction?.paid_at).format(
                        "DD MMMM YYYY"
                      ),
                    },
                    {
                      key: "Jam",
                      value: moment(dataTransaction?.paid_at).format(
                        "HH:mm:ss"
                      ),
                    },
                  ]
                : []),
            ]}
          />
          <div className="flex justify-center gap-2 mt-6 no-print">
            <Button
              as={Link}
              to="/transaction-history"
              variant="outlined"
              color="tertiary"
              className="inline-flex gap-2"
            >
              <ArrowUturnLeftIcon
                width={24}
                height={24}
              />
              Kembali
            </Button>
          </div>
        </section>
      </div>
    </>
  )
}

export default PaymentSuccess
