import clsxm from "utils/clsxm"
import Typography from "../typography"

type Position = "left" | "center" | "right"

interface SectionTitleProps {
  position?: Position
  className?: string
  children: React.ReactNode
}

const SectionTitle = ({ position, className, children }: SectionTitleProps) => {
  return (
    <Typography
      variant="heading5"
      as="h2"
      className={clsxm(
        "text-secondary mb-10",
        [
          position === "left" && "text-left",
          position === "center" && "text-center",
          position === "right" && "text-right",
        ],
        className
      )}
    >
      {children}
    </Typography>
  )
}

export default SectionTitle
