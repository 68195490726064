import { useQuery } from "@tanstack/react-query"

import { getCourseDetail } from "../functions"
import { PublicQueryKey, PublicGetCourseDetailParams } from "../../types"

const useGetCourseDetail = (params?: PublicGetCourseDetailParams) => {
  return useQuery({
    queryKey: [PublicQueryKey.Courses, params?.slug ?? ""],
    queryFn: () => getCourseDetail(params?.slug ?? ""),
    ...params?.options,
  })
}

export default useGetCourseDetail
