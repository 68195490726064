import { useQuery } from "@tanstack/react-query"
import { getPartnership } from "../functions"
import { CmsQueryKey, GetPartnershipParams } from "../../types"

const useGetPartnership = (params?: GetPartnershipParams) => {
  return useQuery({
    queryKey: [CmsQueryKey.Partnership, params?.qParams ?? ""],
    queryFn: () => getPartnership(params?.qParams),
    ...params?.options,
  })
}

export default useGetPartnership
