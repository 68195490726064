import { requestCMS } from "utils/axiosInstance"
import { DashboardResponse } from "utils/types"

const getDashboard = async (startDate: string, endDate: string) => {
  const res = await requestCMS.get<DashboardResponse>(
    `/employee/dashboard?tgl_awal=${startDate}&tgl_akhir=${endDate}`
  )
  return res
}

export default getDashboard
