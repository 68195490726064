import { Helmet } from "react-helmet"

import ListWorkshop from "components/public/workshop/list-workshop"
import Section from "components/ui/section/Section"
import Typography from "components/ui/typography"
import clsxm from "utils/clsxm"

const Workshops = () => {
  return (
    <>
      <Helmet>
        <title>Workshops</title>
      </Helmet>
      <div className="container">
        <div className={clsxm("mt-28 text-center lg:text-left lg:w-1/2")}>
          <Typography
            variant="heading3"
            as="h1"
            className="text-secondary mb-4"
          >
            Ikuti Program Workshop dari BeePlus Institute{" "}
            <span className="text-primary"> Sekarang!</span>
          </Typography>
          <Typography
            variant="text2"
            className="font-medium"
          >
            Kelas interaktif untuk mengembangkan skill secara online bersama
            mentor berpengalaman.
          </Typography>
        </div>

        <Section
          space="top-bottom"
          className="mb-56"
        >
          <Section.Title className="text-center lg:text-left">
            Jadwal <span className="text-primary">Workshop</span>
          </Section.Title>
          <ListWorkshop />
        </Section>
      </div>
    </>
  )
}

export default Workshops
