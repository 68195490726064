import { useMutation } from "@tanstack/react-query"

import { GeneralSlideResponse } from "../../types"
import { GeneralMutationParams } from "utils/types"
import deleteSlide from "../functions/deleteSlide"

const useMutationDeleteSlide = (
  options?: GeneralMutationParams<GeneralSlideResponse, { slideID: string }>
) => {
  return useMutation({
    mutationFn: ({ slideID }) => deleteSlide(slideID),
    ...options,
  })
}

export default useMutationDeleteSlide
