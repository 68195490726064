import { useQuery } from "@tanstack/react-query"
import { getSlideImagePreview } from "../functions"
import { PublicQueryKey, PublicGetSlidePreviewImageParams } from "../../types"

const useGetSlideImagePreview = (
    params?: PublicGetSlidePreviewImageParams
  ) => {
    return useQuery({
      queryKey: [PublicQueryKey.Slides],
      queryFn: getSlideImagePreview,
      ...params?.options,
    })
  }

export default useGetSlideImagePreview