import { requestCMS } from "utils/axiosInstance"

import type {
  GeneralCareerCategoryRequest,
  GeneralCareerCategoryResponse,
} from "../../types"

const createCareerCategory = async (payload: GeneralCareerCategoryRequest) => {
  const res = await requestCMS.post<GeneralCareerCategoryResponse>(
    "/employee/career-categories/create",
    payload
  )
  return res
}

export default createCareerCategory
