import { useQuery } from "@tanstack/react-query"

import { getWebinarsDiscount } from "../functions"
import { CmsQueryKey, GetDiscountWebinarParams } from "../../types"

const useGetWebinarsDiscount = (params?: GetDiscountWebinarParams) => {
  return useQuery({
    queryKey: [CmsQueryKey.DiscountWebinar, params?.qParams ?? ""],
    queryFn: () => getWebinarsDiscount(params?.qParams),
    ...params?.options,
  })
}

export default useGetWebinarsDiscount
