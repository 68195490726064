import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import DefBreadcrumb from "components/ui/breadcrumb/DefBreadcrumb"
import React from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useGetUserDetail, useMutationNewPassword } from "utils/api/partnership/hooks"
import TextInput from "components/ui/form/TextInput"
import { BookmarkIcon, ExclamationTriangleIcon } from "@heroicons/react/24/solid"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import { GeneralNewPassword } from "utils/api/partnership/type"
import { toast } from "react-toastify"
import { useNavigate } from "react-router-dom"

interface PasswordForm {
    id: string
    password: string
}

const ResetSandi: React.FC = () => {
    const segments = ['dashboard', 'ganti-sandi']
    const { data: user } = useGetUserDetail();
    const navigate = useNavigate()

    const {
        handleSubmit,
        register,
        control,
        watch,
        getValues,
        setValue,
        reset,
        formState: { errors, isSubmitting },
    } = useForm<PasswordForm>({})

    const { mutate: mutateNewPassword } =
        useMutationNewPassword({
            onSuccess(data) {
                toast.success("Berhasil mengganti sandi")
                setTimeout(() => {
                    navigate("/partnership/dashboard")
                }, 1000)
            },
            onError(error) {
                toast.error(error.message)
            },
        })

    const onSubmit: SubmitHandler<PasswordForm> = (data) => {
        const payload: GeneralNewPassword = {
            password: data.password,
        }

        mutateNewPassword({
            id: String(user?.data.user.id),
            payload: payload,
        })
    }

    return (
        <EmployeeDashboardWrapper>
            <DefBreadcrumb
                segments={segments}
                prefix="partnership"
            />

            <form
                onSubmit={handleSubmit(onSubmit)}
                className="flex flex-col gap-4 pb-4 min-w-[42rem]"
            >
                <TextInput
                    type="password"
                    className="cms-base-input"
                    labelClass="cms-base-label mt-10"
                    isRequired
                    label="Ganti Sandi"
                    errorMessage={errors.password?.message}
                    errorIcon={
                        <ExclamationTriangleIcon
                            height={24}
                            width={24}
                            className="text-red-800"
                        />
                    }
                    {...register("password", {
                        required: {
                            value: true,
                            message: "Ganti sandi wajib diisi",
                        },
                    })}
                />
                <ButtonWithIcon
                    action={undefined}
                    type="submit"
                    class="self-start mt-5 border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
                    disabled={isSubmitting}
                >
                    <BookmarkIcon className="h-6 w-6 text-white" />
                    <span>
                        {!isSubmitting ? (
                            <>{"Simpan"}</>
                        ) : (
                            "Menyimpan..."
                        )}
                    </span>
                </ButtonWithIcon>
            </form>
        </EmployeeDashboardWrapper>
    )
}

export default ResetSandi
