import { useMutation } from "@tanstack/react-query"

import {
  UploadThumbnailResponse,
  UploadThumbnailWebinarRequest,
} from "../../types"
import { uploadThumbnailWebinar } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationUploadThumbnailWebinar = (
  options?: GeneralMutationParams<
    UploadThumbnailResponse,
    UploadThumbnailWebinarRequest
  >
) => {
  return useMutation({
    mutationFn: ({ webinarID, payload }) =>
      uploadThumbnailWebinar(webinarID, payload),
    ...options,
  })
}

export default useMutationUploadThumbnailWebinar
