const Badge = ({ children, type }) => {
    const badgeColor = {
        success: 'bg-green-100 text-green-800',
        danger: 'bg-red-100 text-red-800',
        warning: 'bg-yellow-100 text-yellow-800',
        info: 'bg-blue-100 text-blue-800',
        neutral: 'bg-gray-100 text-gray-800',
    };

    return (
        <span className={`text-xs font-semibold mr-2 px-2.5 py-0.5 rounded ${badgeColor[type]}`}>
            {children}
        </span>
    );
};

export default Badge;
