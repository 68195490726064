import { requestCMS } from "utils/axiosInstance"
import type { DetailDiscountResponse } from "../../types"

const getDetailDiscount = async (id?: string) => {
  const res = await requestCMS.get<DetailDiscountResponse>(
    `/employee/discount/detail/${id}`
  )
  return res
}

export default getDetailDiscount
