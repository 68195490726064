import { requestUser } from "utils/axiosInstance"
import { PublicGetCareerDetailResponse } from "../../types"

const getCareersDetail = async (slug: string) => {
  const res = await requestUser.get<PublicGetCareerDetailResponse>(
    `/careers/${slug}`
  )
  return res
}

export default getCareersDetail
