import { requestCMS } from "utils/axiosInstance"

import type { CreateVideoRequest, CreateVideoResponse } from "../../types"

const updateVideo = async (videoID: string, payload: CreateVideoRequest) => {
  const res = await requestCMS.put<CreateVideoResponse>(
    `/employee/course/section/video/update/${videoID}`,
    payload
  )
  return res
}

export default updateVideo
