import { Helmet } from "react-helmet"

/** Components */
import Section from "components/ui/section/Section"
import Typography from "components/ui/typography"
import ListCourse from "components/public/course/list-course"
import CourseCard from "components/public/components/CourseCard"

/** Utils */
import { useGetCourses } from "utils/api/public/queries/hooks"
import clsxm from "utils/clsxm"
import CardVerticalSkeleton from "components/public/components/CardVerticalSkeleton"

const Courses = () => {
  const { data: dataLatestCourses, isLoading: isLoadingLatestCourses } =
    useGetCourses({ qParams: { limit: 4 } })

  return (
    <>
      <Helmet>
        <title>Kelas</title>
      </Helmet>
      <div className="container">
        <div className={clsxm("mt-28 text-center lg:text-left lg:w-1/2")}>
          <Typography
            variant="heading3"
            as="h1"
            className="text-secondary mb-4"
          >
            Explorasi Tanpa Batas:{" "}
            <span className="text-primary">
              Temukan Dirimu dalam Salah Satu dari Berbagai Kategori Kursus
              Kami”
            </span>
          </Typography>
          <Typography
            variant="text2"
            className="font-medium"
          >
            Pelajari keterampilan baru, dan jelajahi bidang yang menarik dalam
            mengembangkan kemampuan dan wawasanmu. Pilihlah kategori kursus yang
            akan membantumu dalam meraih tujuan!
          </Typography>
        </div>
        <Section space="top-bottom">
          <Section.Title className={clsxm("text-center lg:text-left")}>
            Bergabunglah dengan kelas <span className="text-primary">Kami</span>
          </Section.Title>
          <div
            className={clsxm(
              "flex flex-wrap justify-center gap-3 lg:flex-nowrap lg:justify-start"
            )}
          >
            {isLoadingLatestCourses
              ? [...Array(4)].map((_, index) => (
                  <CardVerticalSkeleton key={index} />
                ))
              : dataLatestCourses?.data.map((course) => (
                  <CourseCard
                    key={course.id}
                    to={`/courses/${course.slug}`}
                    imgSrc={course.thumbnail}
                    title={course.title}
                    currency={course.price.currency_text}
                    masterPrice={course.price.master_price_text}
                    currentPrice={course.price.current_price_text}
                    lecturer={course.lecturer}
                    totalVideo={(course.videos_count ?? 0) + " Video"}
                  />
                ))}
          </div>
        </Section>

        <Section
          space="top-bottom"
          className="mb-56"
        >
          <Section.Title className="text-center lg:text-left">
            Temukan <span className="text-primary">Kelas</span>
          </Section.Title>
          <ListCourse />
        </Section>
      </div>
    </>
  )
}

export default Courses
