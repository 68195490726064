import { useMutation } from "@tanstack/react-query"

import { GeneralSertifikatProgramResponse, GeneralSertifikatProgramRequest } from "../../types"
import { GeneralMutationParams } from "utils/types"
import { createSertifikatProgram } from "../functions"

const useMutationCreateSertifikatProgram = (
    options?: GeneralMutationParams<GeneralSertifikatProgramResponse, GeneralSertifikatProgramRequest>
) => {
    return useMutation({
        mutationFn: (payload) => createSertifikatProgram(payload),
        ...options,
    })
}

export default useMutationCreateSertifikatProgram
