import { useQuery } from "@tanstack/react-query"

import { getPurchasedCourses } from "../functions"
import { GetPurchasedCoursesParams, UserQueryKey } from "../../types"

const useGetPurchasedCourses = (params?: GetPurchasedCoursesParams) => {
  return useQuery({
    queryKey: [UserQueryKey.PurchasedCourses, params?.qParams ?? {}],
    queryFn: () => getPurchasedCourses(params?.qParams),
    ...params?.options,
  })
}

export default useGetPurchasedCourses
