import { Link, Navigate, useNavigate } from "react-router-dom"
import clsx from "clsx"
import { SubmitHandler, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import Cookies from "js-cookie"
import jwt_decode, { JwtPayload } from "jwt-decode"
import { Helmet } from "react-helmet"
import TextInput from "components/ui/form/TextInput"
import Section from "components/ui/section/Section"
import Typography from "components/ui/typography"
import Button from "components/ui/button/Button"
import type { AuthenticationRequest } from "utils/api/user/types"
import { useMutationAuthenticateUser } from "utils/api/user/mutations/hooks"
import { useUserInfoStore } from "utils/store/user-info"

const Login = () => {
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm<AuthenticationRequest>()
  const { isLoggedIn, setUserInfo } = useUserInfoStore((state) => ({
    isLoggedIn: state.isLoggedIn,
    setUserInfo: state.setUserInfo,
  }))

  const { mutate, isLoading } = useMutationAuthenticateUser({
    onSuccess(data) {
      if (!data.success) {
        toast.error("Login tidak berhasil")
        return
      }

      const dataUser = data.data.user
      setUserInfo({
        isLoggedIn: true,
        userInfo: {
          id: dataUser.id,
          email: dataUser.email,
          first_name: dataUser.first_name,
          middle_name: dataUser.middle_name,
          last_name: dataUser.last_name,
          phone: dataUser.phone
        },
      })

      const token = data.data.token
      const decodedToken = jwt_decode<JwtPayload>(token)
      const tokenExpiration = new Date((decodedToken.exp ?? 0) * 1000)

      Cookies.set("user_auth_token", token, { expires: tokenExpiration })

      toast.success("Login berhasil")
      navigate("/")
    },
    onError(error) {
      if (error.reason === "UNVERIFIED") {
        toast.error(
          "Akun email Anda belum terverifikasi. Cek email Anda dan lakukan verifikasi email !"
        )
        const email = getValues("email")
        localStorage.setItem("email_registration", email)
        navigate("/resend-verify-email")
      }
      toast.error(error.message)
    },
  })

  const onSubmit: SubmitHandler<AuthenticationRequest> = (data) => {
    mutate(data)
  }

  if (isLoggedIn) {
    return <Navigate to="/" />
  }

  return (
    <>
      <Helmet>
        <title>Login</title>
      </Helmet>
      <Section space="top-bottom">
        <div
          className={clsx(
            "flex flex-col items-center justify-center",
            "mx-8 md:mx-auto",
            "max-w-5xl",
            "rounded",
            "space-y-4",
            "md:py-16 md:px-[200px] md:shadow"
          )}
        >
          <Typography
            as="h1"
            variant="heading3"
            className="text-secondary mb-10"
          >
            Log<span className="text-primary">in</span>
          </Typography>
          <form
            className="w-full space-y-5"
            onSubmit={handleSubmit(onSubmit)}
          >
            <TextInput
              type="email"
              isRequired
              label="Email"
              errorMessage={errors.email?.message}
              {...register("email", {
                required: {
                  value: true,
                  message: "Email wajib diisi",
                },
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message:
                    'Pastikan email Anda memiliki format "nama@example.com"',
                },
              })}
            />
            <TextInput
              type="password"
              isRequired
              label="Password"
              errorMessage={errors.password?.message}
              {...register("password", {
                required: {
                  value: true,
                  message: "Password wajib diisi",
                },
              })}
            />
            <Link
              className="text-secondary text-sm hover:underline"
              to="/forgot-password"
            >
              Lupa Password?
            </Link>
            <Button
              className="!mt-8"
              type="submit"
              size="lg"
              fullWidth
              isLoading={isLoading}
            >
              Login
            </Button>
            <hr className="h-1 mx-auto my-4 bg-gray-200 border-0 rounded" />
            <div className="flex justify-center gap-1">
              <Typography
                variant="text3"
                className="text-gray-500 tracking-wider"
              >
                Belum punya akun?
              </Typography>
              <Link
                to="/register"
                className="text-sm font-semibold tracking-wider text-secondary hover:underline"
              >
                Daftar
              </Link>
            </div>
          </form>
        </div>
      </Section>
    </>
  )
}

export default Login
