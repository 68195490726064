import { UserIcon } from "@heroicons/react/20/solid"
import { Tooltip } from "flowbite-react"

import Typography from "components/ui/typography"
import { ProductCategory } from "utils/types"

interface TitleProps {
  title: string
  categories: ProductCategory[]
  date: string
  quota: number
}

const Title = ({ title, categories, date, quota }: TitleProps) => {
  return (
    <div>
      <div className="flex gap-2">
        {categories.map((category) => (
          <Typography
            key={category.id}
            variant="text3"
            className="font-medium"
          >
            #{category.name}
          </Typography>
        ))}
      </div>
      <Typography
        variant="heading3"
        as="h1"
        className="mb-1"
      >
        {title}
      </Typography>
      <Typography
        variant="text3"
        className="flex font-medium"
      >
        {date} |{" "}
        <span className="flex items-center text-primary">
          <Tooltip content="Kuota">
            <UserIcon
              width={20}
              height={20}
              aria-label="Kuota"
            />
          </Tooltip>
          {quota}
        </span>
      </Typography>
    </div>
  )
}

export default Title
