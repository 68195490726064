import { useMutation } from "@tanstack/react-query"

import {
  EmployeeAuthenticationReq,
  EmployeeAuthenticationResponse,
  GeneralMutationParams,
} from "utils/types"
import { login } from "../functions"

const useMutationLogin = (
  options?: GeneralMutationParams<
    EmployeeAuthenticationResponse,
    EmployeeAuthenticationReq
  >
) => {
  return useMutation({
    mutationFn: (payload) => login(payload),
    ...options,
  })
}

export default useMutationLogin
