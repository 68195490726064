import clsx from "clsx"

interface StatusProps {
  isActive: boolean
}

const Status = ({ isActive }: StatusProps) => {
  return (
    <span
      className={clsx("text-xs font-medium mr-2 px-2.5 py-0.5 rounded", [
        isActive
          ? "bg-green-900 text-green-300"
          : "bg-yellow-900 text-yellow-300",
      ])}
    >
      {isActive ? "Aktif" : "Pending"}
    </span>
  )
}

export default Status
