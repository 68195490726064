import { useMutation } from "@tanstack/react-query"

import { GeneralCareerResponse, GeneralCareerRequest } from "../../types"
import { createCareer } from "../functions"
import { GeneralMutationParams } from "utils/types"

const useMutationCreateCareer = (
  options?: GeneralMutationParams<GeneralCareerResponse, GeneralCareerRequest>
) => {
  return useMutation({
    mutationFn: (payload) => createCareer(payload),
    ...options,
  })
}

export default useMutationCreateCareer
