import { useState, useEffect } from "react"
import { PaginationState, createColumnHelper } from "@tanstack/react-table"
import ReactTable from "components/ui/react-table/ReactTable"
import { useGetPesertaWebinar } from "utils/api/cms/queries/hooks"
import type { WebinarPeserta } from "utils/api/cms/types"
import EmployeeDashboardWrapper from "components/layouts/EmployeeDashboardWrapper"
import { CMSWebinarPesertaListResponse } from "utils/types"
import CMSDefaultTableListFilter from "../CMSDefaultTableListFilter"
import { useParams } from "react-router-dom"

const PesertaList = () => {
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 1,
    })
    const params = useParams()
    const [lastPage, setLastPage] = useState(1)
    const [webinarResponse, setWebinarResponse] =
        useState<CMSWebinarPesertaListResponse<WebinarPeserta>>()
    const [searchFilter, setSearchFilter] = useState("")
    const [perPageLimit, setPerPageLimit] = useState(10)
    const [dataWebinar, setDataWebinar] = useState<WebinarPeserta[]>([])

    const { data: resultResponse } = useGetPesertaWebinar({
        options: {
            staleTime: 0,
        },
        qParams: {
            limit: perPageLimit,
            page: pagination.pageIndex + 1,
            keyword: searchFilter,
            webinar_id: Number(params.id)
        },
    })

    useEffect(() => {
        setWebinarResponse(resultResponse)
        setDataWebinar(resultResponse?.data || [])
        setLastPage(resultResponse?.meta?.last_page || 1)
    }, [resultResponse])


    const webinarColumnHelper = createColumnHelper<WebinarPeserta>()

    const webinarColumns = [
        webinarColumnHelper.accessor("no_invoice", {
            header: "No Transaksi",
            cell: (props) => props.getValue(),
        }),
        webinarColumnHelper.accessor("webinars.title", {
            header: "Nama Webinar",
            cell: (props) => props.getValue(),
        }),
        webinarColumnHelper.accessor("transaction.user.email", {
            header: "Peserta",
            cell: (props) => props.getValue(),
        }),
    ]

    const handleSearch = (keyword: string) => {
        setSearchFilter(keyword)
        setPagination((prevPagination) => ({ ...prevPagination, pageIndex: 0 }))
    }

    return (
        <EmployeeDashboardWrapper>
            <CMSDefaultTableListFilter
                pageSelector
                searchPlaceHolder="Cari peserta"
                wrapperClass="ml-auto flex flex-row space-x-6 mb-4"
                onSearchHandler={handleSearch}
                perPageHandler={(selected) => setPerPageLimit(selected)}
            />
            <ReactTable
                columns={webinarColumns}
                data={dataWebinar ?? []}
                pagination={pagination}
                onPaginationChange={setPagination}
                pageCount={lastPage}
            />
        </EmployeeDashboardWrapper>
    )
}

export default PesertaList
