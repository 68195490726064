import { useQuery } from "@tanstack/react-query"
import { dashboard } from "../functions"
import { PartnershipQueryKey } from "../type"

const useGetDashboard = (startDate: string, endDate: string) => {
    return useQuery({
        queryKey: [PartnershipQueryKey.Dashboard, startDate, endDate],
        queryFn: () => dashboard(startDate, endDate),
    })
}

export default useGetDashboard
