import React, { useState } from "react"
import {
  format,
  startOfWeek,
  endOfWeek,
  startOfMonth,
  endOfMonth,
  subWeeks,
  subMonths,
} from "date-fns"

type FilterTanggalProps = {
  onDateChange: (startDate: string, endDate: string) => void
}

const DateRange: React.FC<FilterTanggalProps> = ({ onDateChange }) => {
  const [startDate, setStartDate] = useState<Date>(new Date())
  const [endDate, setEndDate] = useState<Date>(new Date())

  const formatDate = (date: Date) => format(date, "yyyy-MM-dd")

  const handleToday = () => {
    const today = new Date()
    setStartDate(today)
    setEndDate(today)
    onDateChange(formatDate(today), formatDate(today))
  }

  const handleLastWeek = () => {
    const startLastWeek = startOfWeek(subWeeks(new Date(), 1))
    const endLastWeek = endOfWeek(subWeeks(new Date(), 1))
    setStartDate(startLastWeek)
    setEndDate(endLastWeek)
    onDateChange(formatDate(startLastWeek), formatDate(endLastWeek))
  }

  const handleLastMonth = () => {
    const startLastMonth = startOfMonth(subMonths(new Date(), 1))
    const endLastMonth = endOfMonth(subMonths(new Date(), 1))
    setStartDate(startLastMonth)
    setEndDate(endLastMonth)
    onDateChange(formatDate(startLastMonth), formatDate(endLastMonth))
  }

  const handleCustomRange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: "start" | "end"
  ) => {
    const date = new Date(e.target.value)
    if (type === "start") {
      setStartDate(date)
      onDateChange(formatDate(date), formatDate(endDate))
    } else {
      setEndDate(date)
      onDateChange(formatDate(startDate), formatDate(date))
    }
  }

  return (
    <div className="flex flex-col gap-4 p-4 border border-gray-200 rounded-lg shadow">
      <div className="flex gap-2">
        <button
          className="px-4 py-2 text-white bg-blue-500 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
          onClick={handleToday}
        >
          Hari Ini
        </button>
        <button
          className="px-4 py-2 text-white bg-green-500 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-opacity-50"
          onClick={handleLastWeek}
        >
          Minggu Lalu
        </button>
        <button
          className="px-4 py-2 text-white bg-red-500 rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
          onClick={handleLastMonth}
        >
          Bulan Lalu
        </button>
      </div>
      <div className="flex gap-4">
        <label className="flex flex-col gap-1">
          Mulai Tanggal:
          <input
            className="px-2 py-1 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            type="date"
            value={formatDate(startDate)}
            onChange={(e) => handleCustomRange(e, "start")}
          />
        </label>
        <label className="flex flex-col gap-1">
          Sampai Tanggal:
          <input
            className="px-2 py-1 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
            type="date"
            value={formatDate(endDate)}
            onChange={(e) => handleCustomRange(e, "end")}
          />
        </label>
      </div>
    </div>
  )
}

export default DateRange
