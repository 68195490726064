import clsx from "clsx"
import React from "react"
import classes from "../../../assets/styles/ui/Input.module.css"

const TextAreaInput: React.FC<{
  label: string
  name: string
  inputClass: string
  labelClass: string
  value: string
  isRequired?: boolean
  margin?: string
  cols: number
  rows: number
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  changeHandler?: React.ChangeEventHandler<HTMLTextAreaElement> | undefined
  blurHandler?: React.FocusEventHandler<HTMLTextAreaElement> | undefined
  hooks?: any
  validationMessage?: React.ReactNode
}> = (props) => {
  return (
    <>
      <div className={props.margin}>
        <label
          htmlFor={`${props.name}_id`}
          className={clsx(props.labelClass, props.isRequired && classes.required)}
        >
          {props.label}
        </label>
        <textarea
          className={clsx(props.inputClass, props.validationMessage && "mb-2")}
          {...{ required: props.isRequired }}
          defaultValue={props.value}
          onChange={props.changeHandler || props.onChange}
          onBlur={props.changeHandler}
          name={props.name}
          id={`${props.name}_id`}
          cols={props.cols}
          rows={props.rows}
          {...props.hooks}
        />
        {props.validationMessage}
      </div>
    </>
  )
}

export default TextAreaInput
