import { useQuery } from "@tanstack/react-query"

import { getCareerCategories } from "../functions"
import { CmsQueryKey, GetCareerCategoriesParams } from "../../types"

const useGetCareerCategories = (params?: GetCareerCategoriesParams) => {
  return useQuery({
    queryKey: [CmsQueryKey.CareerCategories, params?.qParams ?? ""],
    queryFn: () => getCareerCategories(params?.qParams),
    ...params?.options,
  })
}

export default useGetCareerCategories
