import { requestUser } from "utils/axiosInstance"
import { PublicGetCourseRoadmapResponse } from "../../types"

const getCourseRoadmap = async (courseID: string) => {
  const res = await requestUser.get<PublicGetCourseRoadmapResponse>(
    `/courses/${courseID}/roads`
  )
  return res
}

export default getCourseRoadmap
