import { useEffect, useMemo, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { toast } from "react-toastify"
import { useNavigate, useParams } from "react-router-dom"
import ButtonWithIcon from "components/ui/button/ButtonWithIcon"
import TextInput from "components/ui/form/TextInput"
import { GeneralCourseCategoryRequest } from "utils/api/cms/types"
import {
  BookmarkIcon,
  ExclamationTriangleIcon,
} from "@heroicons/react/24/solid"
import { useMutationUpdateCourseCategory } from "utils/api/cms/mutations/hooks"
import BasicValidationMessage from "components/ui/validation/BasicValidationMessage"
import TextAreaInput from "components/ui/form/TextAreaInput"
import clsx from "clsx"
import { useGetCourseCategoryDetail } from "utils/api/cms/queries/hooks"

interface CourseCategoryForm {
  name: string
  description: string
}

const CourseCategoryForm = () => {
  const navigate = useNavigate()
  const params = useParams()

  const {
    handleSubmit,
    register,
    control,
    watch,
    getValues,
    setValue,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<CourseCategoryForm>({})

  const { data: dataDetail } = useGetCourseCategoryDetail({
    id: params.id ?? "",
  })

  const { mutate: mutateUpdateCourseCategory } =
    useMutationUpdateCourseCategory({
      onSuccess(data) {
        if (!data.success) {
          return
        }
        toast.success("Kategori Pembelajaran berhasil diubah")
        setTimeout(() => {
          navigate("/cms/master/course-category")
        }, 1000)
      },
      onError(error) {
        toast.error(error.message)
      },
    })

  useEffect(() => {
    if (!dataDetail) return

    const CourseCategoryDetail = dataDetail.data

    reset({
      name: CourseCategoryDetail.name,
      description: CourseCategoryDetail.description,
    })
  }, [dataDetail])

  const onSubmit: SubmitHandler<CourseCategoryForm> = (data) => {
    const payload: GeneralCourseCategoryRequest = {
      name: data.name,
      description: data.description,
    }

    mutateUpdateCourseCategory({
      id: params.id ?? "",
      payload: payload,
    })
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-4 pb-4 min-w-[42rem]"
    >
      <TextInput
        type="text"
        className="cms-base-input"
        labelClass="cms-base-label"
        isRequired
        label="Nama kategori pembelajaran"
        errorIcon={
          <ExclamationTriangleIcon
            height={24}
            width={24}
            className="text-red-800"
          />
        }
        {...register("name", {
          required: {
            value: true,
            message: "Nama kategori pembelajaran wajib diisi",
          },
        })}
      />
      <TextAreaInput
        cols={10}
        rows={12}
        inputClass={clsx(
          "min-w-[42rem]",
          errors.name && "cms-base-error-input",
          !errors.name && "cms-base-input"
        )}
        labelClass="cms-base-label"
        label="Deskripsi Kategori Pembelajaran"
        isRequired
        name="description"
        value=""
        hooks={{
          ...register("description", {
            required: "Deskripsi kategori tidak boleh kosong!",
            minLength: {
              message:
                "Deskripsi kategori harus memiliki sedikitnya 9 karakter!",
              value: 9,
            },
          }),
        }}
        validationMessage={
          errors.description && (
            <BasicValidationMessage
              type="error"
              message={errors.description.message as string}
              icon={
                <ExclamationTriangleIcon className="h-6 w-6 text-red-800" />
              }
            />
          )
        }
      />
      <ButtonWithIcon
        action={undefined}
        type="submit"
        class="self-start mt-12 border-2 border-transparent bg-primary text-white space-x-2 hover:bg-transparent hover:text-white hover:border-primary"
        disabled={isSubmitting}
      >
        <BookmarkIcon className="h-6 w-6 text-white" />
        <span>{!isSubmitting ? "Simpan" : "Menyimpan..."}</span>
      </ButtonWithIcon>
    </form>
  )
}

export default CourseCategoryForm
