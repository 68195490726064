import React from "react"

/** Components */

import HomeFaqArea from "components/containers/home-faq-area/HomeFaqArea"
import LatestWorkshop from "components/public/home/latest-workshop"
import LatestCourse from "components/public/home/latest-course"
import HeaderHome from "components/public/home/header-home"
import Testimoni from "components/public/home/testimoni"
import RegisterNow from "components/public/home/register-now"

const HomePage: React.FC = () => {
  return (
    <>
      <HeaderHome />
      <LatestCourse />
      <Testimoni />
      <LatestWorkshop />
      <HomeFaqArea />
      <RegisterNow />
    </>
  )
}

export default HomePage
