import { UploadThumbnailResponse, UploadThumbnail } from "utils/api/cms/types"
import { requestCMS } from "utils/axiosInstance"

const uploadSertifikat = async (
    sertifikatId: string,
    payload: UploadThumbnail
) => {
    const res = await requestCMS.upload<UploadThumbnailResponse>(
        `/employee/sertifikat/upload/${sertifikatId}`,
        payload,
        {
            headers: {
                "Content-Type": "multipart/form-data",
            },
        }
    )
    return res
}

export default uploadSertifikat
